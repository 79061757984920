import { DatasetDTO, DatasetFiledDTO } from "../models/Datasets";

export const getDatasetField = (
  key: string,
  datasets: DatasetDTO[]
): DatasetFiledDTO | undefined => {
  if (!key || !datasets?.[0]?.fields?.length) {
    return undefined;
  }

  return datasets?.[0]?.fields?.find(
    (field: DatasetFiledDTO) => field.key === key
  );
};
