import styled from "styled-components";

export const ChatPanelWrapper = styled.div<{ $isFocused: boolean }>`
  position: relative;
  width: 100%;
  background: #fff;
  padding: 8px;
  padding-top: ${({ $isFocused }) => ($isFocused ? "0px" : "8px")};
  border: ${({ $isFocused }) =>
    $isFocused ? "1px solid #454BDF" : "1px solid #DFE1E4"};
  border-radius: 24px;
  margin: 24px 0;
  box-shadow: 0px 2px 2px rgba(34, 36, 44, 0.06),
    0px 2px 4px 1px rgba(34, 36, 44, 0.04);
  display: grid;
  row-gap: 8px;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: ${({ $isFocused }) =>
    $isFocused
      ? `
        "files files files"
        "input input input"
        "prompts . actions"
        `
      : `
        "prompts input actions"
        `};
`;

export const ChatPanelPromptsButtonWrapper = styled.div`
  grid-area: prompts;
  margin-right: 8px;
`;

export const ChatPanelInput = styled.textarea`
  grid-area: input;
  resize: none;
  padding-left: 8px;
  padding-top: 8px;
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  border: none;
  outline: none;
  overflow: hidden;
  box-sizing: border-box;
  &:focus {
    border: none;
    outline: none;
  }
`;

export const ChatPanelActions = styled.div`
  grid-area: actions;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 8px;
`;

export const ChatPanelFileList = styled.div`
  grid-area: files;
`;

export const ChatPanelSendButtonWrapper = styled.div`
  button {
    padding: 0;
    background: none;
    &:hover {
      background: none;
    }
    svg {
      height: auto;
      width: auto;
      fill: revert-layer;
      path {
        fill: revert-layer;
      }
    }
  }
`;

export const ChatPanelDropOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: #6f6fe91f;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 1px solid #454bdf;
  color: ${({ theme }) => theme.primaryText};
  fill: ${({ theme }) => theme.primaryText};
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
`;
