import { useEffect, useMemo, useRef, useState } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  Title,
} from "./styles";
import { setActiveModal } from "../../../store/slices/modals";
import { getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { hexToRGBA } from "../../../helpers/hexToRgba";
import { ticksFormatter } from "../../../helpers/ticksFormatter";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleBandTickValues,
} from "../widgetHelpers";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { createPortal } from "react-dom";
import { getActiveModal } from "../../../store/selectors/modals";
import { TickLabel } from "../components/LabelTooltip";
import { useDatasetField } from "../../../hooks/useDatasetField";
import { formatLabelValue } from "../utils/formatLabelValue";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";
import { FeedBackButton, WidgetImageWrapper } from "../styles";

interface VerticalBarchartInterface {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  hideSettings?: boolean;
}

export const VerticalBarchart = ({
  storytelling,
  recommended,
  showLegend = true,
  selected = false,
  currentWidget,
  hideName = false,
  hideSettings = false,
}: VerticalBarchartInterface) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const [ref, bounds] = useMeasure();

  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [feedbackState, setFeedbackState] = useState<boolean>(false);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const margin = { top: 10, right: 0, bottom: 25, left: 40 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const minBarWidth = 4;
  const maxBarWidth = 24;

  const barColor = getCurrentColor(currentWidget, "default", styleId);

  const barChartSuggestion = aiSuggestions
    ?.filter((chart: AiSuggestionsDto) => chart.chartType === "barChart")
    ?.at(0);

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : barChartSuggestion?.xAxe?.at(0);
  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : barChartSuggestion?.yAxe?.at(0);

  const barchartData = currentWidget?.data;

  const xScaleDatasetField = useDatasetField(xAxe);

  const sortedData = barchartData?.map((l) => ({
    x: xAxe ? l[xAxe] : l.year,
    y: yAxe ? l[yAxe] : l.value,
  }));

  const xAxes: string[] = useMemo(
    () =>
      currentWidget?.uniqueValues?.[xAxe] ||
      sortedData?.map((d: any) => d.x) ||
      [],
    [currentWidget?.uniqueValues, sortedData, xAxe]
  );

  const yAxes = useMemo(() => {
    return (
      sortedData?.reduce((t: any, l: any) => {
        const y = parseFloat(l.y);
        if (typeof y === "number" && !isNaN(y)) {
          return [...t, y];
        }
        return t;
      }, []) || []
    );
  }, [sortedData]);

  const xScale = scaleBand({
    domain: xAxes,
    range: [margin.left, width - margin.right],
    padding: 0,
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...yAxes)],
    range: [height - margin.bottom, margin.top],
    nice: true,
  });

  const yScaleNumTicks = calculateNumTicks({ height });

  const yTicks = yScale.ticks(yScaleNumTicks);

  const xScaleTickValues = useMemo(() => {
    const xScaleNumTicksCalculated = calculateNumTicks({ width });

    const isReasonableAmountOfTicks =
      xScaleNumTicksCalculated <= xAxes.length &&
      xScaleNumTicksCalculated > 0 &&
      xAxes.length / xScaleNumTicksCalculated >= 1.5;

    const xScaleNumTicks = isReasonableAmountOfTicks
      ? xScaleNumTicksCalculated
      : xAxes.length;

    return getScaleBandTickValues({
      tickCount: xScaleNumTicks,
      ticks: xAxes,
    });
  }, [width, xAxes]);

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: (value) =>
          formatLabelValue(
            value,
            xScaleDatasetField?.type,
            xScaleDatasetField?.subtype
          ),
      }),
    [
      margin.left,
      margin.right,
      width,
      xScaleDatasetField?.subtype,
      xScaleDatasetField?.type,
      xScaleTickValues,
    ]
  );

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  if (!barchartData || !barchartData.length) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        {!storytelling && (
          <HeaderWrapper>
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <div />}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {availableWidgetsCount}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          </HeaderWrapper>
        )}
        <svg height={"100%"} width={"100%"} ref={ref}>
          <AxisBottom
            scale={xScale}
            top={height - margin.bottom}
            left={0}
            labelOffset={20}
            hideTicks
            axisLineClassName="barchartAxisLine"
            tickLabelProps={(_: any, index: number, values: any) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;

              if (!isFirstTick && !isLastTick) {
                return {
                  fontSize: 11,
                  fill: "#5F6877",
                  textAnchor: "middle",
                };
              }

              const charSpace = 8;
              const bandWidth = xScale.bandwidth();
              const labelText = values[index].formattedValue;

              const isLabelTooLong = labelText.length > bandWidth / charSpace;

              const anchorPosition =
                (isLabelTooLong &&
                  ((isFirstTick && "start") || (isLastTick && "end"))) ||
                "middle";

              const tickPositionOffset =
                (isLabelTooLong &&
                  ((isFirstTick && -bandWidth / 2) ||
                    (isLastTick && bandWidth / 2))) ||
                0;

              return {
                fontSize: 11,
                fill: "#5F6877",
                textAnchor: anchorPosition,
                dx: tickPositionOffset,
              };
            }}
            tickValues={xScaleTickValues}
            tickComponent={(props: any) => (
              <TickLabel
                {...props}
                labelText={formatLabelValue(
                  props.formattedValue,
                  xScaleDatasetField?.type,
                  xScaleDatasetField?.subtype
                )}
                length={xScaleTickLabelMaxLength}
                offsetX={-10}
              />
            )}
          />

          <line
            x1={width - margin.right}
            y1={margin.top}
            x2={width - margin.right}
            y2={height - margin.bottom}
            stroke="#ccc"
            strokeDasharray="1, 2"
          />
          {xAxes.map((tickValue, index) => (
            <line
              key={`xLine-${index}`}
              x1={xScale(tickValue)! + xScale.bandwidth()}
              y1={margin.top}
              x2={xScale(tickValue)! + xScale.bandwidth()}
              y2={height - margin.bottom}
              stroke="#ccc"
              strokeDasharray="1 2"
            />
          ))}
          {yTicks.map(
            (tickValue, index) =>
              tickValue !== yTicks[0] && (
                <line
                  key={`yLine-${index}`}
                  x1={margin.left}
                  y1={yScale(tickValue)}
                  x2={width - margin.right}
                  y2={yScale(tickValue)}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              )
          )}
          <AxisLeft
            scale={yScale}
            top={0}
            stroke="#ccc"
            strokeDasharray="1, 2"
            left={margin.left}
            numTicks={yScaleNumTicks}
            tickLineProps={{
              stroke: "#939BA7",
            }}
            tickLabelProps={(_, index) => ({
              dx: -30,
              fontSize: 11,
              fill: "#5F6877",
              dy: index === 0 ? 0 : index === yScaleNumTicks! - 1 ? 7 : 4,
              textAnchor: "start",
            })}
            tickFormat={(value: any) => {
              return ticksFormatter(value);
            }}
          />

          <Group>
            {sortedData?.map((d, i) => {
              const bandWidth = xScale.bandwidth();
              const barWidth = Math.min(
                Math.max(bandWidth, minBarWidth),
                maxBarWidth
              );

              const barX = xScale(d.x)! + bandWidth / 2;
              const barY = yScale(parseInt(d.y));

              const key = "bar-" + i;

              return (
                <Bar
                  key={key}
                  style={{ transition: "0.3s" }}
                  x={barX - 16}
                  y={barY}
                  width={barWidth}
                  height={height - margin.bottom - barY}
                  fill={barColor ? hexToRGBA(barColor, 0.8) : ""}
                  onMouseMove={(event: any) => {
                    if (
                      (showTooltip || currentWidget.tooltip) &&
                      !recommended
                    ) {
                      const { pageX, pageY, clientX, clientY } = event;
                      const coords = { pageX, pageY, clientX, clientY };

                      setHoveredElement(key);
                      setTooltip({
                        data: {
                          [xAxe as string]: d.x,
                          [yAxe as string]: d.y,
                        },
                        coords,
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    setTooltip(null);
                    setHoveredElement(null);
                  }}
                  opacity={
                    hoveredElement ? (hoveredElement === key ? 1 : 0.2) : 1
                  }
                />
              );
            })}
          </Group>
        </svg>
        {tooltip &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              coords={tooltip.coords}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
