import { MarkersVisualisationDataDto } from "../../../../models/Widgets";

export const getRegionId = (
  data: MarkersVisualisationDataDto,
  mapTypeGeoJson?: GeoJSON.GeoJsonProperties,
  mapType?: string
) => {
  if (!data || !mapTypeGeoJson || !mapType) {
    return undefined;
  }

  switch (mapType) {
    case "county":
      return mapTypeGeoJson.features.find(
        (el: { properties: { COUNTY_STATE_NAME: string } }) =>
          el?.properties?.COUNTY_STATE_NAME?.toLocaleLowerCase() ===
          `${data?.county}, ${data?.state}`.toLocaleLowerCase()
      )?.properties?.GEOID;

    case "state":
      return mapTypeGeoJson.features.find(
        (el: { properties: { NAME: string } }) =>
          el?.properties?.NAME?.toLocaleLowerCase() ===
          data.state?.toLocaleLowerCase()
      )?.properties?.STUSPS;

    case "country":
      return mapTypeGeoJson.features.find(
        (el: { properties: { NAME: string } }) =>
          el?.properties?.NAME?.toLocaleLowerCase() ===
          data.country?.toLocaleLowerCase()
      )?.properties?.ISO_A3;

    default:
      return undefined;
  }
};
