import { bbox } from "turf";

export const calculateFeatureCenter = (feature: GeoJSON.Feature) => {
  if (!feature) {
    return {};
  }

  const [minLng, minLat, maxLng, maxLat] = bbox(feature);

  const centerLng = (minLng + maxLng) / 2;
  const centerLat = (minLat + maxLat) / 2;

  return {
    centerLng,
    centerLat,
    minLng,
    minLat,
    maxLng,
    maxLat,
  };
};
