import { styleTypes } from "../../../constants/types/types";
import { getQualitativePalette } from "../../../constants/utils/getQualitativePalette";
import { WidgetItem } from "../../../models/Widgets";
import { IMarkers } from "../../MapSettings/types/types";

export const getCurrentMarker = (
  widget: WidgetItem,
  key: any,
  defaultMarker?: IMarkers
) => {
  const markers = widget?.markers ?? [];

  if (!markers.length) {
    return defaultMarker || "donut";
  }
  if (typeof markers === "string") {
    return defaultMarker || "donut";
  }
  const foundMarker = markers?.find((item) => item.key === key);

  return foundMarker?.shape ?? (defaultMarker || "donut");
};

export const getCurrentColor = (
  widget: WidgetItem,
  key: any,
  styleId: string
) => {
  const colors = widget?.formatting ?? [];

  if (!colors || !colors.length) return "blue";


  const foundColor = Number(
    colors.find((item) => item.key === key)?.color ?? 1
  );

  const currentPalette = getQualitativePalette(
    styleId as styleTypes,
    colors.length
  );

  if (Array.isArray(currentPalette)) {
    return (
      currentPalette
        ?.find(
          (item) => item?.id === (widget?.palette?.paletteId ?? "Qualitative2Colors1")
        )
        ?.colors?.find((item) => item?.key === String(foundColor))?.hex ??
      "#ff0000"
    );
  } else {
    return (
      currentPalette.colors.find((item) => item.key === String(foundColor))
        ?.hex ?? "#ff0000"
    );
  }
};
