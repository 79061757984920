import { ColorRangeI } from "../../../../models/Pages";
import { MarkersVisualisationDataDto } from "../../../../models/Widgets";

export function getLayerColor(
  colorRanges: ColorRangeI[],
  region: MarkersVisualisationDataDto,
  valueProperty: keyof MarkersVisualisationDataDto
) {
  const lastItem = colorRanges[colorRanges?.length - 1].start;
  const regionValue =
    valueProperty && region[valueProperty]
      ? parseInt(region[valueProperty] as string)
      : 0;

  if (regionValue === 0) {
    return colorRanges[0].color;
  } else if (regionValue > lastItem) {
    return colorRanges[colorRanges?.length - 1].color;
  } else {
    const localColor = colorRanges.find((c: ColorRangeI, i: number) => {
      const nextItem =
        i === colorRanges?.length - 1 ? colorRanges?.length - 1 : i + 1;
      return (
        regionValue &&
        regionValue >= c.start &&
        regionValue < colorRanges[nextItem].start
      );
    });
    return localColor ? localColor?.color : colorRanges[0].color;
  }
}
