import React, { useState } from "react";
import {
  CloseIcon,
  ModalFooterButtons,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";

import {
  ConfigureDataset,
  OverviewContentWrapper,
  MainWrapper,
  ConfigureDatasetRow,
  SelectTabWrapper,
  TabElement,
  ContentWrapper,
} from "./styles";
import { Button } from "../../Button";
import { CheckCircleFill, CodeSlash } from "react-bootstrap-icons";
import { Formik } from "formik";
import { connectApiValidationSchema } from "./utils/validationSchema";
import {
  ConnectApiModalTabs,
  defaultDataRow,
  initialValues,
} from "./data/mock";
import { FormConnectAPIGroup } from "./components/FormGroup";
import ConnectLoader, { ConnectLoaderStatus } from "../../ConnectLoader";
import SelectMethod from "../../Inputs/CustomSwitchSelectMethod/Switch";
import { ITabElement, KeyRow } from "./types/types";

type Props = {
  onClose: () => void;
};

const ConnectApiModal = ({ onClose }: Props) => {
  const [isClosing, setIsClosing] = useState(false);

  const [status, setStatus] = useState<ConnectLoaderStatus>("idle");
  


  const [activeTab, setActiveTab] = useState<ITabElement>(
    ConnectApiModalTabs[0]
  );

  const [parameters, setParameters] = useState<KeyRow[]>([defaultDataRow]);
  const [headers, setHeaders] = useState<KeyRow[]>([defaultDataRow]);

  const handleConnect = () => {
    setStatus("loading");

    setTimeout(() => setStatus("success"), 3000);
  };

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 400);
  };
  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalHeading>Connect API</ModalHeading>
          <CloseIcon onClick={handleOnClose} />
        </ModalHeadingWrapper>

        <Formik
          initialValues={initialValues}
          validationSchema={connectApiValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={() => {
            // to do
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <>
                <OverviewContent>
                  {status !== "idle" ? (
                    <ConnectLoader
                      status={status}
                      onLoadingText={[
                        "Establishing a secure connection and retrieving data.",
                        "Please wait...",
                      ]}
                      succefullLoadingText={[
                        "Connection has been established successfully.",
                      ]}
                    />
                  ) : (
                    <OverviewContentWrapper>
                      <MainWrapper>
                        <FormConnectAPIGroup
                          label="Dataset Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          placeholder={"Enter URL or paste text"}
                          type={"text"}
                          name={"url"}
                          values={values.datasetName}
                          id={"url"}
                          required
                          errors={errors["url"]}
                        />
                        <ConfigureDataset>Configure Dataset</ConfigureDataset>

                        <ConfigureDatasetRow>
                          <SelectMethod
                            onChange={() => {}}
                            options={["get", "post"]}
                          />

                          <FormConnectAPIGroup
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            placeholder={"Enter URL or paste text"}
                            type={"text"}
                            name={"url"}
                            values={values.url}
                            id={"url"}
                            errors={errors["url"]}
                          />
                          <Button
                            onClick={() => {}}
                            name="Test Connection"
                            variant={"primary"}
                            size={"large"}
                            icon={<CodeSlash />}
                            fullWidth
                            noTextWrap
                          />
                        </ConfigureDatasetRow>

                        <SelectTabWrapper>
                          {ConnectApiModalTabs.map((item) => (
                            <TabElement
                              $selected={item.name === activeTab.name}
                              onClick={() => setActiveTab(item)}
                            >
                              {item.name}
                            </TabElement>
                          ))}
                        </SelectTabWrapper>
                      </MainWrapper>
                      <ContentWrapper>
                        {activeTab.element({
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                          setRows:
                            activeTab.name === "parameters"
                              ? setParameters
                              : setHeaders,
                          rows:
                            activeTab.name === "parameters"
                              ? parameters
                              : headers,
                        })}
                      </ContentWrapper>
                    </OverviewContentWrapper>
                  )}
                </OverviewContent>

                <ModalFooterWrapper>
                  <Button
                    name="Cancel"
                    onClick={handleOnClose}
                    variant="neutral"
                    size="medium"
                  />
                  <ModalFooterButtons>
                    <Button
                      name={status !== "success" ? "Connect" : "Done"}
                      onClick={handleConnect}
                      type="submit"
                      variant={status !== "loading" ? "secondary" : "disabled"}
                      size="medium"
                      disabled={status !== "loading"}
                      icon={<CheckCircleFill />}
                    />
                  </ModalFooterButtons>
                </ModalFooterWrapper>
              </>
            );
          }}
        </Formik>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default ConnectApiModal;
