import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ModalOverlay, ModalWrapper } from "../styles";
import { getModalData } from "../../../store/selectors/modals";
import {
  requestExploreData,
  setExploreData,
} from "../../../store/slices/widgets";
import { getPageId } from "../../../store/selectors/projects";
import { getExploreData } from "../../../store/selectors/widgets";
import {
  CloseModal,
  Divider,
  ExploreModalContent,
  ExploreModalHeader,
  ExploreModalHeaderSubTitle,
  ExploreModalHeaderTitle,
  ExploreModalMap,
  ExploreModalScroll,
  ExploreModalWidgetWrapper,
} from "./styles";
import { UniversalMap } from "../../Widgets/MapBox";
import { WIDGETS } from "../../Widgets/widgets";
import { KPISettingsWrapper } from "../../KPISettingsWrapper";

interface ModalProps {
  closeModal: () => void;
}

export const ExploreMap = ({ closeModal }: ModalProps) => {
  const dispatch = useDispatch();

  const regionData = useSelector(getModalData);
  const pageId = useSelector(getPageId);
  const exploreData = useSelector(getExploreData);

  const [isClosing, setIsClosing] = useState(false);

  const regionName = useMemo(
    () => regionData?.regionName,
    [regionData?.regionName]
  );
  const mapWidget = useMemo(
    () =>
      exploreData?.items?.length &&
      exploreData?.items?.find(
        (widget: any) => widget.chartType === "mapChart"
      ),
    [exploreData?.items]
  );
  const widgets = useMemo(
    () =>
      exploreData?.items?.length &&
      exploreData?.items?.filter(
        (widget: any) => widget.chartType !== "mapChart"
      ),
    [exploreData?.items]
  );
  const kpis = useMemo(
    () =>
      exploreData?.kpis?.length && exploreData?.kpis?.filter((kpi: any) => kpi),
    [exploreData?.kpis]
  );

  useEffect(() => {
    if (pageId && regionName) {
      dispatch(
        requestExploreData({
          pageId: pageId,
          region: regionName,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionName, pageId]);

  const handleOnClose = () => {
    dispatch(setExploreData(undefined));
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
    }, 400);
  };

  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
      <ModalWrapper
        $exploreMap
        onClick={(e) => e.stopPropagation()}
        $isClosing={isClosing}
      >
        <ExploreModalHeader>
          <ExploreModalHeaderTitle>
            {regionData.regionId}
          </ExploreModalHeaderTitle>

          <ExploreModalHeaderSubTitle>
            {regionData.title}
          </ExploreModalHeaderSubTitle>

          <CloseModal onClick={handleOnClose} />
        </ExploreModalHeader>

        {mapWidget && (
          <ExploreModalMap>
            <UniversalMap
              explore
              exploreLat={regionData.latitude}
              exploreLng={regionData.longitude}
              exploreYear={regionData.year}
              exploreRegion={regionData.regionName}
              exploreRegionId={regionData.regionId}
              currentWidget={mapWidget}
            />
          </ExploreModalMap>
        )}

        <ExploreModalContent>
          <ExploreModalScroll>
            {kpis && kpis.length ? (
              <>
                <ExploreModalWidgetWrapper>
                  <KPISettingsWrapper
                    kpis={kpis}
                    recommended={false}
                    storytelling={false}
                  />
                </ExploreModalWidgetWrapper>

                <Divider />
              </>
            ) : (
              ""
            )}

            {widgets && widgets.length
              ? widgets.map((item: any, i: number) => {
                  const ChartComponent = item && WIDGETS[item.chartType];
                  return (
                    <>
                      <ExploreModalWidgetWrapper key={item.id + i + "explore"}>
                        <ChartComponent
                          currentWidget={item}
                          showLegend={false}
                          recommended={false}
                          hideSettings
                        />
                      </ExploreModalWidgetWrapper>

                      {i < widgets.length - 1 ? <Divider /> : ""}
                    </>
                  );
                })
              : ""}
          </ExploreModalScroll>
        </ExploreModalContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};
