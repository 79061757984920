import { Dot } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const DatasetPreviewPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 0 ;
`;

export const DatasetPreviewPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`


export const APPClasses = styled.div`
  display: flex;
  gap: 8px;
`

export const APPClass = styled.div<{$selected?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #DFE1E4;
  font-size: 14px;
  user-select: none;
  font-weight: 400;
  background-color: #EFF0F1;
  cursor: pointer;
  gap: 8px;
  transition: all 0.3s ease;

  svg{
    color: #8F95A0;
  }

  &:hover{
    background-color: #DFE1E4;
  }

  &:active{
    background-color: #CFD2D6;
  }

  ${({$selected}) => $selected && css`
    background-color: #6F6FE93D !important;
    border: 1px solid #7D82F8 !important;

    svg {
      color: #454BDF !important;
    }
  `}

`;

export const TemporarBackButton = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #5D63F6;  
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-right: auto;
`

export const DatasetPreviewPageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`

export const DatasetPreviewPageHeaderTitle = styled.div`
  font-size: 20px;
  display: flex;
  gap:8px;
  font-weight: 600;
  line-height: 28px;
  vertical-align: middle;
  align-items: center;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
`

export const FileFormat = styled.div`
  display: flex;
  color: #EFF0F1;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 20px;
  padding: 0px 4px;
  border-radius: 32px;
  background: #21262E;
  gap: 8px;
  user-select: none;
`

export const DatasetPreviewPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`
export const SearchInputWrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 320px;
  max-width: 720px;
`

export const Img = styled.img`
  margin-right: auto;
  max-height: 64px;
`
export const DatasetPreviewPageCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  width: 100%;
  max-width: 100vw;
  padding: 16px;
  box-sizing: border-box;
`;

export const DatasetPreviewPageCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 12px;
  height: 100%;
  width: 100%;
`;

export const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
`
export const SeparatorLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const CardTopTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.neutralText};
`

export const CardTopSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralTextWeak};
  height: 48px;
`

export const CardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
`

export const CardBottomTextWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const CardBottomText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralTextWeak};
`

export const CardBottomDot = styled(Dot)`
  color: ${({ theme }) => theme.neutralTextWeak};
`

export const CardBottomTextInfo = styled.div`
  display: flex;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralTextWeak};
`
