import mapboxgl from "mapbox-gl";
import { Col, Row } from "react-flexbox-grid";
import styled, { css } from "styled-components";
import { mapConfig } from "./config";

import { Barchart } from "../../Widgets/Barchart";
import { AreaChart } from "../../Widgets/AreaChart";
import { WidgetItem } from "../../../models/Widgets";
import { KPI } from "../../KPI";
import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { areaChart } from "./constants/areaChart";
import { barChart } from "./constants/barChart";
import { Popup } from "./Popup/Popup";
import { PopupWrapper } from "./Popup/PopupComponents";

// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken =
  "pk.eyJ1IjoiYW5keWsxOTg3IiwiYSI6ImNqeHJtdGJjNTA5bWwzbW1mcXA0cTZuMmkifQ.qAhmQzT0m6-KuvZt7-C83A";

export const WidgetWrapper = styled.div<{
  $storytelling?: boolean;
  $map?: boolean;
  $selected?: boolean;
  $recommended?: boolean;
}>(
  ({ theme, $storytelling, $map, $selected, $recommended }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${$map ? "" : $storytelling ? "" : "16px"};
    border: ${$selected && !$recommended
      ? `1px solid transparent`
      : !$storytelling && !$selected
      ? `1px solid ${theme.neutralBorder}`
      : `1px solid transparent`};
    border-radius: 8px;
    height: ${$storytelling ? "360px" : "100%"};
    width: 100%;
    min-width: 300px;
    min-height: 120px;
    background: ${$selected && $recommended
      ? theme.primaryBackgroundPublish
      : ""};
  `
);

const Container = styled.div`
  display: flex;
  z-index: 10;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  height: max-content;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Button = styled.div<{ $active?: boolean }>(
  ({ $active }) => css`
    padding: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.15s ease;
    color: #000;
    ${$active &&
    css`
      fill: white !important;
      background-color: #473dd9;
      color: #fff;
    `}
  `
);

export const Layout5 = ({ pageWidgets }: any) => {
  const mapContainer = useRef<any>();
  const ref = useRef<any>();
  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [disableBuildings, setDisableBuildings] = useState<boolean>(false);

  useOutsideClick(ref, () => {
    setShowPopup(false);
  });

  useEffect(() => {
    if (!map) setMap(new mapboxgl.Map(mapConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map || !map.isStyleLoaded()) return;

    const visibility = map.getLayoutProperty("building (1)", "visibility");
    setDisableBuildings(!visibility);

    map.on("click", (event) => {
      const features = map.queryRenderedFeatures(event.point, {
        layers: ["building (1)"],
      });

      if (!features.length) return;
      if (!showPopup) setShowPopup(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (!map || !map.isStyleLoaded()) return;

    map.setLayoutProperty(
      "building (1)",
      "visibility",
      disableBuildings ? "none" : "visible"
    );
  }, [disableBuildings, map]);

  return (
    <div style={{ height: "calc(100vh - 136px)" }}>
      <Row style={{ height: "100%" }}>
        <Col
          lg={3}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: "100%",
          }}
        >
          <Row>
            <Col lg={12}>
              <WidgetWrapper $map={true}>
                <KPI />
              </WidgetWrapper>
            </Col>
          </Row>
          <Row style={{ height: "calc(100% - 8px)" }}>
            <Col lg={12}>
              <WidgetWrapper>
                <AreaChart currentWidget={areaChart as any as WidgetItem} />
              </WidgetWrapper>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <WidgetWrapper>
                <Barchart currentWidget={barChart as any as WidgetItem} />
              </WidgetWrapper>
            </Col>
          </Row>
        </Col>
        <Col lg={9}>
          <Row
            style={{
              border: "1px #D3DBE3 solid",
              position: "relative",
              height: "calc(100vh - 136px)",
              marginLeft: "0px",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <div
              id="map"
              ref={mapContainer}
              style={{ width: "100%", height: "calc(100vh - 136px)" }}
            />
            <Container>
              <Button
                $active={disableBuildings}
                onClick={() => setDisableBuildings(true)}
              >
                2D
              </Button>
              <Button
                $active={!disableBuildings}
                onClick={() => setDisableBuildings(false)}
              >
                3D
              </Button>
            </Container>
            {showPopup && (
              <PopupWrapper ref={ref}>
                <Popup />
              </PopupWrapper>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
