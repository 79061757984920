import React from "react";
import {
  Application,
  ApplicationContent,
  ApplicationDescription,
  ApplicationImage,
  ApplicationName,
  ButtonWrapper,
  Count,
  ImageBlock,
  ImageWrapper,
  TextWrapper,
} from "./styles";
import { Button } from "../Button";
import { IApplication } from "../../store/slices/dataProviders";
import MockImage from "../../assets/clock.png";

type Props = {
  card?: boolean;
  active?: boolean;
  application: IApplication;
  onConnect?: () => void;
};

const DeviceCard: React.FC<Props> = ({
  card = false,
  active = false,
  application,
  onConnect = () => {},
}) => {
  return (
    <Application key={application.name} $active={active} $card={card}>
      <ImageBlock>
        <ImageWrapper>
          {active && <Count>1</Count>}
          <ApplicationImage src={MockImage} alt="Application" />
        </ImageWrapper>
        {card && (
          <ButtonWrapper>
            <Button
              onClick={onConnect}
              variant={active ? "danger" : "secondary"}
              size="small"
              name={active ? "Disconnect" : "Connect"}
            />
          </ButtonWrapper>
        )}
      </ImageBlock>

      <ApplicationContent>
        <TextWrapper>
          <ApplicationName>{application.name}</ApplicationName>
          <ApplicationDescription>
            {application.description}
          </ApplicationDescription>
        </TextWrapper>
        {!card && (
          <ButtonWrapper>
            <Button
              onClick={onConnect}
              variant={active ? "danger" : "secondary"}
              size="small"
              name={active ? "Disconnect" : "Connect"}
            />
          </ButtonWrapper>
        )}
      </ApplicationContent>
    </Application>
  );
};

export default DeviceCard;
