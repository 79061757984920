import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { createPortal } from "react-dom";
import {
  axisBottom,
  axisLeft,
  NumberValue,
  scaleBand,
  scaleLinear,
  scaleOrdinal,
  select,
  symbol,
  symbolCircle,
  symbolCross,
  symbolSquare,
  symbolTriangle,
} from "d3";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title,
} from "./styles";
import {
  FeedBackButton,
  HeadingNameAndButton,
  WidgetImageWrapper,
} from "../styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { getActiveModal } from "../../../store/selectors/modals";

import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleBandTickValues,
} from "../widgetHelpers";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { useDatasetField } from "../../../hooks/useDatasetField";
import { formatLabelValue } from "../utils/formatLabelValue";
import { LabelTooltip, LabelTooltipProps } from "../components/LabelTooltip";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";

export interface ScatterPlotProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  preview?: boolean;
}

const yAxisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

const xAxisTickFormatter = (
  value: string,
  length: number = Infinity
): string => {
  const splitValue = String(value)?.split("");

  return `${splitValue?.slice(0, length).join("")}${
    splitValue?.length < length ? "" : "..."
  }`;
};

export const ScatterPlot = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
  preview = false,
}: ScatterPlotProps) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const svgRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure({ scroll: true });
  const [measureRef, bounds] = useMeasure({ scroll: true });

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [tickLabelTooltip, setTickLabelTooltip] =
    useState<LabelTooltipProps | null>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const markerSize = 40; // is not not in px
  const margin = { top: 10, right: 5, bottom: 21, left: 40 };
  const width = bounds.width - margin.left - margin.right;
  const height = bounds.height - margin.top - margin.bottom;

  const chartSuggestion = useMemo(
    () =>
      aiSuggestions?.find(
        (chart: AiSuggestionsDto) => chart.chartType === "scatterplotChart"
      ),
    [aiSuggestions]
  );

  const chartData = useMemo(
    () => currentWidget?.data || [],
    [currentWidget?.data]
  );

  const groupBy = useMemo(() => {
    return currentWidget?.groupBy?.[0] || chartSuggestion?.groupBy;
  }, [currentWidget?.groupBy, chartSuggestion?.groupBy]);

  const xAxe = useMemo(() => {
    return currentWidget?.xAxe?.[0] || chartSuggestion?.xAxe?.[0];
  }, [currentWidget?.xAxe, chartSuggestion?.xAxe]);

  const yAxe = useMemo(() => {
    return currentWidget?.yAxe?.[0] || chartSuggestion?.yAxe?.[0];
  }, [currentWidget?.yAxe, chartSuggestion?.yAxe]);

  const xAxes: string[] = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      Array.from(new Set(chartData.map((d: any) => d[xAxe]))) ||
      []
    );
  }, [chartData, currentWidget?.uniqueValues, xAxe]);

  const groupByKey =
    groupBy ?? Object.keys(currentWidget?.uniqueValues || [])?.[0];

  const xScaleDatasetField = useDatasetField(xAxe);

  const uniqueValues = useMemo(() => {
    return currentWidget?.uniqueValues?.[groupByKey!];
  }, [currentWidget?.uniqueValues, groupByKey]);

  const legendValues = useMemo(() => {
    return (uniqueValues || []).map((key) => ({
      label: key!,
      key: key!,
      color: getCurrentColor(currentWidget, key, styleId),
    }));
  }, [uniqueValues, currentWidget, styleId]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  //* Scales
  const xScale = useMemo(() => {
    return scaleBand<string>()
      .domain(xAxes)
      .range([0, width])
      .paddingOuter(-0.5);
  }, [xAxes, width]);

  const yScale = useMemo(() => {
    return scaleLinear<number, number>()
      .domain([0, Math.max(...chartData.map((d: any) => d[yAxe])) || 0])
      .range([height, margin.top])
      .nice();
  }, [chartData, height, margin.top, yAxe]);

  const colorScale = useMemo(() => {
    const defaultColor = getCurrentColor(currentWidget, "default", styleId);
    const colors = groupBy
      ? legendValues.map((item) => item.color)
      : [defaultColor];

    return scaleOrdinal<string, string>()
      .domain(legendValues.map((item) => item.key))
      .range(colors)
      .unknown(defaultColor);
  }, [legendValues, groupBy, currentWidget, styleId]);

  //* Marker
  const markersTypes = useMemo(
    () => currentWidget?.markers || [],
    [currentWidget?.markers]
  );

  const getMarkerType = useCallback(
    (key: string) =>
      String(markersTypes.find((item) => item.key === key)?.shape ?? ""),
    [markersTypes]
  );

  const getMarkerPath = (markerType: string) => {
    const markerTypesMap: { [key: string]: any } = {
      cross: symbolCross,
      circle: symbolCircle,
      donut: symbolCircle,
      square: symbolSquare,
      rhombus: symbolSquare,
      triangle: symbolTriangle,
    };

    return symbol().type(markerTypesMap[markerType] || symbolCircle);
  };

  const getMarkerRotation = (markerType: string): string => {
    const rotationMap: { [key: string]: number } = {
      rhombus: 45,
      cross: 45,
    };

    return `rotate(${rotationMap[markerType] || 0})`;
  };

  const getMarkerStrokeWidth = (markerType: string): number => {
    return markerType === "donut" ? 1.5 : 0;
  };

  const xScaleTickValues = useMemo(() => {
    const xScaleNumTicksCalculated = calculateNumTicks({ width });

    const isReasonableAmountOfTicks =
      xScaleNumTicksCalculated <= xAxes.length &&
      xScaleNumTicksCalculated > 0 &&
      xAxes.length / xScaleNumTicksCalculated >= 1.5;

    const xScaleNumTicks = isReasonableAmountOfTicks
      ? xScaleNumTicksCalculated
      : xAxes.length;

    return getScaleBandTickValues({
      tickCount: xScaleNumTicks,
      ticks: xAxes,
    });
  }, [width, xAxes]);

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: xAxisTickFormatter,
      }),
    [margin.left, margin.right, width, xScaleTickValues]
  );

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget.tooltip) && !recommended) {
        const { pageX, pageY, clientX, clientY } = event;
        const coords = { pageX, pageY, clientX, clientY };

        setTooltip({
          name: datum[groupByKey],
          data: {
            [xAxe as string]: groupBy ? String(datum[xAxe]) : "",
            [yAxe as string]: String(datum[yAxe]),
          },
          coords,
        });
      }
    },
    [
      currentWidget.tooltip,
      groupBy,
      groupByKey,
      recommended,
      showTooltip,
      xAxe,
      yAxe,
    ]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip || currentWidget.tooltip) {
      setTooltip(null);
    }
  }, [currentWidget.tooltip, showTooltip]);

  const handleMouseOver = useCallback(
    function (self: any, svg: any) {
      if (!showTooltip && !currentWidget.tooltip) {
        return;
      }

      svg
        .selectAll(".scatterplot-marker-container")
        .transition()
        .duration(200)
        .attr("opacity", ".2");

      select(self).transition().duration(200).attr("opacity", "1");
    },
    [currentWidget.tooltip, showTooltip]
  );

  const handleMouseOut = useCallback(
    function (svg: any) {
      if (!showTooltip && !currentWidget.tooltip) {
        return;
      }

      svg
        .selectAll(".scatterplot-marker-container")
        .transition()
        .duration(200)
        .attr("opacity", "1");
    },
    [currentWidget.tooltip, showTooltip]
  );

  const handleMouseMoveTickLabel = useCallback((event: any, datum: any) => {
    setTickLabelTooltip({
      data: datum,
      x: event.pageX - 10,
      y: event.pageY,
    });
  }, []);

  const handleMouseLeaveTickLabel = useCallback(() => {
    setTickLabelTooltip(null);
  }, []);

  //* Chart
  const svgContainer = select(svgRef.current);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    if (!bounds.width || !bounds.height || !chartData.length) {
      return;
    }

    const svg = svgContainer
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    //* yGrid
    svg
      .append("g")
      .attr("class", "y-grid")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(-width)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* y-axis
    svg
      .append("g")
      .attr("class", "y-axis")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(0)
          .tickPadding(8)
          .tickFormat(yAxisTickFormatter)
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("text")
      .attr("dx", `-${margin.left - 10}px`)
      .attr("fill", "#5f6877")
      .attr("font-size", "11px")
      .attr("text-anchor", "start");

    // y-axis tick lines
    svg
      .selectAll(".y-axis .tick")
      .append("line")
      .attr("class", "tick-line")
      .call((g) => g.select(".domain").remove())
      .attr("x1", -8)
      .attr("x2", 0)
      .attr("y1", 0)
      .attr("y2", 0)
      .attr("stroke", "#939ba7")
      .attr("stroke-width", "1px");

    //* xGrid
    svg
      .append("g")
      .attr("class", "x-grid")
      .call(
        axisBottom(xScale)
          .tickSize(height)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* x-axis
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(
        axisBottom(xScale)
          .tickSizeOuter(0)
          .tickSize(0)
          .tickPadding(12)
          .tickValues(xScaleTickValues)
          .tickFormat((str: string) => {
            const formattedTickValue = formatLabelValue(
              str,
              xScaleDatasetField?.type,
              xScaleDatasetField?.subtype
            );

            return xAxisTickFormatter(
              formattedTickValue,
              xScaleTickLabelMaxLength
            );
          })
      )
      .call((g) => g.select(".domain").attr("stroke", "#939ba7"))
      .selectAll("text")
      .attr("fill", "#5f6877")
      .attr("font-size", "11px")
      .classed("tick-label--long", (d: any) => {
        const formattedTickValue = formatLabelValue(
          d,
          xScaleDatasetField?.type,
          xScaleDatasetField?.subtype
        );

        return (
          formattedTickValue.length < d.length ||
          formattedTickValue.length > xScaleTickLabelMaxLength ||
          d.length > xScaleTickLabelMaxLength
        );
      })
      .on("mousemove", (event: any, d: any) => {
        const formattedTickValue = formatLabelValue(
          d,
          xScaleDatasetField?.type,
          xScaleDatasetField?.subtype
        );

        if (
          formattedTickValue.length < d.length ||
          formattedTickValue.length > xScaleTickLabelMaxLength ||
          d.length > xScaleTickLabelMaxLength
        ) {
          handleMouseMoveTickLabel(event, d);
        }
      })
      .on("mouseleave", () => {
        handleMouseLeaveTickLabel();
      });

    // * DataViz
    // Scatter Markers
    svg
      .append("g")
      .selectAll("path")
      .attr("class", "scatterplot-marker-group")
      .data(chartData)
      .join("g")
      .attr("class", "scatterplot-marker-container")
      .each(function (d: any) {
        const markerContainer = select(this);
        const markerType = getMarkerType(groupBy ? d[groupByKey] : "default");

        markerContainer
          .append("path")
          .attr("class", "scatterplot-marker")
          .attr("d", getMarkerPath(markerType).size(markerSize))
          .attr(
            "transform",
            (d: any) =>
              `translate(${
                (xScale(d[xAxe]) || 0) + xScale.bandwidth() / 2
              },${yScale(d[yAxe])}) ${getMarkerRotation(markerType)}`
          )
          .attr("fill", (d: any) =>
            markerType === "donut" ? "none" : colorScale(d[groupByKey])
          )
          .attr("stroke", (d: any) => colorScale(d[groupByKey]))
          .attr("stroke-width", getMarkerStrokeWidth(markerType));
      })
      .on("mouseover", function () {
        handleMouseOver(this, svg);
      })
      .on("mouseout", () => {
        handleMouseOut(svg);
      })
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, width, height]);

  if (!chartData || !Object.keys(chartData).length!) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper ref={refWidget}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <></>}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {availableWidgetsCount + 1}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}
          {legendValues?.length > 1 &&
            groupBy &&
            showLegend &&
            currentWidget?.legend && (
              <ChartLegend
                chartWidth={boundsWidget.width}
                legendType="unit"
                legendValues={legendValues}
              />
            )}
        </HeaderWrapper>

        <SVGStyled
          ref={(node) => {
            svgRef.current = node;
            measureRef(node);
          }}
          width="100%"
          height="100%"
        ></SVGStyled>

        {tooltip &&
          xAxe &&
          yAxe &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              name={tooltip.name}
              coords={tooltip.coords}
            />,
            document.body
          )}

        {tickLabelTooltip &&
          createPortal(
            <LabelTooltip
              x={tickLabelTooltip?.x}
              y={tickLabelTooltip?.y}
              data={tickLabelTooltip?.data}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
