import { Dispatch, SetStateAction, useState } from "react";
import MyDataRow from "../MyDataRow";
import {
  Arrow,
  CheckBoxWrapper,
  DatasetWrapper,
  Format,
  ImportedFilesContainer,
  MyDataHeader,
  Name,
  Trash,
  UploadedOn,
} from "./styles";
import { CheckBox } from "../../../../../Inputs/CustomCheckbox/CheckBox";
import { ISelectedItems } from "../../..";
import { FileLibraryItem } from "../../../../../../models/Files";

type Props = {
  datasets: FileLibraryItem[];
  setDimensionsSelected: Dispatch<SetStateAction<any>>;
  setFileOverviewOpen: Dispatch<SetStateAction<boolean>>;
  searchInput: string;
  libraryDatasetsIds: string[];
  libraryDatasetOverviewOpen?: boolean;
  selectedDataFromTab: ISelectedItems;
};

const MyDataList = ({
  setFileOverviewOpen,
  setDimensionsSelected,
  searchInput,
  libraryDatasetsIds,
  libraryDatasetOverviewOpen,
  selectedDataFromTab,
  datasets,
}: Props) => {

  const [selectedDataSet, setSelectedDataSet] =
    useState<string[]>(libraryDatasetsIds);

  const filteredDatasets = datasets
    .filter((item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    )
    // @ts-ignore
    .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

  const handleSelectAll = () => {
    if (selectedDataFromTab.myData + 2 === filteredDatasets.length) { //TO DO: remove +2 when api datasets will be added from server 
      setSelectedDataSet([]);
    } else {
      setSelectedDataSet(datasets.map((item) => item.id!));
    }
  };

  return (
    <DatasetWrapper $full>
      <ImportedFilesContainer $noGap>
        <MyDataHeader>
          <CheckBoxWrapper>
            <CheckBox
              onChange={handleSelectAll}
              checked={selectedDataFromTab.myData === datasets.length}
              status={
                selectedDataFromTab.myData === datasets.length
                  ? "checked"
                  : selectedDataFromTab.myData < datasets.length &&
                    !!selectedDataFromTab.myData
                  ? "indeterminate"
                  : "none"
              }
            />
          </CheckBoxWrapper>
          <Name>Name</Name>
          <Format>Format</Format>
          <UploadedOn>
            Uploaded on <Arrow />
          </UploadedOn>
          <Trash></Trash>
        </MyDataHeader>
        {filteredDatasets.map((dataSet) => (
          <MyDataRow
            file={dataSet}
            key={dataSet.id + dataSet.name}
            setFileOverviewOpen={setFileOverviewOpen}
            libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
            setDimensionsSelected={setDimensionsSelected}
            selectedDataSet={selectedDataSet}
            setSelectedDataSet={setSelectedDataSet}
          />
        ))}
      </ImportedFilesContainer>
    </DatasetWrapper>
  );
};

export default MyDataList;
