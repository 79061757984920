import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 0px 0px 0px 20px;
  background-color: #F9FAFA;
  width: 100%;
  height: max-content; 
  min-height: 100%;
  max-height: 100%;
  border-top: 1px solid #CBCFD3;
`

export const LeftSideBar = styled.div`
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  max-width: 240px;
  gap: 13px;
`

export const SideBarHeader = styled.p`
  color: #21262E; 
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 6px 0;
`

export const AuthorizationContent = styled.div`
  width: 100%;
  padding: 16px 102px 16px 82px;
  overflow-y: auto;
  scrollbar-width: thin;
`