import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  AvailableWidgetsWrapper,
  AvailableWidgetsCount,
  AvailableWidgetsHeading,
  AvailableWidgetsHeadingWrapper,
  WidgetItem,
  WidgetsItemsWrapper,
} from "./styles";

import { getAvailableWidgetTypes } from "../Widgets/widgetHelpers";
import { VerticalBarchart } from "../Widgets/VerticalBarchart";
import { SankeyPlot } from "../Widgets/Sankey";
import { GroupedBarChart } from "../Widgets/GroupedBarChart";
import { LineChart } from "../Widgets/LineChart/SingleLineChart";
import { AreaChart } from "../Widgets/AreaChart";
import {
  getCurrentProjectData,
  getCurrentWidget,
} from "../../store/selectors/projects";
import { GroupedLineChart } from "../Widgets/LineChart/GroupedLineChart";
import { HorizontalBarChart } from "../Widgets/HorizontalBarChart";
import { HorizontalLollipopChart } from "../Widgets/Lollipop/Horizontal";
import { VerticalLollipopChart } from "../Widgets/Lollipop/Vertical";
import { WidgetChartWrapper } from "../Widgets";
import { MatrixChart } from "../Widgets/MatrixChart";
import { WIDGETS } from "../Widgets/widgets";
import { HorizontalGroupedBarChart } from "../Widgets/HorizontalGroupedBarChart";
import { DataItem, MarkersVisualisationDataDto } from "../../models/Widgets";
import { UniversalMap } from "../Widgets/MapBox";
import { PolarAreaChart } from "../Widgets/PolarAreaChart";
import { RadarChart } from "../Widgets/RadarChart";
import { PunchcardChart } from "../Widgets/PunchcardChart";
import { SparkLineChart } from "../Widgets/SparkLineChart";
import { SparkAreaChart } from "../Widgets/SparkAreaChart";
import { ScatterPlot } from "../Widgets/ScatterPlot";
import {
  getScatterPlotDefaultColors,
  ScatterPlotDefaultMarkers,
} from "../Widgets/ScatterPlot/utils/getGroupData";
import { BubbleChart } from "../Widgets/BubbleChart";
import { RadialBarChart } from "../Widgets/RadialBarChart";
import { determineMapTypeFromSubtypes } from "../Widgets/MapBox/utils/determineMapTypeFromSubtypes";
import { getGeoSpatialDataKeys } from "../../helpers/getGeoSpatialDataKeys";

export const AvailableWidgets = ({
  selectChart,
}: {
  selectChart: (chart?: string) => void;
}) => {
  const [recommendedWidgets, setRecommendedWidgets] = useState<string[]>([]);
  const currentWidget = useSelector(getCurrentWidget);
  const [selectedChart, setSelectedChart] = useState<string | undefined>(
    currentWidget?.id
  );
  const datasets = useSelector(getCurrentProjectData)?.datasets;

  useEffect(() => {
    if (currentWidget) {
      let widgets = getAvailableWidgetTypes(currentWidget);

      // Exclude the current widget from the list of recommended widgets, to be placed as the first widget
      const orientation = currentWidget.orientation;
      const chartType = orientation
        ? `${currentWidget.chartType}_${orientation}`
        : currentWidget.chartType;
      widgets = widgets?.filter((r) => r !== chartType);

      setRecommendedWidgets(widgets || []);
    } else {
      setRecommendedWidgets([]);
    }
  }, [currentWidget]);

  const onSelectChart = (value?: string) => {
    const chart = value !== selectedChart ? value : undefined;
    setSelectedChart(chart);
    selectChart(chart);
  };

  const renderRecommendedWidgets = () => {
    if (!recommendedWidgets?.length) {
      return null;
    }

    return recommendedWidgets?.map((widget: string, index: number) => {
      if (widget === currentWidget?.chartType) {
        return null;
      }

      switch (widget) {
        case "lollipopChart_horizontal":
          return (
            <WidgetItem key={index} $selected={true}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lollipopChart_horizontal"}
                onSelect={() => onSelectChart("lollipopChart_horizontal")}
              >
                <HorizontalLollipopChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "lollipopChart_horizontal"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "lollipopChart_vertical":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lollipopChart_vertical"}
                onSelect={() => onSelectChart("lollipopChart_vertical")}
              >
                <VerticalLollipopChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "lollipopChart_vertical"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "barChart_vertical":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "barChart_vertical"}
                onSelect={() => onSelectChart("barChart_vertical")}
              >
                {currentWidget?.groupBy === null ? (
                  <VerticalBarchart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_vertical"}
                  />
                ) : (
                  <GroupedBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_vertical"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "barChart_horizontal":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "barChart_horizontal"}
                onSelect={() => onSelectChart("barChart_horizontal")}
              >
                {currentWidget?.groupBy === null ? (
                  <HorizontalBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_horizontal"}
                  />
                ) : (
                  <HorizontalGroupedBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_horizontal"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "sankeyChart":
          const groupBy = currentWidget?.groupBy?.at(0)!;
          const xAxe = currentWidget?.xAxe?.at(0)!;
          const yAxe = currentWidget?.yAxe?.at(0)!;

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "sankeyChart"}
                onSelect={() => onSelectChart("sankeyChart")}
              >
                <SankeyPlot
                  currentWidget={{
                    ...currentWidget!,
                    data: currentWidget?.data,
                    display: [yAxe],
                    arrangeBy: [xAxe, groupBy],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "sankeyChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "lineChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lineChart"}
                onSelect={() => onSelectChart("lineChart")}
              >
                {currentWidget?.groupBy ? (
                  <GroupedLineChart
                    currentWidget={currentWidget}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "lineChart"}
                  />
                ) : (
                  <LineChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "lineChart"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "sparkLineChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "sparkLineChart"}
                onSelect={() => onSelectChart("sparkLineChart")}
              >
                <SparkLineChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "sparkLineChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "sparkAreaChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "sparkAreaChart"}
                onSelect={() => onSelectChart("sparkAreaChart")}
              >
                <SparkAreaChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "sparkAreaChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "areaChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "areaChart"}
                onSelect={() => onSelectChart("areaChart")}
              >
                <AreaChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "areaChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "punchcardChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "punchcardChart"}
                onSelect={() => onSelectChart("punchcardChart")}
              >
                <PunchcardChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "punchcardChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "polarAreaChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "polarAreaChart"}
                onSelect={() => onSelectChart("polarAreaChart")}
              >
                <PolarAreaChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "polarAreaChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "radarChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "radarChart"}
                onSelect={() => onSelectChart("radarChart")}
              >
                <RadarChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "radarChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "scatterplotChart":
          const uniqueValuesKeys =
            (currentWidget?.uniqueValues &&
              Object.keys(currentWidget?.uniqueValues!)) ||
            [];
          const groupByScatter = currentWidget?.groupBy?.at(0);

          const groupByKey =
            groupByScatter && groupByScatter?.length
              ? groupByScatter
              : uniqueValuesKeys?.at(0);

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "scatterplotChart"}
                onSelect={() => onSelectChart("scatterplotChart")}
              >
                <ScatterPlot
                  currentWidget={{
                    ...currentWidget!,
                    chartType: "scatterplotChart",
                    formatting: getScatterPlotDefaultColors(
                      currentWidget!,
                      groupByKey
                    ),
                    markers: ScatterPlotDefaultMarkers(
                      currentWidget!,
                      groupByKey
                    ),
                    groupBy: currentWidget?.groupBy || [groupByKey ?? ""],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "scatterplotChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "bubbleChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "bubbleChart"}
                onSelect={() => onSelectChart("bubbleChart")}
              >
                <BubbleChart
                  currentWidget={{
                    ...currentWidget!,
                    chartType: "bubbleChart",
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "bubbleChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "matrixChart":
          const groupByMatrix = currentWidget?.arrangeBy?.at(0)!;
          const arrangeBy = currentWidget?.uniqueValues
            ? Object.keys(currentWidget?.uniqueValues!)?.at(1)!
            : currentWidget?.arrangeBy?.at(0)!;
          const display =
            currentWidget?.display?.at(0)! || currentWidget?.yAxe?.at(0)!;

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "matrixChart"}
                onSelect={() => onSelectChart("matrixChart")}
              >
                <MatrixChart
                  currentWidget={{
                    ...currentWidget!,
                    chartType: "matrixChart",
                    arrangeBy: [],
                    groupBy: [groupByMatrix],
                    xAxe: [arrangeBy],
                    yAxe: [display],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "matrixChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );

        case "matrixChart_map":
          const matrixMapChartData =
            currentWidget?.layers?.at(0)?.data || currentWidget?.data || [];
          const timeAxe =
            currentWidget?.xAxe?.at(0) ??
            currentWidget?.layers?.at(0)?.timePeriod?.field ??
            currentWidget?.arrangeBy?.at(1) ??
            "year";
          const valueAxe =
            currentWidget?.yAxe?.at(0) ??
            currentWidget?.layers?.at(0)?.arrangeByMetric?.at(0) ??
            currentWidget?.display?.at(0) ??
            "value";

          const matrixMapType = determineMapTypeFromSubtypes(
            Object.keys(currentWidget?.layers?.at(0)?.geospatialData || []),
            datasets
          );

          const dataMatrix = matrixMapChartData?.reduce((t: any[], r: any) => {
            if (r.state === "California") {
              const exist = t?.some(
                (n: any) =>
                  n?.[timeAxe] === r?.[timeAxe] &&
                  n[matrixMapType] === r[matrixMapType]
              );
              if (exist) {
                return t?.map((d: any) => {
                  if (
                    d[timeAxe] === r[timeAxe] &&
                    d[matrixMapType] === r[matrixMapType]
                  ) {
                    return {
                      ...d,
                      year: d[timeAxe],
                      value: (
                        Number(d?.[valueAxe]) + Number(r?.[valueAxe])
                      ).toString(),
                    };
                  }
                  return d;
                });
              }
              return [
                ...t,
                {
                  [timeAxe]: r[timeAxe],
                  [valueAxe]: r?.[valueAxe],
                  [matrixMapType]: r?.[matrixMapType],
                  ...(matrixMapType === "county" && { state: r.state }),
                },
              ];
            }
            return t;
          }, []);

          const uniqueValues = {
            [matrixMapType]: dataMatrix?.reduce(
              (t: string[], r: MarkersVisualisationDataDto) => {
                if (
                  !t?.includes(
                    r[
                      matrixMapType as keyof MarkersVisualisationDataDto
                    ] as string
                  )
                ) {
                  return [
                    ...t,
                    r[matrixMapType as keyof MarkersVisualisationDataDto],
                  ];
                }
                return t;
              },
              []
            ),
            [timeAxe]: dataMatrix?.reduce((t: string[], r: any) => {
              if (!t?.includes(r?.[timeAxe])) {
                return [...t, r?.[timeAxe]];
              }
              return t;
            }, []),
          };
          const widgetData = {
            ...currentWidget!,
            layers: [],
            arrangeBy: [
              matrixMapType === "county" ? "state" : matrixMapType,
              "year",
            ],
            chartType: "matrixChart",
            data: dataMatrix,
            xAxe: [timeAxe],
            yAxe: [valueAxe],
            groupBy: [matrixMapType],
            uniqueValues: uniqueValues,
          };

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "matrixChart_map"}
                onSelect={() => onSelectChart("matrixChart_map")}
              >
                <MatrixChart
                  selected={selectedChart === "matrixChart_map"}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  currentWidget={widgetData}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "sankeyChart_map":
          const sankeyMapChartData =
            currentWidget?.layers?.at(0)?.data || currentWidget?.data || [];

          const isMap2Sankey = currentWidget?.chartType === "mapChart";

          const displaySankey: string[] = isMap2Sankey
            ? currentWidget?.layers.at(0)?.arrangeByMetric ?? ["value"]
            : currentWidget?.yAxe ?? ["value"];

          const arrangeBySankey: string = isMap2Sankey
            ? currentWidget?.layers?.at(0)?.timePeriod?.field ?? "year"
            : currentWidget?.xAxe?.at(0) ?? "year";

          const sankeyQuery = isMap2Sankey
            ? currentWidget?.layers?.at(0)?.query ?? ""
            : currentWidget?.query ?? "";

          const data = sankeyMapChartData?.reduce(
            (t: DataItem[], r: DataItem) => {
              const exist = t?.some(
                (n: DataItem) => n[arrangeBySankey] === r[arrangeBySankey]
              );
              if (exist) {
                return t?.map((d: DataItem) => {
                  if (d[arrangeBySankey] === r[arrangeBySankey]) {
                    return {
                      ...d,
                      [displaySankey[0]]: (
                        Number(d[displaySankey[0]]) +
                        Number(r[displaySankey[0]])
                      ).toString(),
                    };
                  }
                  return d;
                });
              }
              return [...t, r];
            },
            []
          );

          const sankeyMapType = determineMapTypeFromSubtypes(
            Object.keys(currentWidget?.layers?.at(0)?.geospatialData || []),
            datasets
          );

          const uniqueValuesSankey = data?.reduce(
            (t: string[], r: DataItem) => {
              if (!t?.includes(r[sankeyMapType] as string)) {
                return [...t, r[arrangeBySankey]];
              }
              return t;
            },
            []
          );
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "sankeyChart_map"}
                onSelect={() => onSelectChart("sankeyChart_map")}
              >
                <SankeyPlot
                  currentWidget={{
                    ...currentWidget!,
                    data: sankeyMapChartData,
                    dataFormat: currentWidget?.dataFormat || {},
                    uniqueValues: {
                      [arrangeBySankey]: uniqueValuesSankey,
                    },
                    palette: currentWidget?.palette || {},
                    arrangeBy: ["state", arrangeBySankey],
                    display: displaySankey,
                    query: sankeyQuery,
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "sankeyChart_map"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "map_matrix":
          const isMatrix = currentWidget?.chartType === "matrixChart";

          const field = isMatrix
            ? currentWidget?.xAxe?.at(0) ?? "year"
            : currentWidget?.arrangeBy?.at(1) ?? "year";

          const arrangeByMetricMap = isMatrix
            ? currentWidget.yAxe ?? ["value"]
            : currentWidget?.display ?? ["value"];

          const mapMatrixType = determineMapTypeFromSubtypes(
            Object.keys(currentWidget?.data?.[0] || []),
            datasets
          );

          const mapMatrixGeoSpatialData = getGeoSpatialDataKeys(
            Object.keys(currentWidget?.data?.[0]),
            datasets
          );

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                map={true}
                recommended={true}
                selected={selectedChart === "map_matrix"}
                onSelect={() => onSelectChart("map_matrix")}
              >
                <UniversalMap
                  showLegend={false}
                  recommended={true}
                  selected={selectedChart === "map_matrix"}
                  currentWidget={{
                    ...currentWidget!,
                    layers: [
                      {
                        data: currentWidget?.data as MarkersVisualisationDataDto[],
                        name: currentWidget?.name || "",
                        query: currentWidget?.query!,
                        colour: "green",
                        format: "geojson",
                        tooltip: true,
                        analytics: "average",
                        datasetId: currentWidget?.datasetId!,
                        geospatialData: mapMatrixGeoSpatialData,
                        arrangeByMetric: arrangeByMetricMap,
                        visualisationType: "markers",
                        geoTarget: [],
                        description: null,
                        timePeriod: {
                          type: "",
                          field:
                            currentWidget?.xAxe?.at(0) ||
                            currentWidget?.arrangeBy?.at(1) ||
                            "year",
                          values:
                            field && currentWidget?.uniqueValues
                              ? currentWidget?.uniqueValues[field]
                              : [],
                        },
                      },
                    ],
                    chartType: "mapChart",
                    xAxe: ["year"],
                    yAxe: ["value"],
                    groupBy: [mapMatrixType],
                    uniqueValues: {},
                  }}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "radialBarChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "radialBarChart"}
                onSelect={() => onSelectChart("radialBarChart")}
              >
                <RadialBarChart
                  currentWidget={{
                    ...currentWidget!,
                    xAxe:
                      currentWidget?.arrangeBy?.filter(Boolean) ||
                      currentWidget?.xAxe ||
                      [],
                    yAxe: currentWidget?.display || currentWidget?.yAxe || [],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "radialBarChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        default:
          return null;
      }
    });
  };

  const renderCurrentWidget = () => {
    if (!currentWidget) {
      return null;
    }
    const ChartComponent: any =
      currentWidget &&
      currentWidget.chartType &&
      WIDGETS[currentWidget.chartType];

    return (
      <WidgetItem key={currentWidget.id}>
        <WidgetChartWrapper
          recommended={true}
          storytelling={false}
          map={currentWidget.chartType === "mapChart"}
          selected={selectedChart === currentWidget.id}
          onSelect={() => onSelectChart(currentWidget.id)}
        >
          <ChartComponent
            recommended={true}
            showLegend={false}
            storytelling={false}
            selected={selectedChart === currentWidget.id}
            currentWidget={currentWidget}
            hideName={true}
          />
        </WidgetChartWrapper>
      </WidgetItem>
    );
  };

  return (
    <AvailableWidgetsWrapper>
      <AvailableWidgetsHeadingWrapper>
        <AvailableWidgetsHeading>Available Widgets</AvailableWidgetsHeading>
        <AvailableWidgetsCount>
          {recommendedWidgets?.length + 1}
        </AvailableWidgetsCount>
      </AvailableWidgetsHeadingWrapper>

      <WidgetsItemsWrapper>
        {renderCurrentWidget()}
        {renderRecommendedWidgets()}
      </WidgetsItemsWrapper>
    </AvailableWidgetsWrapper>
  );
};
