import { RequestCallbacks, ServerResponse } from "../../models";
import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  createProject,
  deleteProjectById,
  getAllProjects,
  getProjectById,
  publishProjectById,
  QueryGetProjects,
  removeProject,
  updateProjectById,
} from "../../api/projects";
import {
  handleDeleteProject,
  requestProjectById,
  setAllProjects,
  setIsLoading,
} from "../slices/projects";
import { ProjectDTO, UpdateProjectDTO } from "../../models/Projects";
import {
  resetPages,
  setCurrentProjectData,
  setPageWidgets,
} from "../slices/projectPages";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import {
  getPublicPageWidgets,
  getPublicProject,
} from "../../api/publicProjects";

export function* handleGetAllProjects({
  payload,
}: PayloadAction<QueryGetProjects>) {
  yield put(setIsLoading(true));
  const { response }: ServerResponse = yield call(getAllProjects, payload);
  if (response) {
    yield put(setIsLoading(false));
  }
  if (response?.status === 200) {
    yield put(setAllProjects(response.data));
    payload?.callbacks?.onSuccess(response.data);
  }
}

export function* handleCreateProject(
  action: PayloadAction<ProjectDTO & { callbacks?: RequestCallbacks<any>; navigate?: NavigateFunction }>
) {
  const loading = toast.loading("Please wait...", { type: "info" });

  const { response, error }: ServerResponse = yield call(
    createProject,
    action.payload
  );

  toast.dismiss(loading);

  if (error) {
    toast.error("Failed to create project");
    action.payload.callbacks?.onError?.();
    return;
  }

  if (response?.status === 201 && response?.data?.id) {
    toast.success("Project created successfully");
    action.payload.callbacks?.onSuccess?.(response.data);
    if (action.payload.navigate) {
      action.payload.navigate(`/projects/${response.data.id}`);
    }
  }
}

export function* handleRemoveProject(
  action: PayloadAction<{ id: string; navigate: NavigateFunction; callbacks?: RequestCallbacks<any> }>
): any {
  const loading = toast.loading("Please wait...", { type: "info" });

  const { response }: ServerResponse = yield call(
    removeProject,
    action.payload.id
  );

  toast.update(loading, {
    render:
      response?.status === 200
        ? "Project removed successfully"
        : "An error occurred",
    type: response?.status === 200 ? "success" : "error",
    isLoading: false,
    autoClose: 800,
  });

  if (response?.status === 200) {
    yield call(handleGetAllProjects, {
      payload: {
        limit: 10,
      },
    } as PayloadAction<QueryGetProjects>);

    action.payload.callbacks?.onSuccess();
    yield put(resetPages());
    yield put(handleDeleteProject(action.payload.id));
  }
}

export function* handleGetProjectById(action: PayloadAction<string>) {
  const { response }: ServerResponse = yield call(
    getProjectById,
    action.payload
  );

  if (response?.status === 200) {
    yield put(setCurrentProjectData(response.data));
  }
}

export function* handlePublishProjectById(action: PayloadAction<string>) {
  const { response }: ServerResponse = yield call(
    publishProjectById,
    action.payload
  );
  if (response?.status === 200) {
    yield put(requestProjectById(action.payload));
  }
}

export function* handlePublicProjectById(action: PayloadAction<string>) {
  const { response }: ServerResponse = yield call(
    getPublicProject,
    action.payload
  );
  if (response?.status === 200) {
    yield put(setCurrentProjectData(response.data));
  }
}

export function* handlePublicProjectWidgetsByPageId(
  action: PayloadAction<{
    id: string;
    pageId: string;
    query?: string;
    callbacks?: RequestCallbacks<any>;
  }>
) {
  const { response }: ServerResponse = yield call(
    getPublicPageWidgets,
    action.payload
  );
  if (response?.status === 200) {
    yield put(setPageWidgets(response.data));
    action.payload.callbacks?.onSuccess();
  }
}

export function* handletUpdateProjectById(
  action: PayloadAction<UpdateProjectDTO>
) {
  yield call(updateProjectById, action.payload);
}

export function* handleRemoveProjectById(action: PayloadAction<string>) {
  const { response }: ServerResponse = yield call(
    deleteProjectById,
    action.payload
  );
  if (response?.status === 200) {
    yield put(
      setCurrentProjectData({
        id: "",
        name: "",
        description: "",
        datasetColumnKeys: [],
        datasets: [],
        pages: [],
        projectType: "basic",
      })
    );
  }
}

export function* watchProjectActions() {
  yield takeLatest("allProjects/requestAllProjects", handleGetAllProjects);
  yield takeLatest("allProjects/requestCreateProject", handleCreateProject);
  yield takeLatest("allProjects/requestDeleteProject", handleRemoveProject);
  yield takeLatest("allProjects/requestProjectById", handleGetProjectById);
  yield takeLatest(
    "allProjects/requestUpdateProjectById",
    handletUpdateProjectById
  );
  yield takeLatest(
    "allProjects/requestRemoveProjectById",
    handleRemoveProjectById
  );
  yield takeLatest(
    "allProjects/requestPublishProject",
    handlePublishProjectById
  );
  yield takeLatest(
    "allProjects/requestPublicProjectById",
    handlePublicProjectById
  );
  yield takeLatest(
    "allProjects/requestPublicProjectWidgetsByPageId",
    handlePublicProjectWidgetsByPageId
  );
}
