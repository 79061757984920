import React from "react";
import { useDropzone } from "react-dropzone";
import {
  Paperclip,
  Mic,
  MicFill,
  ToggleOn,
  ToggleOff,
  Download,
} from "react-bootstrap-icons";
import { Button } from "../../../../Button";
import { PromptIcon, SendIcon, SendDisabledIcon } from "../../../../../assets";
import FileList from "../FileList";
import {
  ChatPanelWrapper,
  ChatPanelPromptsButtonWrapper,
  ChatPanelSendButtonWrapper,
  ChatPanelInput,
  ChatPanelActions,
  ChatPanelDropOverlay,
  ChatPanelFileList,
} from "./styles";

export interface LocalFile {
  file: File;
  meta: {
    id: number;
    extension: string;
  };
}

interface Props {
  onMessageSend: (message: string, files: File[]) => void;
}

const ChatPanel = (props: Props) => {
  const { onMessageSend } = props;

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles) => {
      setLocalFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) => ({
          file,
          meta: {
            extension: file.name.split(".").pop() || "",
            id: Math.random(),
          },
        })),
      ]);
    },
  });

  const [isMicActive, setIsMicActive] = React.useState(false);
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [chatValue, setChatValue] = React.useState("");
  const [localFiles, setLocalFiles] = React.useState<LocalFile[]>([]);

  const inputRef = React.useRef<HTMLTextAreaElement | null>(null);
  const dropRef = React.useRef<HTMLInputElement | null>(null);

  const resetInputHeight = () => {
    if (inputRef.current) inputRef.current.style.height = "revert-layer";
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatValue(e.target.value);

    if (inputRef.current) {
      const scrollHeight = `${inputRef.current.scrollHeight}px`;

      if (inputRef.current.style.height !== scrollHeight) {
        inputRef.current.style.height = "auto";
        inputRef.current.style.height = scrollHeight;
      } else if (!e.target.value?.length) {
        resetInputHeight();
      }
    }
  };

  const deleteFile = (id: number) => {
    setLocalFiles((prevFiles) =>
      prevFiles.filter((file) => file.meta.id !== id)
    );
  };

  const onAttachBtnClick = () => {
    if (dropRef?.current) dropRef.current.click();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (!!(chatValue?.length || localFiles?.length)) {
      const files = localFiles?.length
        ? localFiles.map(({ file }) => file)
        : [];
      onMessageSend(chatValue, files);
      setChatValue("");
      setLocalFiles([]);
      setIsMicActive(false);
      setIsInputFocused(false);
      resetInputHeight();
    }
  };

  const isChatFilled = !!chatValue?.length;
  const isFilesFilled = !!localFiles?.length;
  const isFormDisabled = !(isChatFilled || isFilesFilled);
  const isFormFocused =
    isInputFocused ||
    isChatFilled ||
    isMicActive ||
    isDragActive ||
    isFilesFilled;

  return (
    <ChatPanelWrapper $isFocused={isFormFocused} {...getRootProps()}>
      <input {...getInputProps()} ref={dropRef} />
      {isDragActive && (
        <ChatPanelDropOverlay>
          <Download />
          Drop file here
        </ChatPanelDropOverlay>
      )}
      {isFilesFilled && (
        <ChatPanelFileList>
          <FileList localFiles={localFiles} onFileDelete={deleteFile} />
        </ChatPanelFileList>
      )}
      <ChatPanelPromptsButtonWrapper>
        <Button
          type="button"
          round={!isFormFocused}
          name={isFormFocused ? "Prompts" : ""}
          variant="neutral"
          size="small"
          iconLeft={<PromptIcon />}
          onClick={() => console.log("prompt")}
        />
      </ChatPanelPromptsButtonWrapper>
      <ChatPanelInput
        ref={inputRef}
        value={chatValue}
        placeholder="Ask a question..."
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={() => setIsInputFocused(false)}
        onFocus={() => setIsInputFocused(true)}
      />
      <ChatPanelActions>
        <Button
          round
          type="button"
          variant="neutral"
          size="small"
          icon={<Paperclip />}
          onClick={onAttachBtnClick}
        />
        <Button
          type="button"
          size="small"
          name="Mic"
          variant={isMicActive ? "primary" : "neutral"}
          iconLeft={isMicActive ? <MicFill /> : <Mic />}
          icon={isMicActive ? <ToggleOn /> : <ToggleOff />}
          onClick={() => setIsMicActive((prev) => !prev)}
        />
        <ChatPanelSendButtonWrapper>
          <Button
            round
            type="button"
            variant="neutral"
            size="small"
            disabled={isFormDisabled}
            icon={isFormDisabled ? <SendDisabledIcon /> : <SendIcon />}
            onClick={handleSubmit}
          />
        </ChatPanelSendButtonWrapper>
      </ChatPanelActions>
    </ChatPanelWrapper>
  );
};

export default ChatPanel;
