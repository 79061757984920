import React from "react";
import { Category } from "./styles";

type Props = {
  name: string;
  onClick: () => void;
  active: boolean;
};

const CategoryItem: React.FC<Props> = ({
  name,
  active,
  onClick = () => {},
}) => {
  return (
    <Category $active={active} onClick={onClick}>
      {name}
    </Category>
  );
};

export default CategoryItem;
