import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.neutralTextWeak};
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SelectionText = styled.span`
  color: #1f2937;
  font-size: 14px;
`;

export const MiniSelectionText = styled.div`
  color: #1f2937;
  font-size: 14px;
  p {
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const SearchContainer = styled.div`
  position: relative;
  max-width: 520px;
`;

export const SearchInputWrapper = styled.div<{ $width: number }>`
  width: ${({ $width }) => ($width ? `${$width}px` : "100%")};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 50px;
  transition: all 0.2s ease-in-out;

  &:focus-within {
    border-color: ${({ theme }) => theme.primaryBorder};;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }
`;

export const StyledInput = styled.input`
  flex: 1;
  min-width: 50px;
  border: none;
  outline: none;
  font-size: 14px;
  color: ${({ theme }) => theme.neutralTextWeak};
  background: transparent;

  &::placeholder {
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: none;
  background: transparent;
  color: #6b7280;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    background: #f3f4f6;
    color: #374151;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
  }
`;

export const Divider = styled.div`
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 20px;
  background-color: #e2e8f0;
  margin: 0 4px;
`;