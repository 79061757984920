import React from "react";
import { ColorList, Colors, MapSettingsStyleFormatingColors } from "../styles";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

type Props = {
  colorsArray: any[];
  selectedFormatting: any;
  paletteIndex: number;
  handlePickColor: (color: number) => void;
  handleColorChangePalette: (
    state: "left" | "right",
    paletteId: string
  ) => void;
  type: "sequential" | "qualitative";
};

const SelectPalette = ({
  colorsArray,
  selectedFormatting,
  paletteIndex,
  handlePickColor,
  handleColorChangePalette = () => {},
  type,
}: Props) => {
  return (
    <MapSettingsStyleFormatingColors>
      {paletteIndex !== 0 && (
        <ChevronLeft
          onClick={() =>
            handleColorChangePalette("left", colorsArray[paletteIndex - 1].id)
          }
          cursor={"pointer"}
        />
      )}
      <ColorList $gap={type === "qualitative"}>
        {colorsArray[paletteIndex].colors.map((colors: any) => (
          <Colors
            $active={selectedFormatting === colors.key}
            $color={colors.hex}
            onClick={() =>
              type === "qualitative" && handlePickColor(colors.key)
            }
            key={colors.key}
            $sequential={type === "sequential"}
          />
        ))}
      </ColorList>
      {paletteIndex !== colorsArray.length - 1 && (
        <ChevronRight
          onClick={() =>
            handleColorChangePalette("right", colorsArray[paletteIndex + 1].id)
          }
          cursor={"pointer"}
        />
      )}
    </MapSettingsStyleFormatingColors>
  );
};

export default SelectPalette;
