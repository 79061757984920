import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { createPortal } from "react-dom";
import {
  NumberValue,
  axisBottom,
  axisLeft,
  scaleBand,
  scaleLinear,
  select,
  scaleOrdinal,
} from "d3";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title,
} from "./styles";
import {
  HeadingNameAndButton,
  WidgetImageWrapper,
  FeedBackButton,
} from "../styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { getActiveModal } from "../../../store/selectors/modals";

import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleBandTickValues,
} from "../widgetHelpers";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { BubbleChartGroupedData } from "./utils/getGroupData";
import { getCurrentColor } from "../utils/getCurrentMarker";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";
import { generateColorRanges } from "./utils/generateColorRanges";
import { ColorRangeI } from "../../../models/Pages";
import { getBubbleRadius } from "../PunchcardChart/utils/getBubbleRadius";
import { useDatasetField } from "../../../hooks/useDatasetField";
import { formatLabelValue } from "../utils/formatLabelValue";
import { LabelTooltip, LabelTooltipProps } from "../components/LabelTooltip";

export interface BubbleChartProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  preview?: boolean;
}

const yAxisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

const xAxisTickFormatter = (
  value: string | number,
  length: number = Infinity
): string => {
  const stringValue = String(value);
  const splitedValue = stringValue.split("");

  return `${splitedValue?.slice(0, length).join("")}${
    splitedValue?.length < length ? "" : "..."
  }`;
};

export const BubbleChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
  preview = false,
}: BubbleChartProps) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const svgRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure({ scroll: true });
  const [measureRef, bounds] = useMeasure({ scroll: true });

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [tickLabelTooltip, setTickLabelTooltip] =
    useState<LabelTooltipProps | null>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);
  const [ranges, setRanges] = useState<ColorRangeI[]>([]);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const minBubbleRadiusVisibleLabel = 16;
  const maxBubbleRadius = 30;
  const margin = { top: 10, right: 5, bottom: 21, left: 40 };
  margin.top += maxBubbleRadius / 2 + 1;
  margin.right += maxBubbleRadius / 2 + 1;
  const width = (bounds.width || 1085) - margin.left - margin.right;
  const height = (bounds.height || 300) - margin.top - margin.bottom;

  const chartData: any = useMemo(() => {
    return currentWidget?.data ?? [];
  }, [currentWidget?.data]);

  const chartSuggestion = useMemo(() => {
    return (
      aiSuggestions?.find(
        (chart: AiSuggestionsDto) => chart.chartType === "bubbleChart"
      ) || currentWidget
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiSuggestions]);

  const groupBy = useMemo(() => {
    return currentWidget?.groupBy?.[0] || chartSuggestion?.groupBy;
  }, [currentWidget?.groupBy, chartSuggestion?.groupBy]);

  const xAxe = useMemo(() => {
    return currentWidget?.xAxe?.[0] || chartSuggestion?.xAxe?.[0];
  }, [currentWidget?.xAxe, chartSuggestion?.xAxe]);

  const yAxe = useMemo(() => {
    return currentWidget?.yAxe?.[0] || chartSuggestion.yAxe?.[0];
  }, [currentWidget?.yAxe, chartSuggestion?.yAxe]);

  const xAxes: string[] = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      chartData?.map((d: any) => d[xAxe]) ||
      []
    );
  }, [chartData, currentWidget?.uniqueValues, xAxe]);

  const groupedData: any = useMemo(() => {
    return BubbleChartGroupedData(currentWidget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWidget.data, xAxe, yAxe, groupBy]);

  const xScaleDatasetField = useDatasetField(xAxe);

  const uniqueValuesKeys = useMemo(() => {
    return currentWidget?.uniqueValues
      ? Object.keys(currentWidget.uniqueValues)
      : [];
  }, [currentWidget?.uniqueValues]);

  const groupByKey = useMemo(() => {
    return groupBy || uniqueValuesKeys?.[0];
  }, [groupBy, uniqueValuesKeys]);

  const uniqueValues = useMemo(() => {
    if (uniqueValuesKeys?.length && currentWidget?.uniqueValues) {
      return currentWidget?.uniqueValues[groupByKey!];
    }
    return Object.keys(groupedData);
  }, [uniqueValuesKeys, currentWidget?.uniqueValues, groupByKey, groupedData]);

  const legendValues = useMemo(() => {
    if (!groupedData || !uniqueValues?.length) {
      return [];
    }

    return uniqueValues.map((dataKey) => ({
      label: dataKey!,
      key: dataKey!,
      color: getCurrentColor(currentWidget, dataKey, styleId),
    }));
  }, [groupedData, uniqueValues, currentWidget, styleId]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  //* Scales
  const minValue = useMemo(() => {
    return (
      (chartData &&
        chartData.length &&
        Math.min(...chartData?.map((d: any) => d[yAxe]))) ||
      0
    );
  }, [chartData, yAxe]);

  const maxValue = useMemo(() => {
    return (
      (chartData &&
        chartData.length &&
        Math.max(...chartData.map((d: any) => d[yAxe]))) ||
      0
    );
  }, [chartData, yAxe]);

  const xScale = useMemo(() => {
    return scaleBand<string>().domain(xAxes).range([0, width]).padding(0.1);
  }, [width, xAxes]);

  const yScale = useMemo(() => {
    return scaleLinear<number, number>()
      .domain([minValue, maxValue])
      .range([height, 0])
      .nice();
  }, [height, maxValue, minValue]);

  const colorScale = useMemo(() => {
    return scaleOrdinal<string, string>()
      .domain(legendValues?.map((item) => item.label))
      .range(legendValues.map((item) => item.color))
      .unknown(getCurrentColor(currentWidget, "default", styleId));
  }, [legendValues, currentWidget, styleId]);

  const xScaleTickValues = useMemo(() => {
    const xScaleNumTicksCalculated = calculateNumTicks({ width });

    const isReasonableAmountOfTicks =
      xScaleNumTicksCalculated <= xAxes.length &&
      xScaleNumTicksCalculated > 0 &&
      xAxes.length / xScaleNumTicksCalculated >= 1.5;

    const xScaleNumTicks = isReasonableAmountOfTicks
      ? xScaleNumTicksCalculated
      : xAxes.length;

    return getScaleBandTickValues({
      tickCount: xScaleNumTicks,
      ticks: xAxes,
    });
  }, [width, xAxes]);

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: xAxisTickFormatter,
      }),
    [margin.left, margin.right, width, xScaleTickValues]
  );

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget.tooltip) && !recommended) {
        const { pageX, pageY, clientX, clientY } = event;
        const coords = { pageX, pageY, clientX, clientY };

        setTooltip({
          name: datum[groupByKey],
          data: {
            [xAxe as string]: String(datum[xAxe]),
            [yAxe as string]: String(datum[yAxe]),
          },
          coords,
        });
      }
    },
    [currentWidget.tooltip, groupByKey, recommended, showTooltip, xAxe, yAxe]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip || currentWidget.tooltip) {
      setTooltip(null);
    }
  }, [currentWidget.tooltip, showTooltip]);

  const handleMouseOver = useCallback(
    function (self: any, svg: any) {
      if (!showTooltip && !currentWidget.tooltip) {
        return;
      }

      svg
        .selectAll(".bubble-container")
        .transition()
        .duration(200)
        .attr("opacity", ".2");

      select(self).transition().duration(200).attr("opacity", "1");
    },
    [currentWidget.tooltip, showTooltip]
  );

  const handleMouseOut = useCallback(
    function (svg: any) {
      if (!showTooltip && !currentWidget.tooltip) {
        return;
      }

      svg
        .selectAll(".bubble-container")
        .transition()
        .duration(200)
        .attr("opacity", "1");
    },
    [currentWidget.tooltip, showTooltip]
  );

  const handleMouseMoveTickLabel = useCallback((event: any, datum: any) => {
    setTickLabelTooltip({
      data: datum,
      x: event.pageX - 10,
      y: event.pageY,
    });
  }, []);

  const handleMouseLeaveTickLabel = useCallback(() => {
    setTickLabelTooltip(null);
  }, []);

  //* Chart
  const svgContainer = select(svgRef.current);
  useEffect(() => {
    const values: number[] = chartData?.map((item: any) => item[yAxe] || 0);
    const paletteRanges = currentWidget?.palette?.range;

    generateColorRanges(values, setRanges, paletteRanges);
  }, [currentWidget?.palette?.range, chartData, yAxe]);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    if (!bounds.width || !bounds.height || !chartData.length) {
      return;
    }

    const svg = svgContainer
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    //* yGrid
    svg
      .append("g")
      .attr("class", "y-grid")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(-width)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* y-axis
    svg
      .append("g")
      .attr("class", "y-axis")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(0)
          .tickPadding(8)
          .tickFormat(yAxisTickFormatter)
      )
      .call((g) =>
        g
          .select(".domain")
          .attr("stroke", "#ccc")
          .attr("stroke-dasharray", "1,2")
      )
      .selectAll("text")
      .attr("dx", `-${margin.left - 10}px`)
      .attr("fill", "#5f6877")
      .attr("font-size", "11px")
      .attr("text-anchor", "start");

    // y-axis tick lines
    svg
      .selectAll("line")
      .append("line")
      .attr("class", "tick-line")
      .call((g) => g.select(".domain").remove())
      .attr("x1", -8)
      .attr("x2", 0)
      .attr("y1", 0)
      .attr("y2", 0)
      .attr("stroke", "#939ba7")
      .attr("stroke-width", "1px");

    //* xGrid
    svg
      .append("g")
      .attr("class", "x-grid")
      .call(
        axisBottom(xScale)
          .tickSize(height)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* x-axis
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(
        axisBottom(xScale)
          .tickSizeOuter(0)
          .tickSize(0)
          .tickPadding(12)
          .tickValues(xScaleTickValues)
          .tickFormat((str: string) => {
            const formattedTickValue = formatLabelValue(
              str,
              xScaleDatasetField?.type,
              xScaleDatasetField?.subtype
            );

            return xAxisTickFormatter(
              formattedTickValue,
              xScaleTickLabelMaxLength
            );
          })
      )
      .call((g) => g.select(".domain").attr("stroke", "#939ba7"))
      .selectAll("text")
      .attr("fill", "#5f6877")
      .attr("font-size", "11px")
      .classed("tick-label--long", (d: any) => {
        const formattedTickValue = formatLabelValue(
          d,
          xScaleDatasetField?.type,
          xScaleDatasetField?.subtype
        );

        return (
          formattedTickValue.length < d.length ||
          formattedTickValue.length > xScaleTickLabelMaxLength ||
          d.length > xScaleTickLabelMaxLength
        );
      })
      .on("mousemove", (event: any, d: any) => {
        const formattedTickValue = formatLabelValue(
          d,
          xScaleDatasetField?.type,
          xScaleDatasetField?.subtype
        );

        if (
          formattedTickValue.length < d.length ||
          formattedTickValue.length > xScaleTickLabelMaxLength ||
          d.length > xScaleTickLabelMaxLength
        ) {
          handleMouseMoveTickLabel(event, d);
        }
      })
      .on("mouseleave", () => {
        handleMouseLeaveTickLabel();
      });

    // * DataViz
    // Bubble Container
    svg
      .append("g")
      .selectAll("g")
      .data(chartData)
      .join("g")
      .attr("class", "bubble-container")
      .each(function (d: any) {
        const bubbleContainer = select(this);

        // Bubble
        bubbleContainer
          .append("circle")
          .attr("class", "bubble")
          .attr(
            "cx",
            (d: any) => (xScale(d[xAxe]) || 0) + xScale.bandwidth() / 2
          )
          .attr("cy", (d: any) => yScale(d[yAxe]) || 0)
          .attr("r", (d: any) => getBubbleRadius(d[yAxe], ranges))
          .attr("fill", (d: any) => colorScale(d[groupBy]))
          .attr("fill-opacity", 0.2)
          .attr("stroke", (d: any) => colorScale(d[groupBy]))
          .attr("stroke-width", 1);

        // Bubble Label
        bubbleContainer
          .append("text")
          .attr("class", "bubble-label")
          .attr("x", (xScale(d[xAxe]) || 0) + xScale.bandwidth() / 2)
          .attr("y", yScale(d[yAxe]) || 0)
          .attr("font-size", "12px")
          .attr("fill", "#4e4e4e")
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "middle")
          .text(() =>
            getBubbleRadius(d[yAxe], ranges) >= minBubbleRadiusVisibleLabel
              ? yAxisTickFormatter(d[yAxe])
              : ""
          );
      })
      .on("mouseover", function () {
        handleMouseOver(this, svg);
      })
      .on("mouseout", () => {
        handleMouseOut(svg);
      })
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, width, height]);

  if (!chartData || !Object.keys(chartData).length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper ref={refWidget}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <></>}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {availableWidgetsCount + 1}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}
          {legendValues?.length > 1 &&
            groupBy &&
            showLegend &&
            currentWidget?.legend && (
              <ChartLegend
                chartWidth={boundsWidget.width}
                legendType="unit"
                legendValues={legendValues}
              />
            )}
        </HeaderWrapper>

        <SVGStyled
          ref={(node) => {
            svgRef.current = node;
            measureRef(node);
          }}
          width="100%"
          height="100%"
        />

        {tooltip &&
          xAxe &&
          yAxe &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              name={tooltip.name}
              coords={tooltip.coords}
            />,
            document.body
          )}

        {tickLabelTooltip &&
          createPortal(
            <LabelTooltip
              x={tickLabelTooltip?.x}
              y={tickLabelTooltip?.y}
              data={tickLabelTooltip?.data}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
