import React from "react";
import BasicAuth from "../../components/BasicAuth";
import BearerToken from "../../components/BearerToken";
import JWTBearer from "../../components/JWTBearer";
import DigestAuth from "../../components/DigestAuth";
import { ConnectApiValues } from "../../types/types";
import NoAuth from "../../components/NoAuth";
import {
  AuthorizationContent,
  Container,
  LeftSideBar,
  SideBarHeader,
} from "./styles";
import { AuthTypes } from "../../data/mock";
import RadioButton from "../../../../Inputs/CustomRadio/RadioButton";

type Props = {
  values: ConnectApiValues;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
};

const Authorization = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
}: Props) => {
  return (
    <Container>
      <LeftSideBar>
        <SideBarHeader>Auth Type</SideBarHeader>

        {AuthTypes.map((item, idx) => (
          <RadioButton
            id={`Authorization-${idx}`}
            name={"Authorization"}
            onChange={(e) => setFieldValue("authType", e.target.value)}
            label={item.option}
            subLabel={item.subLabel}
            value={item.value}
            width={"100%"}
            checked={item.value === values.authType}
          />
        ))}
      </LeftSideBar>
      <AuthorizationContent>
        {values.authType === "basicAuth" && (
          <BasicAuth
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
          />
        )}
        {values.authType === "noAuth" && <NoAuth />}
        {values.authType === "bearerToken" && (
          <BearerToken
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
          />
        )}
        {values.authType === "JWTBearer" && (
          <JWTBearer
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            setFieldValue={setFieldValue}
          />
        )}
        {values.authType === "digestAuth" && (
          <DigestAuth
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            setFieldValue={setFieldValue}
          />
        )}
      </AuthorizationContent>
    </Container>
  );
};

export default Authorization;
