import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import useMeasure from "react-use-measure";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../../store/selectors/projects";
import { getAiSuggestions } from "../../../../store/selectors/widgets";
import { AiSuggestionsDto, WidgetItem } from "../../../../models/Widgets";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../../VerticalBarchart/styles";
import { ChartLegend, ChartLegendValue } from "../../../ChartLegend";
import { setActiveModal } from "../../../../store/slices/modals";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleBandTickValues,
} from "../../widgetHelpers";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import { Tooltip, TooltipProps } from "../../Tooltip";
import { Loader } from "../../../Loader";
import { setCurrentWidget } from "../../../../store/slices/projectPages";
import { SelectBage } from "../../SelectBage";
import { replaceWords } from "../../../../helpers/replaceName";
import { LollipopMarkersVertical } from "../../utils/getMarker";
import {
  get_data,
  get_xAxe,
  get_yAxe,
  getGroupedData,
} from "../utils/getLollipopChartMarkers";
import {
  getCurrentColor,
  getCurrentMarker,
} from "../../utils/getCurrentMarker";
import { createPortal } from "react-dom";
import { getPosition } from "../utils/getLollipopAlign";
import {
  FeedBackButton,
  HeadingNameAndButton,
  WidgetImageWrapper,
} from "../../styles";
import { getActiveModal } from "../../../../store/selectors/modals";
import { TickLabel } from "../../components/LabelTooltip";
import { useDatasetField } from "../../../../hooks/useDatasetField";
import { formatLabelValue } from "../../utils/formatLabelValue";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../../utils/feedback";

interface LollipopInterface {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  hideSettings?: boolean;
}

export const VerticalLollipopChart = ({
  storytelling,
  recommended,
  showLegend = true,
  selected = false,
  currentWidget,
  hideName = false,
  hideSettings = false,
}: LollipopInterface) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [groupedData, setGroupedData] = useState<{
    [keey: string]: { x: number; y: number }[];
  }>({});
  const [legendValues, setLegendValues] = useState<ChartLegendValue[]>([]);
  const [xAxes, setXAxes] = useState<string[]>([]);
  const [yAxes, setYAxes] = useState<number[]>([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [groupByKey, setGroupByKey] = useState<string | undefined>();
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);
  const xScaleDatasetField = useDatasetField(xAxe);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const margin = { top: 10, bottom: 25, left: 40, right: 3 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const xScale = scaleBand({
    domain: xAxes,
    range: [margin.left, width - margin.right],
    padding: 0,
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...yAxes)],
    range: [height - margin.bottom, margin.top],
    nice: true,
  });

  const yScaleNumTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  const xScaleTickValues = useMemo(() => {
    const xScaleNumTicksCalculated = calculateNumTicks({ width });

    const isReasonableAmountOfTicks =
      xScaleNumTicksCalculated <= xAxes.length &&
      xScaleNumTicksCalculated > 0 &&
      xAxes.length / xScaleNumTicksCalculated >= 1.5;

    const xScaleNumTicks = isReasonableAmountOfTicks
      ? xScaleNumTicksCalculated
      : xAxes.length;

    return getScaleBandTickValues({
      tickCount: xScaleNumTicks,
      ticks: xAxes,
    });
  }, [width, xAxes]);

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: (value) =>
          formatLabelValue(
            value,
            xScaleDatasetField?.type,
            xScaleDatasetField?.subtype
          ),
      }),
    [
      margin.left,
      margin.right,
      width,
      xScaleDatasetField?.subtype,
      xScaleDatasetField?.type,
      xScaleTickValues,
    ]
  );

  useEffect(() => {
    const lollipopChartSuggestion = aiSuggestions?.find(
      (chart: AiSuggestionsDto) => chart.chartType === "lollipopChart"
    );

    if (currentWidget) {
      const xAxe = get_xAxe(currentWidget);
      setXAxe(xAxe);

      const yAxe = get_yAxe(currentWidget);
      setYAxe(yAxe);

      const data = get_data(currentWidget) ?? [];

      const xAxes: string[] =
        currentWidget?.uniqueValues?.[xAxe] || data?.map((d: any) => d.x) || [];
      setXAxes(xAxes);

      const yAxes =
        data?.reduce((t: any, l: any) => {
          const y = parseFloat(l.y);
          if (typeof y === "number" && !isNaN(y)) {
            return [...t, y];
          }
          return t;
        }, []) || [];
      setYAxes(yAxes);

      let groupedData: any = getGroupedData(
        currentWidget,
        lollipopChartSuggestion
      );

      const newLegendValues = [];

      const groupBy = currentWidget?.groupBy?.at(0);

      const uniqueValuesKeys =
        (currentWidget?.uniqueValues &&
          Object.keys(currentWidget?.uniqueValues!)) ||
        [];
      const groupByKey =
        groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

      let chartGroupKeys =
        uniqueValuesKeys?.length && currentWidget?.uniqueValues
          ? currentWidget?.uniqueValues[groupByKey!]
          : [];

      if (!chartGroupKeys?.length && groupedData) {
        chartGroupKeys = Object.keys(groupedData);
      }

      if (groupedData && chartGroupKeys?.length) {
        for (let i = 0; i < chartGroupKeys?.length; i++) {
          const dataKey = chartGroupKeys?.at(i);
          const color = getCurrentColor(currentWidget, dataKey, styleId);
          newLegendValues.push({ label: dataKey!, color });
        }
      }

      setGroupByKey(groupByKey);
      setLegendValues(newLegendValues);
      setGroupedData(groupedData);
    }
  }, [aiSuggestions, currentWidget, styleId]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  if (Object.keys(groupedData).length === 0) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper ref={refWidget}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <div />}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {availableWidgetsCount}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}

          {legendValues?.length > 1 &&
            currentWidget?.formatting?.length! > 1 &&
            showLegend &&
            currentWidget?.legend && (
              <ChartLegend
                chartWidth={boundsWidget.width}
                legendType="unit"
                legendValues={legendValues}
              />
            )}
        </HeaderWrapper>
        <svg width="100%" height={"100%"} ref={ref}>
          <Group top={0} left={0}>
            <Group>
              {xAxes.map((value, index) => (
                <line
                  key={`${value}-${index}`}
                  x1={xScale(value)}
                  x2={xScale(value)}
                  y1={margin.top}
                  y2={height - margin.bottom}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
            </Group>
            <Group>
              {yScale.ticks(yScaleNumTicks).map((value, index) => {
                return (
                  <line
                    key={`${value}-${index}`}
                    x1={margin.left}
                    y1={yScale(value)}
                    x2={width - margin.right}
                    y2={yScale(value)}
                    stroke="#ccc"
                    strokeDasharray="1 2"
                  />
                );
              })}
              <line
                x1={width - margin.right}
                y1={margin.top}
                x2={width - margin.right}
                y2={height - margin.bottom}
                stroke="#e0e0e0"
                strokeDasharray="1 2"
              />
            </Group>
            <Group>
              {groupedData &&
                Object.keys(groupedData)?.map(
                  (key: string, barIndex: number) => {
                    const groupData = groupedData[key] as any;
                    const countGroupValues =
                      key === "default"
                        ? 1
                        : currentWidget?.formatting?.length || 1;
                    const xVal = getPosition(barIndex, countGroupValues);

                    return groupData?.map((d: any, index: number) => {
                      const color = getCurrentColor(
                        currentWidget,
                        key,
                        styleId
                      );
                      const barKey = `${d.x}-${index}-${barIndex}`;

                      return (
                        <g
                          key={barKey}
                          style={{ transition: "0.3s" }}
                          onMouseMove={(event: any) => {
                            if (
                              (showTooltip || currentWidget.tooltip) &&
                              !recommended
                            ) {
                              const { pageX, pageY, clientX, clientY } = event;
                              const coords = { pageX, pageY, clientX, clientY };

                              let tooltipData: Record<string, string> = {};

                              if (
                                groupedData &&
                                key !== "default" &&
                                groupByKey
                              ) {
                                tooltipData[groupByKey] = key;
                              }

                              tooltipData = {
                                ...tooltipData,
                                [xAxe as string]: d.x,
                                [yAxe as string]: d.y,
                              };

                              setHoveredElement(barKey);
                              setTooltip({
                                data: tooltipData,
                                coords,
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            setTooltip(null);
                            setHoveredElement(null);
                          }}
                          opacity={
                            hoveredElement
                              ? hoveredElement === barKey
                                ? 1
                                : 0.2
                              : 1
                          }
                        >
                          <line
                            x1={xScale(d.x)! + xVal + xScale.bandwidth() / 2}
                            y1={yScale(d.y) + 2}
                            x2={xScale(d.x)! + xVal + xScale.bandwidth() / 2}
                            y2={height - margin.bottom}
                            stroke={hexToRGBA(color!, 0.5)}
                            strokeWidth={3}
                          />
                          {!!color &&
                            LollipopMarkersVertical({
                              markerType: getCurrentMarker(
                                currentWidget,
                                key,
                                "rhombus"
                              ),
                              xScale: xScale(d.x)!,
                              xVal: xVal,
                              xScaleBand: xScale.bandwidth() / 2,
                              color: color,
                              yScale: yScale(d.y),
                            })}
                        </g>
                      );
                    });
                  }
                )}
            </Group>
            <AxisBottom
              top={height - margin.bottom}
              scale={xScale}
              hideTicks
              tickLabelProps={(_: any, index: number, values: any) => {
                const isFirstTick = index === 0;
                const isLastTick = index === values.length - 1;

                if (!isFirstTick && !isLastTick) {
                  return {
                    fontSize: 11,
                    fill: "#5F6877",
                    textAnchor: "middle",
                  };
                }

                const charSpace = 8;
                const bandWidth = xScale.bandwidth();
                const labelText = values[index].formattedValue;

                const isLabelTooLong = labelText.length > bandWidth / charSpace;

                const anchorPosition =
                  (isLabelTooLong &&
                    ((isFirstTick && "start") || (isLastTick && "end"))) ||
                  "middle";

                const tickPositionOffset =
                  (isLabelTooLong &&
                    ((isFirstTick && -bandWidth / 2) ||
                      (isLastTick && bandWidth / 2))) ||
                  0;

                return {
                  fontSize: 11,
                  fill: "#5F6877",
                  textAnchor: anchorPosition,
                  dx: tickPositionOffset,
                };
              }}
              tickValues={xScaleTickValues}
              tickComponent={(props: any) => (
                <TickLabel
                  {...props}
                  labelText={formatLabelValue(
                    props.formattedValue,
                    xScaleDatasetField?.type,
                    xScaleDatasetField?.subtype
                  )}
                  length={xScaleTickLabelMaxLength}
                  offsetX={-10}
                />
              )}
              axisLineClassName="barchartAxisLine"
            />
            {yScaleNumTicks ? (
              <AxisLeft
                left={margin.left}
                scale={yScale}
                hideAxisLine
                numTicks={yScaleNumTicks}
                tickLineProps={{
                  stroke: "#939BA7",
                }}
                tickLabelProps={() => ({
                  fontSize: 11,
                  fill: "#5F6877",
                  textAnchor: "start",
                  dy: 4,
                  dx: -30,
                })}
                tickFormat={(value: any) => {
                  return ticksFormatter(value);
                }}
              />
            ) : null}
          </Group>
        </svg>
        {tooltip &&
          xAxe &&
          yAxe &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              name={tooltip.name}
              coords={tooltip.coords}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
