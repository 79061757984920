import { styled } from "styled-components";


export const OptionText = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.neutralText};
  width: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;


export const CountBadge = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.neutralTextWeak};
  padding: 2px 8px;
  background: #6C75841F;
  border-radius: 12px;
`;


export const OptionItem = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  cursor: pointer;
  background: ${({ $isSelected }) => ($isSelected ? '#6F6FE91F' : 'transparent')};
  transition: all 0.2s ease;
  font-weight: ${({ $isSelected }) => ($isSelected ? '600' : '400')};
  &:hover {
    background: #6F6FE91F;
  }
`;

export const OptionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Checkbox = styled.input`
  width: 15px;
  height: 15px;
  margin: 0;
  cursor: pointer;
  accent-color: #2C33C8;
  
  &:checked {
    background-color: #2C33C8;
    border-color: #2C33C8;
  }
`;
