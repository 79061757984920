export const APP_Mock: any = {
    id: "b6c356f0-f6fe-4b20-ba92-3f3feb94cc96",
    name: "Oura Health Data",
    descriprion: "Oura API",
    format: "APP",
    classes: [
        "Sleep Data",
        "Activity Data",
        "Readiness Data",
        "Heart Rate Data",
        "Daily Summaries"
    ],
    filePath: "04ec2f5a-74da-4955-acc3-8ce35e1b134f/ac5328c4-40df-468d-b58b-e594c02b5851.csv",
    createdAt: "Mon Jan 06 2025 16:01:35 GMT+0000 (Coordinated Universal Time)",
    updatedAt: "Mon Jan 06 2025 16:01:35 GMT+0000 (Coordinated Universal Time)",
    userId: "04ec2f5a-74da-4955-acc3-8ce35e1b134f",
    source: "database",
    sourceType: "database",
    fields: [
        {
        label: "vehicle_id_partial",
        key: "vin-(1-10)",
        icon: "categorical",
        type: "categorical",
        subtype: "text",
        primary: false
        },
        {
        label: "registration_county",
        key: "county",
        icon: "location",
        type: "location",
        subtype: "county",
        primary: false
        },
        {
        label: "registration_city",
        key: "city",
        icon: "location",
        type: "location",
        subtype: "city",
        primary: false
        },
        {
        label: "registration_state",
        key: "state",
        icon: "meta",
        type: "meta",
        subtype: "dimension",
        explainedColumns: ["city"],
        primary: false
        },
        {
        label: "zip_code",
        key: "postal-code",
        icon: "location",
        type: "location",
        subtype: "ZIP",
        primary: false
        },
        {
        label: "vehicle_year",
        key: "model-year",
        icon: "datetime",
        type: "datetime",
        subtype: "year",
        primary: false
        },
        {
        label: "manufacturer",
        key: "make",
        icon: "meta",
        type: "meta",
        subtype: "dimension",
        explainedColumns: ["model"],
        primary: false
        },
        {
        label: "vehicle_model",
        key: "model",
        icon: "categorical",
        type: "categorical",
        subtype: "category",
        primary: false
        },
        {
        label: "ev_type",
        key: "electric-vehicle-type",
        icon: "categorical",
        type: "categorical",
        subtype: "category",
        primary: false
        },
        {
        label: "cafv_status",
        key: "clean-alternative-fuel-vehicle-(cafv)-eligibility",
        icon: "categorical",
        type: "categorical",
        subtype: "status",
        primary: false
        },
        {
        label: "range_miles",
        key: "electric-range",
        icon: "metric",
        type: "metric",
        subtype: "integer",
        primary: false
        },
        {
        label: "retail_price",
        key: "base-msrp",
        icon: "metric",
        type: "metric",
        subtype: "currency",
        primary: false
        },
        {
        label: "leg_district",
        key: "legislative-district",
        icon: "categorical",
        type: "categorical",
        subtype: "category",
        primary: false
        },
        {
        label: "license_id",
        key: "dol-vehicle-id",
        icon: "categorical",
        type: "categorical",
        subtype: "text",
        primary: false
        },
        {
        label: "geo_location",
        key: "vehicle-location",
        icon: "location",
        type: "location",
        subtype: "long_lat",
        primary: false
        },
        {
        label: "power_provider",
        key: "electric-utility",
        icon: "categorical",
        type: "categorical",
        subtype: "text",
        primary: false
        },
        {
        label: "census_tract_id",
        key: "2020-census-tract",
        icon: "categorical",
        type: "categorical",
        subtype: "text",
        primary: false
        }
    ],
    fieldsAi: [
        {
        name: "vin-(1-10)",
        types: [{ type: "categorical", fieldSubtype: "text" }]
        },
        {
        name: "county",
        types: [{ type: "location", fieldSubtype: "county" }]
        },
        {
        name: "city",
        types: [{ type: "location", fieldSubtype: "city" }]
        },
        {
        name: "state",
        types: [
            {
            type: "group",
            fieldType: "location",
            childColumns: [
                { newName: "registration_city", originalName: "city", subtype: "city", type: "location" },
                { newName: "zip_code", originalName: "postal-code", subtype: "ZIP", type: "location" },
                { newName: "registration_county", originalName: "county", subtype: "county", type: "location" }
            ],
            childColumnsName: "geographic divisions",
            groupRelationship: {
                type: "parent-child",
                subtype: "subdivision",
                description: "Classic geographic subdivision hierarchy where each level represents a more granular administrative division"
            },
            parentColumn: "state",
            pattern: "Geographic location hierarchy",
            visualizationRecommendation: "together",
            fieldSubtype: "state"
            }
        ]
        },
        {
        name: "postal-code",
        types: [{ type: "location", fieldSubtype: "ZIP" }]
        },
        {
        name: "model-year",
        types: [{ type: "datetime", fieldSubtype: "year" }]
        },
        {
        name: "make",
        types: [
            {
            type: "group",
            fieldType: "categorical",
            childColumns: [
                { newName: "vehicle_model", originalName: "model", subtype: "category", type: "categorical" },
                { newName: "vehicle_year", originalName: "model-year", subtype: "year", type: "datetime" },
                { newName: "ev_type", originalName: "electric-vehicle-type", subtype: "category", type: "categorical" },
                { newName: "range_miles", originalName: "electric-range", subtype: "integer", type: "metric" }
            ],
            childColumnsName: "vehicle specifications",
            groupRelationship: {
                type: "parent-child",
                subtype: "subdivision",
                description: "Vehicle manufacturer subdivides into specific models and their characteristics"
            },
            parentColumn: "make",
            pattern: "Vehicle identification and specifications",
            visualizationRecommendation: "together",
            fieldSubtype: "category"
            }
        ]
        },
        {
        name: "electric-range",
        types: [{ type: "metric", fieldSubtype: "integer" }]
        },
        {
        name: "base-msrp",
        types: [{ type: "metric", fieldSubtype: "currency" }]
        }
    ],
    descriptionAi: {
        "vin-(1-10)": {
        description: "First 10 characters of the Vehicle Identification Number, serving as a unique identifier for each electric vehicle in the registry",
        type: "categorical",
        subtype: "text"
        },
        "county": {
        description: "County of vehicle registration",
        type: "location",
        subtype: "county"
        },
        "city": {
        description: "City of vehicle registration",
        type: "location",
        subtype: "city"
        },
        "state": {
        description: "State of vehicle registration",
        type: "meta",
        subtype: "dimension"
        },
        "postal-code": {
        description: "ZIP code of vehicle registration",
        type: "location",
        subtype: "ZIP"
        },
        "model-year": {
        description: "Year of the vehicle model",
        type: "datetime",
        subtype: "year"
        },
        "make": {
        description: "Manufacturer of the electric vehicle",
        type: "meta",
        subtype: "dimension"
        },
        "model": {
        description: "Specific model of the electric vehicle",
        type: "categorical",
        subtype: "category"
        },
        "electric-vehicle-type": {
        description: "Type of electric vehicle (e.g., BEV, PHEV)",
        type: "categorical",
        subtype: "category"
        },
        "clean-alternative-fuel-vehicle-(cafv)-eligibility": {
        description: "Eligibility status for Clean Alternative Fuel Vehicle incentives",
        type: "categorical",
        subtype: "status"
        },
        "electric-range": {
        description: "Range of the vehicle in miles on electric power",
        type: "metric",
        subtype: "integer"
        },
        "base-msrp": {
        description: "Manufacturer's Suggested Retail Price (MSRP) of the vehicle",
        type: "metric",
        subtype: "currency"
        },
        "legislative-district": {
        description: "Legislative district where the vehicle is registered",
        type: "categorical",
        subtype: "category"
        },
        "dol-vehicle-id": {
        description: "Department of Licensing's unique ID for the vehicle",
        type: "categorical",
        subtype: "text"
        },
        "vehicle-location": {
        description: "Latitude and longitude of the vehicle's registration location",
        type: "location",
        subtype: "long_lat"
        },
        "electric-utility": {
        description: "Electric utility serving the vehicle's registration area",
        type: "categorical",
        subtype: "text"
        },
        "2020-census-tract": {
        description: "2020 census tract ID for the vehicle's registration area",
        type: "categorical",
        subtype: "text"
        }
    }
};

export const API_Mock: any = {
    id: "b6c356f0-f6fe-4b20-ba92-3f3feb94cc96",
    name: "Population Data",
    descriprion: "https://api.census.gov/",
    format: "API",
    filePath: "04ec2f5a-74da-4955-acc3-8ce35e1b134f/ac5328c4-40df-468d-b58b-e594c02b5851.csv",
    createdAt: "Mon Jan 06 2025 16:01:35 GMT+0000 (Coordinated Universal Time)",
    updatedAt: "Mon Jan 06 2025 16:01:35 GMT+0000 (Coordinated Universal Time)",
    userId: "04ec2f5a-74da-4955-acc3-8ce35e1b134f",
    source: "database",
    sourceType: "database",
    fields: [
        {
        label: "vehicle_id_partial",
        key: "vin-(1-10)",
        icon: "categorical",
        type: "categorical",
        subtype: "text",
        primary: false
        },
        {
        label: "registration_county",
        key: "county",
        icon: "location",
        type: "location",
        subtype: "county",
        primary: false
        },
        {
        label: "registration_city",
        key: "city",
        icon: "location",
        type: "location",
        subtype: "city",
        primary: false
        },
        {
        label: "registration_state",
        key: "state",
        icon: "meta",
        type: "meta",
        subtype: "dimension",
        explainedColumns: ["city"],
        primary: false
        },
        {
        label: "zip_code",
        key: "postal-code",
        icon: "location",
        type: "location",
        subtype: "ZIP",
        primary: false
        },
        {
        label: "vehicle_year",
        key: "model-year",
        icon: "datetime",
        type: "datetime",
        subtype: "year",
        primary: false
        },
        {
        label: "manufacturer",
        key: "make",
        icon: "meta",
        type: "meta",
        subtype: "dimension",
        explainedColumns: ["model"],
        primary: false
        },
        {
        label: "vehicle_model",
        key: "model",
        icon: "categorical",
        type: "categorical",
        subtype: "category",
        primary: false
        },
        {
        label: "ev_type",
        key: "electric-vehicle-type",
        icon: "categorical",
        type: "categorical",
        subtype: "category",
        primary: false
        },
        {
        label: "cafv_status",
        key: "clean-alternative-fuel-vehicle-(cafv)-eligibility",
        icon: "categorical",
        type: "categorical",
        subtype: "status",
        primary: false
        },
        {
        label: "range_miles",
        key: "electric-range",
        icon: "metric",
        type: "metric",
        subtype: "integer",
        primary: false
        },
        {
        label: "retail_price",
        key: "base-msrp",
        icon: "metric",
        type: "metric",
        subtype: "currency",
        primary: false
        },
        {
        label: "leg_district",
        key: "legislative-district",
        icon: "categorical",
        type: "categorical",
        subtype: "category",
        primary: false
        },
        {
        label: "license_id",
        key: "dol-vehicle-id",
        icon: "categorical",
        type: "categorical",
        subtype: "text",
        primary: false
        },
        {
        label: "geo_location",
        key: "vehicle-location",
        icon: "location",
        type: "location",
        subtype: "long_lat",
        primary: false
        },
        {
        label: "power_provider",
        key: "electric-utility",
        icon: "categorical",
        type: "categorical",
        subtype: "text",
        primary: false
        },
        {
        label: "census_tract_id",
        key: "2020-census-tract",
        icon: "categorical",
        type: "categorical",
        subtype: "text",
        primary: false
        }
    ],
    fieldsAi: [
        {
        name: "vin-(1-10)",
        types: [{ type: "categorical", fieldSubtype: "text" }]
        },
        {
        name: "county",
        types: [{ type: "location", fieldSubtype: "county" }]
        },
        {
        name: "city",
        types: [{ type: "location", fieldSubtype: "city" }]
        },
        {
        name: "state",
        types: [
            {
            type: "group",
            fieldType: "location",
            childColumns: [
                { newName: "registration_city", originalName: "city", subtype: "city", type: "location" },
                { newName: "zip_code", originalName: "postal-code", subtype: "ZIP", type: "location" },
                { newName: "registration_county", originalName: "county", subtype: "county", type: "location" }
            ],
            childColumnsName: "geographic divisions",
            groupRelationship: {
                type: "parent-child",
                subtype: "subdivision",
                description: "Classic geographic subdivision hierarchy where each level represents a more granular administrative division"
            },
            parentColumn: "state",
            pattern: "Geographic location hierarchy",
            visualizationRecommendation: "together",
            fieldSubtype: "state"
            }
        ]
        },
        {
        name: "postal-code",
        types: [{ type: "location", fieldSubtype: "ZIP" }]
        },
        {
        name: "model-year",
        types: [{ type: "datetime", fieldSubtype: "year" }]
        },
        {
        name: "make",
        types: [
            {
            type: "group",
            fieldType: "categorical",
            childColumns: [
                { newName: "vehicle_model", originalName: "model", subtype: "category", type: "categorical" },
                { newName: "vehicle_year", originalName: "model-year", subtype: "year", type: "datetime" },
                { newName: "ev_type", originalName: "electric-vehicle-type", subtype: "category", type: "categorical" },
                { newName: "range_miles", originalName: "electric-range", subtype: "integer", type: "metric" }
            ],
            childColumnsName: "vehicle specifications",
            groupRelationship: {
                type: "parent-child",
                subtype: "subdivision",
                description: "Vehicle manufacturer subdivides into specific models and their characteristics"
            },
            parentColumn: "make",
            pattern: "Vehicle identification and specifications",
            visualizationRecommendation: "together",
            fieldSubtype: "category"
            }
        ]
        },
        {
        name: "electric-range",
        types: [{ type: "metric", fieldSubtype: "integer" }]
        },
        {
        name: "base-msrp",
        types: [{ type: "metric", fieldSubtype: "currency" }]
        }
    ],
    descriptionAi: {
        "vin-(1-10)": {
        description: "First 10 characters of the Vehicle Identification Number, serving as a unique identifier for each electric vehicle in the registry",
        type: "categorical",
        subtype: "text"
        },
        "county": {
        description: "County of vehicle registration",
        type: "location",
        subtype: "county"
        },
        "city": {
        description: "City of vehicle registration",
        type: "location",
        subtype: "city"
        },
        "state": {
        description: "State of vehicle registration",
        type: "meta",
        subtype: "dimension"
        },
        "postal-code": {
        description: "ZIP code of vehicle registration",
        type: "location",
        subtype: "ZIP"
        },
        "model-year": {
        description: "Year of the vehicle model",
        type: "datetime",
        subtype: "year"
        },
        "make": {
        description: "Manufacturer of the electric vehicle",
        type: "meta",
        subtype: "dimension"
        },
        "model": {
        description: "Specific model of the electric vehicle",
        type: "categorical",
        subtype: "category"
        },
        "electric-vehicle-type": {
        description: "Type of electric vehicle (e.g., BEV, PHEV)",
        type: "categorical",
        subtype: "category"
        },
        "clean-alternative-fuel-vehicle-(cafv)-eligibility": {
        description: "Eligibility status for Clean Alternative Fuel Vehicle incentives",
        type: "categorical",
        subtype: "status"
        },
        "electric-range": {
        description: "Range of the vehicle in miles on electric power",
        type: "metric",
        subtype: "integer"
        },
        "base-msrp": {
        description: "Manufacturer's Suggested Retail Price (MSRP) of the vehicle",
        type: "metric",
        subtype: "currency"
        },
        "legislative-district": {
        description: "Legislative district where the vehicle is registered",
        type: "categorical",
        subtype: "category"
        },
        "dol-vehicle-id": {
        description: "Department of Licensing's unique ID for the vehicle",
        type: "categorical",
        subtype: "text"
        },
        "vehicle-location": {
        description: "Latitude and longitude of the vehicle's registration location",
        type: "location",
        subtype: "long_lat"
        },
        "electric-utility": {
        description: "Electric utility serving the vehicle's registration area",
        type: "categorical",
        subtype: "text"
        },
        "2020-census-tract": {
        description: "2020 census tract ID for the vehicle's registration area",
        type: "categorical",
        subtype: "text"
        }
    }
};