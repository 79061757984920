import styled, { css } from "styled-components";
import { ReactComponent as Xls } from "../../../../../../assets/xls.svg";
import { ChevronCompactRight, ExclamationCircleFill, SlashLg, Trash } from "react-bootstrap-icons";

interface ContainerProps {
  $datasetLibraryModalOpened?: boolean;
  $datasetOverviewModalOpened?: boolean;
  $previewOpen?: boolean;
  $selected?: boolean;
}

export const TrashIcon = styled(Trash)`
  cursor: pointer;
  fill: #5F6877;
`;

const uploadDatasetContainer = css<ContainerProps>`
  border-top: 1px solid lightgray !important;
  border-right: ${({ $datasetOverviewModalOpened, theme }) =>
    $datasetOverviewModalOpened && `2px solid ${theme.primaryBorder}`};
  background-color: ${({ $selected, $datasetOverviewModalOpened, theme }) =>
    $selected
      ? theme.primaryBackground
      : $datasetOverviewModalOpened
        ? theme.neutralBackgroundActive
        : "unset"};
`;

const datasetLibraryContainer = css<ContainerProps>`
  border-top: 1px solid lightgray;
  background-color: ${({ $selected, $datasetOverviewModalOpened, theme }) =>
    $selected
      ? theme.primaryBackground
      : $datasetOverviewModalOpened
        ? "unset"
        : "unset"};

  &:first-child {
    border-top: none;
  }
`;

const previewOpen = css`
  background-color: ${({ theme }) => theme.neutralBorder};
`;

export const FileIcon = styled(Xls) <{ $itemsSelected?: boolean }>`
  align-self: center;
  cursor: pointer;
  display: ${({ $itemsSelected }) => ($itemsSelected ? "none" : "block")};
`;

export const DatasetRowContainer = styled.div<ContainerProps>`
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  width: 100%;
  padding: 13px 12px;
  border-right: 2px solid transparent;
  transition: 0.3s;

  &:active {
    background: ${({ theme }) => theme.neutralBackgroundActive};
    border-color: ${({ theme }) => theme.primaryBackgroundStrong};
  }

  ${({ $previewOpen }) => $previewOpen && previewOpen}
  ${({ $datasetLibraryModalOpened }) =>
    $datasetLibraryModalOpened
      ? datasetLibraryContainer
      : uploadDatasetContainer}
`;

export const DataSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 253px;
  width: 100%;
  margin-left: 8px;
  margin-right: 20px;
`;

export const DataSetFormat = styled.div`
  text-transform: uppercase;
  margin-left: auto;
  line-height: 20px;
  font-weight: 400;
  min-width: 144px;
  font-size: 14px;
`
export const DataSetUploadedOn = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  min-width: 120px;
  margin-left: 12px;
`

export const DataSetDelete = styled.div`
  width: 20px;
  margin: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const DatasetHeadingWrapper = styled.div`
  display: flex;
  font-size: 14px;
  height: 20px;
  width: 100%;
  font-weight: 600;
  gap: 19px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const DatasetHeading = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
  /* max-width: 450px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DateSpan = styled.div`
  color: ${({ theme }) => theme.neutralText};
`;

export const HoursSpan = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const DatasetMeta = styled.div`
  gap: 4px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
`;

export const DatasetDimensionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 8px 0;
`;

export const AppClasses = styled.div`
  user-select: none;
  display: flex;
  height: 20px;
  padding: 2px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  background: #6c75841f;
  overflow: hidden;
  color: #21262e;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  
  &:hover {
    cursor: pointer;
    background-color: white;
    border: 1px solid #CFD2D6;
  }
`;

export const DimensionsCount = styled.div`
  background-color: ${({ theme }) => theme.neutralBackground};
  border-radius: 5px;
  padding: 4px 6px;
  font-size: 12px;
  color: ${({ theme }) => theme.neutralText};
  cursor: pointer;
`;

export const DimensionsSpan = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-left: 28px;
`;

export const Slash = styled(SlashLg)`
  display: block;
`;
export const Arrow = styled(ChevronCompactRight)`
  display: none;
`;

export const PreviewButton = styled.div<{ $disabled?: boolean, $error?: boolean }>`
  display: flex;
  color: ${({ theme, $disabled, $error }) => $disabled ? theme.neutralTextDisabled : $error ? theme.alertText : theme.primaryText};
  ${Slash} {
    color: ${({ theme, $disabled, $error }) => $disabled ? theme.neutralTextDisabled : $error ? theme.alertText : theme.primaryText};
  }
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  align-items: center;
  align-self: center;
  cursor: ${({ $disabled }) => $disabled ? 'default' : 'pointer'};
  &:hover{
    color: ${({ theme, $disabled, $error }) => $disabled ? theme.neutralTextDisabled : $error ? theme.alertText : theme.primaryTextHover};
    ${Slash} {
      display: none;
      color: ${({ theme, $disabled, $error }) => $disabled ? theme.neutralTextDisabled : $error ? theme.alertText : theme.primaryTextHover};
    }
    ${Arrow} {
      display: block;
      color: ${({ theme, $disabled, $error }) => $disabled ? theme.neutralTextDisabled : $error ? theme.alertText : theme.primaryTextHover};
    }
  }
`;

export const ErrorMessageWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.alertText};
`;

export const ErrorIcon = styled(ExclamationCircleFill)`
  width: 12px;
  height: 12px;
  fill: #c53660;
`;

export const PreviewOpenedDimensions = styled.div`
`

export const RowWrapper = styled.div`
display: flex;
`