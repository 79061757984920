export const getRegionNameFromLayerData = (
  data: Record<string, any>,
  mapType?: string
): string => {
  if (!data || !mapType) {
    return "";
  }

  switch (mapType) {
    case "county":
      return `${data?.county}, ${data?.state}` || "";
    case "state":
      return `${data?.state}` || "";
    case "country":
      return `${data?.country}` || "";
    default:
      return "";
  }
};
