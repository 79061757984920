import styled from "styled-components";

export const ModalTitle = styled.h1`
  color: #000417;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
`;

export const ModalDescription = styled.p`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
`;
