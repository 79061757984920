import { useCallback, useEffect, useState } from "react";
import { MessageTypeEnum } from "../constants";
import { Message } from "../types";

export interface IChatMessage {
  action: MessageTypeEnum;
  instructions: Record<string, any>;
}

const DEFAULT_URL = "wss://dev-chat-api.fuselabcreative.com"

const useChatWS = () => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const connectWebSocket = useCallback(() => {
    // const token = "1";
    // const userId = "1";
    
    try {
      const socketInstance = new WebSocket(
        `${process.env.REACT_APP_WEBSOKET_URL || DEFAULT_URL}/chat`
      );

      socketInstance.onopen = () => {
        setIsConnected(true);
        setError(null);
      };

      socketInstance.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          setMessages((prev) => [...prev, response]);
        } catch (error) {
          console.error("Error parsing message:", error);
          setError(error instanceof Error ? error : new Error('Failed to parse message'));
        }
      };

      socketInstance.onclose = (event) => {
        setIsConnected(false);
        if (!event.wasClean) {
          setError(new Error(`WebSocket connection closed unexpectedly: ${event.code}`));
        }
      };

      socketInstance.onerror = (error) => {
        setError(error instanceof Error ? error : new Error('WebSocket error occurred'));
        setIsConnected(false);
      };

      setSocket(socketInstance);

      return () => {
        socketInstance.close();
        setSocket(null);
      };
    } catch (error) {
      setError(error instanceof Error ? error : new Error('Failed to connect'));
      return () => {};
    }
  }, []);

  useEffect(() => {
    const cleanup = connectWebSocket();
    return cleanup;
  }, [connectWebSocket]);

  const sendMessage = useCallback(
    async (message: IChatMessage): Promise<boolean> => {
      if (!socket || socket.readyState !== WebSocket.OPEN) {
        return false;
      }

      try {
        socket.send(JSON.stringify(message));
        return true;
      } catch (error) {
        console.error('Failed to send message:', error);
        return false;
      }
    },
    [socket]
  );

  return {
    messages,
    sendMessage,
    isConnected,
    error,
  };
};

export default useChatWS;
