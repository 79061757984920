import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getDatasetField } from "../helpers/getDatasetField";
import { DatasetFiledDTO } from "../models/Datasets";
import { getCurrentProjectData } from "../store/selectors/projects";

export const useDatasetField = (
  key: string | undefined
): DatasetFiledDTO | undefined => {
  const datasets = useSelector(getCurrentProjectData)?.datasets;

  return useMemo(() => {
    if (!key || !datasets || !Array.isArray(datasets)) {
      return undefined;
    }

    return getDatasetField(key, datasets) || undefined;
  }, [datasets, key]);
};
