import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);

`;
export const ResourcesWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 32px;
`;

export const HeadingPage = styled.div`
  padding: 20px 0;
  color: #21262E;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  width: 100%;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const DataHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeadingText = styled.p`
  color: #21262E;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ApplicationWrapper = styled.div`
  min-width: 400px;
`