import { getRegionIdFromFeatureProperties } from "./getRegionIdFromFeatureProperties";

export function getGeoJsonData(
  geoJsonProperties: GeoJSON.GeoJsonProperties,
  mapType?: string
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  if (!geoJsonProperties || !mapType) {
    return {
      type: "FeatureCollection",
      features: [],
    };
  }

  return {
    type: "FeatureCollection",
    features: geoJsonProperties?.features?.map(
      (feature: { id: number | string; properties: any; geometry: any }) => ({
        type: "Feature",
        id: feature.id,
        properties: {
          id: getRegionIdFromFeatureProperties(feature.properties, mapType),
        },
        geometry: feature.geometry,
      })
    ),
  };
}
