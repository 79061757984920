import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* background-color: #9FA4AD14; */
  /* padding: 16px; */
  /* border-top: 1px dashed #DFE1E4; */
`