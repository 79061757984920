import styled from "styled-components";

export const FileContainer = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-wrap: ${({ $isExpanded }) => ($isExpanded ? "wrap" : "nowrap")};
  margin: 0 -8px;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px dashed #dfe1e4;
`;

export const FileItem = styled.div`
  position: relative;
  max-width: 160px;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #dfe1e4;
  cursor: pointer;
  &:hover {
    background: #9fa4ad1f;
    .file-close-btn {
      opacity: 1;
    }
  }
`;

export const FileDeleteIconWrapper = styled.div`
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 100%;
  background: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  border: 1px solid #cfd2d6;
  padding: 2px;
`;

export const FileName = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FileExtension = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 10px;
  background: #9fa4ad1f;
  font-weight: 600;
`;
