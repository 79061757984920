import React, { Fragment, useEffect, useState } from "react";
import { GEOCODING_URL } from "../../../api/constants";
import axios from "axios";
import axiosInstance from "../../../api/axios";
import { IMarker, RealEstateMap } from "../../RealEstateMap";
import { HouseSettings } from "../../RealEstateMap/types";
import { useSelector } from "react-redux";
import {
  getCurrentProjectData,
  getPageSettings,
} from "../../../store/selectors/projects";
import {
  getAiKpis,
  getAiSuggestions,
  getLoadingAiSuggestions,
} from "../../../store/selectors/widgets";
import { LayoutRealEstateContainer } from "../LayoutRealEstate/styles";
import { Loader } from "../../Loader";
import { Col, Row } from "react-flexbox-grid";
import { KPISettingsWrapper } from "../../KPISettingsWrapper";
import { WIDGETS } from "../../Widgets/widgets";
import { DataColumn, MapColumn, WrapperRow, WrapperWidget } from "./styles";
import { AiKpiDto, WidgetItem } from "../../../models/Widgets";
import { getLineChartColors } from "../../Widgets/LineChart/utils/getLineChartMarkers";

export enum KPIColumn {
  ZESTIMATE = "zestimate",
  REAL_ZESTIMATE = "rent_zestimate",
  PRICE = "price",
  WALK_SCORE = "walk_score",
  BIKE_SCORE = "bike_score",
}

export enum ChartType {
  TABLE_CHART = "tableChart",
  TEXT_BUTTON_CHART = "textButtonChart",
  LINE_CHART = "lineChart",
  POLAR_AREA_CHART = "polarAreaChart",
}

export enum ChartName {
  PRICE_HISTORY = "Price history",
  TAX_HISTORY = "Tax history",
  NEARBY_SCHOOLS = "Nearby schools",
}

export type ChartProps = {
  chartSuggestions: WidgetItem[] | undefined;
};

export function EstateMetricsLayout() {
  const TOP_LAYOUT_KPIS = [
    KPIColumn.ZESTIMATE,
    KPIColumn.REAL_ZESTIMATE,
    KPIColumn.PRICE,
  ];
  const BOTTOM_LAYOUT_KPIS = [KPIColumn.BIKE_SCORE, KPIColumn.WALK_SCORE];

  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [mapConfig, setMapConfig] = useState<HouseSettings>(); // Default to US center
  const [isLoadingMap, setIsLoadingMap] = useState(true); // Start with loading true
  const [mapError, setMapError] = useState<string | null>(null);
  const [pageHasLoaded, setPageHasLoaded] = useState<boolean>(true);

  const MLChartSuggestions = useSelector(getAiSuggestions);
  const MLKPIs = useSelector(getAiKpis);
  const currentPage = useSelector(getPageSettings);
  const currentProjectData = useSelector(getCurrentProjectData);
  const isLoadingSuggestions = useSelector(getLoadingAiSuggestions);

  useEffect(() => {
    const getCoordinates = async () => {
      if (!pageHasLoaded) return;

      setIsLoadingMap(true);
      setMapError(null);

      try {
        const location = currentPage?.location;
        const geocodingUrl = `${GEOCODING_URL}/${encodeURIComponent(
          location || ""
        )}.json`;

        const geoResponse = await axios.get(geocodingUrl, {
          params: {
            access_token: process.env.REACT_APP_MAPBOX_REAL_ESTATE_ACCESS_TOKEN,
            limit: 1,
          },
        });
        const { features } = geoResponse.data;

        if (!features?.length) throw new Error("Location not found");

        const file = currentProjectData.files?.[0];
        const [longitude, latitude] = features[0].center;

        setMapConfig({
          latitude: file?.mapBoxConfig?.coordinates[1] ?? 0,
          longitude: file?.mapBoxConfig?.coordinates[0] ?? 0,
          rotateX: file?.mapBoxConfig?.rotateX ?? 0,
          rotateY: file?.mapBoxConfig?.rotateY ?? 0,
          rotateZ: file?.mapBoxConfig?.rotateZ ?? 0,
          scale: file?.mapBoxConfig?.scale ?? 1,
        });

        const propertiesResponse = await axiosInstance.post(
          "/data-provider/api/zillow/search",
          {
            location,
            coordinates: { longitude, latitude },
            page: 1,
            status: "forSale",
            getAll: false,
          }
        );

        if (Array.isArray(propertiesResponse.data) && propertiesResponse.data) {
          setMarkers(() => {
            const selectedEstateMarker = propertiesResponse.data
              .map((marker: IMarker) => ({
                longitude: marker.longitude ?? 0,
                latitude: marker.latitude ?? 0,
                imgSrc: marker.imgSrc,
                price: marker.price,
                streetAddress: marker.streetAddress,
                zpid: marker.zpid,
              }))
              .map((marker: IMarker) =>
                String(marker.zpid) === currentPage.zpId ? marker : null
              )
              .filter(Boolean);

            const { latitude, longitude } = selectedEstateMarker.at(0);

            setMapConfig({
              latitude: latitude ?? 0,
              longitude: longitude ?? 0,
              rotateX: 0,
              rotateY: 0,
              rotateZ: 0,
              scale: 1,
            });

            return selectedEstateMarker;
          });
        }
      } catch (error) {
        setMapError(
          error instanceof Error ? error.message : "Failed to load map data"
        );
      } finally {
        if (pageHasLoaded) setIsLoadingMap(false);
      }
    };

    getCoordinates();

    return () => setPageHasLoaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage?.location, currentProjectData?.files]);

  const KPIsToLayoutTop = MLKPIs?.filter((kpi: AiKpiDto) =>
    TOP_LAYOUT_KPIS.includes(kpi.column as KPIColumn)
  );
  const KPIsToLayoutBottom = MLKPIs?.filter((kpi: AiKpiDto) =>
    BOTTOM_LAYOUT_KPIS.includes(kpi.column as KPIColumn)
  );

  return (
    <LayoutRealEstateContainer className="estate-metrics-layout">
      {isLoadingSuggestions && <Loader fullScreen />}

      <WrapperRow>
        <DataColumn lg={6}>
          {KPIsToLayoutTop && (
            <Row>
              <Col lg={6}>
                <WrapperWidget noMarginRight>
                  <KPISettingsWrapper
                    recommended
                    storytelling={false}
                    kpis={KPIsToLayoutTop}
                  />
                </WrapperWidget>
              </Col>

              <HistoryChart chartSuggestions={MLChartSuggestions} />
            </Row>
          )}

          <PriceTables chartSuggestions={MLChartSuggestions} />

          <Row>
            {KPIsToLayoutBottom && (
              <Col lg={12}>
                <WrapperWidget>
                  <KPISettingsWrapper
                    recommended
                    storytelling={false}
                    kpis={KPIsToLayoutBottom}
                  />
                </WrapperWidget>
              </Col>
            )}
          </Row>

          <NearbySchoolTable chartSuggestions={MLChartSuggestions} />
          <BottomCharts chartSuggestions={MLChartSuggestions} />
        </DataColumn>

        <MapColumn lg={12}>
          <Row
            style={{
              border: "1px #D3DBE3 solid",
              position: "relative",
              height: "calc(100vh - 136px)",
              marginLeft: "0px",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            {isLoadingMap && <Loader />}
            {mapError && (
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  padding: "1rem",
                  color: "red",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  borderRadius: "4px",
                  margin: "1rem",
                }}
              >
                {mapError}
              </div>
            )}
            <RealEstateMap
              markers={markers}
              houseSettings={mapConfig}
              threeDModelPath={currentProjectData?.files?.[0]}
            />
          </Row>
        </MapColumn>
      </WrapperRow>
    </LayoutRealEstateContainer>
  );
}

function HistoryChart({ chartSuggestions }: ChartProps) {
  if (!chartSuggestions) return null;

  const historyChart = chartSuggestions.find(
    (widget) => widget.chartType === ChartType.LINE_CHART
  );

  if (!historyChart || !historyChart.data?.length) return null;

  return (
    <Col lg={6}>
      <WrapperWidget noMarginLeft>
        {React.createElement(WIDGETS[historyChart.chartType], {
          storytelling: false,
          currentWidget: {
            ...historyChart,
            formatting: getLineChartColors(historyChart),
          },
          recommended: false,
          showLegend: true,
        })}
      </WrapperWidget>
    </Col>
  );
}

function PriceTables({ chartSuggestions }: ChartProps) {
  if (!chartSuggestions) return null;

  const tableWidgets = chartSuggestions
    .filter(
      (widget) =>
        widget.name === ChartName.TAX_HISTORY ||
        widget.name === ChartName.PRICE_HISTORY
    )
    .map((widget, index) => {
      if (!widget || !widget.data?.length) return null;

      return (
        <Row key={index}>
          <Col lg={12}>
            <WrapperWidget>
              {React.createElement(WIDGETS[widget.chartType], {
                storytelling: false,
                currentWidget: widget,
                recommended: false,
                showLegend: true,
              })}
            </WrapperWidget>
          </Col>
        </Row>
      );
    })
    .filter(Boolean);

  if (!tableWidgets.length) return null;

  return <Fragment>{tableWidgets}</Fragment>;
}

function NearbySchoolTable({ chartSuggestions }: ChartProps) {
  if (!chartSuggestions) return null;

  const nearbySchoolsWidget = chartSuggestions.find(
    (widget) => widget.name === ChartName.NEARBY_SCHOOLS
  );

  if (!nearbySchoolsWidget || !nearbySchoolsWidget.data?.length) return null;

  return (
    <Row>
      <Col lg={12}>
        <WrapperWidget>
          {React.createElement(WIDGETS[nearbySchoolsWidget.chartType], {
            storytelling: false,
            currentWidget: nearbySchoolsWidget,
            recommended: false,
            showLegend: true,
          })}
        </WrapperWidget>
      </Col>
    </Row>
  );
}

function BottomCharts({ chartSuggestions }: ChartProps) {
  if (!chartSuggestions) return null;

  const charts = chartSuggestions
    .filter((widget) => widget.chartType !== ChartType.TABLE_CHART)
    .map((widget) => {
      if (!widget || widget.data?.length) return null;

      return (
        <Row>
          <Col lg={6}>
            <WrapperWidget>
              {React.createElement(WIDGETS[widget.chartType], {
                storytelling: false,
                currentWidget: widget,
                recommended: false,
                showLegend: true,
              })}
            </WrapperWidget>
          </Col>
        </Row>
      );
    })
    .filter(Boolean);

  if (!charts.length) return null;

  return <Fragment>{charts}</Fragment>;
}
