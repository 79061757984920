import React, { useLayoutEffect, useRef, useState, useCallback } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import {
  CheckBoxWrapper,
  Container,
  HeadNameList,
  ListOption,
  OptionsWrapper,
  TextArea,
  TextAreaLabel,
} from "./styled";
import { CheckBox } from "../../../../Inputs/CustomCheckbox/CheckBox";
import { toCamelCase } from "../../utils/toCamelCase";
import { FeedBackKeys, IFeedBack } from "../../../../../store/slices/feedback";

type Props = {
  feedback: IFeedBack;
  setFeedBack: React.Dispatch<React.SetStateAction<IFeedBack>>;
  item?: {
    name: string;
    issues: {
      name: string;
      description: string;
    }[];
  };
  other?: boolean;
};

const OptionsList: React.FC<Props> = ({
  item,
  other,
  feedback,
  setFeedBack,
}) => {
  const [active, setActive] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const textbox = useRef<HTMLTextAreaElement>(null);

  const listKey = toCamelCase(item?.name ?? "") as FeedBackKeys;

  const selectedValues = useCallback(() => {
    return other ? [] : feedback[listKey]?.[0]?.issues ?? [];
  }, [feedback, listKey, other]);

  const adjustHeight = useCallback(() => {
    if (!textbox.current) return;

    textbox.current.style.height = "inherit";
    textbox.current.style.height = `${textbox.current.scrollHeight}px`;

    setIsScroll(textbox.current.scrollHeight > 100);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(adjustHeight, [feedback.additionalComments]);

  const handleKeyDown = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      adjustHeight();
      setFeedBack((prev) => ({
        ...prev,
        additionalComments: e.target.value,
      }));
    },
    [adjustHeight, setFeedBack]
  );

  const toggleSelection = useCallback(
    (name: string, description: string) => {
      const values = selectedValues();
      const isSelected = values.some(
        (issue) => issue.name === name && issue.description === description
      );

      const updatedIssues = isSelected
        ? values.filter(
            (issue) => issue.name !== name || issue.description !== description
          )
        : [...values, { name, description }];

      setFeedBack((prev) => ({
        ...prev,
        [listKey]: [{ name: item?.name, issues: updatedIssues }],
      }));
    },
    [selectedValues, setFeedBack, listKey, item?.name]
  );

  const renderIssues = useCallback(() => {
    return (
      item?.issues.map(({ name, description }) => (
        <ListOption key={`${name}-${description}`}>
          <CheckBoxWrapper>
            <CheckBox
              onChange={() => toggleSelection(name, description)}
              checked={selectedValues().some(
                (issue) =>
                  issue.name === name && issue.description === description
              )}
            />
          </CheckBoxWrapper>
          {name}: {description}
        </ListOption>
      )) || null
    );
  }, [item?.issues, toggleSelection, selectedValues]);

  return (
    <Container $other={other}>
      {!other && (
        <HeadNameList
          $active={active}
          onClick={() => setActive((prev) => !prev)}
        >
          <ChevronRight /> {item?.name || "Other"}
        </HeadNameList>
      )}

      {active && item && <OptionsWrapper>{renderIssues()}</OptionsWrapper>}
      {other && (
        <>
          <TextAreaLabel>Please describe your experience</TextAreaLabel>
          <TextArea
            placeholder="Type here your ideas"
            $isScroll={isScroll}
            ref={textbox}
            onChange={handleKeyDown}
            value={feedback.additionalComments}
          />
        </>
      )}
    </Container>
  );
};

export default OptionsList;
