import { PageDescription, PageHeroContainer, PageTitle } from "./styles";
import SearchRealEstate from "../../components/SearchRealEstate";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { requestProjectById } from "../../store/slices/projects";
import { getCurrentProjectData } from "../../store/selectors/projects";
import { RealEstateMap } from "../../components/RealEstateMap";

const RealEstateExplore = () => {
  const dispatch = useDispatch();
  const { id: projectId } = useParams();
  const currentProject = useSelector(getCurrentProjectData);

  useEffect(() => {
    if (projectId) {
      dispatch(requestProjectById(projectId));
    }
  }, [projectId, dispatch]);

  const houses = currentProject?.files?.map((file) => {
    return {
      latitude: file.mapBoxConfig?.coordinates[1] ?? 40.748731,
      longitude: file.mapBoxConfig?.coordinates[0] ?? -73.99178,
      rotateX: file.mapBoxConfig?.rotateX ?? 0,
      rotateY: file.mapBoxConfig?.rotateY ?? 0,
      rotateZ: file.mapBoxConfig?.rotateZ ?? 0,
      scale: file.mapBoxConfig?.scale ?? 1,
    };
  });

  return (
    <div style={{ height: "calc(100vh - 64px - 68px - 160px)" }}>
      <PageHeroContainer>
        <PageTitle>
          Start exploring by searching location you are interested
        </PageTitle>
        <PageDescription>
          Start exploring by entering an exact address, ZIP code, city, or
          neighborhood to find detailed analytics related to housing, rental
          prices, population trends, and more.
        </PageDescription>
        <SearchRealEstate />
      </PageHeroContainer>
      <RealEstateMap
        threeDModelPath={currentProject?.files?.[0]}
        houseSettings={houses?.[0]}
      />
    </div>
  );
};

export default RealEstateExplore;
