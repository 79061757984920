import { Navigate, Route, Routes } from "react-router-dom";

import { SignIn } from "../pages/Auth/SignIn";
import { Projects } from "../pages/ProjectsList";
import { Project } from "../pages/Project";
import { Community } from "../pages/Community";
import { SignUp } from "../pages/Auth/SignUp";
import { PasswordReset } from "../pages/Auth/PasswordRest";
import { PrivateRoutes } from "./PrivateRoutes";
import { useSelector } from "react-redux";
import { ErrorPage } from "../pages/404";
import { ProjectPage } from "../pages/ProjectPage";
import { getUser } from "../store/selectors/main";
import { ProfilePage } from "../pages/Profile";
import { SettingsPage } from "../pages/SettingsPage";
import { Resources } from "../pages/Resources";
import * as ResourcesPages from "../pages/Resources/pages";

export const Routing = () => {
  const user = useSelector(getUser);

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate to="/projects" replace={true} />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:id" element={<Project />}>
          <Route path=":pageId" element={<ProjectPage />} />
        </Route>
        <Route path="/resources" element={<Resources />}>
          <Route
            path="datasets/:id"
            element={<ResourcesPages.Datasets.DatasetPreviewPage />}
          />

          {/* <Route path="datasets" element={<ResourcesPages.Datasets.Index />} /> */}

          {/* <Route path="apis" element={<ResourcesPages.Apis />} /> */}

          {/* <Route
            path="public-resources"
            element={<ResourcesPages.PublicResources.Index />}
          />
          <Route
            path="public-resources/:id"
            element={<ResourcesPages.Datasets.DatasetPreviewPage />}
          />
          <Route
            path="public-resources/search"
            element={<ResourcesPages.PublicResources.SearchData />}
          />
          <Route
            path="public-resources/search/:id"
            element={<ResourcesPages.PublicResources.SearchDataById />}
          >
            <Route
              path=":datasetId"
              element={<ResourcesPages.PublicResources.SearchDataById />}
            />
          </Route> */}
        </Route>
        <Route path="/community" element={<Community />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
      <Route path="/projects/public/:id" element={<Project />}>
        <Route path=":pageId" element={<ProjectPage />} />
      </Route>
      <Route
        path="/sign-in"
        element={!user ? <SignIn /> : <Navigate to="/" replace />}
      />
      <Route
        path="/sign-up"
        element={!user ? <SignUp /> : <Navigate to="/" replace />}
      />
      <Route
        path="/forgot-password"
        element={!user ? <PasswordReset /> : <Navigate to="/" replace />}
      />
    </Routes>
  );
};
