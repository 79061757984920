import React from "react";
import KeysTable from "../../components/KeysTable";
import { KeyRow } from "../../types/types";

type Props = {
  rows: KeyRow[];
  setRows: React.Dispatch<React.SetStateAction<KeyRow[]>>;
};
const Headers = ({ rows, setRows }: Props) => {
  return <KeysTable rows={rows} setRows={setRows} />;
};

export default Headers;
