import { Button } from "../../Button";
import { DPAContainer, DPADescription, DPAHeader } from "./styles";
import { WrapperWidget } from "../widgets";

interface DownPaymentAssistanceProps {
  onClick: () => void;
}

const DownPaymentAssistance = ({
  onClick,
  currentWidget,
}: Partial<WrapperWidget> & DownPaymentAssistanceProps) => {
  if (!currentWidget) return null;

  const { name, description } = currentWidget;

  return (
    <DPAContainer>
      <DPAHeader>{name}</DPAHeader>
      <DPADescription>{description}</DPADescription>
      <Button
        name="Check Eligibility"
        onClick={onClick}
        variant="primary"
        size="medium"
      />
    </DPAContainer>
  );
};

export default DownPaymentAssistance;
