import { useEffect, useMemo, useRef, useState } from "react";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import useMeasure from "react-use-measure";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../../store/selectors/projects";
import { getAiSuggestions } from "../../../../store/selectors/widgets";
import { AiSuggestionsDto, WidgetItem } from "../../../../models/Widgets";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  Title,
} from "../../VerticalBarchart/styles";
import { ChartLegend, ChartLegendValue } from "../../../ChartLegend";
import { setActiveModal } from "../../../../store/slices/modals";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleLinearTickValues,
} from "../../widgetHelpers";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import { Tooltip, TooltipProps } from "../../Tooltip";
import { Loader } from "../../../Loader";
import { setCurrentWidget } from "../../../../store/slices/projectPages";
import { SelectBage } from "../../SelectBage";
import { replaceWords } from "../../../../helpers/replaceName";
import { LollipopMarkersHorizontal } from "../../utils/getMarker";
import {
  get_data,
  get_xAxe,
  get_yAxe,
  getGroupedData,
} from "../utils/getLollipopChartMarkers";
import {
  getCurrentColor,
  getCurrentMarker,
} from "../../utils/getCurrentMarker";
import { TickLabel } from "../../components/LabelTooltip";
import { createPortal } from "react-dom";
import { getPosition } from "../utils/getLollipopAlign";
import { getActiveModal } from "../../../../store/selectors/modals";
import { useDatasetField } from "../../../../hooks/useDatasetField";
import { formatLabelValue } from "../../utils/formatLabelValue";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../../utils/feedback";
import { FeedBackButton, WidgetImageWrapper } from "../../styles";

interface LollipopInterface {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  hideSettings?: boolean;
}

export const HorizontalLollipopChart = ({
  storytelling,
  recommended,
  showLegend = true,
  selected = false,
  currentWidget,
  hideName = false,
  hideSettings = false,
}: LollipopInterface) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const [refChartWrapper, boundsChartWrapper] = useMeasure();
  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [groupedData, setGroupedData] = useState<{
    [key: string]: { x: number; y: number }[];
  }>({});
  const [xAxes, setXAxes] = useState<number[]>([]);
  const [yAxes, setYAxes] = useState<string[]>([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [legendValues, setLegendValues] = useState<ChartLegendValue[]>([]);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);
  const yScaleDatasetField = useDatasetField(xAxe);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const barHeight = 10;
  const xAxeHeight = 20;

  const calculatedHeight =
    yAxes.length * ((Object.keys(groupedData).length + 2) * barHeight);

  const width = bounds.width || 1084;
  const height =
    calculatedHeight > bounds.height ? calculatedHeight : bounds.height;

  const hasChartYOverflow = calculatedHeight > boundsChartWrapper.height;

  const hasLongBarValue = useMemo(
    () => yAxes?.some((key) => key.length > 6),
    [yAxes]
  );

  const margin = {
    top: 1,
    right: 1,
    bottom: 0,
    left: hasLongBarValue ? 80 : 40,
  };

  const yScale = scaleBand({
    domain: yAxes,
    range: [margin.top, height],
    padding: 0,
  });

  const maxXAxe = Math.max(...xAxes);

  const roundedMaxXAxe = useMemo(
    () =>
      Math.ceil(
        maxXAxe / Math.pow(10, Number(Math.floor(Math.log10(maxXAxe))))
      ) * Math.pow(10, Number(Math.floor(Math.log10(maxXAxe)))),
    [maxXAxe]
  );

  const xScale = scaleLinear({
    domain: [0, roundedMaxXAxe],
    nice: true,
    range: [margin.left, width - margin.right],
  });

  const xTicks = xScale.ticks();

  const xScaleTickValues = useMemo(() => {
    const xScaleNumTicksCalculated = calculateNumTicks({ width });

    const xScaleNumTicks =
      xScaleNumTicksCalculated <= xTicks.length
        ? xScaleNumTicksCalculated
        : xTicks.length;

    return getScaleLinearTickValues({
      scale: xScale,
      tickCount: xScaleNumTicks,
    });
  }, [width, xScale, xTicks]);

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: ticksFormatter,
      }),
    [margin.left, margin.right, width, xScaleTickValues]
  );

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  useEffect(() => {
    const lollipopChartSuggestion = aiSuggestions?.find(
      (chart: AiSuggestionsDto) => chart.chartType === "lollipopChart"
    );

    if (currentWidget) {
      const xAxe = get_xAxe(currentWidget, lollipopChartSuggestion);
      setXAxe(xAxe);

      const yAxe = get_yAxe(currentWidget, lollipopChartSuggestion);
      setYAxe(yAxe);

      const data = get_data(currentWidget, lollipopChartSuggestion);

      const xAxes =
        data?.reduce((t: any, l: any) => {
          const y = parseFloat(l.y);
          if (typeof y === "number" && !isNaN(y)) {
            return [...t, y];
          }
          return t;
        }, []) || [];
      setXAxes(xAxes);

      const yAxes: string[] =
        currentWidget?.uniqueValues?.[xAxe] || data?.map((d: any) => d.x) || [];
      setYAxes(yAxes);

      const groupedData: any = getGroupedData(
        currentWidget,
        lollipopChartSuggestion
      );
      setGroupedData(groupedData);

      const groupBy = currentWidget?.groupBy?.at(0);
      if (groupBy && groupBy.length) {
        const newLegendValues = [];

        const uniqueValuesKeys =
          (currentWidget?.uniqueValues &&
            Object.keys(currentWidget?.uniqueValues!)) ||
          [];
        const groupByKey =
          groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

        const chartGroupKeys =
          uniqueValuesKeys?.length && currentWidget?.uniqueValues
            ? currentWidget?.uniqueValues[groupByKey!]
            : Object.keys(groupedData);

        if (groupedData && chartGroupKeys?.length) {
          for (let i = 0; i < chartGroupKeys?.length; i++) {
            const dataKey = chartGroupKeys?.at(i);
            const color = getCurrentColor(currentWidget, dataKey, styleId);
            newLegendValues.push({ label: dataKey!, color });
          }
        }
        setLegendValues(newLegendValues);
      }
    }
  }, [aiSuggestions, currentWidget, styleId]);

  if (groupedData && Object.keys(groupedData).length === 0) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  const gKeys = groupedData ? Object.keys(groupedData) : [];

  return (
    <>
      {feedbackState && <Loader />}

      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper ref={refWidget} style={{ marginBottom: "10px" }}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <div />}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {availableWidgetsCount}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}

          {legendValues?.length > 1 &&
            currentWidget?.formatting?.length! > 1 &&
            showLegend &&
            currentWidget?.legend && (
              <ChartLegend
                chartWidth={boundsWidget.width}
                legendType="unit"
                legendValues={legendValues}
              />
            )}
        </HeaderWrapper>

        <div
          ref={refChartWrapper}
          style={{
            height: "100%",
            flexGrow: 1,
            overflowY: hasChartYOverflow ? "scroll" : "visible",
          }}
        >
          <svg
            width="100%"
            height={hasChartYOverflow ? calculatedHeight : "100%"}
            ref={ref}
          >
            <Group>
              <Group>
                {yAxes.map((value, index) => (
                  <line
                    key={`${value}-${index}`}
                    x1={margin.left}
                    y1={yScale(value)!}
                    x2={width - margin.right}
                    y2={yScale(value)!}
                    stroke="#ccc"
                    strokeDasharray="1 2"
                  />
                ))}
              </Group>

              <Group>
                {xScaleTickValues.map((value: number, index: number) => (
                  <line
                    key={`${value}-${index}`}
                    x1={xScale(value)}
                    y1={margin.top}
                    x2={xScale(value)}
                    y2={height}
                    stroke="#ccc"
                    strokeDasharray="1 2"
                  />
                ))}
              </Group>

              <Group>
                {groupedData &&
                  Object.keys(groupedData)?.map(
                    (key: string, barIndex: number) => {
                      const groupData = groupedData[key] as any;
                      const countGroupValues =
                        key === "default" ? 1 : gKeys?.length;

                      const yVal = getPosition(barIndex, countGroupValues);

                      return groupData?.map((d: any, index: number) => {
                        const color = getCurrentColor(
                          currentWidget,
                          key,
                          styleId
                        );

                        const barKey = `${d.x}-${index}-${barIndex}`;

                        return (
                          <g
                            key={barKey}
                            style={{ transition: "0.3s" }}
                            onMouseMove={(event: any) => {
                              if (
                                (showTooltip || currentWidget.tooltip) &&
                                !recommended
                              ) {
                                const { pageX, pageY, clientX, clientY } =
                                  event;
                                const coords = {
                                  pageX,
                                  pageY,
                                  clientX,
                                  clientY,
                                };

                                setHoveredElement(barKey);

                                setTooltip({
                                  name: key !== "default" ? key : undefined,
                                  data: {
                                    [xAxe as string]: d.x,
                                    [yAxe as string]: d.y,
                                  },
                                  coords,
                                });
                              }
                            }}
                            onMouseLeave={() => {
                              setTooltip(null);
                              setHoveredElement(null);
                            }}
                            opacity={
                              hoveredElement
                                ? hoveredElement === barKey
                                  ? 1
                                  : 0.4
                                : 1
                            }
                          >
                            <line
                              x1={xScale(d.y)}
                              y1={yScale(d.x)! + yScale.bandwidth() / 2 + yVal}
                              x2={margin.left}
                              y2={yScale(d.x)! + yScale.bandwidth() / 2 + yVal}
                              stroke={hexToRGBA(color!, 0.5)}
                              strokeWidth={3}
                            />

                            {!!color &&
                              LollipopMarkersHorizontal({
                                markerType: getCurrentMarker(
                                  currentWidget,
                                  key,
                                  "rhombus"
                                ),
                                yScale: yScale(d.x)! + yScale.bandwidth() / 2,
                                yVal: yVal,
                                yScaleBand: 0,
                                color: color,
                                xScale: xScale(d.y) + 1,
                              })}
                          </g>
                        );
                      });
                    }
                  )}
              </Group>

              <AxisLeft
                left={margin.left}
                scale={yScale}
                top={margin.top}
                hideTicks
                hideAxisLine
                numTicks={yAxes.length}
                tickLabelProps={(value) => ({
                  dx: hasLongBarValue ? -80 : -40,
                  fontSize: 11,
                  fill: "#5F6877",
                  textAnchor: "start",
                  dominantBaseline: "middle",
                })}
                tickComponent={(props: any) => (
                  <TickLabel
                    {...props}
                    labelText={formatLabelValue(
                      props.formattedValue,
                      yScaleDatasetField?.type,
                      yScaleDatasetField?.subtype
                    )}
                    x={0}
                    length={hasLongBarValue ? 10 : 4}
                    offsetX={-10}
                  />
                )}
              />
            </Group>
          </svg>
        </div>

        <svg
          height={xAxeHeight}
          width="100%"
          style={{ flexShrink: 0, marginBottom: margin.bottom }}
        >
          <AxisBottom
            scale={xScale}
            top={1}
            hideTicks
            tickFormat={(value: any) => {
              return ticksFormatter(value);
            }}
            tickLabelProps={(_, index, values) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;
              const textAnchor =
                (isFirstTick && "start") || (isLastTick && "end") || "middle";

              return {
                fontSize: 10,
                fill: "#5F6877",
                textAnchor,
                dx: 0,
              };
            }}
            tickValues={xScaleTickValues}
            tickComponent={(props: any) => (
              <TickLabel
                {...props}
                length={xScaleTickLabelMaxLength}
                offsetX={-10}
              />
            )}
            axisLineClassName="barchartAxisLine"
          />
        </svg>

        {tooltip &&
          xAxe &&
          yAxe &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              name={tooltip.name}
              coords={tooltip.coords}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
