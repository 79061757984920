import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AddNewPage,
  AddNewPageWrapper,
  FeaturesSection,
  MenuWrapper,
  PageItemsWrapper,
  PagesSection,
  RecommendedWidgetsWrapper,
  WidgetsCount,
  WidgetsTitle,
  Settings,
  SettingsBlock,
} from "./styles";
import { setActiveModal } from "../../store/slices/modals";
import { PageTab } from "../PageTab";
import {
  getAllPages,
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getPageSettings,
} from "../../store/selectors/projects";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";
import { OptionsInterface } from "../YearFiltering";
import { getAiKpis, getAiSuggestions } from "../../store/selectors/widgets";
import { PageSettingsDTO } from "../../models/Pages";
import { getAllWidgetsCount } from "../Widgets/utils/getAllWidgetsCount";
import SearchComponent from "../Search";
import { searchOptions } from "../Search/mock";

const options: OptionsInterface[] = [
  { label: "2017", value: "2017" },
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
];

export const ProjectMenu = () => {
  const pageWidgets = useSelector(getCurrentPageWidgets);
  const isEditMode = useSelector(getIsEditMode);
  const pages = useSelector(getAllPages);
  const pageSettings = useSelector(getPageSettings);
  const isPublicRoute = useSelector(getIsPublicMode);
  const recommendedWidgets = useSelector(getAiSuggestions);
  const recommendedKPIS = useSelector(getAiKpis);
  const currentKpis = useSelector(getCurrentPageKpi);
  const dispatch = useDispatch();
  const [_, setYearsFilter] = useState<OptionsInterface[]>([]);
  const [firstLoad, setFirstLoad] = useState<string | undefined>();

  useEffect(() => {
    if (pageWidgets.count && firstLoad !== pageSettings.id) {
      setFirstLoad(pageSettings.id);
      const item = pageWidgets.items[0];

      let data;
      if (item.chartType === "mapChart") {
        data = item?.layers?.[0]?.data;
      } else {
        data = item?.data;
      }

      if (data) {
        const mappedYears = data.reduce((uniqueYears, entry) => {
          if (!uniqueYears.includes(entry.year)) {
            uniqueYears.push(entry.year);
          }
          return uniqueYears;
        }, []);

        const years = mappedYears
          .map((year: any) => parseInt(year, 10))
          .filter(
            (year: any) =>
              !Number.isNaN(year) && year !== Infinity && year !== -Infinity
          );

        years.sort((a: any, b: any) => a - b);

        if (years.length) {
          const yearsOptions = years.map((year: any) => ({
            value: String(year),
            label: String(year),
          }));
          setYearsFilter(yearsOptions);
        } else {
          setYearsFilter(options);
        }
      } else {
        setYearsFilter(options);
      }
    }
  }, [firstLoad, pageSettings.id, pageWidgets.count, pageWidgets.items]);

  const sortByCreatedAt = (a: PageSettingsDTO, b: PageSettingsDTO) => {
    const dateA = new Date(a?.createdAt || "");
    const dateB = new Date(b?.createdAt || "");
    return dateA.getTime() - dateB.getTime(); // For ascending order. Use dateB - dateA for descending.
  };

  // const sampleOptions = [
  //   { id: '1', label: 'County', count: 58 },
  //   { id: '2', label: 'Year', count: 7 },
  //   { id: '3', label: 'Health Plan', count: 58 },
  //   { id: '4', label: 'Gender', count: 58 },
  //   { id: '5', label: 'Age group', count: 58 },
  //   { id: '6', label: 'Race / Ethnicity', count: 58 },
  //   { id: '7', label: 'Primary Language', count: 58 },
  // ];

  return (
    <>
      <MenuWrapper>
        <PagesSection>
          <PageItemsWrapper>
            {pages &&
              pages?.items &&
              [...pages?.items].sort(sortByCreatedAt)?.map((i) => (
                <React.Fragment key={i.id}>
                  <PageTab tabId={i.id!} name={i.name} isPublic={i.isPublic} />
                </React.Fragment>
              ))}
          </PageItemsWrapper>
          {!isPublicRoute && isEditMode ? (
            <AddNewPageWrapper
              onClick={() => {
                dispatch(
                  setActiveModal({
                    id: "projectSettingsModal",
                    createOptions: true,
                  })
                );
              }}
            >
              <AddNewPage />
            </AddNewPageWrapper>
          ) : null}
        </PagesSection>

        {!!pages?.items?.length && (
          <FeaturesSection>
            <>
              {/*<DropDown*/}
              {/*  title="Race / Ethnicity"*/}
              {/*  selectedItem={"All"}*/}
              {/*  filterOptions={["All", "Male", "Female"]}*/}
              {/*/>*/}
              {/*<DropDown*/}
              {/*  title="Measure"*/}
              {/*  selectedItem={"All"}*/}
              {/*  filterOptions={["All", "Male", "Female"]}*/}
              {/*/>*/}
              {/* {pageSettings?.showFilters ? (
                <YearFiltering years={years} />
              ) : null} */}
              {/*<DropDown*/}
              {/*  title="Year"*/}
              {/*  selectedItem={"All"}*/}
              {/*  filterOptions={["All", "Male", "Female"]}*/}
              {/*/>*/}
              {/* <DotSeparator /> */}
              {pageSettings?.showSearch ? (
                <SearchComponent searchOptions={searchOptions} />
              ) : null}
            </>
            {isEditMode && !isPublicRoute && (
              <>
                <SettingsBlock>
                  <Settings
                    onClick={() => {
                      dispatch(setActiveModal({ id: "projectSettingsModal" }));
                    }}
                  />
                </SettingsBlock>
                {pageSettings.dashType !== "table" ? (
                  <RecommendedWidgetsWrapper
                    onClick={() =>
                      dispatch(
                        setActiveModal({
                          id: "pageRecommendedWidgetsModal",
                          firstItem: pageWidgets.count + 1,
                        })
                      )
                    }
                  >
                    <WidgetsCount>
                      {!pageWidgets.count
                        ? 0
                        : getAllWidgetsCount(
                            recommendedWidgets,
                            recommendedKPIS,
                            currentKpis.items
                          )}
                    </WidgetsCount>
                    <WidgetsTitle>Recommended Widgets</WidgetsTitle>
                  </RecommendedWidgetsWrapper>
                ) : (
                  ""
                )}
              </>
            )}
          </FeaturesSection>
        )}
      </MenuWrapper>
    </>
  );
};
