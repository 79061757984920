import React, { useEffect, useState } from "react";
import { Search } from "../../../../components/SearchBar";
import {
  ApplicationsList,
  Container,
  Header,
  Tags,
  Separator,
  RequestNewDevice,
  RequestNewDeviceText,
  HeaderWrapper,
} from "./styles";

import CategoryItem from "../../../../components/CategoryItem";
import DeviceCard from "../../../../components/DeviceCard";
import { useDispatch } from "react-redux";
import { setActiveModal } from "../../../../store/slices/modals";
import { Button } from "../../../../components/Button";
import { CodeSlash } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { getApplications } from "../../../../store/selectors/dataProviders";
import { requestApplications } from "../../../../store/slices/dataProviders";

export const Applications: React.FC = () => {
  const dispatch = useDispatch();

  const allApplications = useSelector(getApplications);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedApplications, setSelectedApplications] = useState<string[]>(
    []
  );

  const categories = [
    ...new Set(allApplications.flatMap((app) => app.appCategory)),
  ];

  const handleSelectApplication = (id: string, deviceName?: string) => {
    const existId = selectedApplications.includes(id);

    if (existId) {
      setSelectedApplications((prev) => prev.filter((appId) => appId !== id));
    } else {
      setSelectedApplications((prev) => [...prev, id]);
      dispatch(
        setActiveModal({
          id: "connectDevice",
          data: {
            deviceName,
          },
        })
      );
    }
  };

  const handleSelectCategory = (tag: string) => {
    const existId = selectedCategories.includes(tag);

    setSelectedCategories((prev) =>
      existId ? prev.filter((appId) => appId !== tag) : [...prev, tag]
    );
  };

  useEffect(() => {
    dispatch(requestApplications());
  }, []);

  return (
    <Container>
      <HeaderWrapper>
        <Header>Applications</Header>
        <Search
          value={searchValue}
          onChange={setSearchValue}
          settings
          onSettings={() => {}}
        />
      </HeaderWrapper>

      <Tags>
        {categories.map((tag) => (
          <CategoryItem
            key={tag}
            name={tag}
            active={selectedCategories.includes(tag)}
            onClick={() => handleSelectCategory(tag)}
          />
        ))}
      </Tags>

      <ApplicationsList>
        {selectedApplications.length > 0 && (
          <>
            {allApplications
              .filter(
                (item) =>
                  selectedApplications.includes(item.id) &&
                  item.name.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((application) => (
                <DeviceCard
                  key={
                    application.id + application.name + application.description
                  }
                  application={application}
                  active={true}
                  onConnect={() => handleSelectApplication(application.id)}
                />
              ))}
            <Separator />
          </>
        )}

        {allApplications
          .filter(
            (item) =>
              !selectedApplications.includes(item.id) &&
              item.name.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((application) => (
            <DeviceCard
              key={application.id + application.name + application.description}
              application={application}
              onConnect={() =>
                handleSelectApplication(application.id, application.name)
              }
            />
          ))}
      </ApplicationsList>

      <RequestNewDevice>
        <RequestNewDeviceText>
          If you can’t find your device here, please send a request indicating
          the device model and we will create it.
        </RequestNewDeviceText>
        <Button
          onClick={() => {
            dispatch(
              setActiveModal({
                id: "requestNewDevice",
              })
            );
          }}
          name="Send Request"
          variant={"primary"}
          size={"small"}
          icon={<CodeSlash />}
        />
      </RequestNewDevice>
    </Container>
  );
};
