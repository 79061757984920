import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseIcon,
  ModalContent,
  ModalFooterWrapper,
  OverviewContent,
} from "../styles";
import { ModalHeading } from "../styles";
import { ModalWrapper } from "../styles";
import { ModalHeadingWrapper } from "../styles";
import { ModalOverlay } from "../styles";
import {
  removeAllSelectedFiles,
  requestGetDatasetContents,
  resetDatasetContents,
  setDatasetPreview,
} from "../../../store/slices/datasets";
import { resetNewProjectSelectedFile } from "../../../store/slices/datasets";
import { Button } from "../../Button";
import { ArrowRepeat, PlusLg } from "react-bootstrap-icons";
import {
  ButtonsWrapper,
  DataListHeader,
  HeaderButtons,
  ImagesIcon,
  TablesContainer,
  TablesContent,
  TablesHeader,
} from "../SelectedDatasetOverview/styles";
import {
  DataList,
  DataListRowName,
  DataListRow,
  DataListRowDescription,
} from "../CreateProject/components/RealEstate/style";
import { getCurrentProject } from "../../../store/selectors/projects";
import Badge from "../../Badge";
import { getIconSrc } from "../../../helpers/getIconType";
import {
  BadgesWrapper,
  CreateButton,
  CreateButtonWrapper,
  HeaderWrapper,
  ModalContentWrapper,
  ModalWrapperContent,
  SeparatorSpan,
} from "./styles";
import {
  HeaderRow,
  HeadingIcon,
  HeadingText,
  Icon,
  More,
  Primary,
  StarIcon,
  Table,
  TableHeading,
  TableRowCell,
  TableRowDataSet,
  TablesWrapper,
} from "../DatasetsOverview/styles";
import useMeasure from "react-use-measure";
import {
  getDatasetContents,
  getSelectedDataset,
} from "../../../store/selectors/datasets";
import { DimensionSettingsInterface } from "../../../models/Files";
import { handleColumnClick } from "../../../helpers/handleColumnClick";
import { ILastAction } from "../../../pages/Resources/pages/Datasets/pages/DatasetPreview";
import {
  sortTableAscending,
  sortTableDescending,
} from "../../DimensionSettings/utils/tableActions";
import { ButtonWrapper } from "../UploadedFilePreview/styles";
import { Search } from "../../SearchBar";
import { DimensionSettings } from "../../DimensionSettings";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

interface ModalProps {
  onClose: () => void;
}
const ProjectData = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [tableRef, tableBounds] = useMeasure();
  const rowsRef = useRef<Array<HTMLDivElement | null>>([]);

  const datasetContents = useSelector(getDatasetContents);
  const { projectData } = useSelector(getCurrentProject);
  const selectedDataset = useSelector(getSelectedDataset);
  const [isClosing, setIsClosing] = useState(false);
  const [pinedColumns, setPinedColumns] = useState<string[]>([]);
  const [maxRowHeight, setMaxRowHeight] = useState(0);
  const [searchInput, setSearchInput] = useState<string>("");
  const [lastAction, setLastAction] = useState<ILastAction>({});
  const [limit, setLimit] = useState(30);
  const [showDimensionSettings, setShowDimensionSettings] =
    useState<DimensionSettingsInterface | null>(null);
  const [selectedDatasetContents, setSelectedDatasetContents] = useState<any>(
    []
  );

  const { datasets, files, datasetColumnKeys } = projectData;
  const datasetId = (selectedDataset?.id || selectedDataset?.sourceId) ?? "";
  const headers = (selectedDataset?.fields ?? []).filter((item) =>
    datasetColumnKeys.includes(item.key)
  );
  const filteredHeaders = headers?.filter((item) =>
    item.label.toLowerCase().includes(searchInput.toLowerCase())
  );
  const isDatasetSelected = !!Object.keys(selectedDataset).length;
  const columnWidth =
    tableBounds.width > 195 * filteredHeaders?.length!
      ? tableBounds.width / filteredHeaders?.length!
      : undefined;

  const getDatasetContentsR = (plus?: number, id?: string) => {
    const count = datasetContents?.count ?? 0;
    const plusLimit = limit + (plus || 0);
    const newLimit = plusLimit > count ? count : plusLimit;

    dispatch(
      requestGetDatasetContents({
        id: datasetId ?? id,
        limit: newLimit,
        skip: 0,
      })
    );
    setLimit(newLimit);
  };

  const handleOnClose = () => {
    setIsClosing(true);
    dispatch(resetNewProjectSelectedFile());
    dispatch(removeAllSelectedFiles());
    dispatch(resetDatasetContents());
    dispatch(setDatasetPreview({}));
    setTimeout(() => {
      onClose();
    }, 400);
  };

  useEffect(() => {
    if (!datasetContents) return;

    const newData = datasetContents?.items?.map(
      (contentItem: any) => contentItem
    );

    if (!lastAction || !selectedDatasetContents) {
      setSelectedDatasetContents(newData);
      return;
    }

    if (lastAction.ascending) {
      sortTableAscending({
        key: lastAction.key!,
        subtype: lastAction.subtype!,
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else if (lastAction.descending) {
      sortTableDescending({
        key: lastAction.key!,
        subtype: lastAction.subtype!,
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else {
      setSelectedDatasetContents(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetContents]);

  useEffect(() => {
    const heights = rowsRef.current.map((row) => row?.offsetHeight || 0);
    setMaxRowHeight(Math.max(...heights));
  }, [selectedDatasetContents, filteredHeaders, pinedColumns]);

  useEffect(() => {
    if (isDatasetSelected && !datasetContents?.count) {
      getDatasetContentsR();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataset]);

  useOutsideClick(ref, () => {
    setShowDimensionSettings(null);
  });

  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
      <ModalWrapper
        onClick={(e) => e.stopPropagation()}
        $isClosing={isClosing}
        $maxWidth={!!isDatasetSelected}
      >
        <ModalWrapperContent>
          <ModalContentWrapper $isDatasetSelected={isDatasetSelected}>
            <ModalHeadingWrapper $disableSeparator={false}>
              <ModalHeading>{"Project Data"}</ModalHeading>
              <ButtonsWrapper>
                <Button
                  name="Add files"
                  onClick={() => {}}
                  variant={"ghost"}
                  size="medium"
                />
                <Button
                  name={"Add data"}
                  onClick={() => {}}
                  type="button"
                  variant={"primary"}
                  size="medium"
                  icon={<PlusLg />}
                />
                <CloseIcon onClick={handleOnClose} />
              </ButtonsWrapper>
            </ModalHeadingWrapper>
            <OverviewContent>
              <ModalContent>
                {datasets?.length > 0 && (
                  <DataList>
                    <DataListHeader>
                      <ImagesIcon /> Datasets:
                    </DataListHeader>
                    {datasets.map((item, index) => (
                      <DataListRow
                        $pointer
                        $active={item.id === datasetId}
                        style={{ width: "100%" }}
                        key={index}
                        onClick={() => {
                          dispatch(setDatasetPreview(item));
                          dispatch(resetDatasetContents());
                        }}
                      >
                        <BadgesWrapper>
                          <DataListRowName>{item.name}</DataListRowName>
                          <SeparatorSpan>/</SeparatorSpan>
                          {item.fields.map(
                            (field, index) =>
                              datasetColumnKeys.includes(field.key) && (
                                <Badge
                                  key={index}
                                  name={field.label}
                                  onClick={() => {}}
                                  keyName={field.key}
                                  type={field.type}
                                  subtype={field.subtype}
                                  icon={getIconSrc({
                                    type: field.type,
                                    subtype: field.subtype,
                                  })}
                                />
                              )
                          )}
                          <SeparatorSpan>/</SeparatorSpan>
                        </BadgesWrapper>
                      </DataListRow>
                    ))}
                    <DataListRow style={{ width: "100%" }} $column $pointer>
                      <HeaderWrapper>
                        <DataListRowName>Custom dimensions</DataListRowName>
                        <CreateButtonWrapper>
                          /
                          <CreateButton>
                            Create
                            <PlusLg />
                          </CreateButton>
                          /
                        </CreateButtonWrapper>
                      </HeaderWrapper>
                      <DataListRowDescription>
                        New dimension created by combining multiple dimensions
                        from different datasets.
                      </DataListRowDescription>
                    </DataListRow>
                  </DataList>
                )}
                {files && files?.length > 0 && (
                  <DataList>
                    <DataListHeader>
                      <ImagesIcon /> Files:
                    </DataListHeader>
                    {files.map((item, index) => (
                      <DataListRow style={{ width: "100%" }} key={index}>
                        <DataListRowName>{item.name}</DataListRowName>
                      </DataListRow>
                    ))}
                  </DataList>
                )}
              </ModalContent>
            </OverviewContent>
            <ModalFooterWrapper>
              <Button
                name="Cancel"
                onClick={handleOnClose}
                variant="neutral"
                size="medium"
              />

              <Button
                name={"Update"}
                onClick={() => {}}
                type="submit"
                disabled={true}
                variant={"disabled"}
                size="medium"
                icon={<ArrowRepeat />}
              />
            </ModalFooterWrapper>
          </ModalContentWrapper>

          {!!Object.keys(selectedDataset).length && (
            <TablesContainer>
              <TablesHeader>
                {selectedDataset?.name.charAt(0).toUpperCase()! +
                  selectedDataset?.name.slice(1, -4)}
                <HeaderButtons>
                  <Search
                    width="xs"
                    placeholder="Search"
                    onChange={(value: string) => setSearchInput(value)}
                  />
                  <CloseIcon
                    onClick={() => {
                      dispatch(setDatasetPreview({}));
                      dispatch(resetDatasetContents());
                    }}
                  />
                </HeaderButtons>
              </TablesHeader>
              <TablesContent>
                <TablesWrapper ref={tableRef} $noBorder>
                  {/*key: previewTable - Project Data info Preview Datasets */}

                  {/* {!!warningNoData && (
                    <NoDataWarning
                      title={"Insufficient Data"}
                      description={[warningNoData]}
                      close={() => setWarningNoData(null)}
                    />
                  )} */}

                  {!!pinedColumns.length && (
                    <Table $pined={true}>
                      {
                        <HeaderRow>
                          {filteredHeaders
                            ?.filter((item) => pinedColumns.includes(item.key))
                            .map((datasetItem: any, idx) => (
                              <TableHeading $width={columnWidth}>
                                {datasetItem.primary && (
                                  <Primary>
                                    <StarIcon></StarIcon>
                                  </Primary>
                                )}

                                <HeadingIcon>
                                  <Icon
                                    src={getIconSrc({
                                      type: datasetItem.type,
                                      subtype: datasetItem.subtype,
                                    })}
                                  />
                                </HeadingIcon>
                                <HeadingText>
                                  {datasetItem.label
                                    ?.charAt(0)
                                    ?.toUpperCase()! +
                                    datasetItem.label?.slice(1)}
                                </HeadingText>
                                <ButtonsWrapper>
                                  <More
                                    onClick={(e) => {
                                      setShowDimensionSettings(
                                        handleColumnClick({
                                          header: datasetItem.label,
                                          event: e,
                                          key: datasetItem.key,
                                          errorsDetected: false,
                                          suggestedType: datasetItem.type,
                                          subtype: datasetItem.subtype,
                                        })
                                      );
                                    }}
                                  />
                                </ButtonsWrapper>
                              </TableHeading>
                            ))}
                        </HeaderRow>
                      }

                      {selectedDatasetContents.map((item: any, idx: number) => {
                        return (
                          <TableRowDataSet
                            key={idx}
                            ref={(el) => (rowsRef.current[idx] = el)}
                            style={{
                              height: maxRowHeight
                                ? `${maxRowHeight}px`
                                : "auto",
                            }}
                          >
                            {filteredHeaders
                              ?.filter((item) =>
                                pinedColumns.includes(item.key)
                              )
                              .map((header) => (
                                <TableRowCell $width={columnWidth}>
                                  {item[header.key] || "No data"}
                                </TableRowCell>
                              ))}
                          </TableRowDataSet>
                        );
                      })}
                    </Table>
                  )}
                  <Table>
                    {
                      <HeaderRow>
                        {filteredHeaders
                          ?.filter((item) => !pinedColumns.includes(item.key))
                          .map((datasetItem: any, idx) => (
                            <TableHeading $width={columnWidth}>
                              {datasetItem.primary && (
                                <Primary>
                                  <StarIcon></StarIcon>
                                </Primary>
                              )}

                              <HeadingIcon>
                                <Icon
                                  src={getIconSrc({
                                    type: datasetItem.type,
                                    subtype: datasetItem.subtype,
                                  })}
                                />
                              </HeadingIcon>
                              <HeadingText>
                                {datasetItem.label?.charAt(0)?.toUpperCase()! +
                                  datasetItem.label?.slice(1)}
                              </HeadingText>
                              <ButtonsWrapper>
                                <More
                                  onClick={(e) => {
                                    setShowDimensionSettings(
                                      handleColumnClick({
                                        header: datasetItem.label,
                                        event: e,
                                        key: datasetItem.key,
                                        errorsDetected: false,
                                        suggestedType: datasetItem.type,
                                        subtype: datasetItem.subtype,
                                      })
                                    );
                                  }}
                                />
                              </ButtonsWrapper>
                            </TableHeading>
                          ))}
                      </HeaderRow>
                    }

                    {selectedDatasetContents.map((item: any, idx: number) => {
                      return (
                        <TableRowDataSet
                          key={idx}
                          ref={(el) => (rowsRef.current[idx] = el)}
                          style={{
                            height: maxRowHeight ? `${maxRowHeight}px` : "auto",
                          }}
                        >
                          {filteredHeaders
                            ?.filter((item) => !pinedColumns.includes(item.key))
                            .map((header) => (
                              <TableRowCell $width={columnWidth}>
                                {item[header.key] || "No data"}
                              </TableRowCell>
                            ))}
                        </TableRowDataSet>
                      );
                    })}
                  </Table>
                </TablesWrapper>
                <ButtonWrapper>
                  <Button
                    disabled={datasetContents?.count <= limit}
                    onClick={() => {
                      getDatasetContentsR(30);
                    }}
                    size="small"
                    variant="primary"
                    name="Load More"
                  />
                </ButtonWrapper>
              </TablesContent>
            </TablesContainer>
          )}
        </ModalWrapperContent>
        {showDimensionSettings && (
          <div ref={ref}>
            <DimensionSettings
              filePath={""}
              showDimensionSettings={showDimensionSettings}
              lastAction={lastAction}
              pinedColumns={pinedColumns}
              selectedDatasetContents={selectedDatasetContents}
              setShowDimensionSettings={setShowDimensionSettings}
              setLastAction={setLastAction}
              setPinedColumns={setPinedColumns}
              setSelectedDatasetContents={setSelectedDatasetContents}
              topSpace={-50}
              changeCurrentDataset
              noPrimary
            />
          </div>
        )}
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default ProjectData;
