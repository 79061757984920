import { AVAILABLE_WIDGETS } from "../../constants/widgetRecomended";
import { WidgetItem } from "../../models/Widgets";

export const calculateNumTicks = ({
  height,
  width,
}: {
  height?: number;
  width?: number;
}) => {
  const defaultHeightThreshold = 10;
  const defaultWidthThreshold = 8;

  const heightThresholds = [
    { limit: 80, numTicks: 2 },
    { limit: 100, numTicks: 3 },
    { limit: 130, numTicks: 4 },
    { limit: 600, numTicks: 6 },
    { limit: 800, numTicks: 8 },
  ];

  const widthThresholds = [
    { limit: 300, numTicks: 3 },
    { limit: 600, numTicks: 4 },
    { limit: 800, numTicks: 5 },
  ];

  const heightThreshold = heightThresholds.find((t) => height! <= t.limit);
  const widthThreshold = widthThresholds.find((t) => width! <= t.limit);

  if (heightThreshold)
    return heightThreshold ? heightThreshold.numTicks : defaultHeightThreshold;

  if (widthThreshold)
    return widthThreshold ? widthThreshold.numTicks : defaultWidthThreshold;

  if (height !== undefined) return defaultHeightThreshold;

  if (width !== undefined) return defaultWidthThreshold;

  return 0;
};

export const calculateLabelLength = ({
  width = 0,
  tickValues = [],
  charSpace = 12,
  tickFormatter = (value: any) => value,
}: {
  width?: number;
  tickValues?: string[] | number[];
  charSpace?: number;
  tickFormatter?: (value: any) => any;
}): number => {
  const maxLabelLength = Math.max(
    ...tickValues.map(
      (value: string | number) => (value ?? "").toString().length
    )
  );
  const defaultLength = width || maxLabelLength;

  if (width <= 0 || tickValues.length <= 0 || charSpace <= 0) {
    return defaultLength;
  }

  const tickValuesStrings = tickValues.map((value: string | number) =>
    tickFormatter(value ?? "").toString()
  );

  const sumOfLabelLengths = tickValuesStrings.reduce(
    (acc: number, cur: string) => (acc += cur.length * charSpace),
    0
  );

  if (sumOfLabelLengths <= width) {
    return defaultLength;
  } else {
    return Math.floor(width / tickValues.length / charSpace);
  }
};

export const getScaleBandTickValues = ({
  tickCount = 0,
  ticks = [],
}: {
  tickCount?: number;
  ticks: number[] | string[];
}): any[] => {
  const totalTicks = ticks.length;
  if (tickCount <= 0 || isNaN(tickCount) || totalTicks < 2) {
    return [];
  }

  if (totalTicks < 2) {
    return ticks;
  }

  const firstValue = ticks[0];
  const lastValue = ticks[totalTicks - 1];

  const rangeStepsCount = tickCount - 2;
  if (rangeStepsCount === 0) {
    return [firstValue, lastValue];
  } else if (rangeStepsCount < 0) {
    return [firstValue];
  }

  if (typeof firstValue === "number" && typeof lastValue === "number") {
    const segmentValue = (lastValue - firstValue) / (rangeStepsCount + 1);

    const rangeValues = Array.from(
      { length: rangeStepsCount },
      (_, i) => firstValue + (i + 1) * segmentValue
    );
    return [firstValue, ...rangeValues, lastValue];
  }

  const segmentValue = Math.floor(totalTicks / (rangeStepsCount + 1));
  const rangeValues = Array.from(
    { length: rangeStepsCount },
    (_, i) => ticks[(i + 1) * segmentValue]
  );

  return [firstValue, ...rangeValues, lastValue];
};

export const getScaleLinearTickValues = ({
  scale,
  tickCount = 2,
}: {
  scale: any;
  tickCount?: number;
}): number[] => {
  if (tickCount <= 0 || isNaN(tickCount) || scale === undefined) {
    return [];
  }

  return scale.ticks(tickCount).map((value: any) => value.valueOf());
};

export const getScaleTimeTickValues = ({
  tickCount = 0,
  scale,
  axisOrientation = "horizontal",
  width = 0,
  height = 0,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
}: {
  tickCount?: number;
  scale: any;
  axisOrientation?: "horizontal" | "vertical";
  width?: number;
  height?: number;
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}): Date[] => {
  if (tickCount <= 0 || isNaN(tickCount)) return [];

  const rangeStepsCount = tickCount - 2;

  if (rangeStepsCount < 0) return [scale.domain()[0]];
  if (rangeStepsCount === 0) return [scale.domain()[0], scale.domain()[1]];

  let segmentValue = 0;
  let rangeValues = [];

  if (axisOrientation === "horizontal") {
    segmentValue = Math.ceil(
      (width - margin.left - margin.right) / (rangeStepsCount + 1)
    );

    rangeValues = Array.from({ length: rangeStepsCount }, (_, i) =>
      scale.invert(margin.left + (i + 1) * segmentValue)
    );
  }

  if (axisOrientation === "vertical") {
    segmentValue = Math.ceil(
      (height - margin.top - margin.bottom) / rangeStepsCount
    );

    rangeValues = Array.from({ length: rangeStepsCount }, (_, i) =>
      scale.invert(margin.top + (i + 1) * segmentValue)
    );
  }

  return [scale.domain()[0], ...rangeValues, scale.domain()[1]];
};

export const getAvailableWidgetTypes = (
  currentWidget: WidgetItem
): string[] => {
  if (!currentWidget?.chartType) {
    return [];
  }

  const defaultAvailableWidgets: string[] =
    AVAILABLE_WIDGETS[currentWidget.chartType] || [];
  const availableWidgets: Set<string> = new Set(defaultAvailableWidgets);
  const unavailableWidgets: Set<string> = new Set();

  //* Logic for horizontal widgets with many labels
  const limitedByAxeWidgetTypes = [
    "areaChart",
    "barChart_vertical",
    "lineChart",
    "lollipopChart_vertical",
    "polarAreaChart",
    "radarChart",
  ];
  if (limitedByAxeWidgetTypes.some((widget) => availableWidgets.has(widget))) {
    const xScaleTickCountLimit = 12;
    const xAxisTickIdentifier = currentWidget.xAxe?.[0] || "";
    const xScaleTickCount =
      currentWidget.uniqueValues?.[xAxisTickIdentifier]?.length || 0;

    if (xScaleTickCount > xScaleTickCountLimit) {
      limitedByAxeWidgetTypes.forEach((widget) =>
        unavailableWidgets.add(widget)
      );
    }
  }
  //* End - Logic for horizontal widgets with many labels

  //* Logic for unsupported grouped data widgets
  const unsupportedGroupedDataWidgets = ["polarAreaChart"];
  if (currentWidget.groupBy?.length) {
    unsupportedGroupedDataWidgets.forEach((widget) =>
      unavailableWidgets.add(widget)
    );
  }
  //* End - Logic for unsupported grouped data widgets

  //* Logic for map interchangeable widgets
  const mapInterchangeableWidgetTypes = ["matrixChart", "sankeyChart"];
  if (mapInterchangeableWidgetTypes.includes(currentWidget.chartType)) {
    const ifRecommendedToMap = currentWidget.data?.some(
      (d: any) => d?.county && d?.state
    );

    if (ifRecommendedToMap) {
      mapInterchangeableWidgetTypes.forEach((widgetType) => {
        if (availableWidgets.has(widgetType)) {
          availableWidgets.delete(widgetType);
          availableWidgets.add(`${widgetType}_map`);
        }
      });

      availableWidgets.add("map_matrix");
    }
  }
  //* END - Logic for unsupported widgets

  // Exclude unavailable widgets
  unavailableWidgets.forEach((widget) => availableWidgets.delete(widget));

  return Array.from(availableWidgets);
};
