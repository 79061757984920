import { styled } from "styled-components";

export const ChartBackground  = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder}; 
  background-image: radial-gradient(#D7D7D8 1px, transparent 1px);
  background-color: #ffffff;
  background-size: 3px 3px; 
  justify-content: center;
  padding: 23px 0 47px;
  align-items: center;
  display: flex;
`

export const FeedBackBlock = styled.div`
  padding: 20px;
`

export const FeedBackHeadingWrapper = styled.div`
  margin-bottom: 16px;
`

export const FeedBackHeader = styled.p`
  margin-bottom: 10px;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  font-size: 18px;
  color: #000417;
`
export const ClassesHead = styled.p`
  color: #21262E;
  font-size: 14px;
  margin-bottom: 14px; 
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`

export const FeedBackSubHead = styled.p`
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #2E3849;
`

export const FeedBackOptions = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 16px; 
`

export const FeedBackOptionText = styled.p`
  text-align: center;
  line-height: 24px;
  font-weight: 600;
  font-size:16px;
`

export const FeedBackOption = styled.div<{$active: boolean; $isNegative?: boolean; $isPositive?: boolean}>`
  border:1px solid transparent;
  border-radius: 999px;
  padding: 12px 16px;
  align-items:center;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  gap: 8px;
  user-select: none;

  ${({$isNegative}) => $isNegative && `
    background-color: #DD2E431F;
    color: #940000;
  `}

  ${({$isPositive}) => $isPositive && `
    background-color: #1386601F;
    color: #003E1B;
  `}


  &:hover {
    ${({$isNegative}) => $isNegative && `
      border:1px solid #E45869;
    `}
    ${({$isPositive}) => $isPositive && `
      border:1px solid #429E80;
    `}
  }

  ${({$active, $isNegative}) => 
    $active && $isNegative && `
      background-color: #DD2E43;
      color: white;
      &:hover {
        background-color: #C5162C;
      }
    `
  }

  ${({$active, $isPositive}) => 
    $active && $isPositive && `
      background-color: #138660;
      color: white;

      &:hover {
        background-color: #006E49;
      }
    `
  }
`

export const OptionWrapper = styled.div`
  margin-top: 16px;
`

export const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`