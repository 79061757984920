export const getRegionIdFromFeatureProperties = (
  featureProperties: GeoJSON.GeoJsonProperties,
  mapType?: string
) => {
  if (!featureProperties || !mapType) {
    return undefined;
  }

  switch (mapType) {
    case "county":
      return featureProperties?.GEOID;

    case "state":
      return featureProperties?.STUSPS;

    case "country":
      return featureProperties?.ISO_A3;

    default:
      return undefined;
  }
};
