import React from "react";
import {
  Checkmark,
  Input,
  Container,
  RadioButtonWrapper,
  Label,
  TextWrapper,
  SubLabel,
} from "./styles";

type Props = {
  size?: "M" | "S";
  name: string;
  id?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  subLabel?: string;
  value: string;
  width?: string | number;
  checked?: boolean;
  disabled?: boolean;
};

const RadioButton = ({
  id,
  name,
  size,
  label,
  subLabel,
  value,
  checked,
  width,
  disabled,
  onChange = () => {},
}: Props) => {
  return (
    <RadioButtonWrapper $width={width}>
      <Container>
        <Input
          id={id}
          name={name}
          disabled={disabled}
          type="radio"
          onChange={onChange}
          value={value}
          checked={checked}
        />
        <Checkmark />
      </Container>
      <TextWrapper>
        <Label id={id} $disabled={disabled}>
          {label}
        </Label>
        {!!subLabel && <SubLabel $disabled={disabled}>{subLabel}</SubLabel>}
      </TextWrapper>
    </RadioButtonWrapper>
  );
};

export default RadioButton;
