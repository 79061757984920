import React, { useState } from "react";
import {
  CloseIcon,
  ModalFooterButtons,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  OverviewContent,
} from "../styles";
import {
  OverviewContentWrapper,
  ConnectOURAModal,
  InputWrapper,
  RenameInputHeader,
  RenameInput,
  Required,
} from "./styles";
import { Button } from "../../Button";
import { Check } from "react-bootstrap-icons";
import ConnectLoader, { ConnectLoaderStatus } from "../../ConnectLoader";
import { useDispatch } from "react-redux";

interface ModalProps {
  onClose: () => void;
}

const RequestNewDeviceModal = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState<ConnectLoaderStatus>("idle");

  const [data, setData] = useState<{
    deviceType: string;
    deviceMake: string;
    deviceModel: string;
    integrationPurpose: string;
  }>({
    deviceMake: "",
    deviceModel: "",
    deviceType: "",
    integrationPurpose: "",
  });

  const handleConnect = () => {
    if (status === "success") {
      onClose();
      return;
    }

    setStatus("loading");

    setTimeout(() => setStatus("success"), 3000);
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ConnectOURAModal onClick={(e) => e.stopPropagation()}>
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalHeading>Request new device</ModalHeading>
          <CloseIcon onClick={onClose} />
        </ModalHeadingWrapper>

        <OverviewContent>
          {status !== "idle" ? (
            <ConnectLoader
              status={status}
              onLoadingText={[
                "Establishing a secure connection and retrieving data.",
                "Please wait...",
              ]}
              succefullLoadingText={[
                "Connection has been established successfully.",
              ]}
            />
          ) : (
            <OverviewContentWrapper>
              <InputWrapper>
                <RenameInputHeader>
                  Device Type <Required>*</Required>
                </RenameInputHeader>
                <RenameInput
                  value={data.deviceType}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, deviceType: e.target.value }))
                  }
                  placeholder="Enter device type: Watch, Band, etc."
                />
              </InputWrapper>
              <InputWrapper>
                <RenameInputHeader>
                  Device Make <Required>*</Required>
                </RenameInputHeader>
                <RenameInput
                  value={data.deviceMake}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, deviceMake: e.target.value }))
                  }
                  placeholder="Manufacturer"
                />
              </InputWrapper>
              <InputWrapper>
                <RenameInputHeader>
                  Device Model <Required>*</Required>
                </RenameInputHeader>
                <RenameInput
                  value={data.deviceModel}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      deviceModel: e.target.value,
                    }))
                  }
                  placeholder="Enter device model"
                />
              </InputWrapper>
              <InputWrapper>
                <RenameInputHeader>
                  Integration Purpose <Required>*</Required>
                </RenameInputHeader>
                <RenameInput
                  value={data.integrationPurpose}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      integrationPurpose: e.target.value,
                    }))
                  }
                  placeholder="Type here"
                />
              </InputWrapper>
            </OverviewContentWrapper>
          )}
        </OverviewContent>

        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={onClose}
            variant="neutral"
            size="medium"
          />
          <ModalFooterButtons>
            <Button
              name={status === "success" ? "Done" : "Send Request"}
              onClick={() => handleConnect()}
              type="submit"
              variant={status === "loading" ? "disabled" : "secondary"}
              disabled={status === "loading"}
              size="medium"
              icon={<Check />}
            />
          </ModalFooterButtons>
        </ModalFooterWrapper>
      </ConnectOURAModal>
    </ModalOverlay>
  );
};

export default RequestNewDeviceModal;
