import { StoreInterface } from "../../models";

export const getDataProvidersList = (store: StoreInterface) =>
  store.dataProviders.dataProvidersList;

export const getDataProvider = (store: StoreInterface) =>
  store.dataProviders.dataProvider;

export const getApplications = (store: StoreInterface) =>
  store.dataProviders.applications;
