import { CheckLg } from "react-bootstrap-icons";
import styled from "styled-components";

export const OverviewLoaderWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 228px);
  overflow-y: auto;
`

export const FinishLoadWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DFE0FD;
  border: 1px solid #7D82F8;
  width:56px;
  height: 56px;
`

export const FinishLoad = styled(CheckLg)`
  fill: #141BB1;
`

export const LoaderWrapper = styled.div`
  position: relative;
  width:56px;
  height: 56px;
`



export const LoaderText = styled.div`
  display: flex;
  flex-direction: column;
  color: #5F6877;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; 
  text-align: center;
`