import { useSelector } from "react-redux";
import AboutHome from "./components/AboutHome";
import Carousel from "./components/Carousel";
import FactsAndFeatures from "./components/FactsAndFeatures";
import FinancialAndListing from "./components/FinancialAndListing";
import HousePerformance from "./components/Performance";
import {
  HouseDetailsContainer,
  HouseDetailsContent,
  HouseDetailsHero,
} from "./styles";
import {
  getPropertyData,
  getPropertyLoading,
} from "../../store/selectors/realEstate";
import { Loader } from "../Loader";

const HouseDetails = () => {
  const propertyData = useSelector(getPropertyData);
  const isLoading = useSelector(getPropertyLoading);

  const transformToCarouselData = (zillowData: any[] | undefined): any[] => {
    return (zillowData || []).map((item, index) => {
      const highestResJpeg = item.mixedSources.jpeg?.reduce(
        (prev: any, current: any) => {
          return prev.width > current.width ? prev : current;
        }
      );

      return {
        image: highestResJpeg.url,
        component: (
          <img
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
            src={highestResJpeg.url}
            alt={`Thumbnail ${index + 1}`}
          />
        ),
      };
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader fullScreen />
      ) : (
        <HouseDetailsContainer>
          <HouseDetailsHero>
            <Carousel
              items={transformToCarouselData(propertyData?.originalPhotos)}
            />
          </HouseDetailsHero>

          <HouseDetailsContent>
            <HousePerformance
              price={propertyData?.price || 0}
              priceCut={propertyData?.priceChange}
              priceEstimatedMonthly={propertyData?.rentZestimate || 0}
              beds={propertyData?.bedrooms || 0}
              baths={propertyData?.bathrooms || 0}
              squareFeet={propertyData?.livingArea || 0}
              address={propertyData?.address?.streetAddress || ""}
              priceChangeDate={propertyData?.priceChangeDate}
            />

            <AboutHome data={propertyData} />

            <FactsAndFeatures data={propertyData} />

            <FinancialAndListing data={propertyData} />
          </HouseDetailsContent>
        </HouseDetailsContainer>
      )}
    </>
  );
};

export default HouseDetails;
