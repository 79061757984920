import React, { useMemo } from "react";
import { ProjectMenu } from "../../components/ProjectMenu";
import { Loader } from "../../components/Loader";
import { useSelector } from "react-redux";
import {
  getAllPages,
  getCurrentProjectData,
} from "../../store/selectors/projects";

interface LayoutProps {
  children: React.ReactNode;
}

const ProjectPageLayout = ({ children }: LayoutProps) => {
  const pages = useSelector(getAllPages);

  const {
    complexPlotsCount = 0,
    suggestionsCount = 0,
    projectType,
  } = useSelector(getCurrentProjectData);

  const countPossiblePlots = useMemo(() =>
    complexPlotsCount + suggestionsCount,
    [complexPlotsCount, suggestionsCount]
  );

  return (
    <>
      {(projectType === "real_estate" || !!countPossiblePlots) && (
        <ProjectMenu />
      )}
      {children}
      {pages.isLoading && <Loader />}
    </>
  );
};

export default ProjectPageLayout;
