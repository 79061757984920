import React, { useRef, useState, useCallback } from "react";
import { useSearchNavigation } from "./hooks/useSearchNavigation";
import { Button } from "../Button";
import * as s from "./styles";
import {
  SearchItem,
  SelectedItems,
  RecentSearch,
  CategorySelection,
  ItemData,
} from "./types";
import {
  DefaultView,
  GlobalSearch,
  RecentSearches,
  SearchInput,
} from "./components";
import { useClickOutside } from "./hooks/useClickOutside";

interface HierarchicalSearchProps {
  searchOptions: SearchItem[];
}

const Search: React.FC<HierarchicalSearchProps> = ({ searchOptions }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selections, setSelections] = useState<SelectedItems>({});
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const {
    navigationStack,
    setNavigationStack,
    expandedCategories,
    setExpandedCategories,
    getCurrentItems,
  } = useSearchNavigation(searchOptions);

  useClickOutside(ref, () => setIsOpen(false));

  const handleOptionClick = (item: SearchItem): void => {
    if (item.items) {
      const cleanItem = {
        ...item,
        name: item.name,
      };
      setNavigationStack((prev) => [...prev, cleanItem]);

      setExpandedCategories((prev) => {
        const newSet = new Set(prev);
        newSet.add(cleanItem.name);
        return newSet;
      });

      setSelections((prev) => ({
        ...prev,
        [item.name]: {},
      }));

      setSearchValue("");
    } else {
      handleItemSelect(item);
    }
  };

  const handleItemSelect = (
    item: ItemData,
    categoryName: string = ""
  ): void => {
    const category = navigationStack[0]?.name || categoryName;

    setSelections((prev) => {
      const newSelections = { ...prev };
      if (!newSelections[category]) {
        newSelections[category] = {};
      }

      if (!newSelections[category][item.id]) {
        newSelections[category][item.id] = {
          selected: true,
          item,
        };
      } else {
        newSelections[category][item.id] = {
          ...newSelections[category][item.id],
          selected: !newSelections[category][item.id].selected,
        };
      }

      return newSelections;
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setSearchValue(value);
    setIsOpen(true);

    if (value && navigationStack.length === 0) {
      setExpandedCategories(new Set(searchOptions.map((cat) => cat.name)));
    }
  };

  const handleClearAll = (): void => {
    setSearchValue("");
    setSelections({});
    setNavigationStack([]);
    setExpandedCategories(new Set());
  };

  const handleResetNavigation = () => {
    if (navigationStack.length > 0) {
      const currentCategory = navigationStack[navigationStack.length - 1].name;

      const hasSelectedItems =
        selections[currentCategory] &&
        Object.values(selections[currentCategory]).some(
          (item) => item.selected
        );

      if (!hasSelectedItems) {
        setSelections((prev) => {
          const newSelections = { ...prev };
          delete newSelections[currentCategory];
          return newSelections;
        });
      }

      const newStack = navigationStack.slice(0, -1);
      setNavigationStack(newStack);

      if (newStack.length > 0) {
        setSearchValue(`${newStack[newStack.length - 1].name}: `);
      } else {
        setSearchValue("");
      }

      if (navigationStack.length === 1) {
        setExpandedCategories(new Set(searchOptions.map((cat) => cat.name)));
      }
    }
  };

  const handleCategoryClick = (
    category: SearchItem,
    matchingItems?: ItemData[]
  ) => {
    if (searchValue && matchingItems) {
      setNavigationStack([
        {
          ...category,
          items: matchingItems,
        },
      ]);
    } else {
      setExpandedCategories((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(category.name)) {
          newSet.delete(category.name);
        } else {
          newSet.add(category.name);
        }
        return newSet;
      });
    }
  };

  const getSelections = useCallback((): string[] => {
    return Object.entries(selections)
      .map(([category, items]) => {
        const selectedItems = Object.values(items)
          .filter((data) => data.selected)
          .map((data) => data.item.name);

        if (selectedItems.length === 0) return "";
        return `${category}: ${selectedItems.join(", ")}`;
      })
      .filter(Boolean);
  }, [selections]);

  const searchAllCategories = useCallback(() => {
    const results: { category: SearchItem; matchingItems: ItemData[] }[] = [];

    searchOptions.forEach((category) => {
      const matchingItems = (category.items || []).filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      if (matchingItems.length > 0) {
        results.push({
          category,
          matchingItems,
        });
      }
    });

    return results;
  }, [searchOptions, searchValue]);

  const handleSelectAll = (items: SearchItem[]): void => {
    const currentCategory =
      navigationStack[navigationStack.length - 1]?.name || "";

    const allSelected = items.every(
      (item) => selections[currentCategory]?.[item.id]?.selected
    );

    setSelections((prev) => {
      const newSelections = { ...prev };
      if (!newSelections[currentCategory]) {
        newSelections[currentCategory] = {};
      }

      items.forEach((item) => {
        newSelections[currentCategory][item.id] = {
          selected: !allSelected,
          item: item,
        };
      });

      return newSelections;
    });
  };

  const formatSelectionsToString = (
    categorySelections: CategorySelection[]
  ): string => {
    return categorySelections
      .map(({ category, values }) => `${category}: ${values.join(", ")}`)
      .join(" ");
  };

  const handleSearchSubmit = () => {
    const categorySelections: CategorySelection[] = Object.entries(selections)
      .map(([category, items]) => {
        const selectedItems = Object.values(items)
          .filter((data) => data.selected)
          .map((data) => data.item.name);

        return {
          category,
          values: selectedItems,
        };
      })
      .filter((selection) => selection.values.length > 0);

    if (categorySelections.length > 0) {
      const searchString = formatSelectionsToString(categorySelections);

      const newSearch: RecentSearch = {
        id: `search-${Date.now()}`,
        searchString,
        selections: categorySelections,
        timestamp: Date.now(),
      };

      setRecentSearches((prev) => [newSearch, ...prev].slice(0, 5));
    }

    setIsOpen(false);
    setSearchValue("");
    setNavigationStack([]);
  };

  const restoreSelections = (search: RecentSearch) => {
    setSelections({});

    const newSelections: SelectedItems = {};

    search.selections.forEach(({ category, values }) => {
      const categoryOption = searchOptions.find((cat) => cat.name === category);
      if (categoryOption && categoryOption.items) {
        newSelections[category] = {};

        values.forEach((value) => {
          const item = categoryOption.items?.find(
            (item) => item.name === value
          );
          if (item) {
            newSelections[category][item.id] = {
              selected: true,
              item: item,
            };
          }
        });
      }
    });

    setSelections(newSelections);
    setIsOpen(true);
  };

  return (
    <s.SearchContainer ref={ref}>
      <SearchInput
        isOpen={isOpen}
        inputValue={searchValue}
        onSearchChange={handleSearchChange}
        onClearAll={handleClearAll}
        onResetNavigation={handleResetNavigation}
        selections={getSelections()}
        setIsOpen={setIsOpen}
      />

      {isOpen && (
        <s.DropdownContainer>
          {!navigationStack.length && searchValue ? (
            <GlobalSearch
              searchResults={searchAllCategories()}
              expandedCategories={expandedCategories}
              selections={selections}
              handleCategoryClick={handleCategoryClick}
              handleItemSelect={handleItemSelect}
            />
          ) : Object.entries(recentSearches).length &&
            !navigationStack.length ? (
            <RecentSearches
              recentSearches={recentSearches}
              onRecentSearchClick={restoreSelections}
              renderDefaultView={() => (
                <DefaultView
                  currentItems={getCurrentItems(searchValue)}
                  navigationStack={navigationStack}
                  selections={selections}
                  handleSelectAll={handleSelectAll}
                  handleOptionClick={handleOptionClick}
                  handleItemSelect={handleItemSelect}
                />
              )}
            />
          ) : (
            <DefaultView
              currentItems={getCurrentItems(searchValue)}
              navigationStack={navigationStack}
              selections={selections}
              handleSelectAll={handleSelectAll}
              handleOptionClick={handleOptionClick}
              handleItemSelect={handleItemSelect}
            />
          )}
          {!!navigationStack.length && (
            <s.SubmitButtonContainer>
              <Button
                name="Submit"
                variant="primaryInvert"
                onClick={handleSearchSubmit}
                size="small"
              />
            </s.SubmitButtonContainer>
          )}
        </s.DropdownContainer>
      )}
    </s.SearchContainer>
  );
};

export default Search;
