export const AVAILABLE_WIDGETS: { [key: string]: string[] } = {
  lollipopChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
  ],
  lineChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "areaChart",
    "radarChart",
    "scatterplotChart"
  ],
  sparkLineChart: ["sparkAreaChart"],
  sparkAreaChart: ["sparkLineChart"],
  areaChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
  ],
  barChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
    "polarAreaChart",
    "radarChart",
  ],
  sankeyChart: ["matrixChart"],
  matrixChart: ["sankeyChart", "punchcardChart", "scatterplotChart"],
  mapChart: ["sankeyChart_map", "matrixChart_map"],
  bubbleChart: ["scatterplotChart"],
  scatterplotChart: ["bubbleChart", "lineChart"],
  punchcardChart: ['matrixChart'],
  polarAreaChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "radarChart",
  ],
  radarChart: [
    "polarAreaChart",
    "lineChart",
    "barChart_vertical",
    "barChart_horizontal",
  ],
  radialBarChart: [],
};
