import styled, { css } from "styled-components";

export const ImageWrapper = styled.div`
  min-width: 64px;
  max-width: 64px;
  height: 64px;
  position: relative;
`;

export const Count = styled.div`
  position: absolute;
  text-align: center;
  background: #5D63F6;
  border-radius: 50%;
  line-height: 16px;
  padding: 2px 4px;
  font-weight: 400;
  font-size: 12px;
  color: #EFF0F1;
  height: 20px;
  width: 20px;
  left: 0;
  top: 0;
`;

export const ApplicationImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const ApplicationContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TextWrapper = styled.div<{$card?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 100%;
`;

export const ApplicationName = styled.div`
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #21262E;
`;

export const ApplicationDescription = styled.div`
  width: calc(100% - 24px);
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 16px;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  color: #6C7584;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  transition: 0.3s;
  display: flex;
  height: 100%;
  opacity: 0;
`;

export const ImageBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Application = styled.div<{ $active?: boolean, $card?: boolean }>`
  overflow: hidden;
  background: #EFF0F1;
  border: 1px solid #DFE1E4;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  padding: 12px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;

  ${({$card}) => $card && css`
    flex-direction: column;

    ${TextWrapper} {
      width: 100%;
    }
    ${ButtonWrapper} {
      height: 100%;
      align-items: flex-start;
      justify-content: flex-end;
    }
  `}

  &:hover {
    background: white;
    ${ButtonWrapper} {
      opacity: 1;
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      background: white;
    `}
`;

