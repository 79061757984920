import { put, call, takeLatest } from "redux-saga/effects";
import { ServerResponse } from "../../models";
import { getDataProvider, getDataProviders } from "../../api/dataProviders";
import { setApplications, setDataProvider, setDataProvidersList } from "../slices/dataProviders";
import { PayloadAction } from "@reduxjs/toolkit";
import { getApplications, getServiceEndpoints, IGetAppEndpoints, IGetApplications, IValidateAppCredentials, validateAppAuthCredentials } from "../../api/app-api";

export function* handleGetDataProviders() {
  const { response }: ServerResponse = yield call(getDataProviders);
  if (response?.status === 200) {
    yield put(setDataProvidersList(response.data));
  }
}
export function* handleGetDataProvider(action: PayloadAction<string>) {
  const { response }: ServerResponse = yield call(getDataProvider, action.payload);
  if (response?.status === 200) {
    yield put(setDataProvider(response.data));
  }
}
export function* handleAppValidate({payload}: PayloadAction<IValidateAppCredentials>) {
  const { response }: ServerResponse = yield call(validateAppAuthCredentials, payload);
  
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(true);
  } else {
    payload.callbacks?.onSuccess(false);
  }
}
export function* handleGetAppEndpoints({ payload }: PayloadAction<IGetAppEndpoints>) {
  const { response }: ServerResponse = yield call(getServiceEndpoints, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(response.data);
  } else {
    payload.callbacks?.onError && payload.callbacks?.onError();
  }
}
export function* handleGetApplications({ payload }: PayloadAction<IGetApplications>) {
  const { response }: ServerResponse = yield call(getApplications);

  if (response?.status === 200) {
    yield put(setApplications(response.data.applications))
    
    payload?.callbacks?.onSuccess(response.data);
  } else {
    payload?.callbacks?.onError && payload?.callbacks?.onError();
  }
}
export function* watchDataProvidersActions() {
  yield takeLatest("dataProviders/requestGetDataProviders", handleGetDataProviders);
  yield takeLatest("dataProviders/requestGetDataProvider", handleGetDataProvider);
  yield takeLatest("dataProviders/requestGetAppEndpoints", handleGetAppEndpoints);
  yield takeLatest("dataProviders/requestApplications", handleGetApplications);
  yield takeLatest("dataProviders/requestAppValidate", handleAppValidate);
}
