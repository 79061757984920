import { getRegionIdFromFeatureProperties } from "./getRegionIdFromFeatureProperties";

export const getRegionFeatureById = (
  id: number | string,
  mapTypeGeoJson?: GeoJSON.GeoJsonProperties,
  mapType?: string
): GeoJSON.Feature | undefined => {
  if (!mapTypeGeoJson || !mapType) {
    return undefined;
  }

  return mapTypeGeoJson.features.find(
    (el: { id: number | string; properties: Record<string, any> }) => {
      const regionId = getRegionIdFromFeatureProperties(el.properties, mapType);
      return String(regionId) === String(id);
    }
  );
};
