import { DatasetDTO } from "../models/Datasets";
import { getDatasetField } from "./getDatasetField";

export const getGeoSpatialDataKeys = (
  dataKeys: string[],
  datasets: DatasetDTO[]
): string[] => {
  if (!dataKeys?.length || !datasets) {
    return [];
  }

  return (
    dataKeys.filter((key) => {
      const field = getDatasetField(String(key), datasets);
      return field?.type === "location";
    }) || []
  );
};
