import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { closeActiveModal } from "../../../store/slices/modals";
import { QuestionCircleFill } from "react-bootstrap-icons";
import ChatPanel from "./components/ChatPanel";
import AIChatHeader from "./components/AIChatHeader";
import AiChatLoader from "./components/AiChatLoader";
import AiChatInitialView from "./components/AiChatInitialView";
import { uploadFile } from "../../../api/files";
import useChatWS, { IChatMessage } from "./hooks/useChatWS";
// import RecommendationTable from "./components/RecommendationTable";
import {
  ModalFooterWrapper,
  ModalHeadingWrapper,
  ModalOverlay,
} from "../styles";
import {
  ModalFooterContainer,
  ModalTicketLink,
  ModalWrapper,
  ModalContentContainer,
  ModalChatWrapper,
  ModalChatPanelWrapper,
} from "./styles";
import { MessageStatus, MessageTypeEnum } from "./constants";
import ChatMessageMain from "./components/ChatMessageMain";
// import { splitCamelCase } from "./utils";

interface ModalProps {
  onClose: () => void;
}

const AiChatModal = ({ onClose }: ModalProps) => {
  const { messages, sendMessage } = useChatWS();
  const isNotComplete = messages?.at(-1)?.status === MessageStatus.NOT_COMPLETE;
  const dataset_id = messages?.find(msg => msg?.payload?.dataset_id)?.payload?.dataset_id;

  const dispatch = useDispatch();

  const [isClosing, setIsClosing] = useState(false);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      dispatch(closeActiveModal());
    }, 400);
  };

  const [isLoading, setIsLoading] = useState(false);
  const chatSectionRef = useRef<any>(null);

  const handeSendMessage = async (message: string, files: File[]) => {
    setIsLoading(true);
    if (files?.length) {
      try {
        const { response } = (await uploadFile({ files })) as any;

        if (response?.data.items?.[0]) {
          const uploadMessage: IChatMessage = {
            action: MessageTypeEnum.UPLOAD,
            instructions: {
              file_path: response?.data.items[0].path,
            },
          };

          sendMessage(uploadMessage);
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (message) {
      const promptMessage: IChatMessage = {
        action: MessageTypeEnum.EXPLORE,
        instructions: {
          message,
          dataset_id,
        },
      };
      sendMessage(promptMessage)
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const handlePromptClick = (
    action: MessageTypeEnum,
    instructions: any
  ) => {
    const promptMessage: IChatMessage = {
      action: action,
      instructions,
    };
    sendMessage(promptMessage);
  };

  const isInititalState = !(isLoading || !!messages?.length);

  useEffect(() => {
    chatSectionRef?.current?.lastElementChild?.scrollIntoView();
  }, [messages]);

  return (
    <ModalOverlay $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper $isClosing={isClosing} onClick={(e) => e.stopPropagation()}>
        <ModalHeadingWrapper>
          <AIChatHeader onClose={handleOnClose} />
        </ModalHeadingWrapper>
        {/* <RecommendationTable /> */}
        <ModalContentContainer $isInitialState={isInititalState}>
          <ModalChatWrapper ref={chatSectionRef}>
            {isInititalState ? (
              <AiChatInitialView />
            ) : (
              messages
                ?.filter((message) => message.type !== MessageTypeEnum.SYSTEM)
                .map((message, index) => (
                  <ChatMessageMain
                    key={index}
                    message={message}
                    onPromptClick={handlePromptClick}
                  />
                ))
            )}
          </ModalChatWrapper>
          <ModalChatPanelWrapper $isInitialState={isInititalState}>
            {(isLoading || isNotComplete) && <AiChatLoader />}
            <ChatPanel onMessageSend={handeSendMessage} />
          </ModalChatPanelWrapper>
        </ModalContentContainer>
        {isInititalState && (
          <ModalFooterWrapper>
            <ModalFooterContainer>
              <QuestionCircleFill />
              Need help?
              <ModalTicketLink to="/">Create a Ticket</ModalTicketLink>
            </ModalFooterContainer>
          </ModalFooterWrapper>
        )}
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default AiChatModal;
