import { useEffect, useMemo, useRef, useState } from "react";
import { BarGroupHorizontal } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { setActiveModal } from "../../../store/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import useMeasure from "react-use-measure";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { ticksFormatter } from "../../../helpers/ticksFormatter";
import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleLinearTickValues,
} from "../widgetHelpers";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { TickLabel } from "../components/LabelTooltip";
import { ChartLegend, ChartLegendValue } from "../../ChartLegend";
import { getBarChartKeys } from "../GroupedBarChart/utils/getKeys";
import { createPortal } from "react-dom";
import { Rect } from "./styles";
import {
  FeedBackButton,
  HeadingNameAndButton,
  WidgetImageWrapper,
} from "../styles";
import { getActiveModal } from "../../../store/selectors/modals";
import { useDatasetField } from "../../../hooks/useDatasetField";
import { formatLabelValue } from "../utils/formatLabelValue";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";

interface GroupedBarChartProps {
  storytelling?: boolean;
  recommended?: boolean;
  currentWidget: WidgetItem;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
}

type DataItem = {
  [key: string]: string | number;
};

export const HorizontalGroupedBarChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
}: GroupedBarChartProps) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [feedbackState, setFeedbackState] = useState<boolean>(false);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const barChartSuggestion = aiSuggestions
    ?.filter((chart: AiSuggestionsDto) => chart.chartType === "barChart")
    ?.at(0);

  const xAxe = currentWidget?.xAxe?.[0] || barChartSuggestion?.xAxe?.[0];
  const yAxe = currentWidget?.yAxe?.[0] || barChartSuggestion?.yAxe?.[0];
  const groupBy = currentWidget?.groupBy?.at(0);

  const getValue = (d: DataItem) => Number(d[yAxe]);

  const barchartData: any = useMemo(
    () => currentWidget?.data || [],
    [currentWidget?.data]
  );

  const yScaleDatasetField = useDatasetField(xAxe);

  const yAxes: string[] = useMemo(
    () =>
      currentWidget?.uniqueValues?.[xAxe] ||
      barchartData?.map((d: any) => d[xAxe]) ||
      [],
    [barchartData, currentWidget?.uniqueValues, xAxe]
  );

  const maxXAxe = Math.max(...barchartData?.map(getValue));

  const roundedMaxXAxe = useMemo(
    () =>
      Math.ceil(
        maxXAxe / Math.pow(10, Number(Math.floor(Math.log10(maxXAxe))))
      ) * Math.pow(10, Number(Math.floor(Math.log10(maxXAxe)))),
    [maxXAxe]
  );

  const divRef = useRef<HTMLDivElement | null>(null);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);

  const [hoveredElement, setHoveredElement] = useState<null | string>(null);

  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const keys =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : getBarChartKeys(currentWidget);

  const legendValues: ChartLegendValue[] = keys?.map((key: string) => ({
    label: key,
    color: getCurrentColor(currentWidget, key, styleId),
  }));

  const uniqueCategories = new Set(
    barchartData?.map((item: DataItem) => item[xAxe])
  ).size;

  const hasLongBarValue = barchartData?.some(
    (el: DataItem) => el[xAxe].toString().length > 6
  );

  const margin = {
    top: 0,
    right: 1,
    bottom: barchartData?.length! > 7 ? -4 : 40,
    left: hasLongBarValue ? 80 : 40,
  };

  const name = useMemo(
    () =>
      recommended ? replaceWords(currentWidget?.name) : currentWidget?.name,
    [currentWidget?.name, recommended]
  );

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  const refHeight = useMemo(
    () => divRef?.current?.parentElement?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, divRef.current]
  );

  if (!barchartData?.length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  const numBarsInGroup = keys?.length;
  const fixedBarHeight = 16;
  const fixedBarSpacing = 1;
  const totalSpaceRequiredPerGroup =
    numBarsInGroup * fixedBarHeight + (numBarsInGroup - 1) * fixedBarSpacing;
  const yMax =
    totalSpaceRequiredPerGroup * uniqueCategories + 50 * uniqueCategories - 1;

  const formattedData = barchartData?.reduce((acc: any[], item: any) => {
    const itemIndex = acc.findIndex((obj) => obj[xAxe] === item[xAxe]);
    if (itemIndex !== -1) {
      acc[itemIndex] = {
        ...acc[itemIndex],
        [item[groupBy!]]: item[yAxe],
      };
      return acc;
    }
    acc.push({
      [xAxe]: item[xAxe],
      [item[groupBy!]]: item[yAxe],
    });
    return acc;
  }, []);

  const yScale = scaleBand<string>({
    domain: yAxes,
    align: 0.5,
    range: [0, yMax],
  });

  const keysScale = scaleBand<string>({
    domain: keys,
    range: [0, totalSpaceRequiredPerGroup],
    paddingInner: 0.1,
    paddingOuter: 0,
  });

  const xScale = scaleLinear<number>({
    domain: [0, roundedMaxXAxe],
    range: [margin.left, width - margin.right],
    nice: true,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: legendValues?.map((r) => r.color),
  });

  const yScaleNumTicks = calculateNumTicks({ height: height });

  const yTicks = yScale.domain();

  const xTicks = xScale.ticks();

  const xScaleNumTicksCalculated = calculateNumTicks({ width });

  const xScaleNumTicks =
    xScaleNumTicksCalculated <= xTicks.length
      ? xScaleNumTicksCalculated
      : xTicks.length;

  const xScaleTickValues = getScaleLinearTickValues({
    scale: xScale,
    tickCount: xScaleNumTicks,
  });

  const xScaleTickLabelMaxLength = calculateLabelLength({
    width: width - margin.left - margin.right,
    tickValues: xScaleTickValues,
    tickFormatter: ticksFormatter,
  });

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper ref={refWidget} style={{ marginBottom: "10px" }}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <div />}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings &&
              !isPublicRoute &&
              !recommended &&
              isEditMode &&
              !storytelling ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {availableWidgetsCount}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}

              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}
          {legendValues?.length > 1 &&
            currentWidget?.formatting?.length! > 1 &&
            showLegend &&
            currentWidget?.legend && (
              <ChartLegend
                chartWidth={boundsWidget.width}
                legendType="unit"
                legendValues={legendValues}
              />
            )}
        </HeaderWrapper>
        <div
          ref={divRef}
          style={
            barchartData.length > 7
              ? {
                  height: refHeight
                    ? refHeight - (44 + 12 + 12) < 170
                      ? 170
                      : refHeight - (44 + 12 + 12)
                    : 170,
                  overflowY: "auto",
                }
              : { height: "100%" }
          }
        >
          <svg width={"100%"} height={yMax} ref={ref}>
            <Group>
              {yTicks.map((d, idx) => (
                <line
                  key={idx}
                  x1={margin.left}
                  x2={width - margin.right}
                  y1={yScale(d)!}
                  y2={yScale(d)!}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
              {xScaleTickValues.map((tick: number, idx: number) => (
                <line
                  key={idx}
                  x1={xScale(tick)}
                  x2={xScale(tick)}
                  y1={0}
                  y2={height - margin.bottom}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
            </Group>
            {!(barchartData.length > 7) && (
              <AxisBottom
                scale={xScale}
                top={height - margin.bottom}
                hideTicks
                tickFormat={(value: any) => ticksFormatter(value)}
                tickLabelProps={(_, index, values) => {
                  const isFirstTick = index === 0;
                  const isLastTick = index === values.length - 1;
                  const textAnchor =
                    (isFirstTick && "start") ||
                    (isLastTick && "end") ||
                    "middle";
                  return {
                    fontSize: 10,
                    fill: "#5F6877",
                    textAnchor,
                    dx: 0,
                  };
                }}
                tickValues={xScaleTickValues}
                tickComponent={(props: any) => (
                  <TickLabel
                    {...props}
                    length={xScaleTickLabelMaxLength}
                    offsetX={-10}
                  />
                )}
                axisLineClassName="barchartAxisLine"
              />
            )}
            <AxisLeft
              scale={yScale}
              top={0}
              hideTicks
              hideAxisLine
              left={margin.left}
              tickLineProps={{ stroke: "#939BA7" }}
              numTicks={
                barchartData.length > 7 ? barchartData.length : yScaleNumTicks
              }
              tickLabelProps={() => ({
                fontSize: 11,
                fill: "#5F6877",
                textAnchor: "start",
                dominantBaseline: "middle",
                dx: hasLongBarValue ? -80 : -40,
              })}
              tickComponent={(props: any) => (
                <TickLabel
                  {...props}
                  labelText={formatLabelValue(
                    props.formattedValue,
                    yScaleDatasetField?.type,
                    yScaleDatasetField?.subtype
                  )}
                  x={0}
                  length={hasLongBarValue ? 10 : 4}
                  dy={4}
                  offsetX={-10}
                />
              )}
            />
            <Group left={margin.left}>
              <BarGroupHorizontal
                data={formattedData}
                keys={keys}
                height={yMax}
                y0={(d) => d[xAxe]}
                y0Scale={yScale}
                y1Scale={keysScale}
                xScale={xScale}
                color={colorScale}
                width={0}
              >
                {(barGroups) => {
                  return barGroups.map((barGroup) => (
                    <Group
                      key={`bar-group-${barGroup.index}-${barGroup.y0}`}
                      top={barGroup.y0}
                    >
                      {barGroup.bars.map((bar) => {
                        if (!bar?.value) {
                          return "";
                        }
                        const legend = legendValues.find(
                          (r) => r.label === bar.key
                        );
                        const key = `bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`;
                        return (
                          <Rect
                            key={key}
                            x={bar.x}
                            y={bar.y + bar.height * 1.55}
                            width={bar.width - xScale(0)}
                            height={bar.height}
                            fill={legend?.color}
                            opacity={
                              hoveredElement
                                ? hoveredElement === key
                                  ? 1
                                  : 0.3
                                : 1
                            }
                            onMouseMove={(event: any) => {
                              const hoveredBarGroup =
                                formattedData[barGroup.index];
                              if (
                                (showTooltip || currentWidget.tooltip) &&
                                !recommended
                              ) {
                                const { pageX, pageY, clientX, clientY } =
                                  event;
                                const coords = {
                                  pageX,
                                  pageY,
                                  clientX,
                                  clientY,
                                };

                                setHoveredElement(key);
                                setTooltip({
                                  name: bar.key,
                                  data: {
                                    [xAxe as string]: hoveredBarGroup[xAxe],
                                    [groupBy! as string]: keys[bar.index],
                                    [yAxe as string]: bar?.value?.toString(),
                                  },
                                  coords,
                                });
                              }
                            }}
                            onMouseLeave={() => {
                              setTooltip(null);
                              setHoveredElement(null);
                            }}
                          />
                        );
                      })}
                    </Group>
                  ));
                }}
              </BarGroupHorizontal>
            </Group>
          </svg>
        </div>
        {barchartData.length > 7 && (
          <svg height={30} width={"100%"}>
            <AxisBottom
              scale={xScale}
              hideTicks
              top={1}
              tickFormat={(value: any) => ticksFormatter(value)}
              tickLabelProps={(_, index, values) => {
                const isFirstTick = index === 0;
                const isLastTick = index === values.length - 1;
                const textAnchor =
                  (isFirstTick && "start") || (isLastTick && "end") || "middle";
                return {
                  fontSize: 10,
                  fill: "#5F6877",
                  textAnchor,
                  dx: 0,
                  dy: -1,
                };
              }}
              tickValues={xScaleTickValues}
              tickComponent={(props: any) => (
                <TickLabel
                  {...props}
                  length={xScaleTickLabelMaxLength}
                  offsetX={-10}
                />
              )}
              axisLineClassName="barchartAxisLine"
            />
          </svg>
        )}
        {tooltip &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              name={tooltip.name}
              coords={tooltip.coords}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
