import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import { CloseIcon, Heading } from "./styles";
import { ProjectType } from "./ProjectType";
import { StyleSection } from "./Style";
import { Interactivity } from "./Interactivity";
import {
  ModalContent,
  ModalFooterWrapper,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { SelectTemplate } from "./SelectTemplate";
import { Button } from "../../Button";

import { CustomInput } from "../../CustomInput";
import {
  requestAllPages,
  requestAllPublicPages,
  requestCreatePage,
  requestPageById,
  requestPageWidgets,
  requestUpdatePage,
  resetDraftSettings,
  setDraftPageSettings,
  setPageSettings,
  setPageWidgets,
} from "../../../store/slices/projectPages";
import { resetActiveModal } from "../../../store/slices/modals";
import { PageSettingsDTO } from "../../../models/Pages";
import {
  getCurrentPageWidgets,
  getCurrentProjectId,
  getDraftPageSettings,
  getPageSettings,
} from "../../../store/selectors/projects";
import {
  getActiveModal,
  getModalCreateOptions,
} from "../../../store/selectors/modals";
import { LAYOUTS } from "../../ProjectPageLayouts/config";
import { adjustArray } from "../../../helpers/ajustArray";
import { findFirstCommonElement } from "../../../helpers/firstCommonElement";
import { extractBlockIds, LayoutI } from "../../../helpers/extractBlockIds";
import { defaultHeader } from "../../AddStorytellingSection";
import { UpdateWidgetDTO, WidgetsItemsDTO } from "../../../models/Widgets";
import { defaultNewWidget } from "../../../pages/ProjectPage/constants";
import { requestUpdateWidgets } from "../../../store/slices/widgets";
import { Loader } from "../../Loader";
import { getIsPublicMode } from "../../../store/selectors/main";

type WidgetDescriptions = {
  story: string;
  widget_id: string;
}[];

type ProjectSettingsModalProps = {
  closeModal: () => void;
};

export const ProjectSettingsModal = ({
  closeModal,
}: ProjectSettingsModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPublicRoute = useSelector(getIsPublicMode);
  const pageSettings = useSelector(getPageSettings);
  const pageDraftSettings = useSelector(getDraftPageSettings);
  const activeModal = useSelector(getActiveModal);
  const createNewProject = useSelector(getModalCreateOptions);
  const currentProjectId = useSelector(getCurrentProjectId);
  const modalOpen = activeModal.includes("projectSettingsModal");
  const widgets = useSelector(getCurrentPageWidgets);
  const settings = createNewProject ? pageDraftSettings : pageSettings;
  const [name, setName] = useState(settings.name);

  const resetPage = () => {
    dispatch(requestPageById({ id: settings.id! }));
    dispatch(requestPageWidgets({ pageId: settings.id!, includeData: true }));
  };

  const handleOnClose = () => {
    setIsClosing(true);
    // setIsLoading(false);
    pageDraftSettings && dispatch(resetDraftSettings());
    if (settings.id) {
      resetPage();
    }
    setTimeout(() => {
      closeModal();
    }, 400);
  };

  const noLayout = settings.dashType === "dashboard" && !settings.templateId;

  const handleSettingsUpdate = (newSettings: PageSettingsDTO) => {
    const newPage = { ...newSettings, header: defaultHeader };

    dispatch(
      createNewProject
        ? setDraftPageSettings(newPage)
        : setPageSettings(newPage)
    );

    if (
      newSettings.templateId &&
      newSettings.templateId !== settings.templateId &&
      !createNewProject
    ) {
      let mappedWidgets = [...widgets?.items];

      const layout = LAYOUTS.find((l) => l.id === newSettings.templateId);
      const widgetItems = [...widgets?.items];
      const chartTypes = widgetItems.map((r: any) => r.chartType);
      const blockIds = extractBlockIds(layout?.arranging as LayoutI);
      const blocks =
        blockIds.length > widgetItems?.length
          ? [...adjustArray(blockIds, widgetItems?.length)]
          : [...blockIds];

      for (let i = 0; i < blocks?.length; i++) {
        const block = blocks[i];
        const chartType = findFirstCommonElement(chartTypes, block.widgets);

        const chart = widgetItems?.find(
          (widget: any) => widget.chartType === chartType
        );

        if (chart) {
          const widgetItemsIndex = widgetItems?.findIndex(
            (widget: any) => widget.chartType === chartType
          );

          if (widgetItemsIndex !== -1) widgetItems?.splice(widgetItemsIndex, 1);

          mappedWidgets = mappedWidgets?.map((w) => {
            if (w.id === chart.id) {
              return { ...w, blockId: block?.blockId?.toString() };
            }
            return w;
          });
        }
      }

      const sortedWidgetItems = mappedWidgets.sort(
        (a, b) => parseInt(a.blockId!) - parseInt(b.blockId!)
      );

      dispatch(
        setPageWidgets({ count: widgets.count, items: sortedWidgetItems })
      );
    }
  };

  const handleEditPage = (widgetDescriptions?: WidgetDescriptions) => {
    const { ...requestUpdatePageSettings } = pageSettings;

    const chartData = getChartData(
      requestUpdatePageSettings.id!,
      widgets,
      widgetDescriptions
    );

    if (chartData.count > 0) {
      dispatch(setPageWidgets({ items: [], count: 0 }));
      dispatch(
        requestUpdateWidgets({
          ...chartData.items,
        })
      );
    }

    const header = requestUpdatePageSettings.header ?? defaultHeader;
    const requestPageMethod = isPublicRoute
      ? requestAllPublicPages
      : requestAllPages;

    handleSettingsUpdate({ ...pageSettings, name: name });

    dispatch(
      requestUpdatePage({
        ...requestUpdatePageSettings,
        name: name,
        header,
        callbacks: {
          onSuccess: () => {
            dispatch(requestPageMethod({ projectId: currentProjectId }));
          },
        },
      })
    );

    handleOnClose();
  };

  const handleCreatePage = () => {
    if (createNewProject) {
      const { ...requestCreatePageSettings } = pageDraftSettings;
      const newPage = {
        ...requestCreatePageSettings,
        header: defaultHeader,
        projectId: currentProjectId,
        interactivity: ["tooltip"],
        navigate,
      };
      dispatch(requestCreatePage(newPage));
      dispatch(resetActiveModal());
    } else if (!createNewProject && pageSettings.id) {
      setIsLoading(true);
      handleEditPage();
    }
  };

  const isCTADisabled =
    !settings.name?.trim().length ||
    !name?.trim().length ||
    !(!!settings.dashType || noLayout);

  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing} $noBlur>
      <ModalWrapper
        onClick={(e) => e.stopPropagation()}
        $projectSettingsOpen={modalOpen}
        $isClosing={isClosing}
      >
        {isLoading && <Loader fullScreen />}
        <ModalHeadingWrapper>
          <Heading>
            {createNewProject ? "Create new page" : "Page configuration"}
          </Heading>
          <CloseIcon
            onClick={() => {
              handleOnClose();
            }}
          />
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <CustomInput
              name="Page name"
              type="text"
              value={name}
              heading="Name the page"
              onChange={(e) => {
                setName(e);
                if (createNewProject)
                  handleSettingsUpdate({ ...settings, name: e });
              }}
              maxLength={24}
            />
            <ProjectType
              settings={settings}
              onUpdateSettings={handleSettingsUpdate}
            />
            {settings.dashType === "dashboard" && (
              <SelectTemplate
                settings={settings}
                onUpdateSettings={handleSettingsUpdate}
              />
            )}
            <StyleSection
              settings={settings}
              onUpdateSettings={handleSettingsUpdate}
            />
            <Interactivity />
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
            name="Close"
          />
          <Button
            onClick={handleCreatePage}
            icon={<CheckCircle />}
            variant={isCTADisabled ? "disabled" : "secondary"}
            size="medium"
            disabled={isCTADisabled}
            name={createNewProject ? "Create page" : "Save changes"}
          />
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export function getChartData(
  pageId: string,
  widgets: WidgetsItemsDTO,
  widgetDescriptions?: WidgetDescriptions
) {
  const barChartRequestData: UpdateWidgetDTO[] = [];
  const lineChartRequestData: UpdateWidgetDTO[] = [];
  const sparkLineChartRequestData: UpdateWidgetDTO[] = [];
  const sparkAreaChartRequestData: UpdateWidgetDTO[] = [];
  const lollipopChartRequestData: UpdateWidgetDTO[] = [];
  const sankeyChartRequestData: UpdateWidgetDTO[] = [];
  const mapChartRequestData: UpdateWidgetDTO[] = [];
  const areaChartRequestData: UpdateWidgetDTO[] = [];
  const matrixChartRequestData: UpdateWidgetDTO[] = [];
  const scatterplotChartRequestData: UpdateWidgetDTO[] = [];
  const radarChartRequestData: UpdateWidgetDTO[] = [];
  const punchcardChartRequestData: UpdateWidgetDTO[] = [];
  const polarAreaChartRequestData: UpdateWidgetDTO[] = [];
  const bubbleChartRequestData: UpdateWidgetDTO[] = [];
  const radialBarChartRequestData: UpdateWidgetDTO[] = [];

  for (const chart of widgets.items) {
    const descriptionData = widgetDescriptions?.find(
      (w) => w.widget_id === chart.id
    );
    const chartDataDescriptions = descriptionData
      ? descriptionData.story
      : chart?.description || defaultNewWidget.description;

    let chartData = {
      ...defaultNewWidget,
      description: chartDataDescriptions,
      insights: chart?.insights || defaultNewWidget.insights,
    };

    switch (chart?.chartType) {
      case "mapChart":
        mapChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "areaChart":
        areaChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "lineChart":
        lineChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "sparkLineChart":
        sparkLineChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "sparkAreaChart":
        sparkAreaChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "barChart":
        barChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "lollipopChart":
        lollipopChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "sankeyChart":
        sankeyChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "matrixChart":
        matrixChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "polarAreaChart":
        polarAreaChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "punchcardChart":
        punchcardChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "radarChart":
        radarChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "scatterplotChart":
        scatterplotChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "bubbleChart":
        bubbleChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      case "radialBarChart":
        radialBarChartRequestData.push({
          id: chart.id,
          blockId: chart?.blockId!,
          ...chartData,
        });
        break;
      default:
    }
  }

  const count = [
    barChartRequestData?.length,
    lineChartRequestData?.length,
    sparkLineChartRequestData?.length,
    sparkAreaChartRequestData?.length,
    lollipopChartRequestData?.length,
    sankeyChartRequestData?.length,
    areaChartRequestData?.length,
    matrixChartRequestData?.length,
    radarChartRequestData?.length,
    scatterplotChartRequestData?.length,
    polarAreaChartRequestData?.length,
    punchcardChartRequestData?.length,
    bubbleChartRequestData?.length,
    radialBarChartRequestData?.length,
  ].reduce((acc, cur) => acc + cur, 0);

  const items = {
    barChart: barChartRequestData,
    lineChart: lineChartRequestData,
    sparkLineChart: sparkLineChartRequestData,
    sparkAreaChart: sparkAreaChartRequestData,
    lollipopChart: lollipopChartRequestData,
    sankeyChart: sankeyChartRequestData,
    areaChart: areaChartRequestData,
    matrixChart: matrixChartRequestData,
    mapChart: mapChartRequestData,
    scatterplotChart: scatterplotChartRequestData,
    polarAreaChart: polarAreaChartRequestData,
    punchcardChart: punchcardChartRequestData,
    radarChart: radarChartRequestData,
    bubbleChart: bubbleChartRequestData,
    radialBarChart: radialBarChartRequestData,
    pageId: pageId,
  };

  return { count, items };
}
