// import { getIcon } from "./icons/getIcon";
// import { IconType } from "./icons/icons.type";
import { useRef, useState } from "react";
import { Slider, SwitchOption, SwitchWrapper } from "./style";

type Props = {
  onChange: (e: string | number) => void;
  onBlur?: React.ChangeEventHandler;
  values?: any;
  checked?: boolean;
  name?: string;
  disabled?: boolean;
  errors?: string;
  touched?: boolean;
  label?: string;
  options: (string | number)[];
  activeValue?: string | number;
};

const SelectValueComponent: React.FC<Props> = ({ options, activeValue, onChange = () => {} }) => {
  const ref = useRef<
    Array<{
      key: string | number;
      width?: number;
    }>
  >([]);

  const [selectedMethod, setSelectedMethod] = useState<string | number>(
    activeValue || options[0]
  );

  const indexOfSelectedItem = options.findIndex(
    (item) => item === selectedMethod
  );

  const getMargin: number = ref.current
    .slice(0, indexOfSelectedItem)
    .reduce(
      (accumulator, current, idx) => accumulator + (current.width ?? 0),
      0
    );

  return (
    <SwitchWrapper>
      <Slider $margin={getMargin}>{selectedMethod}</Slider>
      {options.map((item, index) => (
        <SwitchOption
          key={`switch-option-${index}`}
          onClick={() => {
            onChange(item);
            setSelectedMethod(item);
          }}
          ref={(el) =>
            (ref.current[index] = {
              key: item,
              width: el?.offsetWidth,
            })
          }
        >
          {item}
        </SwitchOption>
      ))}
    </SwitchWrapper>
  );
};

export default SelectValueComponent;
