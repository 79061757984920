import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { arc, NumberValue, range, scaleLinear, scaleOrdinal, select } from "d3";
//@ts-ignore
import { useScreenshot } from "use-react-screenshot";

import { FeedBackButton, WidgetImageWrapper } from "../styles";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  SVGStyled,
  Title,
} from "./styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { getActiveModal } from "../../../store/selectors/modals";

import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { LabelTooltip, LabelTooltipProps } from "../components/LabelTooltip";

import { openFeedBackModal } from "../utils/feedback";
import { getAvailableWidgetTypes } from "../widgetHelpers";
import { formatLabelValue } from "../utils/formatLabelValue";
import { useDatasetField } from "../../../hooks/useDatasetField";

export interface RadialBarChartProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  preview?: boolean;
}

const axisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

const labelFormatter = (value: string, length: number = Infinity): string => {
  const splitedValue = String(value)?.split("");

  return `${splitedValue?.slice(0, length).join("")}${
    splitedValue?.length < length + 1 ? "" : "..."
  }`;
};

export const RadialBarChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
  preview = false,
}: RadialBarChartProps) => {
  const dispatch = useDispatch();

  const svgRef = useRef<any>(null);
  const widgetRef = useRef(null);
  const [measureRef, bounds] = useMeasure({ scroll: true });

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [tickLabelTooltip, setTickLabelTooltip] =
    useState<LabelTooltipProps | null>(null);

  const [feedbackState, setFeedbackState] = useState<boolean>(false);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const margin = { top: 25, right: 5, bottom: 25, left: 5 };
  const width = bounds.width - margin.left - margin.right;
  const height = bounds.height - margin.top - margin.bottom;
  const outerRadius = Math.min(width, height) / 2;
  const innerRadius = outerRadius * 0.2;
  const maxLengthBarLabel = Math.ceil((outerRadius - innerRadius) / 8);
  const numTicks = 5;
  const minArcWidth = 4;
  const maxArcWidth = 24;

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const chartSuggestion = useMemo(() => {
    return aiSuggestions?.find(
      (chart: AiSuggestionsDto) => chart.chartType === "radialBarChart"
    );
  }, [aiSuggestions]);

  const xAxe = useMemo(() => {
    return currentWidget?.xAxe?.[0] ?? chartSuggestion?.xAxe?.[0];
  }, [currentWidget?.xAxe, chartSuggestion?.xAxe]);

  const yAxe = useMemo(() => {
    return currentWidget?.yAxe?.[0] ?? chartSuggestion?.yAxe?.[0];
  }, [chartSuggestion?.yAxe, currentWidget?.yAxe]);

  const categoryDatasetField = useDatasetField(xAxe);

  const uniqueValues = useMemo(() => {
    const uniqueValuesKey = Object.keys(currentWidget?.uniqueValues || {})?.[0];

    return currentWidget.uniqueValues?.[uniqueValuesKey] || [];
  }, [currentWidget.uniqueValues]);

  const legendValues = useMemo(() => {
    if (!uniqueValues?.length) return [];
    return uniqueValues.map((key) => ({
      label: key!,
      key: key!,
      color: getCurrentColor(currentWidget, key, styleId),
    }));
  }, [uniqueValues, currentWidget, styleId]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  //* Chart Data
  const chartData = useMemo(() => {
    return (currentWidget?.data || chartSuggestion?.data)?.toReversed();
  }, [currentWidget?.data, chartSuggestion?.data]);

  const numArcs = chartData?.length;
  const arcWidth = Math.max(
    minArcWidth,
    Math.min(
      maxArcWidth,
      ((outerRadius - innerRadius - numArcs * 1) / numArcs) * 0.5
    )
  );

  //* Scales
  const colorScale = useMemo(() => {
    return scaleOrdinal<string, string>()
      .domain(legendValues?.map((item) => item.label))
      .range(legendValues?.map((item) => item.color))
      .unknown(getCurrentColor(currentWidget, "default", styleId));
  }, [currentWidget, legendValues, styleId]);

  const maxValue = useMemo(
    () =>
      chartData && Math.max(...chartData?.map((d: any) => d[yAxe] as number)),
    [chartData, yAxe]
  );

  const radialScale = useMemo(() => {
    return scaleLinear<any, number>()
      .domain([0, maxValue])
      .range([0, 1.5 * Math.PI])
      .nice();
  }, [maxValue]);

  const niceMaxValue = useMemo(() => {
    return radialScale.domain()[1];
  }, [radialScale]);

  const radiusScale = useMemo(() => {
    return scaleLinear<number, number>()
      .domain([innerRadius, outerRadius])
      .rangeRound([innerRadius, outerRadius])
      .nice();
  }, [innerRadius, outerRadius]);

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget?.tooltip) && !recommended) {
        const { pageX, pageY, clientX, clientY } = event;
        const coords = { pageX, pageY, clientX, clientY };

        setTooltip({
          name: String(datum[xAxe]),
          data: {
            [xAxe]: String(datum[xAxe]),
            [yAxe]: String(datum[yAxe]),
          },
          coords,
        });
      }
    },
    [currentWidget?.tooltip, recommended, showTooltip, xAxe, yAxe]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip || currentWidget?.tooltip) {
      setTooltip(null);
    }
  }, [currentWidget?.tooltip, showTooltip]);

  const handleMouseOver = useCallback(
    function (self: any, svg: any) {
      if (!currentWidget.tooltip) {
        return;
      }

      svg
        .selectAll(".radial-bar")
        .transition()
        .duration(200)
        .attr("opacity", 0.2);

      select(self).transition().duration(200).attr("opacity", "1").attr("r", 6);
    },
    [currentWidget.tooltip]
  );

  const handleMouseOut = useCallback(
    function (svg: any) {
      if (!currentWidget.tooltip) {
        return;
      }

      svg
        .selectAll(".radial-bar")
        .transition()
        .duration(200)
        .attr("opacity", 0.8);
    },
    [currentWidget.tooltip]
  );

  const handleMouseMoveTickLabel = useCallback((event: any, datum: any) => {
    setTickLabelTooltip({
      data: datum,
      x: event.pageX - 10,
      y: event.pageY,
    });
  }, []);

  const handleMouseLeaveTickLabel = useCallback(() => {
    setTickLabelTooltip(null);
  }, []);

  //* Chart
  const svgContainer = select(svgRef.current);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    if (!bounds.width || !bounds.height || !chartData.length) {
      return;
    }

    const svg = svgContainer
      .append("g")
      .attr(
        "transform",
        `translate(${(width + margin.left + margin.right) / 2}, ${
          (height + margin.top + margin.bottom) / 2
        })`
      );

    //* Grid
    const grid = svg.append("g").attr("class", "grid");
    const radiusInterval = (outerRadius - innerRadius) / numArcs;
    const endAngle = radialScale(niceMaxValue) as number;

    grid
      .selectAll(".grid-arc")
      .data(range(0, numArcs + 1))
      .enter()
      .append("path")
      .attr("class", "grid-arc")
      .attr("d", (i: number) =>
        arc()
          .innerRadius(radiusScale(innerRadius + radiusInterval * i))
          .outerRadius(radiusScale(innerRadius + radiusInterval * i))
          .startAngle(0)
          .endAngle(endAngle)(i as any)
      )
      .attr("fill", "none")
      .attr("stroke", "#939ba7");

    //* Axes
    const axisGrid = svg.append("g").attr("class", "axis-grid");

    radialScale.ticks(numTicks).forEach((value: any, i: number) => {
      const angle = radialScale(value) - Math.PI / 2;
      const innerRadiusScale = radiusScale(innerRadius);
      const outerRadiusScale = radiusScale(outerRadius);
      const angleCos = Math.cos(angle);
      const angleSin = Math.sin(angle);
      const x1 = innerRadiusScale * angleCos;
      const y1 = innerRadiusScale * angleSin;
      const x2 = outerRadiusScale * angleCos;
      const y2 = outerRadiusScale * angleSin;

      const textAnchor =
        Math.abs(angle) === Math.PI / 2
          ? "middle"
          : angle < Math.PI / 2 && angle > -Math.PI / 2
          ? "start"
          : "end";

      // Radial axis line
      axisGrid
        .append("line")
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2)
        .attr("class", "grid")
        .attr("stroke", "#939ba7")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-dasharray", "4,4");

      // Axis labels
      axisGrid
        .append("text")
        .attr("class", "axis-label")
        .attr("x", (outerRadiusScale + 12) * angleCos)
        .attr("y", (outerRadiusScale + 12) * angleSin)
        .attr("text-anchor", textAnchor)
        .attr("dominant-baseline", "central")
        .attr("font-size", "12px")
        .attr("fill", "#5f6877")
        .text(axisTickFormatter(value));
    });

    // Bars labels
    const barsLabels = svg.append("g").attr("class", "bars-labels");
    barsLabels
      .selectAll(".bar-label")
      .data(chartData)
      .join("g")
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d: any, i: number) => radiusScale(outerRadius) * -1)
      .attr(
        "y",
        (d: any, i: number) =>
          (radiusScale(innerRadius + radiusInterval * i) + radiusInterval / 2) *
          -1
      )
      .attr("text-anchor", "start")
      .attr("dominant-baseline", "central")
      .attr("font-size", "11px")
      .attr("fill", "#5f6877")
      .text((d: any) => {
        const formattedTickValue = formatLabelValue(
          d[xAxe],
          categoryDatasetField?.type,
          categoryDatasetField?.subtype
        );

        return labelFormatter(formattedTickValue, maxLengthBarLabel);
      })
      .each(function (this: any, d: any) {
        const barLabel = select(this);

        const formattedTickValue = formatLabelValue(
          d[xAxe],
          categoryDatasetField?.type,
          categoryDatasetField?.subtype
        );

        if (formattedTickValue.length > maxLengthBarLabel) {
          barLabel
            .classed("tick-label--long", true)
            .on("mousemove", (event: any, d: any) => {
              handleMouseMoveTickLabel(event, d[xAxe]);
            })
            .on("mouseleave", handleMouseLeaveTickLabel);
        }
      });

    // * DataViz
    // Draw radial bars
    svg
      .append("g")
      .attr("class", "radial-bars-group")
      .selectAll(".radial-bar")
      .data(chartData)
      .join("g")
      .selectAll("path")
      .data((d: any, i: number) => [
        {
          [xAxe]: d[xAxe],
          [yAxe]: d[yAxe],
          pos: i,
        },
      ])
      .join("path")
      .attr("class", "radial-bar")
      .attr("fill", (d: any) => colorScale(String(d[xAxe])))
      .attr("opacity", 0.8)
      .attr("d", (d) => {
        const arcCenter =
          radiusScale(innerRadius + radiusInterval * d.pos) +
          radiusInterval / 2;

        return arc()
          .cornerRadius(1)
          .innerRadius(arcCenter - arcWidth / 2)
          .outerRadius(arcCenter + arcWidth / 2)
          .startAngle(0)
          .endAngle(radialScale(d[yAxe]))(d as any);
      })
      .on("mouseover", function () {
        handleMouseOver(this, svg);
      })
      .on("mouseout", () => {
        handleMouseOut(svg);
      })
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, width, height]);

  if (!chartData || !Object.keys(chartData).length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper style={{ marginBottom: "10px" }}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <div />}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings &&
              !isPublicRoute &&
              !recommended &&
              isEditMode &&
              !storytelling ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {availableWidgetsCount + 1}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}

              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}
        </HeaderWrapper>

        <SVGStyled
          ref={(node) => {
            svgRef.current = node;
            measureRef(node);
          }}
          width="100%"
          height="100%"
        ></SVGStyled>

        {tooltip &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              coords={tooltip.coords}
            />,
            document.body
          )}

        {tickLabelTooltip &&
          createPortal(
            <LabelTooltip
              x={tickLabelTooltip?.x}
              y={tickLabelTooltip?.y}
              data={tickLabelTooltip?.data}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
