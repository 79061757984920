export const getRegionNameFromFeatureProperties = (
  featureProperties: GeoJSON.GeoJsonProperties,
  mapType?: string
): string => {
  if (!featureProperties || !mapType) {
    return "";
  }

  switch (mapType) {
    case "county":
      return featureProperties.COUNTY_STATE_CODE || "";
    case "state":
    case "country":
      return featureProperties.NAME || "";
    default:
      return "";
  }
};
