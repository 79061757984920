import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`

const RowStyles = css`
  display: flex;
  padding: 0 12px;
  border-top: 1px solid #CBCFD3;
`

const RowCellStyles = css`
  flex: 1 3;
  padding: 8px 12px;
  color: #515967;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
`



export const HeadRow = styled.div`
  ${RowStyles};
  background-color: #6C75840A;
`
export const TableRow = styled.div<{$selected?: boolean}>`
  ${RowStyles};
  ${({$selected}) => $selected && css`
    background-color: #6F6FE91F;
  ` }
`

export const CheckBoxWrapper = styled.div`
  padding: 8px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeadItem = styled.div`
  ${RowCellStyles};
`

export const RowCell = styled.div<{$selected?: boolean}>`
  ${RowCellStyles};
`

export const Input = styled.input`
  outline: none;
  border: none;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #21262E;
  width: 100%;
  height: 100%;
`