import styled from "styled-components";

export const ChatTitleType = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-weight: 600;
`;

export const ChatBulletType = styled.div`
  list-style-type: disc;
  display: list-item;
  margin-left: 24px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const ChatSystemType = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const ChatPromptWrapper = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  border-top: 1px dashed ${({ theme }) => theme.neutralBorder};
  padding-top: 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

export const ChatPromptsButton = styled.button`
  color: ${({ theme }) => theme.primaryText};
  background: #fff;
  box-shadow: 0 2px 4px 1px #22242c0a;
  border-radius: 16px;
  padding: 8px;
  border: none;
  cursor: pointer;
`;

export const RecTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  /* border: 1px solid #cfd2d6; */
  width: 100%;
`;

export const RecTableTh = styled.th`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-weight: 400;
  font-size: 14px;
  background: #f9fafa;
  padding: 8px 12px;
  text-align: left;
  width: 33.33%;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  border-top: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const RecTableTd = styled.td`
  align-content: flex-start;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  padding: 8px 12px;
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  width: 33.33%;
  &:nth-child(2) {
    padding: 0;
  }
  svg {
    fill: ${({ theme }) => theme.neutralTextWeak};
    font-size: 20px;
  }
`;

export const FirstCol = styled.span`
  font-weight: 600;
`;

export const SecondCol = styled.div`
  padding: 8px 12px;
`;
