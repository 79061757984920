import styled from "styled-components";

export const Container = styled.div`
  background-color: #6C75840A;
  padding: 12px; 
  height: 100%;
  border-top: 1px solid #CBCFD3;
  overflow-y: auto;
  display: flex;
`

export const RowsCount = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5px;
`

export const RowNumber = styled.div`
  height: 23.8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #8F95A0;

`
