import React, { useCallback, useEffect, useState } from "react";

import {
  SelectTemplateHeading,
  SelectTemplateWrapper,
  TemplateIcon,
  TemplateImg,
  TemplateItem,
  TemplatesWrapper,
} from "../styles";
import { ComponentSettings } from "../../../../models/Pages";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentPageWidgets,
  getCurrentProjectData,
} from "../../../../store/selectors/projects";
import { LAYOUTS } from "../../../ProjectPageLayouts/config";
import { getModalCreateOptions } from "../../../../store/selectors/modals";
import { requestWidgetsSuggestions } from "../../../../store/slices/widgets";
import { Loader } from "../../../Loader";

export const SelectTemplate = ({
  settings,
  onUpdateSettings,
}: ComponentSettings) => {
  const project = useSelector(getCurrentProjectData);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const widgets = useSelector(getCurrentPageWidgets);
  const createNewProject = useSelector(getModalCreateOptions);
  const dispatch = useDispatch();

  const setFirstlyTemplates = (numWidgets: number, suggestions?: any[]) => {
    try {
      const numComplexWidgets = LAYOUTS.map((r) => r.numComplexWidgets);
      const maxComplexWidget = Math.max(...numComplexWidgets);
      let complexWidgets: number | undefined = 0;

      if (suggestions?.length) {
        complexWidgets = suggestions?.filter((c) =>
          ["mapChart", "sankeyChart", "sankeyChart"].includes(c.chartType)
        )?.length;
      } else {
        complexWidgets = widgets?.items?.filter((c) =>
          ["mapChart", "sankeyChart", "sankeyChart"].includes(c.chartType)
        )?.length;
      }

      let complexPlotsCount = createNewProject
        ? project.complexPlotsCount! > maxComplexWidget
          ? maxComplexWidget
          : project.complexPlotsCount
        : 0;

      complexPlotsCount =
        maxComplexWidget > complexWidgets ? complexWidgets : maxComplexWidget;

      const currentNumWidgets = numWidgets >= 8 ? 8 : numWidgets;

      let prevNumWidgets = currentNumWidgets;
      if (createNewProject) {
        prevNumWidgets = currentNumWidgets === 1 ? 1 : currentNumWidgets - 1;
      }
      let templates = LAYOUTS.filter(
        (r) =>
          r.numComplexWidgets === complexPlotsCount &&
          (r.numWidgets === currentNumWidgets ||
            r.numWidgets === prevNumWidgets)
      );

      // const existsMap = !!widgets?.items?.filter((t) => t.chartType === "mapChart").length;

      // if (!existsMap) {
      //   templates = templates.filter((t) => !checkFullProperty(t?.arranging));
      // }

      templates = [...templates.reverse()];
      setTemplates((templates as any) || []);

      if (
        (settings.templateId === "default" || settings.templateId === "") &&
        templates?.length
      ) {
        onUpdateSettings({ ...settings, templateId: templates[0]?.id });
      }
    } finally {
      setLoading(false);
    }
  };

  const getTemplates = useCallback(() => {
    setLoading(true);
    if (widgets?.count && !createNewProject) {
      setFirstlyTemplates(widgets?.count);
    } else {
      dispatch(
        requestWidgetsSuggestions({
          projectId: project.id!,
          includeData: false,
          callbacks: {
            onSuccess: (data) => {
              setFirstlyTemplates(
                data?.suggested_charts?.length,
                data.suggested_charts
              );
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewProject, project.id, widgets?.count]);

  useEffect(() => {
    // If the projectType is real estate, don't fetch templates because API fails
    // to return templates for real estate projects the API need pageId but this field is not available at this step
    // FIXME: Remove this condition when the API is fixed
    if (
      settings.dashType === "dashboard" &&
      project.projectType !== "real_estate"
    ) {
      getTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTemplates, settings.dashType]);

  return (
    <SelectTemplateWrapper>
      <SelectTemplateHeading>Select template</SelectTemplateHeading>
      <TemplatesWrapper $loading={loading}>
        {loading ? <Loader blur={false} /> : null}
        {templates?.map((t: any) => {
          return (
            <TemplateItem
              key={t.id}
              $selectedItem={settings.templateId === t.id}
              onClick={() => {
                onUpdateSettings({ ...settings, templateId: t.id });
              }}
            >
              <TemplateIcon>
                <TemplateImg
                  $selectedItem={settings.templateId === t.id}
                  src={t.image}
                  alt={t.id}
                />
              </TemplateIcon>
            </TemplateItem>
          );
        })}
      </TemplatesWrapper>
    </SelectTemplateWrapper>
  );
};
