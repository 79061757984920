import { getDayAbbreviation } from "./getDayAbbreviation";
import { getMonthAbbreviation } from "./getMonthAbbreviation";

export const formatDatetimeTypeLabel = (
  value: string | number | Date,
  subtype?: string
): string => {
  if (!value) {
    return "";
  }

  const strValue = String(value);
  if (!strValue) {
    return "";
  }

  if (!subtype) {
    return strValue;
  }

  //* Subtypes for datetime type
  // - date: Date only
  // - time: Time only
  // - timestamp: Full date and time
  // - datetime: Combined date and time
  // - season: Seasonal information
  // - century: Century information
  // - year: Year only
  // - month: Month only
  // - week: Week information
  // - day: Day of the month
  // - dayOfWeek: Day of the week
  // - dayOfYear: Day of the year
  // - semester: Semester information
  // - quarter: Quarter of the year
  // - quadrimester: Four-month period
  // - hour: Hour information
  // - minutes: Minute information
  // - seconds: Second information
  switch (subtype) {
    case "date":
      return new Date(value).toLocaleDateString("en-US");
    case "dayOfWeek":
      return getDayAbbreviation(strValue);
    case "month":
      return getMonthAbbreviation(strValue);
    default:
      return strValue;
  }
};
