import React, { useState } from "react";
import {
  SectionWrapper,
  ContentWrapper,
  InformativeSection,
  InformativeInsideWrapper,
  ChildrenWrapper,
  SettingsWrapper,
  BorderedSettingsBlock,
  Dot,
  HeaderWrapper,
  TextArea,
  CloseWrapper,
  IsDragging,
  IsHovered,
  BlockLoader,
} from "./styles";
import { StoryTellingSectionProps } from "./types/StoryTellingSectionProps.interface";
import { getSectionStyles } from "./utils/getSectionStyles";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";
import { CenterIcon, LeftIcon, RightIcon } from "./icons/TextPositionIcons";
import { WidgetChartWrapper } from "../../../Widgets";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import {
  requestPageWidgets,
  setCurrentWidget,
} from "../../../../store/slices/projectPages";
import { setActiveModal } from "../../../../store/slices/modals";
import { CloseIcon } from "../../../Modals/styles";
import {
  requestDeleteWidget,
  requestWidgetsSuggestions,
} from "../../../../store/slices/widgets";
import { useParams } from "react-router-dom";
import { TextLoader } from "../../../LoaderText";
import {
  SettingsButtonWrapper,
  DatavizRecommendedCount,
  DatavizSettingsIcon,
} from "../../../Widgets/VerticalBarchart/styles";
import { getCurrentWidget } from "../../../../store/selectors/projects";
import { getActiveModal } from "../../../../store/selectors/modals";
import StyledDropdown from "./components/TextsDropdown/TextsDropdown";
import { getAvailableWidgetTypes } from "../../../Widgets/widgetHelpers";

const defaultApparitionConfig = {
  textAlignment: "center",
  background: "",
  border: "",
  descriptionDisabled: false,
  insightsDisabled: false,
};

export const StoryTellingSection: React.FC<StoryTellingSectionProps> = ({
  item,
  setContentItem,
  children,
  draggable,
  disabledSettings,
}) => {
  const dispatch = useDispatch();
  const { id, pageId } = useParams();

  const { apparitionConfig, name, description, insights } = item;

  const isEditMode = useSelector(getIsEditMode);
  const isPublicMode = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);

  const [open, setOpen] = useState(false);

  const isActive =
    apparitionConfig?.descriptionDisabled === false ||
    apparitionConfig?.insightsDisabled === false;
  const { flexOrientation, informativeFlexOrientation } = getSectionStyles(
    apparitionConfig?.textAlignment
  );

  const handleOpen = () => {
    if (isPublicMode) {
      return;
    }
    setOpen(true);
  };
  const handleClose = () => {
    if (isPublicMode) {
      return;
    }
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(requestDeleteWidget(item.id));
    setTimeout(() => {
      if (pageId && id) {
        dispatch(requestPageWidgets({ pageId: pageId, includeData: true }));
        dispatch(
          requestWidgetsSuggestions({
            pageId: pageId,
            projectId: id,
            includeData: false,
          })
        );
      }
    }, 500);
  };

  const availableWidgetsCount = getAvailableWidgetTypes(item).length;

  const ifRecomendedToMap = item?.data?.some((d) => d?.county && d?.state);
  // Increase because it isn't included in the AVAILABLE WIDGETS, and is self-recommendated
  const isSelfRecommended = !["barChart", "lollipopChart"].includes(
    item.chartType
  );
  const recommendedCountAditional =
    (ifRecomendedToMap ? 1 : 0) + (isSelfRecommended ? 1 : 0);

  return (
    <SectionWrapper
      $draggable={draggable}
      $storytelling={true}
      $isEditMode={isEditMode && !isPublicMode}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      {open && isEditMode && !isPublicMode ? <IsHovered /> : null}
      {isEditMode && draggable ? <IsDragging /> : null}
      {isEditMode && !isPublicMode && (
        <>
          <SettingsWrapper $hover={open}>
            <BorderedSettingsBlock $background={"neutralBackgroundMedium"}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 2.5C1.5 1.67157 2.17157 1 3 1H13C13.8284 1 14.5 1.67157 14.5 2.5V6.06301C15.3626 6.28503 16 7.06808 16 8C16 8.93192 15.3626 9.71497 14.5 9.93699V13.5C14.5 14.3284 13.8284 15 13 15H3C2.17157 15 1.5 14.3284 1.5 13.5V9.93699C0.637386 9.71497 0 8.93192 0 8C0 7.06808 0.637386 6.28503 1.5 6.06301V2.5ZM2.5 6.06301C3.36261 6.28503 4 7.06808 4 8C4 8.93192 3.36261 9.71497 2.5 9.93699V13.5C2.5 13.7761 2.72386 14 3 14H13C13.2761 14 13.5 13.7761 13.5 13.5V9.93699C12.6374 9.71497 12 8.93192 12 8C12 7.06808 12.6374 6.28503 13.5 6.06301V2.5C13.5 2.22386 13.2761 2 13 2H3C2.72386 2 2.5 2.22386 2.5 2.5V6.06301ZM2 7C1.44772 7 1 7.44772 1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7ZM14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7Z"
                  fill="#0B101A"
                />
                <path
                  d="M11.4338 4H4.56619L4.5 5.99446H4.88613C5.09574 4.74238 5.49842 4.54848 7.0595 4.49862L7.4015 4.48753V10.831C7.4015 11.3684 7.28566 11.4958 6.35343 11.5789V12H9.64657V11.5789C8.70883 11.4958 8.59299 11.3684 8.59299 10.831V4.48753L8.9405 4.49862C10.5016 4.54848 10.9043 4.74238 11.1139 5.99446H11.5L11.4338 4Z"
                  fill="#0B101A"
                />
              </svg>
              <SwitchComponent
                checked={isActive}
                onChange={() =>
                  setContentItem({
                    ...item,
                    apparitionConfig: {
                      ...(apparitionConfig || defaultApparitionConfig),
                      insightsDisabled: !apparitionConfig?.insightsDisabled,
                      descriptionDisabled:
                        !apparitionConfig?.descriptionDisabled,
                    },
                  })
                }
              />
            </BorderedSettingsBlock>
            {isActive && (
              <>
                <Dot />
                <BorderedSettingsBlock>
                  <LeftIcon
                    type={apparitionConfig?.textAlignment}
                    onClick={() =>
                      setContentItem({
                        ...item,
                        apparitionConfig: {
                          ...(apparitionConfig || defaultApparitionConfig),
                          textAlignment: "left",
                        },
                      })
                    }
                  />
                  <CenterIcon
                    type={apparitionConfig?.textAlignment}
                    onClick={() =>
                      setContentItem({
                        ...item,
                        apparitionConfig: {
                          ...(apparitionConfig || defaultApparitionConfig),
                          textAlignment: "center",
                        },
                      })
                    }
                  />
                  <RightIcon
                    type={apparitionConfig?.textAlignment}
                    onClick={() =>
                      setContentItem({
                        ...item,
                        apparitionConfig: {
                          ...(apparitionConfig || defaultApparitionConfig),
                          textAlignment: "right",
                        },
                      })
                    }
                  />
                </BorderedSettingsBlock>
                <StyledDropdown
                  open={open}
                  item={item}
                  setContentItem={setContentItem}
                  apparitionConfig={apparitionConfig}
                />
                {/* <BorderedSettingsBlock $gap="2px">
                  <HashIcon />
                  <SelectorIcon />
                </BorderedSettingsBlock>
                <Dot />
                <SaveButton onClick={() => { }} name={"Save"} /> */}
              </>
            )}
          </SettingsWrapper>
          <CloseWrapper>
            {!disabledSettings && (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length && modalCurrentWidget?.id === item?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(item!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount + recommendedCountAditional}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            )}
            {open && (
              <CloseIcon
                onClick={() => {
                  handleDelete();
                }}
              />
            )}
          </CloseWrapper>
        </>
      )}
      <HeaderWrapper>
        {!!name && (
          <TextArea
            key={"name"}
            value={name || ""}
            setContentItem={(newName?: string) =>
              setContentItem({ ...item, name: newName! })
            }
            variant={"xl"}
          />
        )}
      </HeaderWrapper>
      <ContentWrapper $flexOrientation={flexOrientation}>
        <InformativeSection $flexOrientation={informativeFlexOrientation}>
          {!apparitionConfig?.descriptionDisabled && (
            <>
              {description && description !== "Add here description" ? (
                <TextArea
                  key={"description"}
                  value={description || ""}
                  setContentItem={(newDescription?: string) =>
                    setContentItem({
                      ...item,
                      description: newDescription!,
                    })
                  }
                  variant={"base"}
                />
              ) : (
                <BlockLoader>
                  <TextLoader />
                  <TextLoader />
                  <TextLoader />
                </BlockLoader>
              )}
            </>
          )}
          {!apparitionConfig?.insightsDisabled && (
            <InformativeInsideWrapper>
              {insights && insights !== "Add here insights" ? (
                <TextArea
                  key={"insights"}
                  value={insights || ""}
                  setContentItem={(newInsights?: string) =>
                    setContentItem({ ...item, insights: newInsights! })
                  }
                  variant={"sm"}
                />
              ) : (
                <BlockLoader>
                  <TextLoader />
                  <TextLoader />
                </BlockLoader>
              )}
            </InformativeInsideWrapper>
          )}
        </InformativeSection>
        <ChildrenWrapper>
          <WidgetChartWrapper storytelling>{children}</WidgetChartWrapper>
        </ChildrenWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
};
