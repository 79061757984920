import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle, CodeSlash } from "react-bootstrap-icons";

import { FileImport } from "../../FileImport";
import {
  Actions,
  BackIcon,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { UploadedFileItem } from "../../NewProject/UploadedFileItem";
import { Button } from "../../Button";
import {
  removeAllSelectedFiles,
  removeSelectedFile,
  requestCreateDataset,
  resetNewProjectSelectedFile,
} from "../../../store/slices/datasets";

import { closeActiveModal, setActiveModal } from "../../../store/slices/modals";
import { UploadedFilePreview } from "../UploadedFilePreview";
import {
  getFiles,
  getFileUploadProgress,
} from "../../../store/selectors/files";
import { getSelectedFiles } from "../../../store/selectors/datasets";
import { getModalBackOptions } from "../../../store/selectors/modals";
import { Loader } from "../../Loader";
import {
  removeFileData,
  removeUploadedFilesCount,
} from "../../../store/slices/files";
import {
  Categories,
  ConnectAppApiWrapper,
  ConnectAppApiHeader,
  ConnectButtons,
  ConnectHeaderText,
  Devices,
  SendRequest,
  SendRequestText,
} from "./style";
import { Search } from "../../SearchBar";
import CategoryItem from "../../CategoryItem";
import DeviceCard from "../../DeviceCard";
import { FileLibraryItem } from "../../../models/Files";
import { getApplications } from "../../../store/selectors/dataProviders";
import { requestApplications } from "../../../store/slices/dataProviders";

type ModalProps = {
  onClose: () => void;
};

export const UploadDatasets: React.FC<ModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalBackOption = useSelector(getModalBackOptions);
  const selectedFiles = useSelector(getSelectedFiles);
  const files = useSelector(getFiles) as FileLibraryItem[];
  const fileUploadProgress = useSelector(getFileUploadProgress);
  const allApplications = useSelector(getApplications);

  const [dimensionsSelected, setDimensionsSelected] = useState<any[]>([]);
  const [selectedChips, setSelectedChips] = useState<string[]>([]);
  const [fileOverviewOpen, setFileOverviewOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedApplications, setSelectedApplications] = useState<string[]>(
    []
  );
  const filePath = files[0]?.path;

  const categories = [
    ...new Set(allApplications.flatMap((app) => app.appCategory)),
  ];

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      dispatch(resetNewProjectSelectedFile());
      dispatch(closeActiveModal({ back: true } as any));
      dispatch(removeAllSelectedFiles());
      dispatch(removeSelectedFile(filePath));
      dispatch(removeFileData(filePath));
      dispatch(removeUploadedFilesCount());
    }, 400);
  };

  const handleSelectCategory = (tag: string) => {
    const existId = selectedChips.includes(tag);

    setSelectedChips((prev) =>
      existId ? prev.filter((appId) => appId !== tag) : [...prev, tag]
    );
  };

  const handleSelectApplication = (id: string, deviceName?: string) => {
    const existId = selectedApplications.includes(id);

    if (existId) {
      setSelectedApplications((prev) => prev.filter((appId) => appId !== id));
    } else {
      setSelectedApplications((prev) => [...prev, id]);
      dispatch(
        setActiveModal({
          id: "connectDevice",
          data: {
            deviceName,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(requestApplications());
  }, []);

  return (
    <ModalOverlay $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $fileOverviewOpen={fileOverviewOpen}
      >
        <ModalHeadingWrapper
          $disableSeparator={true}
          $fileOverviewOpen={fileOverviewOpen}
        >
          <ModalContainer>
            {modalBackOption.includes("uploadDatasetModal") && (
              <BackIcon
                onClick={() => {
                  dispatch(removeAllSelectedFiles());
                  dispatch(closeActiveModal());
                  dispatch(setActiveModal({ id: "datasetLibraryModal" }));
                }}
              />
            )}
            <ModalHeading>Upload Datasets</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>

        <OverviewContent $isLoading={isLoading}>
          <ModalContent $fileOverviewOpen={fileOverviewOpen}>
            <FileImport />
            <UploadedFileItem
              setDimensionsSelected={setDimensionsSelected}
              setFileOverviewOpen={setFileOverviewOpen}
            />
            {!(fileUploadProgress || files.length) && (
              <ConnectAppApiWrapper>
                <ConnectAppApiHeader>
                  <ConnectHeaderText>
                    Connect an application or API
                  </ConnectHeaderText>

                  <ConnectButtons>
                    <Search
                      onChange={(e) => setSearchValue(e)}
                      value={searchValue}
                    />
                    <Button
                      onClick={() => {
                        dispatch(setActiveModal({ id: "connectApiModal" }));
                      }}
                      name="Custom API"
                      variant={"secondary"}
                      size={"large"}
                      noTextWrap
                      icon={<CodeSlash />}
                    />
                  </ConnectButtons>
                </ConnectAppApiHeader>

                <Categories>
                  {categories.map((category, idx) => (
                    <CategoryItem
                      key={category + idx}
                      name={category}
                      active={selectedChips.includes(category)}
                      onClick={() => handleSelectCategory(category)}
                    />
                  ))}
                </Categories>

                <Devices>
                  <SendRequest>
                    <SendRequestText>
                      If you can’t find your device here, please send a request
                      indicating the device model and we will create it.
                    </SendRequestText>
                    <Button
                      onClick={() => {
                        dispatch(
                          setActiveModal({
                            id: "requestNewDevice",
                          })
                        );
                      }}
                      variant={"primary"}
                      size={"small"}
                      name={"Send Request"}
                      icon={<CodeSlash />}
                    />
                  </SendRequest>
                  {allApplications
                    .filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    )
                    .map((app) => (
                      <DeviceCard
                        card
                        application={app}
                        active={selectedApplications.includes(app.id)}
                        onConnect={() => {
                          handleSelectApplication(app.id, app.name);
                        }}
                      />
                    ))}
                </Devices>
              </ConnectAppApiWrapper>
            )}
          </ModalContent>

          {fileOverviewOpen && (
            <UploadedFilePreview
              dimensionsSelected={dimensionsSelected}
              setFileOverviewOpen={setFileOverviewOpen}
            />
          )}

          {isLoading && <Loader fullScreen={fileOverviewOpen} />}
        </OverviewContent>

        <ModalFooterWrapper $fileOverviewOpen={fileOverviewOpen}>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Save"
              onClick={() => {
                setIsLoading(true);
                const suggestedGroups =
                  files?.at(0)?.suggestions?.suggestedGroups;
                const descriptionAi = files?.at(0)?.suggestions?.description;

                const isPrimary =
                  files
                    .find((file) => file.path === selectedFiles.at(0)?.id)
                    ?.parsedSuggestions?.filter(
                      (suggestion) => suggestion.primary
                    )
                    .map((suggestion) => suggestion.objectKey) || [];

                const selectedHeaders = selectedFiles[0].fields;

                dispatch(
                  requestCreateDataset({
                    filePath,
                    name: files[0].originalName,
                    fields: selectedHeaders?.map((s) => ({
                      ...s,
                      primary: isPrimary.includes(s.key),
                    })),
                    descriptionAi,
                    fieldsAi: suggestedGroups?.length
                      ? suggestedGroups?.map((g) => ({
                          ...g,
                          types: g.types?.map((t) => ({
                            type: t.type,
                            subtype: t.subtype,
                            pattern: t.pattern,
                            childColumns:
                              t.childColumns?.map((c) => ({
                                type: c.type,
                                subtype: c.subtype,
                                newName: c.newName,
                                originalName: c.originalName,
                              })) || [],
                            childColumnsName: t.childColumnsName,
                            fieldType: t.fieldType,
                            fieldSubtype: t.fieldSubtype,
                            countValues: t.countValues,
                            groupRelationship: t.groupRelationship,
                            parentColumn: t.parentColumn,
                            group: t.group,
                            explanation: t.explanation,
                            visualizationRecommendation:
                              t.visualizationRecommendation,
                          })),
                        }))
                      : [],
                    navigate,
                    sourceType: "database",
                  })
                );
                dispatch(removeSelectedFile(filePath));
                dispatch(removeFileData(filePath));
              }}
              variant={
                selectedFiles.length && !isLoading ? "secondary" : "disabled"
              }
              disabled={!selectedFiles.length || isLoading}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
