import styled from "styled-components";
import { ReactComponent as EmptyWidgetsImg } from "../../assets/EmptyWidgets.svg";

export const EmptyWidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.neutralBorder};
  /* height: calc(100vh - 150px); */
  height: calc(100vh - 105px);
  margin: 20px 0;
  justify-content: center;
  border-radius: 8px;
`;

export const EmptyWidgetsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 433px;
  margin: 24px 0;
`;

export const EmptyWidgetsDetailsTitle = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 16px;
  font-weight: 600;
`;

export const EmptyWidgetsDetailsDescription = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const EmptyWidgetsIcon = styled(EmptyWidgetsImg)``;
