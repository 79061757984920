import React, { useState } from "react";
import HouseDetails from "../../HouseDetails";
import {
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterButtons,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { HouseDetailsStatus, HouseDetailsZipCode } from "./styles";
import { Button } from "../../Button";
import { useDispatch, useSelector } from "react-redux";
import { requestCreatePage } from "../../../store/slices/projectPages";
import { useNavigate } from "react-router-dom";
import { defaultHeader } from "../../AddStorytellingSection";
import {
  getCurrentProjectId,
  getPageSettings,
} from "../../../store/selectors/projects";
import { getPropertyData } from "../../../store/selectors/realEstate";
import { resetActiveModal } from "../../../store/slices/modals";

type HouseDetailsModalProps = {
  onClose: () => void;
};

const HouseDetailsModal = ({ onClose }: HouseDetailsModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isClosing, setIsClosing] = useState(false);

  const pageSettings = useSelector(getPageSettings);
  const currentProjectId = useSelector(getCurrentProjectId);
  const propertyData = useSelector(getPropertyData);

  const handleOnClick = () => {
    const { ...requestCreatePageSettings } = pageSettings;

    const newPage = {
      ...requestCreatePageSettings,
      name: propertyData?.address.streetAddress ?? "Residence Data",
      header: defaultHeader,
      projectId: currentProjectId,
      interactivity: ["tooltip"],
      navigate,
      zpId: String(propertyData?.zpid),
    };

    dispatch(requestCreatePage(newPage));
    dispatch(resetActiveModal());
  };

  const handleClose = () => setIsClosing(true);

  return (
    <ModalOverlay onClick={handleClose} $isClosing={isClosing}>
      <ModalWrapper onClick={(e) => e.stopPropagation()} $isClosing={isClosing}>
        <ModalContainer>
          <ModalContent>
            <ModalHeadingWrapper>
              <ModalHeading>
                <div>
                  {propertyData?.address?.streetAddress}
                  <HouseDetailsStatus>Status</HouseDetailsStatus>
                </div>
                <HouseDetailsZipCode>
                  ZIP {propertyData?.address?.zipcode}
                </HouseDetailsZipCode>
              </ModalHeading>
              <CloseIconWrap onClick={handleClose}>
                <CloseIcon />
              </CloseIconWrap>
            </ModalHeadingWrapper>
            <OverviewContent>
              <HouseDetails />
            </OverviewContent>
          </ModalContent>
        </ModalContainer>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleClose}
            variant="neutral"
            size="medium"
          />
          <ModalFooterButtons>
            <Button
              name={"Create Dashboard"}
              onClick={handleOnClick}
              type="submit"
              variant={"secondary"}
              disabled={false}
              size="medium"
            />
          </ModalFooterButtons>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default HouseDetailsModal;

