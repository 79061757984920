import { FC, Fragment, useState, useEffect } from "react";
import { FlexContainer } from "../../../../../styles";
import { MessageTextTypeEnum, MessageTypeEnum } from "../../constants";
import {
  DropdownOption,
  Message,
  Prompt,
  TestColumn,
} from "../../types";

import {
  ChatBulletType,
  ChatTitleType,
  ChatSystemType,
  ChatPromptsButton,
  ChatPromptWrapper,
  RecTable,
  RecTableTh,
  RecTableTd,
  FirstCol,
  SecondCol,
} from "./styles";
import { sortPromptListToEnd, splitCamelCase, transformColumnsData } from "../../utils";
import { Button } from "../../../../Button";
import { Dropdown } from "../DropDown/Dropdown";

interface Props {
  message: Message;
  onPromptClick: (action: MessageTypeEnum, instruction: any) => void;
}

const ChatMessage: FC<Props> = ({ message, onPromptClick }) => {
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});

  const handleSelectOption = (current: string, option: DropdownOption) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [current]: option.value,
    }));
  };

  const handleApplyClick = (message: Message) => () => {
    const toApply = Object.entries(selectedOptions).map(([column, method]) => ({
      column,
      method,
    }));

    const instructions = {
      dataset_id: message.payload?.dataset_id,
      test_name: message.payload?.test_name,
      to_apply: toApply,
    };

    onPromptClick(MessageTypeEnum.APPLY, instructions);
  };

  useEffect(() => {
    const tableData = transformColumnsData(
      message.elements.find((el) => el.type === MessageTextTypeEnum.TEST)
        ?.payload as unknown as TestColumn[]
    );

    const defaultSelections = tableData.reduce((acc, item) => {
      if (item.strategy) {
        acc[item.current] = item.strategy;
      }
      if (item.suggested_name) {
        acc[item.current] = item.suggested_name;
      }
      return acc;
    }, {} as { [key: string]: string });

    setSelectedOptions(defaultSelections);
  }, [message]);

  const renderTableContent = (tableData: any[]) => (
    <Fragment>
      <RecTable>
        <thead>
          <tr>
            <RecTableTh>Current column names</RecTableTh>
            <RecTableTh>Recommended names</RecTableTh>
            <RecTableTh>Reason</RecTableTh>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item) => (
            <tr key={item.current}>
              <RecTableTd>
                <FirstCol>{item.current}</FirstCol>
              </RecTableTd>
              <RecTableTd>
                {item?.suggested_name ? (
                  <SecondCol>{item?.suggested_name}</SecondCol>
                ) : (
                  <Dropdown
                    options={(item.methods || []).map((method: string) => ({
                      label: method,
                      value: method,
                    }))}
                    value={selectedOptions[item.current] || ""}
                    onChange={(option) =>
                      handleSelectOption(item.current, option)
                    }
                  />
                )}
              </RecTableTd>
              <RecTableTd>{item.reason}</RecTableTd>
            </tr>
          ))}
        </tbody>
      </RecTable>
      <Button
        name="Apply all recommendations"
        onClick={handleApplyClick(message)}
        variant="black"
        size="small"
      />
    </Fragment>
  );

  const renderPrompts = (prompts: Prompt[]) => (
    <ChatPromptWrapper>
      <div>Recommended prompts:</div>
      <FlexContainer $gap="8px" $direction="row" $wrap="wrap">
        {prompts?.map(({ payload: prompt }, index) => (
          <ChatPromptsButton
            key={index}
            onClick={() =>
              onPromptClick(prompt.action, {
                dataset_id: message.payload?.dataset_id,
              })
            }
          >
            {prompt.label}
          </ChatPromptsButton>
        ))}
      </FlexContainer>
    </ChatPromptWrapper>
  );

  type MessageTypeMap = {
    [key in MessageTextTypeEnum]?: (payload: any) => JSX.Element | null;
  };

  const messageTypeMap: MessageTypeMap = {
    [MessageTextTypeEnum.TITLE]: (payload: string) => 
      payload ? <ChatTitleType>{splitCamelCase(payload)}</ChatTitleType> : null,
    [MessageTextTypeEnum.TEXT]: (payload: string) => 
      payload ? <ChatSystemType>{splitCamelCase(payload)}</ChatSystemType> : null,
    [MessageTextTypeEnum.POINT]: (payload: string) => 
      payload ? <ChatBulletType>{payload}</ChatBulletType> : null,
    [MessageTextTypeEnum.SYSTEM]: (payload: string) => 
      payload ? <ChatSystemType>{payload}</ChatSystemType> : null,
    [MessageTextTypeEnum.TEST]: (payload: any) => {
      const tableData = transformColumnsData(payload as TestColumn[]);
      return tableData?.length ? renderTableContent(tableData) : null;
    },
    [MessageTextTypeEnum.PROMPT]: (payload: Prompt[]) => 
      payload?.length ? renderPrompts(payload) : null
  };

  return (
    <FlexContainer $direction="column" $gap="16px">
      {sortPromptListToEnd(message.elements)
        .filter(element => element.payload && element.type in messageTypeMap)
        .map((element, index) => {
          const renderFunction = messageTypeMap[element.type];
          if (!renderFunction) return null;
          const rendered = renderFunction(element.payload);
          return rendered ? <Fragment key={index}>{rendered}</Fragment> : null;
        })
        .filter(Boolean)}
    </FlexContainer>
  );
};

export default ChatMessage;