import { MessageTextTypeEnum, MessageTypeEnum } from "./constants";
import { Message, MessageData, TestColumn } from "./types";

export function processMessages(messages: Message[]): Message[] {
  const analyzeMessages = messages.filter((msg) => msg.type === "analyze");
  const nonAnalyzeMessages = messages.filter((msg) => msg.type !== "analyze");
  const updatedMessages = [...nonAnalyzeMessages];

  if (analyzeMessages.length > 0) {
    const firstTitle = analyzeMessages[0]?.elements?.find(
      (el) => el.type === "title"
    );

    const groupedByDatasetId = analyzeMessages.reduce<{
      [key: string]: Message[];
    }>((acc, message) => {
      const datasetId = message.payload.dataset_id || "";
      if (!acc[datasetId]) {
        acc[datasetId] = [];
      }
      acc[datasetId].push(message);
      return acc;
    }, {});

    Object.entries(groupedByDatasetId).forEach(([datasetId, groupMessages]) => {
      const lastMessage = groupMessages[groupMessages.length - 1];

      if (lastMessage.status === "complete") {
        const groupElements = groupMessages.reduce<MessageData[]>(
          (elements, message) => {
            if (message.elements) {
              return [...elements, ...message.elements];
            }
            return elements;
          },
          []
        );

        const groupedAnalyze: Message = {
          type: MessageTypeEnum.ANALYZE,
          elements: firstTitle
            ? [firstTitle, ...groupElements.filter((el) => el.type !== "title")]
            : groupElements,
          message: null,
          payload: { dataset_id: datasetId },
          status: lastMessage.status,
        };

        updatedMessages.push(groupedAnalyze);
      }
    });
  }

  return updatedMessages;
}

export function splitCamelCase(text: string | null | undefined): string {
  if (!text) {
    return "";
  }

  if (!text?.length) {
    return "";
  }

  let result = text.replace(/NaN(?=[A-Z])/g, "NaN ");

  return result
    .replace(
      /(?<!Na)(?<=[a-z])(?=[A-Z])|(?<!Na)(?<=[A-Z])(?=[A-Z][a-z])|(?<=\d)(?=[a-zA-Z])|(?<=[a-zA-Z])(?=\d)/g,
      " "
    )
    .trim();
}

export function sortPromptListToEnd(array: MessageData[]) {
  const promptLists = array?.filter(
    (item) => item.type === MessageTextTypeEnum.PROMPT
  );
  const otherItems = array?.filter(
    (item) => item.type !== MessageTextTypeEnum.PROMPT
  );

  return [...otherItems, ...promptLists];
}

export function transformColumnsData(data: TestColumn[]) {
  if (!data) return [];
  return data
    .filter((item) => item.payload.status === "fail") // Only show items that need changes
    .map((item) => {
      const currentName = item.payload.column;
      let recommendedName = currentName;

      if (item.payload.action.data_type === "datetime") {
        recommendedName = `${currentName}_dt`;
      } else if (item.payload.action.data_type === "double") {
        recommendedName = `${currentName}_num`;
      } else if (item.payload.action.suggested_name) {
        recommendedName = item.payload.action.suggested_name;
      }

      return {
        current: currentName,
        recommended: recommendedName,
        reason: item.payload.action.motivation || "No reason provided",
        methods: item.payload.action.methods,
        strategy: item.payload.action.strategy,
        suggested_name: item.payload.action.suggested_name,
      };
    });
};
