import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight, Check, Trash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import { DatasetsItemsWrapper } from "./styles";
import {
  CloseIcon,
  ModalContent,
  ModalFooterButtons,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";

import { Button } from "../../Button";
import { ProjectDetails } from "../../NewProject/ProjectDetails";
import { AddDatasetsContainer } from "../../AddDatasetsContainer";
import {
  requestCreateProject,
  requestDeleteProject,
  requestRemoveProjectById,
  requestUpdateProjectById,
} from "../../../store/slices/projects";
import { resetActiveModal } from "../../../store/slices/modals";

import {
  removeAllSelectedFiles,
  resetNewProjectSelectedFile,
  setNewProjectDetails,
  setNewProjectSelectedFiles,
} from "../../../store/slices/datasets";
import { DatasetsItem } from "../../DatasetsItem";
import {
  getDraftProjectDetails,
  getDraftProjectFiles,
  getNewProjectSelectedDatasets,
  getNewProjectSelectedDatasetsColumns,
} from "../../../store/selectors/datasets";
import { DatasetDTO } from "../../../models/Datasets";
import { getModalProjectId } from "../../../store/selectors/modals";
import { getCurrentProjectData } from "../../../store/selectors/projects";
import { FileLibraryItem } from "../../../models/Files";
import { setCurrentProjectData } from "../../../store/slices/projectPages";
import RealEstate from "./components/RealEstate";
import {
  datasetColumnKeysMockRealEstate,
  REAL_ESTATE_MOCK_DATASETS,
} from "./mocks";

export enum ProjectTypeEnum {
  "basic",
  "real_estate",
  "healthcare",
}

interface ModalProps {
  onClose: () => void;
}

export const CreateProject = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const newProjectSelectedDatasets = useSelector(getNewProjectSelectedDatasets);
  const newProjectSelectedDatasetsColumns = useSelector(
    getNewProjectSelectedDatasetsColumns
  );
  const currentProject = useSelector(getCurrentProjectData);

  const newProjectDetails = useSelector(getDraftProjectDetails);
  const newProjectFiles = useSelector(getDraftProjectFiles);
  const projectId = useSelector(getModalProjectId);
  const current = useSelector(getCurrentProjectData);

  const isCreateButtonEnabled =
    newProjectDetails.name.trim().length &&
    newProjectDetails.description.trim().length &&
    ((newProjectDetails.projectType === "basic" &&
      newProjectDetails.selectedDatasets.length) ||
      newProjectDetails.projectType === "real_estate");

  const setName = (name: string) => {
    dispatch(
      setNewProjectDetails({
        name,
        description: newProjectDetails.description,
        projectType: newProjectDetails.projectType,
      })
    );
  };

  const setDescription = (description: string) => {
    dispatch(
      setNewProjectDetails({
        name: newProjectDetails.name,
        projectType: newProjectDetails.projectType,
        description,
      })
    );
  };

  const setProjectType = (projectType: keyof typeof ProjectTypeEnum) => {
    dispatch(
      setNewProjectDetails({
        name: newProjectDetails.name,
        description: newProjectDetails.description,
        projectType: projectType,
      })
    );
  };

  const newProjectData = {
    name: newProjectDetails.name,
    description: newProjectDetails.description,
    projectType: newProjectDetails.projectType,
    datasets: newProjectSelectedDatasets as DatasetDTO[],
    datasetColumnKeys:
      (Boolean(newProjectSelectedDatasets.length) &&
        newProjectSelectedDatasetsColumns.find(
          (item) => item.datasetId === newProjectSelectedDatasets[0].sourceId
        )?.selectedColumns) ||
      [],
    pages: [],
  };

  const handleCreate = () => {
    if (projectId) {
      dispatch(
        requestUpdateProjectById({
          id: projectId,
          name: newProjectDetails.name,
          description: newProjectDetails.description,
          projectType: newProjectDetails.projectType,
        })
      );

      dispatch(
        setCurrentProjectData({
          ...current,
          name: newProjectDetails.name,
          description: newProjectDetails.description,
          projectType: newProjectDetails.projectType,
        })
      );
    }

    if (!projectId) {
      // The datasets is mocked because the api requires it, but UI does not have the functionality to add datasets
      //TODO: Remove IF statement when UI datasets are implemented
      if (newProjectDetails.projectType === "real_estate") {
        dispatch(
          requestCreateProject({
            ...newProjectData,
            datasets: REAL_ESTATE_MOCK_DATASETS as any,
            datasetColumnKeys: datasetColumnKeysMockRealEstate,
            files: newProjectFiles,
            navigate,
          })
        );
      }

      if (newProjectDetails.projectType !== "real_estate") {
        if (currentProject.id) {
          dispatch(requestDeleteProject({ id: currentProject.id, navigate }));
          // navigate("/");
        }

        dispatch(
          requestCreateProject({
            ...newProjectData,
            files: newProjectFiles,
            navigate,
            callbacks: {
              onSuccess: () => {
                dispatch(removeAllSelectedFiles());
              }
            }
          })
        );
      }
    }

    dispatch(resetActiveModal());
    dispatch(resetNewProjectSelectedFile());
  };

  const handleRemove = () => {
    if (projectId) {
      dispatch(requestRemoveProjectById(projectId));
      dispatch(resetActiveModal());
      dispatch(resetNewProjectSelectedFile());
      navigate("/projects");
    }
  };

  const handleOnClose = () => {
    setIsClosing(true);
    dispatch(resetNewProjectSelectedFile());
    dispatch(removeAllSelectedFiles());
    setTimeout(() => {
      onClose();
    }, 400);
  };

  useEffect(() => {
    if (projectId) {
      dispatch(
        setNewProjectDetails({
          name: current.name,
          description: current.description,
          projectType: current.projectType,
        })
      );
      dispatch(
        setNewProjectSelectedFiles(
          current.datasets.map((item) => {
            return {
              name: item?.name,
              filePath: item?.filePath,
              fields: item?.fields,
              sourceId: item.id,
              source: item?.source,
              fieldsAi: item?.fieldsAi,
              descriptionAi: item?.descriptionAi,
              sourceType: item?.sourceType,
            };
          }) as FileLibraryItem[]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, projectId]);

  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
      <ModalWrapper onClick={(e) => e.stopPropagation()} $isClosing={isClosing}>
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalHeading>
            {projectId ? "Edit project" : "Create project"}
          </ModalHeading>
          <CloseIcon onClick={handleOnClose} />
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <ProjectDetails
              name={newProjectDetails.name}
              description={newProjectDetails.description}
              projectType={newProjectDetails.projectType}
              setName={setName}
              setDescription={setDescription}
              setProjectType={setProjectType}
            />
            {newProjectDetails.projectType === "real_estate" && <RealEstate />}
            {newProjectDetails.projectType === "basic" && (
              <AddDatasetsContainer
                name={newProjectDetails.name}
                description={newProjectDetails.description}
                disabledButton={!!projectId}
              />
            )}

            <DatasetsItemsWrapper>
              {newProjectSelectedDatasets.map((dataset, index) => (
                <DatasetsItem
                  dataset={dataset}
                  key={index}
                  filePath={dataset.filePath}
                  hideRemove={!!projectId}
                  selectedDatasetOverview
                  selectedColumns={
                    newProjectSelectedDatasetsColumns &&
                    newProjectSelectedDatasetsColumns.find(
                      (item) => item.datasetId === dataset.sourceId
                    )?.selectedColumns
                  }
                />
              ))}
            </DatasetsItemsWrapper>
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <ModalFooterButtons>
            {projectId ? (
              <Button
                name={"Remove"}
                onClick={handleRemove}
                type="reset"
                variant={"danger"}
                size="medium"
                icon={<Trash fill="#992A4B" />}
              />
            ) : null}
            <Button
              name={projectId ? "Submit" : "Create"}
              onClick={handleCreate}
              type="submit"
              variant={!isCreateButtonEnabled ? "disabled" : "secondary"}
              disabled={!isCreateButtonEnabled}
              size="medium"
              icon={projectId ? <Check /> : <ArrowRight />}
            />
          </ModalFooterButtons>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
