import {
  IFormatting,
  IMarkersType,
  WidgetItem,
} from "../../../../models/Widgets";

export const getLineChartData = (
  currentWidget: WidgetItem,
  chartSuggestion?: any
) => {
  const data = currentWidget?.data || [];
  const xAxe = currentWidget?.xAxe?.[0] ?? chartSuggestion?.xAxe?.[0];
  const xAxes =
    currentWidget?.uniqueValues?.[xAxe] ||
    Array.from(new Set(data.map((d: any) => d[xAxe] as string))) ||
    [];
  return xAxes.flatMap((key: string) =>
    data.filter((d: any) => {
      return String(d[xAxe]) === String(key);
    })
  );
};

export const getGroupedData = (lineChartData: any, groupKey: any) =>
  lineChartData?.reduce((acc: any, cur: any) => {
    acc[cur[groupKey[0]]] = [...(acc[cur[groupKey[0]]] || []), cur];
    return acc;
  }, {});

export const getLineChartMarkers = (currentWidget: any): IMarkersType[] => {
  const DEFAULT_MARKER = [{ key: "default", shape: "donut" }] as IMarkersType[];

  const groupKey = currentWidget?.groupBy;

  if (!groupKey) return DEFAULT_MARKER;

  const lineChartData = getLineChartData(currentWidget);
  const groupedData = getGroupedData(lineChartData, groupKey);

  if (!groupedData) return DEFAULT_MARKER;

  return Object.keys(groupedData).map((item) => ({
    key: item,
    shape: "donut",
  }));
};

export const getLineChartColors = (currentWidget: any): IFormatting[] => {
  const DEFAULT_COLORS = [{ key: "default", color: "1" }];

  const groupKey = currentWidget?.groupBy;

  if (!groupKey) return DEFAULT_COLORS;

  const lineChartData = getLineChartData(currentWidget);
  const groupedData = getGroupedData(lineChartData, groupKey);

  if (!groupedData) return DEFAULT_COLORS;

  return Object.keys(groupedData).map((item, index) => ({
    key: item,
    color: String(index + 1),
  }));
};
