import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Button } from "../../../Button";
import { ChevronRight } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { setActiveModal } from "../../../../store/slices/modals";

const PopupContainer = styled.div<{ x: number; y: number }>`
  min-width: 140px;
  position: absolute;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: visible;
  border: solid 1px #c8c8c8;
  &::before {
    z-index: 1;
    content: "";
    left: 5;
    position: absolute;
    bottom: -8px;
    border-top: solid 8px #fff;
    border-right: solid 5px transparent;
    border-left: solid 5px transparent;
  }
  &::after {
    content: "";
    left: 5;
    position: absolute;
    bottom: -10px;
    border-top: solid 10px #c8c8c8;
    border-right: solid 5px transparent;
    border-left: solid 5px transparent;
  }
`;

const Code = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`;

const MembersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
  padding-top: 8px;
  border-bottom: 1px dashed ${({ theme }) => theme.neutralBorder};
`;

const YearContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
`;

const Label = styled.p`
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  line-height: 12px;
`;

const Value = styled.p`
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
`;

export interface PopupDataProps {
  x: number;
  y: number;
  code?: string;
  title?: string;
  year?: string;
  value?: string;
  regionId?: string;
  regionName?: string;
}

interface PopupProps {
  mapContainer: HTMLElement;
  popupData: PopupDataProps;
  clearPopupData: () => void;
}

export const Popup = ({
  mapContainer,
  popupData,
  clearPopupData,
}: PopupProps) => {
  const { x, y, title, year, value } = popupData;
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<{ x: number; y: number }>({ x, y });

  const dispatch = useDispatch();

  useEffect(() => {
    if (popupRef.current && mapContainer) {
      const popupElement = popupRef.current;
      const popupWidth = popupElement.offsetWidth;
      const popupHeight = popupElement.offsetHeight;
      const containerRect = mapContainer.getBoundingClientRect();

      let newX = x - 20;
      let newY = y - popupHeight - 20;

      if (newX < 0) {
        newX = 0;
      } else if (newX + popupWidth > containerRect.width) {
        newX = containerRect.width - popupWidth;
      }

      if (newY < 0) {
        newY = 0;
      } else if (newY + popupHeight > containerRect.height) {
        newY = containerRect.height - popupHeight;
      }

      setPosition({ x: newX, y: newY });
    }
  }, [x, y, mapContainer]);

  return createPortal(
    <PopupContainer ref={popupRef} x={position.x} y={position.y}>
      <Code>{title || ""}</Code>

      <MembersContainer>
        <Label>Members</Label>&nbsp;
        <Value>{value || ""}</Value>
      </MembersContainer>

      <YearContainer>
        <Label>Year</Label>&nbsp;
        <Value>{year || 2024}</Value>
      </YearContainer>

      <Button
        onClick={() => {
          dispatch(
            setActiveModal({
              id: "exploreMapModal",
              data: { ...popupData, region: popupData.regionName },
            })
          );

          clearPopupData();
        }}
        variant="neutral"
        size={"xs"}
        name="Explore"
        icon={<ChevronRight style={{ height: "14px", width: "14px" }} />}
      />
    </PopupContainer>,
    mapContainer
  );
};
