import React, { useState } from "react";
import { JsonData, JsonEditor } from "json-edit-react";
import { Container } from "./styles";

import "./index.css";

type Props = {};

const Body = (props: Props) => {
  const [json, setJson] = useState({});

  const handleJsonChange = (newData: JsonData) => {
    setJson(newData as typeof json);
  };

  return (
    <Container>
      <JsonEditor
        data={json}
        setData={handleJsonChange}
        rootName=""
        indent={2}
        collapse={1}
        restrictDrag={false}
        showCollectionCount={true}
      />
    </Container>
  );
};

export default Body;
