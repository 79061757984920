import { css, styled } from "styled-components";

export const ConnectAPIModal = styled.div`
  background: white;
  position: absolute;
  right: 50%;
  left: 50%;
  min-width: 496px;
  transform: translate(-50%, 0);
  border-radius: 16px;
  z-index: 11;
`

export const OverviewContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 165px);
  overflow-y: auto;
`

export const MainWrapper = styled.div`
  width: 100%;
  padding: 16px 20px 0;
`

export const ConfigureDataset = styled.p`
  color: #000417;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 30px;
  margin-bottom: 16px;
`

export const ConfigureDatasetRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

export const SelectTabWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`

export const TabElement = styled.div<{$selected?: boolean}>`
  border-bottom: 1px solid transparent;
  text-transform: capitalize;
  text-align: center;
  line-height: 20px; 
  user-select: none;
  /* transition: 0.3s; */
  font-weight: 400;
  font-size: 14px;
  padding: 12px 0;
  cursor: pointer;
  color: #515967;
  display: flex;
  gap: 4px;

  ${({$selected}) => $selected && css`
    border-bottom: 1px solid #454BDF;
    color: #2C33C8;
    font-weight: 600;
  `}
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 225px);
`

export const Info = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
`

export const InfoText = styled.p`
  color: #5F6877;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; 
`