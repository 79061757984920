import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  background: #f9fafa;
  max-width: 400px;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 142px);
  min-height: calc(100vh - 142px);
  width: 100%;
  border: 1px solid #CFD2D6;
`;

export const HeaderWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  color: #21262e;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 6px 0 18px;
`;

export const Tags = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  padding: 12px 16px 16px;
`;

export const Tag = styled.div<{ $active?: boolean }>`
  color: #21262E;
  user-select: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #CFD2D6;
  background: #EFF0F1;
  transition: 0.3s;

  &:hover {
    background: #DFE1E4;
  }

  &:active {
    background: #CFD2D6;
  }

  ${({ $active }) =>
    $active &&
    css`
      background: #6F6FE93D !important;
      border-color: #7D82F8 !important;
    `}
`;

export const ApplicationsList = styled.div`
  flex-direction: column;
  scrollbar-width: thin;
  overflow-y: auto;
  display: flex;
  gap: 4px;
  padding: 12px 16px;
`;


export const ButtonWrapper = styled.div`
  transition: 0.3s;
  height: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
`;

export const Application = styled.div<{ $active?: boolean }>`
  background: #EFF0F1;
  border: 1px solid #DFE1E4;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  padding: 12px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;

  &:hover {
    background: white;
    ${ButtonWrapper} {
      opacity: 1;
    }
  }

  &:active {
    background: #EFF0F1;
    ${ButtonWrapper} {
      opacity: 1;
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      background: white;

    `}
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px dashed #DFE1E4;
  margin: 6px 0;
`;

export const ImageWrapper = styled.div`
  min-width: 64px;
  max-width: 64px;
  height: 64px;
  position: relative;
`;

export const ApplicationImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const ApplicationContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ApplicationName = styled.div`
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #21262E;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

export const ApplicationDescription = styled.div`
  line-height: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #6C7584;
`;

export const Count = styled.div`
  position: absolute;
  text-align: center;
  background: #5D63F6;
  border-radius: 50%;
  line-height: 16px;
  padding: 2px 4px;
  font-weight: 400;
  font-size: 12px;
  color: #EFF0F1;
  height: 20px;
  width: 20px;
  left: 0;
  top: 0;
`;


export const RequestNewDevice = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-top: 1px solid #CFD2D6;
  padding: 12px 16px;
`

export const RequestNewDeviceText = styled.p`
  color: #515967;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`