import React, { useEffect, useRef, useState } from "react";
import {
  ChartAndLineWrapper,
  ColorsLine,
  Container,
  InputValue,
  InputWrapper,
  RangeWrapper,
  RefreshButton,
  Value,
  Values,
} from "./styles";
import MultiRangeInput from "../../../Inputs/CustomMultiRange/RangeInput";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import { WidgetItem } from "../../../../models/Widgets";
import DataRangeFilterChart from "../DataRangeFilterChart/DataRangeFilterChart";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { generateBreakPoints } from "../../../../helpers/generateBreakPoints";

type Props = {
  widgetData: WidgetItem;
  colors: string[];
  setColorRanges: any;
  colorRanges: number[];
  bubbles?: boolean;
  spikes?: boolean;
};

const DataRangeFilter = ({
  widgetData,
  colors,
  setColorRanges,
  colorRanges,
  bubbles,
  spikes,
}: Props) => {
  const ref = useRef(null);

  const [firstSlider, setFirstSlider] = useState<number>(0);
  const [secondSlider, setSecondtSlider] = useState<number>(0);
  const [thirdSlider, setThirdSlider] = useState<number>(0);
  const [fourSlider, setFourSlider] = useState<number>(0);
  const [fiveSlider, setFiveSlider] = useState<number>(0);
  const [sixSlider, setSixSlider] = useState<number>(0);

  const sliders = [
    {
      slideValue: firstSlider,
      slideSet: setFirstSlider,
    },
    {
      slideValue: secondSlider,
      slideSet: setSecondtSlider,
    },
    {
      slideValue: thirdSlider,
      slideSet: setThirdSlider,
    },
    {
      slideValue: fourSlider,
      slideSet: setFourSlider,
    },
    {
      slideValue: fiveSlider,
      slideSet: setFiveSlider,
    },
    {
      slideValue: sixSlider,
      slideSet: setSixSlider,
    },
  ];

  const [currentValue, setCurrentValue] = useState<number | undefined>();

  const [activeSlider, setActiveSlider] = useState<number>(-1);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const yAxe = widgetData?.yAxe?.at(0) ?? "value";

  const values =
    widgetData?.data?.map((item) => Number(item[yAxe])).sort((a, b) => a - b) ??
    [];
  const autoRanges = generateBreakPoints(values);

  const showReset = String(autoRanges) === String(widgetData.palette?.range);

  const minValue = values[0] > 0 ? 0 : values[0];
  const maxValue = values.at(-1) ?? 0;
  const rangeStep = Math.floor((maxValue - minValue) / 7);

  const calculateRanges = () => {
    const ranges = widgetData.palette?.range;
    if (!ranges?.at(ranges?.length - 1)) {
      return autoRanges;
    }
    return ranges;
  };

  const [ranges, setRanges] = useState(calculateRanges());

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }

    if (widgetData.palette?.autoRange && !firstLoad) {
      setRanges(autoRanges);
    } else {
      setFirstSlider(rangeStep);
      setSecondtSlider(rangeStep * 2);
      setThirdSlider(rangeStep * 3);
      setFourSlider(rangeStep * 4);
      setFiveSlider(rangeStep * 5);
      setSixSlider(rangeStep * 6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData.palette?.autoRange]);

  useEffect(() => {
    setFirstSlider(ranges[1]);
    setSecondtSlider(ranges[2]);
    setThirdSlider(ranges[3]);
    setFourSlider(ranges[4]);
    setFiveSlider(ranges[5]);
    setSixSlider(ranges[6]);
  }, [ranges]);

  useEffect(() => {
    setColorRanges([
      0,
      firstSlider,
      secondSlider,
      thirdSlider,
      fourSlider,
      fiveSlider,
      sixSlider,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstSlider,
    secondSlider,
    thirdSlider,
    fourSlider,
    fiveSlider,
    sixSlider,
  ]);

  useOutsideClick(ref, () => {
    setCurrentValue(undefined);
    setActiveSlider(-1);
  });

  const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (isNaN(value)) {
      return sliders[activeSlider].slideValue;
    }

    const isLast = activeSlider === sliders.length - 1;

    if (value > maxValue) {
      const value = isLast
        ? maxValue
        : sliders[activeSlider + 1].slideValue - 10;
      setCurrentValue(value);
      sliders[activeSlider].slideSet(value);
      return;
    } else {
      setCurrentValue(value);
    }

    switch (true) {
      case !activeSlider && value < sliders[1].slideValue:
        return sliders[activeSlider].slideSet(value);

      case isLast && value > sliders[sliders.length - 2].slideValue:
        return sliders[activeSlider].slideSet(value);

      case value > sliders[activeSlider - 1].slideValue &&
        value < sliders[activeSlider + 1].slideValue:
        return sliders[activeSlider].slideSet(value);
    }
  };

  return (
    <Container>
      {
        <RefreshButton
          $show={Boolean(!showReset && widgetData.palette?.autoRange)}
          onClick={() =>
            !showReset && widgetData.palette?.autoRange && setRanges(autoRanges)
          }
        />
      }
      <ChartAndLineWrapper>
        <DataRangeFilterChart
          values={values}
          maxValue={maxValue}
          rangeStep={rangeStep}
          ranges={[...colorRanges, maxValue]}
          colors={colors}
          bubbles={bubbles}
          spikes={spikes}
        />
      </ChartAndLineWrapper>
      <RangeWrapper ref={ref}>
        <MultiRangeInput
          min={minValue}
          max={maxValue}
          activeSlider={activeSlider}
          setCurrentValue={setCurrentValue}
          setActiveSlider={setActiveSlider}
          currentValue={currentValue}
          sliders={sliders}
        />

        <ColorsLine
          $colors={colors}
          $ranges={[
            (firstSlider / maxValue) * 100 + 1,
            (secondSlider / maxValue) * 100 + 1,
            (thirdSlider / maxValue) * 100 + 0.5,
            (fourSlider / maxValue) * 100,
            (fiveSlider / maxValue) * 100,
            (sixSlider / maxValue) * 100 - 1,
          ]}
        />

        <Values>
          <Value>{minValue}</Value>
          <InputWrapper>
            {activeSlider !== -1 && (
              <InputValue
                pattern="[0-9]*"
                type="text"
                value={currentValue}
                maxLength={10}
                $width={11 * String(currentValue).length + 6}
                onChange={onChangeHandle}
              />
            )}
          </InputWrapper>
          <Value>{ticksFormatter(maxValue!)}</Value>
        </Values>
      </RangeWrapper>
    </Container>
  );
};

export default DataRangeFilter;
