export const getFields = (data: any) => {
    const fields = data.flatMap((category: any) => {
        const responseFields = Array.isArray(category.response_fields) ? category.response_fields : [];
        const fields = responseFields.map((field:any) => {
            return {
                ...field,
                tableKey: category.id,
            }
        });
        return fields;
    });

    const fieldsAi = data.flatMap((category: any) => {
        const responseFields = Array.isArray(category.response_fields) ? category.response_fields : [];
        const fields = responseFields.map((field:any) => {
            return {
                name: field.key,
                types: [
                    {
                        type: field.type,
                        subtype: field.subtype,
                        group: false,
                    }
                ]
            }
        });
        return fields;
    });

    const fieldsIds = data.map((category: any) => category.id);

    return {fields, fieldsAi, fieldsIds};
}