import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { createPortal } from "react-dom";
import {
  NumberValue,
  scaleLinear,
  scaleOrdinal,
  select,
  range,
  curveLinearClosed,
  lineRadial,
  symbolTriangle,
  symbolSquare,
  symbolCircle,
  symbolCross,
  symbol,
} from "d3";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title,
} from "./styles";
import {
  FeedBackButton,
  HeadingNameAndButton,
  WidgetImageWrapper,
} from "../styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { getActiveModal } from "../../../store/selectors/modals";

import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { RadarChartGroupedData } from "./utils/getGroupData";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { useDatasetField } from "../../../hooks/useDatasetField";
import { formatLabelValue } from "../utils/formatLabelValue";
import { LabelTooltip, LabelTooltipProps } from "../components/LabelTooltip";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";
import { getAvailableWidgetTypes } from "../widgetHelpers";

export interface RadarChartProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  preview?: boolean;
}

const axisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

export const RadarChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
  preview = false,
}: RadarChartProps) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const svgRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure({ scroll: true });
  const [measureRef, bounds] = useMeasure({ scroll: true });

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [tickLabelTooltip, setTickLabelTooltip] =
    useState<LabelTooltipProps | null>(null);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else {
      if (feedbackState && image) {
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const markerSize = 50; // is not not in px
  const margin = { top: 25, right: 5, bottom: 25, left: 5 };
  const width = bounds.width - margin.left - margin.right;
  const height = bounds.height - margin.top - margin.bottom;
  const outerRadius = useMemo(
    () => Math.min(width, height) / 2,
    [width, height]
  );
  const rScaleSteps = 5;

  const chartSuggestion = useMemo(
    () =>
      aiSuggestions?.find(
        (chart: AiSuggestionsDto) => chart.chartType === "radarChart"
      ),
    [aiSuggestions]
  );

  const groupBy = useMemo(() => {
    return currentWidget?.groupBy?.[0] || chartSuggestion?.groupBy;
  }, [currentWidget?.groupBy, chartSuggestion?.groupBy]);

  const xAxe = useMemo(() => {
    return currentWidget?.xAxe?.[0] || chartSuggestion?.xAxe?.[0];
  }, [currentWidget?.xAxe, chartSuggestion?.xAxe]);

  const yAxe = useMemo(() => {
    return currentWidget?.yAxe?.[0] || chartSuggestion?.yAxe?.[0];
  }, [currentWidget?.yAxe, chartSuggestion?.yAxe]);

  const groupedData = useMemo(
    () => RadarChartGroupedData(currentWidget),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentWidget?.data, xAxe, yAxe, groupBy]
  );

  const groupByKey = useMemo(() => {
    return groupBy || Object.keys(currentWidget?.uniqueValues || [])?.[0];
  }, [currentWidget?.uniqueValues, groupBy]);

  const uniqueValues = useMemo(() => {
    return currentWidget?.uniqueValues?.[groupByKey!];
  }, [currentWidget?.uniqueValues, groupByKey]);

  const legendValues = useMemo(() => {
    return (uniqueValues || []).map((key) => ({
      label: key!,
      key: key!,
      color: getCurrentColor(currentWidget, key, styleId),
    }));
  }, [uniqueValues, currentWidget, styleId]);

  //* Marker
  const markersTypes = currentWidget?.markers || [];

  const getMarkerType = (key: string) =>
    String(markersTypes.find((item) => item.key === key)?.shape ?? "");

  const getMarkerPath = (markerType: string) => {
    const markerTypesMap: { [key: string]: any } = {
      cross: symbolCross,
      circle: symbolCircle,
      donut: symbolCircle,
      square: symbolSquare,
      rhombus: symbolSquare,
      triangle: symbolTriangle,
    };

    return symbol().type(markerTypesMap[markerType] || symbolCircle);
  };

  const getMarkerRotation = (markerType: string): string => {
    const rotationMap: { [key: string]: number } = {
      rhombus: 45,
      cross: 45,
    };

    return `rotate(${rotationMap[markerType] || 0})`;
  };

  const getMarkerStrokeWidth = (markerType: string): number => {
    return markerType === "donut" ? 1.5 : 1;
  };

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  // Determine the series
  const chartData = useMemo(() => {
    const groupedDataKeys = groupedData && Object.keys(groupedData);
    return (
      groupedData &&
      groupedDataKeys.length &&
      groupedData?.[groupedDataKeys?.[0]] &&
      Object.keys(groupedData)
        .reverse()
        .map((key: string, index: number) => {
          const groupedDataByKey = key && groupedData?.[key];
          const data = groupedDataByKey && [
            ...groupedDataByKey?.map((d: any) => [0, d?.y, d?.x]),
          ];
          data["key" as any] = key;
          data["index" as any] = index;
          return data;
        })
    );
  }, [groupedData]);

  const categories: string[] = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      Array.from(new Set(currentWidget?.data?.map((d: any) => d[xAxe]))) ||
      []
    );
  }, [currentWidget?.uniqueValues, currentWidget?.data, xAxe]);

  const categoryDatasetField = useDatasetField(xAxe);

  const angleSlice = useMemo(
    () => (Math.PI * 2) / categories.length,
    [categories.length]
  );

  //* Scales
  const colorScale = useMemo(() => {
    const defaultColor = getCurrentColor(currentWidget, "default", styleId);
    const colors = groupBy
      ? legendValues.map((item) => item.color)
      : [defaultColor];

    return scaleOrdinal<string, string>()
      .domain(legendValues.map((item) => item.label))
      .range(colors)
      .unknown(defaultColor);
  }, [currentWidget, groupBy, legendValues, styleId]);

  const maxValue = useMemo(() => {
    return (
      chartData &&
      Math.max(
        ...chartData?.map((d: any) => Math.max(...d?.map((d: any) => d[1])))
      )
    );
  }, [chartData]);

  const rScale = useMemo(() => {
    return scaleLinear<number, number>()
      .rangeRound([0, outerRadius])
      .domain([0, maxValue])
      .nice();
  }, [outerRadius, maxValue]);

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget?.tooltip) && !recommended) {
        const { pageX, pageY, clientX, clientY } = event;
        const coords = { pageX, pageY, clientX, clientY };

        setTooltip({
          name: datum.key !== "default" ? datum.key : String(datum[2]),
          data: {
            [xAxe as string]: datum.key !== "default" ? String(datum[2]) : "",
            [yAxe as string]: String(datum[1] - datum[0]),
          },
          coords,
        });
      }
    },
    [currentWidget?.tooltip, recommended, showTooltip, xAxe, yAxe]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip || currentWidget?.tooltip) {
      setTooltip(null);
    }
  }, [currentWidget?.tooltip, showTooltip]);

  const handleMouseOver = useCallback(
    function (self: any, svg: any, markerType: string) {
      if (!(showTooltip || currentWidget?.tooltip)) {
        return;
      }

      svg
        .selectAll(".radarchart-marker, .radar-path-group")
        .transition()
        .duration(200)
        .attr("opacity", "0.4");

      select(self)
        .transition()
        .duration(200)
        .attr("opacity", "1")
        .attr("d", getMarkerPath(markerType).size(70));
    },
    [showTooltip, currentWidget?.tooltip]
  );

  const handleMouseOut = useCallback(
    function (svg: any, markerType: string) {
      if (!(showTooltip || currentWidget?.tooltip)) {
        return;
      }

      svg
        .selectAll(".radarchart-marker, .radar-path-group")
        .transition()
        .duration(200)
        .attr("opacity", "1")
        .attr("d", getMarkerPath(markerType).size(markerSize));
    },
    [showTooltip, currentWidget?.tooltip]
  );

  const handleMouseMoveTickLabel = useCallback((event: any, datum: any) => {
    setTickLabelTooltip({
      data: datum,
      x: event.pageX - 10,
      y: event.pageY,
    });
  }, []);

  const handleMouseLeaveTickLabel = useCallback(() => {
    setTickLabelTooltip(null);
  }, []);

  //* Chart
  const svgContainer = select(svgRef.current);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    if (!bounds.width || !bounds.height || !chartData?.length) {
      return;
    }

    const svg = svgContainer
      .append("g")
      .attr(
        "transform",
        `translate(${(width + margin.left + margin.right) / 2}, ${
          (height + margin.top + margin.bottom) / 2
        })`
      );

    //* Grid
    const grid = svg.append("g").attr("class", "grid");
    grid
      .selectAll(".grid-polygon")
      .data(range(1, rScaleSteps + 1))
      .enter()
      .append("polygon")
      .attr("class", "grid-polygon")
      .attr("points", (d) => {
        const points = categories.map((c: string, i: number) => {
          const angle = angleSlice * i - Math.PI / 2;
          const x = rScale((d * maxValue) / rScaleSteps) * Math.cos(angle);
          const y = rScale((d * maxValue) / rScaleSteps) * Math.sin(angle);
          return `${x},${y}`;
        });
        return points.join(" ");
      })
      .attr("fill", "none")
      .attr("stroke", "#939ba7");

    //* Axes
    const axisGrid = svg.append("g").attr("class", "axis-grid");
    categories.forEach((category: string, i: number) => {
      // INFO: Start from the top: - Math.PI / 2
      const angle = angleSlice * i - Math.PI / 2;
      const x = rScale(maxValue) * Math.cos(angle);
      const y = rScale(maxValue) * Math.sin(angle);
      const labelText = formatLabelValue(
        category,
        categoryDatasetField?.type,
        categoryDatasetField?.subtype
      );
      const isLabelShorter = labelText.length < category.length;

      const textAnchor =
        Math.abs(angle) === Math.PI / 2
          ? "middle"
          : angle < Math.PI / 2 && angle > -Math.PI / 2
          ? "start"
          : "end";

      // Radial axis line
      axisGrid
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", x)
        .attr("y2", y)
        .attr("class", "axis")
        .attr("stroke", "#939ba7")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-dasharray", "2,2");

      // Radial axis line's cap
      axisGrid
        .append("circle")
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", 3)
        .attr("class", "axis-cap")
        .attr("fill", "#939ba7");

      // Axis labels
      axisGrid
        .append("text")
        .attr("class", "axis-label")
        .attr("x", (rScale(maxValue) + 12) * Math.cos(angle))
        .attr("y", (rScale(maxValue) + 12) * Math.sin(angle))
        .attr("text-anchor", textAnchor)
        .attr("font-size", "12")
        .attr("dominant-baseline", "central")
        .text(labelText)
        .classed("tick-label--long", isLabelShorter)
        .on("mousemove", (event: any) => {
          if (isLabelShorter) {
            handleMouseMoveTickLabel(event, category);
          }
        })
        .on("mouseleave", () => {
          handleMouseLeaveTickLabel();
        });
    });

    //* Grid ticks
    const measuredRectHeight = 21;
    const hasChartSufficientHeight =
      height / 2 >= (rScaleSteps - 1) * measuredRectHeight;
    if (hasChartSufficientHeight) {
      const tick = svg.append("g").attr("class", "tick");
      tick
        .selectAll(".tick-group")
        .data(range(1, rScaleSteps + 1))
        .enter()
        .append("g")
        .attr("class", "tick-group")
        .each(function (d, i) {
          const group = select(this);
          const yPosition = -rScale((d * maxValue) / rScaleSteps);

          // Create the text element first to measure its size
          const textNode = group
            .append("text")
            .attr("class", "tick-rect")
            .attr("x", 0)
            .attr("y", yPosition)
            .attr("font-size", "12px")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "central")
            .text(axisTickFormatter(Math.floor((maxValue / rScaleSteps) * d)));

          const textBBox = textNode.node()?.getBBox() as DOMRect;
          const padding = {
            x: 7,
            y: 3,
          };
          const rectWidth = textBBox.width + padding.x * 2;
          const rectHeight = textBBox.height + padding.y * 2;
          const rectX = textBBox.x - padding.x;
          const rectY = textBBox.y - padding.y;

          // Create the rect element before the text element
          group
            .insert("rect", "text")
            .attr("class", "tick-label")
            .attr("x", rectX)
            .attr("y", rectY)
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", 4)
            .attr("ry", 4)
            .attr("fill", "#f1f4f7");
        });
    }

    // * DataViz
    // Line for each series.
    svg
      .append("g")
      .attr("class", "radar-path-group")
      .selectAll()
      .data(chartData)
      .join("path")
      .attr(
        "d",
        lineRadial()
          .angle((d, j) => angleSlice * j)
          .radius((d: any) => rScale(d[1]))
          .curve(curveLinearClosed) as any
      )
      .attr("stroke", (d: any) => {
        return colorScale(d.key) as string;
      })
      .attr("stroke-width", 2)
      .attr("fill", (d: any) => colorScale(d.key) as string)
      .attr("fill-opacity", 0.15);

    // Circle for each data point.
    svg
      .append("g")
      .attr("class", "radar-data-point-group")
      .selectAll()
      .data(chartData)
      .join("g")
      .attr("class", "radarchart-marker-container")
      .each(function (d: any, i: number) {
        const markerContainer = select(this);

        // Iterate through each data point in the series
        d.forEach((point: any, j: number) => {
          const markerType = getMarkerType(groupBy ? d.key : "default");
          if (markerType === "disabled") return;

          markerContainer
            .append("path")
            .attr("class", "radarchart-marker")
            .attr("d", getMarkerPath(markerType).size(markerSize))
            .attr("transform", () => {
              const angle = angleSlice * j - Math.PI / 2;
              return `translate(${rScale(point[1]) * Math.cos(angle)} ${
                rScale(point[1]) * Math.sin(angle)
              }) ${getMarkerRotation(markerType)}`;
            })
            .attr("fill", () =>
              markerType === "donut" ? "white" : colorScale(d.key)
            )
            .attr("stroke", () =>
              markerType === "donut" ? colorScale(d.key) : "white"
            )
            .attr("stroke-width", getMarkerStrokeWidth(markerType))
            .on("mouseover", function () {
              handleMouseOver(this, svg, markerType);
            })
            .on("mouseout", () => {
              handleMouseOut(svg, markerType);
            })
            .on("mousemove", (event) =>
              handleMouseMove(event, { ...point, key: d.key })
            )
            .on("mouseleave", handleMouseLeave);
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, width, height]);

  if (!chartData || !Object.keys(chartData).length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        <HeaderWrapper ref={refWidget}>
          {!storytelling && (
            <HeadingNameAndButton>
              {!hideName ? <Title>{name}</Title> : <></>}
              {!recommended && (
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              )}
              {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
                <SettingsButtonWrapper
                  $modalOpen={
                    !!activeModal?.length &&
                    modalCurrentWidget?.id === currentWidget?.id
                  }
                  onClick={() => {
                    dispatch(setCurrentWidget(currentWidget!));
                    dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                  }}
                >
                  <DatavizRecommendedCount>
                    {availableWidgetsCount + 1}
                  </DatavizRecommendedCount>
                  <DatavizSettingsIcon />
                </SettingsButtonWrapper>
              ) : null}
              {recommended ? <SelectBage selected={selected} /> : null}
            </HeadingNameAndButton>
          )}
          {legendValues?.length > 1 &&
            groupBy &&
            showLegend &&
            currentWidget?.legend && (
              <ChartLegend
                chartWidth={boundsWidget.width}
                legendType="unit"
                legendValues={legendValues}
              />
            )}
        </HeaderWrapper>

        <SVGStyled
          ref={(node) => {
            svgRef.current = node;
            measureRef(node);
          }}
          width="100%"
          height="100%"
        ></SVGStyled>

        {tooltip &&
          xAxe &&
          yAxe &&
          createPortal(
            <Tooltip
              xAxe={xAxe}
              yAxe={yAxe}
              data={tooltip.data}
              name={tooltip.name}
              coords={tooltip.coords}
            />,
            document.body
          )}

        {tickLabelTooltip &&
          createPortal(
            <LabelTooltip
              x={tickLabelTooltip?.x}
              y={tickLabelTooltip?.y}
              data={tickLabelTooltip?.data}
            />,
            document.body
          )}
      </WidgetImageWrapper>
    </>
  );
};
