import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { ModalWrapper as BaseModalWrapper } from "../styles";

export const ModalWrapper = styled(BaseModalWrapper)`
  display: flex;
  flex-direction: column;
  background: #f0f0f0;
`;

export const ModalContentContainer = styled.div<{ $isInitialState: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 560px;
  max-width: 100%;
  margin: 0 auto;
  padding: 24px 0;
  overflow-y: hidden;
  justify-content: ${({ $isInitialState }) =>
    $isInitialState ? "center" : "unset"};
`;

export const ModalChatPanelWrapper = styled.div<{ $isInitialState: boolean }>`
  margin-top: ${({ $isInitialState }) => ($isInitialState ? "0" : "auto")};
`;

export const ModalChatWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  overflow-y: auto;
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
`;

export const ModalTicketLink = styled(NavLink)`
  display: inline-block;
  font-weight: 400;
  background-color: #fff;
  padding: 6px 8px;
  color: ${({ theme }) => theme.primaryText};
  border-radius: 16px;
  box-shadow: 0px 2px 2px rgba(34, 36, 44, 0.06),
    0px 2px 4px 1px rgba(34, 36, 44, 0.04);
`;
