import React, { useRef, useState } from "react";
import { ChevronDown } from "react-bootstrap-icons";
import { useOnClickOutside } from "usehooks-ts";
import {
  DropdownButton,
  DropdownContainer,
  DropdownItem,
  DropdownList,
  ItemDescription,
} from "./styles";
import { DropdownOption } from "../../types";

interface DropdownProps {
  options: DropdownOption[];
  value?: string;
  onChange: (option: DropdownOption) => void;
  placeholder?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  value,
  onChange,
  placeholder = "Select option",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setIsOpen(false));

  const handleSelect = (option: DropdownOption) => {
    onChange(option);
    setIsOpen(false);
  };

  const selectedOption = options.find((opt) => opt.value === value);

  return (
    <DropdownContainer ref={ref}>
      <DropdownButton
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span>{selectedOption?.label || placeholder}</span>
        <ChevronDown size={16} />
      </DropdownButton>

      {isOpen && (
        <DropdownList role="listbox">
          {options.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleSelect(option)}
              $hasDescription={!!option.description}
              role="option"
              aria-selected={option.value === value}
            >
              <div>{option.label}</div>
              {option?.description && (
                <ItemDescription>{option.description}</ItemDescription>
              )}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};
