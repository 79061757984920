import styled from "styled-components";

export const MapWrapper = styled.div<{ $explore?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: ${({ $explore }) => ($explore ? "0" : "6px")};
  animation: fadeIn 1s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .mapboxgl-ctrl-logo {
    display: none;
  }
`;

export const MapHeader = styled.p`
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 12px;
  margin-left: 16px;
`;

export const MapControl = styled.div`
  position: absolute;
  z-index: 100000;
  top: 12px;
  right: 70px;
`;

export const MapControlsWrapper = styled.div``;

export const SelectBageBlock = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 5;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: white;
  border-radius: 6px;
  z-index: 1;
`;

export const DisableBuildingsWrapper = styled.div<{
  $isCard?: boolean;
  $timeline: boolean;
}>`
  background: white;
  padding: 6px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  width: min-content;
  position: absolute;

  ${({ $isCard, $timeline }) =>
    $isCard
      ? `
    right: 22px;
    top: 45px;
  `
      : `
    right: ${$timeline ? "380px" : "60px"};
    top: 16px;
  `}
  z-index: 1;
`;
