import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompaniesI } from "../../pages/Resources/pages/PublicResources/pages/SearchDataById/interfaces/companies.interface";
import { IGetAppEndpoints, IValidateAppCredentials } from "../../api/app-api";

interface ProviderListItem {
  id: string,
  name: string
}

export interface IApplication {
  name: string;
  id: string;
  description: string;
  appCategory: string[];
  credentials: Credential[];
}

interface Credential {
  name: string;
}

export interface DataProviders {
  dataProvidersList: ProviderListItem[];
  dataProvider?: CompaniesI;
  applications: IApplication[]
}

const initialState: DataProviders = {
  dataProvidersList: [],
  dataProvider: undefined,
  applications: []
};

const appConfigSlice = createSlice({
  name: "dataProviders",
  initialState,
  reducers: {
    requestAppValidate: (state, action: PayloadAction<IValidateAppCredentials>) => {},
    requestGetAppEndpoints: (state, action: PayloadAction<IGetAppEndpoints>) => {},
    requestApplications: (state) => {},
    setApplications: (state, { payload }: PayloadAction<IApplication[]>) => {
      return {
        ...state,
        applications: payload
      }
    },
    requestGetDataProviders: (state, action) => {},
    requestGetDataProvider: (state, action: PayloadAction<ProviderListItem['id']>) => { },
    setDataProvidersList: (state, action: PayloadAction<ProviderListItem[]>) => {
      return {
        ...state,
        dataProvidersList: action.payload
      }
    },
    setDataProvider: (state, action: PayloadAction<CompaniesI>) => {
      return {
        ...state,
        dataProvider: action.payload
      }
    },
  }
});

export const {
  requestAppValidate,
  requestGetAppEndpoints,
  requestGetDataProviders,
  requestGetDataProvider,
  requestApplications,
  setDataProvidersList,
  setDataProvider,
  setApplications
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
