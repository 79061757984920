import { generateBreakPoints } from "../../../../helpers/generateBreakPoints";
import { ColorRangeI } from "../../../../models/Pages";

export const generateColorRanges = (
  variations: string[],
  values: number[],
  setColorRanges: React.Dispatch<React.SetStateAction<ColorRangeI[]>>,
  ranges?: number[]
) => {
  if (!variations?.length || !values?.length) {
    setColorRanges([]);
    return;
  }

  const bubleRadius = [5, 10, 14, 17, 20, 24, 26];

  const sortedValues = [...new Set(values)].sort((a, b) => a - b);
  const steps = ranges ?? generateBreakPoints(sortedValues);

  const colorRanges = steps.map((step, i) => ({
    color: variations[i],
    radius: bubleRadius[i],
    start: step,
    end: Math.ceil(i === steps.length - 1 ? sortedValues.at(-1)! : steps[i + 1]) + 1,
  }));

  setColorRanges(colorRanges);
};
