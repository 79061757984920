import React from "react";

import { WidgetItem } from "../../../models/Widgets";
import { GroupedLineChart } from "./GroupedLineChart";
import { LineChart } from "./SingleLineChart";

type WrapperLineInterface = {
  storytelling?: boolean;
  recommended?: boolean;
  selected?: boolean;
  showLegend?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  hideSettings?: boolean;
};

export const WrapperLineChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
}: WrapperLineInterface) => {
  if (currentWidget?.groupBy && currentWidget?.groupBy?.length > 0)
    return (
      <GroupedLineChart
        recommended={recommended}
        selected={selected}
        storytelling={storytelling}
        showLegend={showLegend}
        currentWidget={currentWidget}
        hideName={hideName}
        hideSettings={hideSettings}
      />
    );

  return (
    <LineChart
      recommended={recommended}
      selected={selected}
      storytelling={storytelling}
      showLegend={showLegend}
      currentWidget={currentWidget!}
      hideName={hideName}
      hideSettings={hideSettings}
    />
  );
};
