import styled from "styled-components";

export const SearchContainer = styled.div`
  position: relative;
  max-width: 520px;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  height: 337px;
  z-index: 50;
`;

export const SearchResults = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  overflow: hidden;
`;

export const MainColumn = styled.div`
  flex: 1;
  overflow-y: auto;
  border-right: 1px solid #e5e7eb;
`;

export const SideColumn = styled.div`
  width: 220px;
  background: #6C75840A;
  min-height: 337px;
`;

export const SideColumnTitle = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  padding: 4px 12px;
  margin-top: 8px;
`;

export const CategoryGroup = styled.div`
  border-bottom: 1px solid #e5e7eb;
  &:last-child {
    border-bottom: none;
  }
`;

export const CategoryHeader = styled.div`
  padding: 12px 16px;
  font-weight: 400;
  color: #374151;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #f9fafb;
  }
`;

export const CategoryTitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const CategoryItems = styled.div``;

export const SearchItem = styled.div`
  padding: 8px 16px 8px 40px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: #374151;

  &:hover {
    background-color: #f9fafb;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid #d1d5db;
  cursor: pointer;
`;

export const FilterOption = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 6px;
  cursor: pointer;
  color: #374151;

  &:hover {
    background-color: #f3f4f6;
  }
`;

export const FilterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FilterCount = styled.span`
  margin-left: auto;
  background-color: #e5e7eb;
  color: #6b7280;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
`;

export const NavigationHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  padding: 4px 12px;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  border: none;
  background: transparent;
  color: #6b7280;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #f3f4f6;
    color: #374151;
  }
`;

export const SubmitButtonContainer = styled.div`
  padding: 12px;
  border-top: 1px solid #e5e7eb;
  background: white;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
`;

export const ChevronIcon = styled.span`
  font-size: 12px;
  color: #9ca3af;
`;

export const OptionsList = styled.div<{ $hasSubmit: boolean }>`
  padding: 8px 0;
  height: ${({  $hasSubmit }) =>   $hasSubmit ? "calc(337px - 59px)" : "337px" };
  overflow: auto;
`;


export const RecentSearches = styled.div`
  margin-top: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: #ffffff;
  padding: 16px;
`;

export const RecentSearchesTitle = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  padding: 4px 12px;
  margin-top: 8px;
`;

export const RecentSearchItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.neutralText};
`;

export const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.neutralTextWeak};
`;