import React from "react";
import { Container, Finish, Message, SubMessage, TextWrapper } from "./styled";
import { Button } from "../../../../Button";

type Props = {
  handleOnClose: () => void;
};

const FinishPage = ({ handleOnClose }: Props) => {
  return (
    <Container>
      <Finish />
      <TextWrapper>
        <Message>Thank you!</Message>
        <SubMessage>Your Feedback was Successfully Submitted.</SubMessage>
      </TextWrapper>
      <Button
        name="Close"
        onClick={handleOnClose}
        variant="neutral"
        size="medium"
      />
    </Container>
  );
};

export default FinishPage;
