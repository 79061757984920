import styled, { css } from "styled-components";
import { Col, Row } from "react-flexbox-grid";

export const WrapperRow = styled(Row)(
  () => css`
    position: relative;
    height: 100%;
    width: 100%;
  `
);

export const WrapperWidget = styled(Row)<{
  noMarginRight?: boolean;
  noMarginLeft?: boolean;
}>(
  ({ theme, noMarginLeft, noMarginRight }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    position: relative;
    margin: 0 12px;
    border: 1px solid ${theme.neutralBorder};
    border-radius: 8px;
    background-color: white;
    margin-right: ${noMarginRight ? "-2px" : "12px"};
    margin-left: ${noMarginLeft ? "-2px" : "12px"};
  `
);

export const DataColumn = styled(Col)(
  () => css`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: calc(100vh - 156px);
    z-index: 100;
    gap: 8px;
    margin-top: 12px;

    overflow-y: scroll;
    scroll-behavior: smooth;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none;
    }
  `
);

export const MapColumn = styled(Col)(
  () => css`
    height: 100%;
    width: 100%;
  `
);
