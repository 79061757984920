import React from "react";
import {
  EmptyWidgetsDetailsDescription,
  EmptyWidgetsDetailsWrapper,
  EmptyWidgetsDetailsTitle,
  EmptyWidgetsWrapper,
  EmptyWidgetsIcon,
} from "./styles";
import { Button } from "../Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setNewProjectSelectedFilesColumns,
  setNewProjectSelectedFiles,
  requestGetAllDatasets,
  setNewProjectDetails,
  updateSelectedFiles,
  setDatasetPreview,
} from "../../store/slices/datasets";
import { getCurrentProjectData } from "../../store/selectors/projects";
import { DatasetsResponseListDto } from "../../models/Datasets";
import { setActiveModal } from "../../store/slices/modals";
import { FileLibraryItem } from "../../models/Files";
import { PlusLg } from "react-bootstrap-icons";

export const NoDataMessage: React.FC = () => {
  const dispatch = useDispatch();
  const { datasets, projectType, name, description, datasetColumnKeys } =
    useSelector(getCurrentProjectData);

  const addData = () => {
    dispatch(
      requestGetAllDatasets({
        limit: 1000,
        callbacks: {
          onSuccess(data) {
            const allDatasets = data as DatasetsResponseListDto;
            const datasetId = (datasets && datasets.at(0)?.sourceId) ?? "";

            const fields = allDatasets.items.find(
              (item) => item.id === datasetId
            )?.fields;

            const selectedFiles = [
              {
                id: datasetId,
                fields: fields!,
              },
            ];

            dispatch(
              updateSelectedFiles({
                id: datasetId,
                fields: fields!,
              })
            );

            dispatch(
              setNewProjectDetails({
                name,
                description,
                projectType,
              })
            );

            const newProjectSelectedDatasets = allDatasets.items
              .filter((dataset) =>
                [datasetId].includes(dataset.id || dataset.sourceId || "")
              )
              .map((dataset) => {
                const primaryFields = dataset.fields
                  ?.filter((item) => item.primary)
                  .map((item) => item.key);

                const selectedDataset = selectedFiles.find(
                  (file) => file.id === dataset.id
                );
                if (selectedDataset) {
                  const selectedHeaders = selectedDataset.fields.map((item) => {
                    return {
                      ...item,
                      primary: !!primaryFields?.includes(item.key),
                    };
                  });

                  return {
                    ...dataset,
                    fields: selectedHeaders,
                  };
                }
                return null;
              })
              .filter((dataset) => dataset !== null);

            dispatch(
              setNewProjectSelectedFiles(
                newProjectSelectedDatasets.map((item) => {
                  return {
                    name: item?.name,
                    filePath: item?.filePath,
                    fields: item?.fields,
                    sourceId: item?.id,
                    source: item?.source,
                    fieldsAi: item?.fieldsAi,
                    descriptionAi: item?.descriptionAi,
                    sourceType: item?.sourceType,
                  };
                }) as FileLibraryItem[]
              )
            );

            dispatch(
              setNewProjectSelectedFilesColumns(
                newProjectSelectedDatasets.map((item) => {
                  return {
                    name: item?.name,
                    filePath: item?.filePath,
                    fields: item?.fields.filter((item) =>
                      datasetColumnKeys.includes(item.key)
                    ),
                    sourceId: item?.id,
                    source: item?.source,
                    fieldsAi: item?.fieldsAi,
                    descriptionAi: item?.descriptionAi,
                    sourceType: item?.sourceType,
                  };
                }) as FileLibraryItem[]
              )
            );

            dispatch(setDatasetPreview(newProjectSelectedDatasets[0]));

            dispatch(
              setActiveModal({
                id: "selectedDatasetOverviewModal",
                data: {
                  deleteAllOnClose: true,
                  closeWarning: () => {},
                },
              })
            );
          },
        },
      })
    );
  };

  return (
    <EmptyWidgetsWrapper>
      <EmptyWidgetsIcon />
      <EmptyWidgetsDetailsWrapper>
        <EmptyWidgetsDetailsTitle>Insufficient Data</EmptyWidgetsDetailsTitle>
        <EmptyWidgetsDetailsDescription>
          The system is unable to generate a comprehensive analytical dashboard
          due to the limited amount of data provided. To enhance your dashboard,
          consider adding more data or connecting to public data sources.
        </EmptyWidgetsDetailsDescription>
      </EmptyWidgetsDetailsWrapper>
      <Button
        onClick={addData}
        variant={"secondary"}
        size={"large"}
        name="Add data"
        icon={<PlusLg />}
      />
    </EmptyWidgetsWrapper>
  );
};
