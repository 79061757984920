import { FC } from "react";
import { AiLoadingIcon } from "../../../../../assets";
import { FlexContainer } from "../../../../../styles";
import { MessageTypeEnum } from "../../constants";
import { Message } from "../../types";
import ChatMessage from "../ChatMessage";
import SummaryMessage from "../SummaryMessage";
import { MessageContainer } from "./styles";

interface Props {
  message: Message;
  onPromptClick: (
    action: MessageTypeEnum,
    dataset_id: string
  ) => void;
}

const ChatMessageMain: FC<Props> = ({ message, onPromptClick }) => {
  const renderMessageContent = () => {
    if (message.type === MessageTypeEnum.ANALYZE_SUMMARY) {
      return <SummaryMessage message={message} onPromptClick={onPromptClick} />;
    }
    return <ChatMessage message={message} onPromptClick={onPromptClick} />;
  };

  return (
    <MessageContainer>
      <FlexContainer $gap="16px">
        <div>
          <AiLoadingIcon />
        </div>
        {renderMessageContent()}
      </FlexContainer>
    </MessageContainer>
  );
};

export default ChatMessageMain;