import styled, { keyframes } from "styled-components";
import { ReactComponent as Dot } from "../../../../assets/dot.svg";

const Spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderWrapper = styled.div<{
  $fullScreen?: boolean;
  $disableAbsolute?: boolean;
  $blur?: boolean;
}>`
  position: ${({ $fullScreen, $disableAbsolute }) => ($disableAbsolute ? "relative" : $fullScreen ? "fixed" : "absolute")};
  backdrop-filter: ${({ $blur }) => $blur && "blur(6px)"};
  width: ${({ $disableAbsolute }) => ($disableAbsolute ? "max-content" : "100%")} ;
  height: ${({ $fullScreen }) => ($fullScreen ? "100dvh" : "100%")};
  top: 0;
  left: 0;
  z-index: 9990;
`;
export const SpinnerContainer = styled.div<{
  $disableAbsolute?: boolean;
}>`
  position: ${({ $disableAbsolute }) => ($disableAbsolute ? "relative" : "absolute")} ;
  top: ${({ $disableAbsolute }) => ($disableAbsolute ? "0" : "50%")};
  left: ${({ $disableAbsolute }) => ($disableAbsolute ? "0" : "50%")};
  transform: ${({ $disableAbsolute }) => ($disableAbsolute ? "0" : "translate(-50%, -50%)")};
  z-index: 2;
`;

export const LoadingSpinner = styled.div`
  width: 56px;
  height: 56px;
  border: 2px solid ${({ theme }) => theme.primaryAction};
  border-top: 2px solid ${({ theme }) => theme.primaryBackground};
  border-radius: 50%;
  animation: 1.5s ${Spinner} linear infinite;
`;

export const Dots = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
align-items: center;
justify-content: center;
`


const Dot1 = keyframes`
  0% {
    transform: scale(1);
    opacity:1;
  }
  25% {
    transform: scale(2);
    opacity:0.4;
  }
  50% {
    transform: scale(1);
    opacity:0.4;
  }
  75% {
    transform: scale(1);
    opacity:0.4;
  }
  100% {
    transform: scale(1);
    opacity:1;
  }
`;

const Dot2 = keyframes`
  0% {
    transform: scale(1);
    opacity:0.4;
  }
  25% {
    transform: scale(1);
    opacity:1;
  }
  50% {
    transform: scale(2);
    opacity:0.4;
  }
  75% {
    transform: scale(1);
    opacity:0.4;
  }
  100% {
    transform: scale(1);
    opacity:0.4;
  }
`;

const Dot3 = keyframes`
  0% {
    transform: scale(1);
    opacity:0.4;
  }
  25% {
    transform: scale(1);
    opacity:0.4;
  }
  50% {
    transform: scale(1);
    opacity:1;
  }
  75% {
    transform: scale(2);
    opacity:0.4;
  }
  100% {
    transform: scale(1);
    opacity:0.4;
  }
`;

export const DotIcon = styled(Dot) <{
  $animation: 1 | 2 | 3
}>`
  fill: ${({ theme }) => theme.primaryBackgroundStrong};
  animation: 1.5s ${({ $animation }) => $animation === 1 ?
    Dot1 : $animation === 2 ?
      Dot2 : $animation === 3 ? Dot3 : ''} linear infinite;
`
