import React from "react";
import {
  CheckBoxWrapper,
  Container,
  HeadItem,
  HeadRow,
  Input,
  RowCell,
  TableRow,
} from "./styles";
import { CheckBox } from "../../../../Inputs/CustomCheckbox/CheckBox";
import { KeyRow } from "../../types/types";
import { defaultDataRow } from "../../data/mock";

type Props = {
  rows: KeyRow[];
  setRows: React.Dispatch<React.SetStateAction<KeyRow[]>>;
};

const KeysTable = ({ rows, setRows }: Props) => {
  const changeRowValue = (
    currentRowData: KeyRow,
    key: keyof KeyRow,
    value: string | boolean
  ) => {
    const newValue: KeyRow = {
      ...currentRowData,
      [key]: value,
      ...(currentRowData.default && {
        selected: true,
        id: rows.length - 1,
        default: false,
      }),
    };

    const newRowsData = rows.map((row) =>
      row.id === currentRowData.id ? newValue : row
    );

    setRows(
      currentRowData.default ? [...newRowsData, defaultDataRow] : newRowsData
    );
  };

  return (
    <Container>
      <HeadRow>
        <CheckBoxWrapper></CheckBoxWrapper>
        <HeadItem>Key</HeadItem>
        <HeadItem>Value</HeadItem>
        <HeadItem>Description</HeadItem>
      </HeadRow>
      {rows.map((item) => (
        <TableRow>
          <CheckBoxWrapper>
            <CheckBox
              onChange={(e) =>
                changeRowValue(item, "selected", e.target.checked)
              }
              checked={item.selected}
              disabled={item.default}
            />
          </CheckBoxWrapper>
          <RowCell>
            <Input
              placeholder="Key"
              value={item.key}
              onChange={(e) => changeRowValue(item, "key", e.target.value)}
            />
          </RowCell>
          <RowCell>
            <Input
              placeholder="Value"
              value={item.value}
              onChange={(e) => changeRowValue(item, "value", e.target.value)}
            />
          </RowCell>
          <RowCell>
            <Input
              placeholder="Description"
              value={item.description}
              onChange={(e) =>
                changeRowValue(item, "description", e.target.value)
              }
            />
          </RowCell>
        </TableRow>
      ))}
    </Container>
  );
};

export default KeysTable;
