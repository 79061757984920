import React, { ReactNode } from "react";
import {
  ButtonContainer,
  CustomButton,
  IconWrapper,
  VerticalLine,
} from "./styles";

type ButtonProps = {
  name?: string;
  onClick: (event?: any) => void;
  icon?: ReactNode;
  iconLeft?: ReactNode;
  variant:
    | "primary"
    | "primaryInvert"
    | "secondary"
    | "neutral"
    | "neutral-color"
    | "disabled"
    | "danger"
    | "ghost"
    | "black";
  disabled?: boolean;
  size: "xs" | "small" | "medium" | "large" | "full";
  round?: boolean;
  fullWidth?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  noTextWrap?: boolean;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
};

export const Button = ({
  name,
  onClick,
  icon,
  iconLeft,
  variant,
  type,
  disabled,
  size,
  fullWidth,
  round,
  onMouseLeave,
  onMouseEnter,
  noTextWrap,
}: ButtonProps) => {
  return (
    <ButtonContainer $full={size === "full"}>
      <CustomButton
        disabled={disabled}
        $disabled={disabled}
        $variant={variant}
        $size={size}
        $fullWidth={fullWidth}
        onClick={disabled ? () => {} : onClick}
        type={type}
        $round={round}
        $noTextWrap={noTextWrap}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        <IconWrapper $small={size === "small"}>
          {iconLeft}
          {name ? (
            <>
              {iconLeft ? <VerticalLine $variant={variant} /> : null}
              {name}
              {icon ? <VerticalLine $variant={variant} /> : null}
            </>
          ) : null}
          {icon}
        </IconWrapper>
      </CustomButton>
    </ButtonContainer>
  );
};
