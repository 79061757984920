import { FillLayer, LineLayer } from "mapbox-gl";

export const getLayerStyle = (
  paintColors: string[],
  source = "county"
): FillLayer => ({
  id: `${source}-fill-layer`,
  type: "fill",
  source,
  paint: {
    "fill-color": [
      "match",
      ["get", "id"],
      ...paintColors,
      "rgba(72, 70, 91, 0.15)",
    ],
    "fill-opacity": 1,
  },
});

export const getLayerStrokeStyle = (
  selectedId: number | string | null,
  showTooltip: boolean,
  source = "county"
): LineLayer => ({
  id: `${source}-stroke-layer`,
  type: "line",
  source,
  ...(showTooltip
    ? {
        paint: {
          "line-color": [
            "case",
            ["==", ["get", "id"], selectedId],
            "#000000",
            "#FFFFFF",
          ],
          "line-width": ["case", ["==", ["get", "id"], selectedId], 2, 1],
          "line-offset": ["case", ["==", ["get", "id"], selectedId], 1, 0],
        },
      }
    : {
        paint: {
          "line-color": "#FFFFFF",
          "line-width": 1,
        },
      }),
});
