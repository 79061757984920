import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { WidgetChartWrapper } from "../../Widgets";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getLayout,
  getPageSettings,
} from "../../../store/selectors/projects";
import { LAYOUTS } from "../config";
import { WIDGETS } from "../../Widgets/widgets";
import { requestPageKpis } from "../../../store/slices/projectPages";
import { KPISettingsWrapper } from "../../KPISettingsWrapper";
import { Loader } from "../../Loader";

export const Layout = () => {
  const dispatch = useDispatch();
  const pageWidgetsState = useSelector(getCurrentPageWidgets);
  const currentPage = useSelector(getPageSettings);
  const selectedLayout = useSelector(getLayout);
  const aiKpi = useSelector(getCurrentPageKpi);
  const [isFullTemplate, setIsFullTemplate] = useState(false);

  const pageWidgets = useMemo(() => pageWidgetsState, [pageWidgetsState]);
  
  const layout = useMemo(() => {
    let localLayout = LAYOUTS.find((r) => r.id === selectedLayout);
    if (!localLayout) {
      localLayout = LAYOUTS.find((r) => r.id === "3_8_c");
    }
    return localLayout;
  }, [selectedLayout]);

  const checkFullProperty = useCallback((obj: any) => {
    if (typeof obj !== "object" || obj === null) {
      return false;
    }
    if (obj.hasOwnProperty("full")) {
      return true;
    }
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (checkFullProperty(obj[key])) {
          return true;
        }
      }
    }
    return false;
  }, []);

  useEffect(() => {
    const newFullTemplate = checkFullProperty(layout?.arranging);
    setIsFullTemplate(newFullTemplate);
  }, [checkFullProperty, layout?.arranging]);

  const Column = ({
    size,
    children,
    scroll,
    isWidget,
    full = false,
    hasPadding = false,
  }: {
    size: number;
    scroll: boolean;
    isWidget: boolean;
    children: any;
    full?: boolean;
    hasPadding?: boolean;
  }) => {
    const [flexBasis, setFlexBasis] = useState(size);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setFlexBasis(12);
        } else {
          setFlexBasis(size);
        }
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, [size]);

    const width = (100 * flexBasis) / 12;

    let style: CSSProperties = {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: `${width}%`,
      gap: "16px",
      position: "relative",
      zIndex: 1,
    };
    if (full) {
      style = {
        ...style,
        width: "100%",
        position: "absolute",
        zIndex: 0,
      };
    }
    if (scroll) {
      style = {
        ...style,
        overflowY: "auto",
      };
    }
    if (hasPadding) {
      style = {
        ...style,
        padding: "0 16px",
      };
    }
    if (!isWidget) {
      style = {
        ...style,
        margin: "auto",
      };
    }
    return <div style={style}>{children}</div>;
  };

  const Row = ({ children, height }: { children: any; height?: string }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: height ? height : "100%",
        minHeight: "200px",
        justifyContent: "space-between",
        width: "100%",
        gap: "16px",
        position: "relative",
      }}
    >
      {children}
    </div>
  );

  const getKpis = useCallback(() => {
    if (currentPage?.id) {
      dispatch(
        requestPageKpis({
          pageId: currentPage?.id,
          includeData: true,
        })
      );
    }
  }, [currentPage?.id, dispatch]);

  useEffect(() => {
    getKpis();
  }, [currentPage?.id, getKpis]);

  const renderLayout = useCallback((arranging: any) => {
    return (
      <>
        {arranging?.rows &&
          arranging?.rows.map((row: any, rowIndex: number) => {
            if (layout?.id && ["3_8_c", "2_8_c"].includes(layout?.id)) {
              const processedRows = row.columns
                .map((column: any) => {
                  const isMainRow = column.scroll;
                  if (isMainRow) return column;

                  let widget =
                    column?.blockId &&
                    pageWidgets?.items?.find(
                      (w) =>
                        w.blockId && parseInt(w.blockId) === column?.blockId
                    );

                  if (column?.blockId === 1 && aiKpi?.count > 0) {
                    widget = true;
                  }

                  return widget ? column : null;
                })
                .filter((column: any) => column !== null);

              const isEmptyRow = processedRows.length === 0;
              if (isEmptyRow) return null;
            }

            return (
              <Row key={rowIndex} height={row.height}>
                {row.columns.map((column: any, colIndex: number) => {
                  const widget =
                    column?.blockId &&
                    pageWidgets?.items?.find(
                      (w) =>
                        w.blockId && parseInt(w.blockId) === column?.blockId
                    );
                  const ChartComponent =
                    widget && widget.chartType && WIDGETS[widget.chartType];

                  return (
                    <Column
                      key={colIndex}
                      size={column.size}
                      scroll={column.scroll}
                      full={
                        column.full &&
                        widget?.chartType === "mapChart" &&
                        widget.layout === "fullScreen"
                      }
                      isWidget={column.rows?.length > 0}
                      hasPadding={column.hasPadding}
                    >
                      {column.widgets ? (
                        column.blockId === 1 && aiKpi?.count > 0 ? (
                          <WidgetChartWrapper
                            storytelling={false}
                            map={false}
                            isFullTemplate={isFullTemplate}
                          >
                            <KPISettingsWrapper
                              recommended={false}
                              storytelling={false}
                              kpis={aiKpi.items}
                            />
                          </WidgetChartWrapper>
                        ) : widget && ChartComponent ? (
                          <WidgetChartWrapper
                            storytelling={false}
                            map={widget.chartType === "mapChart"}
                            isFullTemplate={
                              isFullTemplate && widget.chartType !== "mapChart"
                            }
                          >
                            <ChartComponent
                              key={colIndex}
                              storytelling={false}
                              currentWidget={widget}
                              isFullScreen={column.full}
                            />
                          </WidgetChartWrapper>
                        ) : null
                      ) : null}
                      {column.rows && renderLayout(column)}
                    </Column>
                  );
                })}
              </Row>
            );
          })}
      </>
    );
  }, [aiKpi?.count, aiKpi.items, isFullTemplate, layout?.id, pageWidgets?.items]);

  const renderedLayout = useMemo(() => {
    return layout && layout?.id && !!pageWidgets?.count
      ? renderLayout(layout?.arranging)
      : null
  }, [layout, pageWidgets?.count, renderLayout])

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        gap: "16px",
        height: "calc(100vh - 64px - 68px - 20px)",
        minHeight: !checkFullProperty(layout) ? "800px" : "auto",
      }}
      key={layout?.id}
    >
      {!renderedLayout || !pageWidgets.count || !layout || !layout?.arranging ? <Loader /> : renderedLayout}
    </div>
  );
};
