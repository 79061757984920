import styled from "styled-components";

import { fadeInDown } from "../../../styles";
import { Images } from "react-bootstrap-icons";


export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.neutralBackgroundActive};
  display: flex;
  align-items: center;
  z-index: 999;
  opacity: 0;
  animation: 0.4s ${fadeInDown} ease forwards;
`;

export const ModalWrapper = styled.div<{$isSelectedDatasetPreview?: boolean}>`
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border-radius: 12px;
  height: 97%;
  position: absolute;
  right: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
`;

export const SelectedDataSets = styled.div`
  height: 100%;
  width:500px;

  border-right: 1px solid ${({theme}) => theme.neutralBorder};
  display: flex;
  flex-direction: column;  
  gap: 15px;
`
export const SelectedDataSetsList = styled.div`
  height: 100%;
  overflow-y: auto;
  width:100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
`

export const SelectedDataSetsListHeader = styled.div`
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
  height: 68px;
  display: flex;
  align-items:center;
`

export const DataListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 28px 0 8px;
  color: #5F6877;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 0 20px 0 20px;
` 

export const ImagesIcon = styled(Images)`
  width: 16px;
  height: 16px;
`


export const DatasetsButtons = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px ;
`

export const ContentContainer = styled.section`
  width: 100%;
  display: flex;
  overflow: hidden;
  height: 100%;
`

export const TablesHeader = styled.div`
  padding: 16px 20px;
  height: 68px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
  display: flex;
  justify-content: space-between;
  align-items:center;
`

export const TablesContent = styled.div`
  overflow-y: scroll;
  scrollbar-width: thin;
`
export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const TablesContainer = styled.div`
  width: 100%; 
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  background-color: #F8F8F9;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 20px;
  overflow: auto;
  position: relative;
`;

export const CheckBoxWrapper = styled.div`
  margin-right: 8px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
  align-items: center;
`

export const DatasetOverviewLoaderWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
`;
