import { styled } from "styled-components";

export const DPAContainer = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid rgb(211, 219, 227);
  background-color: white;
`;

export const DPAHeader = styled.h6`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
  margin-bottom: 12px;
`;

export const DPADescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-bottom: 8px;
`;
