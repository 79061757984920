export const enum MessageTypeEnum {
  ANALYZE = "analyze",
  ANALYZE_SUMMARY = "analyze_summary",
  UPLOAD = "upload",
  GET_TEST = "get_test",
  ABOARD = "aboard",
  SYSTEM = "_system",
  APPLY = "apply",
  EXPLORE = "explore"
}

export const enum MessageTextTypeEnum {
  TITLE = "title",
  POINT = "point",
  SYSTEM = "system_text",
  PROMPT = "prompt_list",
  TEST_SUMMARY = "test_summary",
  TEXT = "text",
  TEST = "test"
}

export const enum MessageStatus {
  COMPLETE = "complete",
  NOT_COMPLETE = "not complete"
}