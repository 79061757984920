import { useSelector } from "react-redux";
import { Button } from "../../../../Button";
import { ImagesIcon } from "../../../CreateProject/components/RealEstate/style";
import {
  DataResourcesButtonsContainer,
  DataResourcesContainer,
  DataResourcesHeader,
  DataResourcesHeading,
  DataResourcesItem,
  DataResourcesSubItem,
} from "./styles";
import { CheckCircleFill, Table } from "react-bootstrap-icons";
import { getDraftProjectFiles } from "../../../../../store/selectors/datasets";
import { IFile } from "../../../../../models/Projects";
import { useEffect, useState } from "react";

type DataResourcesProps = {
  onClose: () => void;
  onDone: () => void;
  onSelectFile: (file: IFile) => void;
};

const DataResources = ({
  onClose,
  onDone,
  onSelectFile,
}: DataResourcesProps) => {
  const [selectedItem, setSelectedItem] = useState<IFile>();
  const uploadedFiles = useSelector(getDraftProjectFiles);

  useEffect(() => {
    if (uploadedFiles?.length > 0) {
      // Set the first file as selected by default
      setSelectedItem(uploadedFiles[0]);
      onSelectFile(uploadedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);

  return (
    <DataResourcesContainer>
      <DataResourcesHeader>
        <DataResourcesHeading>Data Resources</DataResourcesHeading>
      </DataResourcesHeader>
      <div>
        <DataResourcesItem style={{ marginTop: "20px" }}>
          <Table />
          Datasets:
        </DataResourcesItem>
        <DataResourcesSubItem>LTSS data</DataResourcesSubItem>

        <DataResourcesItem>
          <ImagesIcon />
          Files:
        </DataResourcesItem>
        {uploadedFiles.map((file, idx) => (
          <DataResourcesSubItem
            active={selectedItem?.name === file.name}
            onClick={() => {
              onSelectFile(file);
              setSelectedItem(file);
            }}
            key={idx}
          >
            {file.name}
          </DataResourcesSubItem>
        ))}
      </div>

      <DataResourcesButtonsContainer>
        <Button
          size="medium"
          variant="neutral"
          type="button"
          name="Cancel"
          onClick={onClose}
        />
        <Button
          size="medium"
          variant="secondary"
          type="button"
          name="Done"
          icon={<CheckCircleFill />}
          onClick={() => {
            // save data and close modal
            onDone();
            onClose();
          }}
        />
      </DataResourcesButtonsContainer>
    </DataResourcesContainer>
  );
};

export default DataResources;
