import React, { useState } from "react";
import {
  CloseIcon,
  ModalFooterButtons,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  OverviewContent,
} from "../styles";
import {
  OverviewContentWrapper,
  ConnectOURAModal,
  InputWrapper,
  RenameInputHeader,
  RenameInput,
  Required,
} from "./styles";
import { Button } from "../../Button";
import { Check, InfoCircle } from "react-bootstrap-icons";
import ConnectLoader, { ConnectLoaderStatus } from "../../ConnectLoader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getModalData } from "../../../store/selectors/modals";
import { IValidateAppCredentials } from "../../../api/app-api";
import {
  requestAppValidate,
  requestGetAppEndpoints,
} from "../../../store/slices/dataProviders";
import { getFields } from "./utils/fields";
import { requestCreateDataset } from "../../../store/slices/datasets";

interface ModalProps {
  onClose: () => void;
}

const ConnectDeviceModal = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();

  const dataModal = useSelector(getModalData);

  const [status, setStatus] = useState<ConnectLoaderStatus>("idle");

  const [connectAppCredentials, setConnectAppCredentials] =
    useState<IValidateAppCredentials>({
      service: "",
      credentials: "",
    });

  const deviceName =
    "deviceName" in dataModal ? String(dataModal.deviceName) : "OURA";

  const handleConnect = () => {
    if (status === "success") {
      onClose();
      return;
    }
    setStatus("loading");

    dispatch(
      requestAppValidate({
        ...connectAppCredentials,
        callbacks: {
          onSuccess: (data) => {
            if (data) {
              dispatch(
                requestGetAppEndpoints({
                  service: connectAppCredentials.service,
                  callbacks: {
                    onSuccess: async (data) => {
                      const { fields, fieldsAi, fieldsIds } = await getFields(
                        data
                      );
                      dispatch(
                        requestCreateDataset({
                          name: connectAppCredentials.service,
                          filePath: "app",
                          fields,
                          fieldsAi,
                          source: "app",
                          sourceType: "database",
                          requestConfig: {
                            variables: fieldsIds,
                            authorization: {
                              token: connectAppCredentials.credentials,
                            },
                          },
                          callbacks: {
                            onSuccess: () => {
                              setStatus("success");
                            },
                            onError: () => {
                              setStatus("success");
                            },
                          },
                        })
                      );
                    },
                    onError: () => {
                      setStatus("idle");
                    },
                  },
                })
              );
            } else {
              setStatus("idle");
            }
          },
        },
      })
    );
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ConnectOURAModal onClick={(e) => e.stopPropagation()}>
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalHeading>Connecting {deviceName}</ModalHeading>
          <CloseIcon onClick={onClose} />
        </ModalHeadingWrapper>

        <OverviewContent>
          {status !== "idle" ? (
            <ConnectLoader
              status={status}
              onLoadingText={[
                "Establishing a secure connection and retrieving data.",
                "Please wait...",
              ]}
              succefullLoadingText={[
                "Connection has been established successfully.",
              ]}
            />
          ) : (
            <OverviewContentWrapper>
              <InputWrapper>
                <RenameInputHeader>
                  Dataset Name <Required>*</Required>
                </RenameInputHeader>
                <RenameInput
                  value={connectAppCredentials.service}
                  onChange={(e) =>
                    setConnectAppCredentials({
                      ...connectAppCredentials,
                      service: e.target.value,
                    })
                  }
                  placeholder="Enter dataset Name"
                />
              </InputWrapper>
              <InputWrapper>
                <RenameInputHeader>
                  Token <Required>* </Required>
                  <InfoCircle />
                </RenameInputHeader>
                <RenameInput
                  value={connectAppCredentials.credentials}
                  onChange={(e) =>
                    setConnectAppCredentials({
                      ...connectAppCredentials,
                      credentials: e.target.value,
                    })
                  }
                  placeholder="Enter token here"
                />
              </InputWrapper>
            </OverviewContentWrapper>
          )}
        </OverviewContent>
        {status !== "success" && (
          <ModalFooterWrapper>
            <Button
              name="Cancel"
              onClick={onClose}
              variant="neutral"
              size="medium"
            />
            <ModalFooterButtons>
              <Button
                name={status === "loading" ? "Done" : "Send Request"}
                onClick={() => handleConnect()}
                type="submit"
                variant={status === "loading" ? "disabled" : "secondary"}
                disabled={status === "loading"}
                size="medium"
                icon={<Check />}
              />
            </ModalFooterButtons>
          </ModalFooterWrapper>
        )}
      </ConnectOURAModal>
    </ModalOverlay>
  );
};

export default ConnectDeviceModal;
