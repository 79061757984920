import { getDatasetField } from "../../../../helpers/getDatasetField";
import { DatasetDTO } from "../../../../models/Datasets";

export const determineMapTypeFromSubtypes = (
  dataKeys: string[],
  datasets: DatasetDTO[]
): string => {
  const defaultMapType = "county";
  const availableMapTypes = ["county", "state", "country"]; // from lowest to highest level

  if (!dataKeys?.length || !datasets?.length) {
    return defaultMapType;
  }

  const mapDataSubtypes = dataKeys
    ?.map((key) => {
      const field = getDatasetField(String(key), datasets);

      if (!field || field.type !== "location") {
        return "";
      }

      return field.subtype;
    })
    .filter(Boolean);

  if (!mapDataSubtypes.length) {
    return defaultMapType;
  }

  const foundMapType = availableMapTypes.find((type) =>
    mapDataSubtypes.includes(type)
  );

  return foundMapType || defaultMapType;
};
