import styled from "styled-components";

export const SwitchWrapper = styled.div`
  height: 36px;
  position: relative;
  display: flex;
  border: 1px solid #CFD2D6;
  background-color: #444B570A;
  border-radius: 50px;
`;

export const Slider = styled.div<{$margin?: number}>`
  position: absolute;
  ${({$margin}) => !!$margin && `transform: translateX(${$margin}px);`};
  padding: 10px 14px;
  transition: 0.3s;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  background-color: transparent;
  color: #515967;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  user-select: none;
  cursor: pointer;
  text-transform: capitalize;
  background-color: ${({theme}) => theme.neutralBackgroundBase};
  color: #2C33C8;
  font-weight: 600;
  box-shadow: 0px 2px 2px 0px rgba(19, 22, 29, 0.06), 0px 2px 4px 1px rgba(19, 22, 29, 0.04);
`

export const SwitchOption = styled.div`
  padding: 10px 14px;
  transition: 0.3s;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  background-color: transparent;
  color: #515967;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  user-select: none;
  cursor: pointer;
  text-transform: capitalize;
`;