import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const BadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  cursor: pointer;
`;

export const SeparatorSpan = styled.span`
  color: ${({ theme }) => theme.neutralTextWeak};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const CreateButtonWrapper = styled.div`
  gap: 4px;
  display: flex;
  width: max-content;
  user-select: none; 
  font-size: 14px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const CreateButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryText};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const ModalContentWrapper = styled.div<{$isDatasetSelected: boolean}>`
  width: ${({$isDatasetSelected}) => $isDatasetSelected? "704px" : "100%" };
  border-right: 1px solid ${({theme, $isDatasetSelected}) => $isDatasetSelected ? theme.neutralBorder : "none"};
`

export const ModalWrapperContent = styled.div`
  display: flex;
  height: 100%;
`