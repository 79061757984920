import { SequentialColors } from "../colors"
import { styleTypes } from "../types/types"

interface IGetSequentialColors {
  id: string,
  colors: {
    key: string;
    hex: string;
  }[]
}

export const getSequentialColors = (styleId: string): IGetSequentialColors[] => {
  const palettes = SequentialColors[styleId as styleTypes]
  
  return palettes
}

export const getSequentialColorsHex = (styleId: string, paletteId?: string, disableColors?: boolean): string[] => {

  if(disableColors) {
    return ["#939BA7", "#939BA7", "#939BA7", "#939BA7", "#939BA7", "#939BA7", "#939BA7"]
  }

  const palettes = SequentialColors[styleId as styleTypes]  
  const palette =  palettes.find((item) => item.id === paletteId)

  if(!palette) {
    return palettes[0].colors.map((item) => item.hex)
  }
  return palette.colors.map((item) => item.hex)
}
