import { formatDatetimeTypeLabel } from "./formatDatetimeTypeLabel";
import { formatMetricTypeLabel } from "./formatMetricTypeLabel";

export const formatLabelValue = (
  value: string | number | Date,
  type?: string,
  subtype?: string
): string => {
  if (!value) {
    return "";
  }
  const strValue = String(value);
  if (!strValue) {
    return "";
  }

  if (!type || !subtype) {
    return strValue;
  }

  if (type === "metric" && typeof value === "number" && isFinite(value)) {
    return formatMetricTypeLabel(value, subtype);
  }

  if (
    type === "datetime" &&
    (typeof value === "string" ||
      typeof value === "number" ||
      value instanceof Date)
  ) {
    return formatDatetimeTypeLabel(value, subtype);
  }

  return strValue.length > 10 ? strValue.slice(0, 10) : strValue;
};
