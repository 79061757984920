import styled from "styled-components";

export const UploadedItemsHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
  padding: 8px 22px;
  display: flex;

`

export const FileName = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: ${({theme}) => theme.neutralWeak};
`

export const ConnectAppApiWrapper = styled.div`
  flex-direction: column;
  padding: 0 20px 20px;
  display: flex;
  gap: 16px;
`

export const ConnectAppApiHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
`

export const ConnectHeaderText = styled.div`
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  color: #21262E;
`

export const ConnectButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const Categories = styled.div`
  display: flex;
  gap: 8px;
`

export const Devices = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(223px, 100%), 1fr));
  gap: 8px;
`

export const SendRequest = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  border-radius: 8px;
  border: 1px dashed #7D82F8;
`

export const SendRequestText = styled.div`
  color: #515967;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`