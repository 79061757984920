import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  CloseIcon,
  ModalContent,
  ModalFooterButtons,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { useSelector } from "react-redux";
import { getModalData } from "../../../store/selectors/modals";
import {
  ChartBackground,
  ClassesHead,
  ContentWrapper,
  FeedBackBlock,
  FeedBackHeader,
  FeedBackHeadingWrapper,
  FeedBackOption,
  FeedBackOptions,
  FeedBackOptionText,
  FeedBackSubHead,
  OptionWrapper,
} from "./styled";
import { Button } from "../../Button";
import {
  HandThumbsDown,
  HandThumbsDownFill,
  HandThumbsUp,
  HandThumbsUpFill,
  SendFill,
} from "react-bootstrap-icons";

import NegativeOptions from "./data/user_feedback_v2.json";
import OptionsList from "./components/OptionsList";

import { WidgetWrapper } from "../../Widgets/VerticalBarchart/styles";
import {
  IFeedBack,
  requestCreateFeedBack,
} from "../../../store/slices/feedback";
import {
  getCurrentPageId,
  getCurrentProject,
} from "../../../store/selectors/projects";
import { requestUploadSimpleFile } from "../../../store/slices/files";
import { Loader } from "../../Loader";
import FinishPage from "./components/FinishPage";

type Props = {
  onClose: () => void;
};

const FeedBackModal: React.FC<Props> = ({ onClose = () => {} }) => {
  const dispatch = useDispatch();
  const data = useSelector(getModalData);
  const project = useSelector(getCurrentProject);
  const { settings } = useSelector(getCurrentPageId);
  const widgetId = String(data.component.id);

  const [feedback, setFeedBack] = useState<IFeedBack>({
    pageId: String(settings.id) ?? "",
    projectId: project.id,
    widgetId,
    overallExperience: "",
    dataErrors: [{ issues: [] }],
    chartDesignErrors: [{ issues: [] }],
    technicalErrors: [{ issues: [] }],
    userInteractionErrors: [{ issues: [] }],
    dataIntegrityErrors: [{ issues: [] }],
    aestheticIssues: [{ issues: [] }],
    additionalComments: "",
    screenshot: "",
  });
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinish, setIsFinish] = useState(false);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const serverUpload = (image: any) => {
    if (image) {
      setIsLoading(true);
      const filename = widgetId + ".jpg";
      const byteString = atob(image.split(",")[1]);

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;

        const maxWidth = 1024;
        const maxHeight = 568;

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (compressedBlob) => {
            const compressedFile = new File([compressedBlob!], filename, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            dispatch(
              requestUploadSimpleFile({
                files: [compressedFile],
                callbacks: {
                  onSuccess: (resp: any) => {
                    const uploadedFile = resp?.at(0);
                    if (uploadedFile) {
                      dispatch(
                        requestCreateFeedBack({
                          feedback: {
                            ...feedback,
                            screenshot: uploadedFile.path,
                          },
                          callbacks: {
                            onSuccess() {
                              setIsFinish(true);
                              setIsLoading(false);
                            },
                          },
                        })
                      );
                    }
                  },
                  onError: () => {
                    setIsLoading(false);
                  },
                },
              })
            );
          },
          "image/jpeg",
          1
        );
      };

      img.src = image;
    }
  };

  const handleSendFeedback = () => {
    serverUpload(data.photo);
  };

  const handleFeedBackOption = (option: string) => {
    const isPositive = option === "positive";
    const isNegative = option === "negative";

    setFeedBack({
      ...feedback,
      overallExperience: feedback.overallExperience === option ? "" : option,
      ...(isPositive && {
        dataErrors: [],
        technicalErrors: [],
        aestheticIssues: [],
        chartDesignErrors: [],
        dataIntegrityErrors: [],
        userInteractionErrors: [],
        additionalComments: "",
      }),
      ...(isNegative && {
        additionalComments: "",
      }),
    });
  };

  const checkIfDisabled = (): boolean => {
    if (feedback.overallExperience === "positive") return false;

    if (feedback.overallExperience === "negative") {
      const errorCategories = [
        feedback.dataErrors[0]?.issues,
        feedback.chartDesignErrors[0]?.issues,
        feedback.technicalErrors[0]?.issues,
        feedback.userInteractionErrors[0]?.issues,
        feedback.dataIntegrityErrors[0]?.issues,
        feedback.aestheticIssues[0]?.issues,
      ];

      const count = errorCategories.reduce((total, category) => {
        const length = Array.isArray(category) ? category.length : 0;
        return total + length;
      }, 0);

      const comment = feedback.additionalComments.length;

      return !(count + comment);
    }
    return true;
  };

  return (
    <>
      <ModalOverlay onClick={handleOnClose} $isClosing={isClosing}>
        <ModalWrapper
          onClick={(e) => e.stopPropagation()}
          $isClosing={isClosing}
        >
          <ModalHeadingWrapper>
            <ModalHeading>{"Send Feedback"}</ModalHeading>
            <CloseIcon onClick={handleOnClose} />
          </ModalHeadingWrapper>
          {isFinish ? (
            <FinishPage handleOnClose={handleOnClose} />
          ) : (
            <>
              <OverviewContent>
                {isLoading && <Loader />}

                <ModalContent>
                  <ContentWrapper>
                    <ChartBackground>
                      <WidgetWrapper
                        style={{ maxWidth: "800px", width: "min-content" }}
                      >
                        <img src={data.photo} alt="screen" />
                      </WidgetWrapper>
                    </ChartBackground>
                    <FeedBackBlock>
                      <FeedBackHeadingWrapper>
                        <FeedBackHeader>
                          How was your overall experience?
                        </FeedBackHeader>
                        <FeedBackSubHead>
                          It will help us to serve you better.
                        </FeedBackSubHead>
                      </FeedBackHeadingWrapper>
                      <FeedBackOptions>
                        <FeedBackOption
                          $active={feedback.overallExperience === "positive"}
                          $isPositive={true}
                          onClick={() => handleFeedBackOption("positive")}
                        >
                          {feedback.overallExperience === "positive" ? (
                            <HandThumbsUpFill />
                          ) : (
                            <HandThumbsUp />
                          )}
                          <FeedBackOptionText>Positive</FeedBackOptionText>
                        </FeedBackOption>
                        <FeedBackOption
                          $active={feedback.overallExperience === "negative"}
                          $isNegative={true}
                          onClick={() => handleFeedBackOption("negative")}
                        >
                          {feedback.overallExperience === "negative" ? (
                            <HandThumbsDownFill />
                          ) : (
                            <HandThumbsDown />
                          )}
                          <FeedBackOptionText>Negative</FeedBackOptionText>
                        </FeedBackOption>
                      </FeedBackOptions>

                      {feedback.overallExperience === "positive" && (
                        <OptionWrapper>
                          <OptionsList
                            other
                            feedback={feedback}
                            setFeedBack={setFeedBack}
                          />
                        </OptionWrapper>
                      )}

                      {feedback.overallExperience === "negative" && (
                        <OptionWrapper>
                          <OptionsList
                            other
                            feedback={feedback}
                            setFeedBack={setFeedBack}
                          />
                          <FeedBackHeadingWrapper>
                            <ClassesHead>
                              Choose one or more options below.
                            </ClassesHead>
                          </FeedBackHeadingWrapper>
                          {NegativeOptions.classes.map((item) => (
                            <OptionsList
                              item={item}
                              feedback={feedback}
                              setFeedBack={setFeedBack}
                            />
                          ))}
                        </OptionWrapper>
                      )}
                    </FeedBackBlock>
                  </ContentWrapper>
                </ModalContent>
              </OverviewContent>
              <ModalFooterWrapper>
                <Button
                  name="Cancel"
                  onClick={handleOnClose}
                  variant="neutral"
                  size="medium"
                />
                <ModalFooterButtons>
                  <Button
                    name={"Send Feedback"}
                    onClick={handleSendFeedback}
                    type="submit"
                    variant={checkIfDisabled() ? "disabled" : "secondary"}
                    disabled={checkIfDisabled()}
                    size="medium"
                    icon={<SendFill style={{ transform: "rotateZ(40deg)" }} />}
                  />
                </ModalFooterButtons>
              </ModalFooterWrapper>
            </>
          )}
        </ModalWrapper>
      </ModalOverlay>
    </>
  );
};

export default FeedBackModal;
