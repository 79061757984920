import { MarkersVisualisationDataDto } from "../../../../models/Widgets";

interface FeatureRegionProperties {
  featureProperties: GeoJSON.GeoJsonProperties;
  layerData: MarkersVisualisationDataDto[];
  mapType?: string;
  field?: string;
  timePeriod?: string;
}

export const getRegionLayerData = ({
  featureProperties,
  layerData,
  mapType,
  field,
  timePeriod,
}: FeatureRegionProperties): MarkersVisualisationDataDto | undefined => {
  if (!featureProperties || !layerData || !mapType) {
    return undefined;
  }

  const { COUNTY_STATE_NAME, NAME } = featureProperties;

  const matchFieldTimePeriod = (el: MarkersVisualisationDataDto): boolean => {
    return field && timePeriod
      ? el[field as keyof MarkersVisualisationDataDto] === timePeriod
      : true;
  };

  const findMatch = (
    predicate: (el: MarkersVisualisationDataDto) => boolean
  ) => {
    return layerData.find((el) => predicate(el) && matchFieldTimePeriod(el));
  };

  switch (mapType) {
    case "county": {
      const [county, state] = COUNTY_STATE_NAME.split(", ");
      return findMatch((el) => el?.county === county && el?.state === state);
    }
    case "state": {
      return findMatch((el) => el?.state === NAME);
    }
    case "country": {
      return findMatch((el) => el?.country === NAME);
    }
    default:
      return undefined;
  }
};
