import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Source, Layer, FillLayer } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";

import { MapLegend } from "../../MapLegend";
import { setActiveModal } from "../../../store/slices/modals";
import {
  getCurrentProjectData,
  getPageSettings,
} from "../../../store/selectors/projects";
import { colorsPalettes } from "../../../constants";
import { MapYear } from "../../MapYear";
import {
  MarkersVisualisationDataDto,
  WidgetItem,
} from "../../../models/Widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { ColorRangeI } from "../../../models/Pages";
import { Loader } from "../../Loader";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  SettingsButtonWrapper,
} from "../VerticalBarchart/styles";
import {
  DisableBuildingsWrapper,
  LoaderWrapper,
  MapHeader,
  MapWrapper,
  SelectBageBlock,
} from "./styles";
import { getLayerStrokeStyle, getLayerStyle } from "./utils/getLayerStyle";
import { hexToRGBA } from "../../../helpers/hexToRgba";
import { generateBreakPoints } from "../../../helpers/generateBreakPoints";
import { SelectBage } from "../SelectBage";
import { calculateValuesAndSum } from "./utils/calculateValuesAndSum";
import { getYears } from "./utils/getYears";
import { getLayerColor } from "./utils/getLayerColor";
import { getGeoJsonData } from "./utils/getGeoJsonData";
import { Popup, PopupDataProps } from "./components/Popup";
import { getRegionFeatureById } from "./utils/getRegionFeatureById";
import { getValueWithDecimals } from "./utils/getValueWithDecimals";
import SwitchComponent from "../../Inputs/CustomSwitch/Switch";
import { changeLayerVisibility } from "./utils/disableLayer";
import { setIsDraggingDisabled } from "../../../store/slices/pageContentGenerator";
import { getGeoJsonForMapType } from "./utils/getGeoJsonForMapType";
import { getRegionLayerData } from "./utils/getRegionLayerData";
import { getRegionNameFromFeatureProperties } from "./utils/getRegionNameFromFeatureProperties";
import { getRegionId } from "./utils/getRegionId";
import { getRegionIdFromFeatureProperties } from "./utils/getRegionIdFromFeatureProperties";
import { getRegionNameFromLayerData } from "./utils/getRegionNameFromLayerData";
import { determineMapTypeFromSubtypes } from "./utils/determineMapTypeFromSubtypes";

//@ts-ignore
import { useScreenshot } from "use-react-screenshot";
import { openFeedBackModal } from "../utils/feedback";
import { FeedBackButton, WidgetImageWrapper } from "../styles";
import { getAvailableWidgetTypes } from "../widgetHelpers";
import { calculateFeatureCenter } from "./utils/calculateFeatureCenter";

interface MapChartInterface {
  showSettings?: boolean;
  showLegend?: boolean;
  recommended?: boolean;
  selected?: boolean;
  isFullScreen?: boolean;
  currentWidget: WidgetItem;
  explore?: boolean;
  exploreYear?: number;
  exploreRegion?: string;
  exploreLat?: number;
  exploreLng?: number;
  exploreRegionId?: string;
}

export const UniversalMap = ({
  showSettings = true,
  currentWidget,
  recommended,
  selected,
  explore,
  exploreYear,
  exploreRegion,
  exploreLat,
  exploreLng,
  exploreRegionId,
}: MapChartInterface) => {
  const dispatch = useDispatch();

  const widgetRef = useRef(null);
  const mapRef = useRef<any>();
  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [ref, bounds] = useMeasure();

  const colorPalette = colorsPalettes.find(
    (palette) => palette.id === (styleId || "default")
  );
  const colorPaletteVariations = colorPalette?.colors[0].variations;

  const datasets = useSelector(getCurrentProjectData)?.datasets;
  const geoSpatialDataKeys = currentWidget?.layers?.at(0)?.geospatialData || [];
  const mapType = determineMapTypeFromSubtypes(geoSpatialDataKeys, datasets);
  const mapTypeGeoJson: GeoJSON.GeoJsonProperties =
    getGeoJsonForMapType(mapType);

  const [layerStyle, setLayerStyle] = useState<FillLayer>(
    getLayerStyle([], mapType)
  );
  const [layerData, setLayerData] = useState<MarkersVisualisationDataDto[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [year, setYear] = useState<string | undefined>();
  const [yearProperty, setYearProperty] = useState<string>("year");
  const [valueProperty, setValueProperty] = useState<string>("value");
  const [average, setAverage] = useState<number | undefined>();
  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const [popupData, setPopupData] = useState<
    | (PopupDataProps & {
        latitude?: number;
        longitude?: number;
      })
    | null
  >(null);

  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [values, setValues] = useState<number[]>([]);
  const [leftSlider, setLeftSlider] = useState(0);
  const [rightSlider, setRightSlider] = useState(6);
  const [disableBuildings, setDisableBuildings] = useState<boolean>(false);
  const [feedbackState, setFeedbackState] = useState<boolean>(false);

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  useEffect(() => {
    if (feedbackState && widgetRef.current && !image) {
      takeScreenShot(widgetRef.current).then((image: any) =>
        openFeedBackModal({
          dispatch,
          currentWidget,
          image,
          setFeedbackState,
        })
      );
    } else if (feedbackState && image) {
      openFeedBackModal({
        dispatch,
        currentWidget,
        image,
        setFeedbackState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackState]);

  const id = useMemo(
    () => (recommended ? "recommended_map" : explore ? "explore_map" : "map"),
    [recommended, explore]
  );

  const generateColorRanges = useCallback(() => {
    if (colorPaletteVariations?.length && values.length) {
      const colors = colorPaletteVariations.slice().reverse();
      const sortedValues = [...new Set(values)].sort();
      const steps = generateBreakPoints(sortedValues);
      const newColorRanges = steps?.map((c, index) => {
        const end = index === steps.length - 1 ? c * 3 : steps[index + 1];
        return {
          color:
            index >= leftSlider && index <= rightSlider
              ? colors[index]
              : "#E0DDDC",
          start: parseInt(c.toString()),
          end: parseInt(end.toString()),
        };
      });
      setColorRanges(newColorRanges);
    }
  }, [colorPaletteVariations, values, leftSlider, rightSlider]);

  useEffect(() => {
    if (mapRef?.current) {
      const mapInstance = mapRef?.current;
      mapInstance.resize();
    }
  }, [bounds.height, bounds.width]);

  useEffect(() => {
    if (colorPaletteVariations?.length) generateColorRanges();
  }, [colorPaletteVariations, values, generateColorRanges]);

  useEffect(() => {
    if (!currentWidget) {
      return;
    }

    const valueKey =
      currentWidget?.layers?.at(0)?.arrangeByMetric?.at(0) || "value";
    const layerData = currentWidget?.layers?.at(0)?.data || [];
    const timePeriod = currentWidget?.layers?.at(0)?.timePeriod;
    setYearProperty(timePeriod?.field || "year");
    setValueProperty(valueKey);
    const { values, sum } = calculateValuesAndSum(layerData, valueKey);
    setValues(values);
    setAverage(sum / (layerData.length || 1));
    setLayerData(layerData);
    getYears(layerData, timePeriod, setYear, setYears);
  }, [currentWidget]);

  const geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(
    () =>
      mapTypeGeoJson
        ? getGeoJsonData(mapTypeGeoJson, mapType)
        : { type: "FeatureCollection", features: [] },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapTypeGeoJson]
  );

  const clearPopupData = () => {
    setSelectedRegion(null);
    setPopupData(null);
  };

  useEffect(() => {
    if (explore && geoJsonData) {
      setSelectedRegion(exploreRegionId || "");
    }
  }, [explore, exploreRegionId, geoJsonData]);

  useEffect(() => {
    const field: keyof MarkersVisualisationDataDto =
      (yearProperty as keyof MarkersVisualisationDataDto) || "year";
    const filteredData = layerData?.filter(
      (d) =>
        d[field] === (exploreYear || year) &&
        (exploreRegion
          ? exploreRegion === getRegionNameFromLayerData(d, mapType)
          : true)
    );

    if (!(filteredData && colorRanges.length)) {
      return;
    }

    const stylesMap: Record<string, string> = {};

    filteredData.forEach((region) => {
      const id = getRegionId(region, mapTypeGeoJson, mapType);
      const color = getLayerColor(
        colorRanges,
        region,
        valueProperty as keyof MarkersVisualisationDataDto
      );

      if (stylesMap[id]) {
        return;
      }

      stylesMap[id] = hexToRGBA(color, 1) as string;
    });

    const styles = Object.entries(stylesMap).flat();

    setLayerStyle(getLayerStyle(styles, mapType));
  }, [
    year,
    layerData,
    explore,
    colorRanges,
    exploreYear,
    exploreRegion,
    yearProperty,
    valueProperty,
    mapType,
    mapTypeGeoJson,
  ]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();
    if (!map) {
      return;
    }

    if (exploreRegionId) {
      const regionFeature = getRegionFeatureById(
        exploreRegionId,
        mapTypeGeoJson,
        mapType
      );

      if (regionFeature) {
        const { minLat, minLng, maxLat, maxLng } =
          calculateFeatureCenter(regionFeature);

        map.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 16, duration: 750 }
        );
      }
    }

    map.on("load", () => {
      changeLayerVisibility("building (1)", map);
      changeLayerVisibility("country-label", map);
    });

    if (explore) {
      return;
    }

    const onMouseEnter = (e: any) => {
      if (!showTooltip || recommended) {
        return;
      }

      const features = map.queryRenderedFeatures(e.point, {
        layers: [`${mapType}-fill-layer`],
      });

      if (!features?.length || !features[0] || !layerData?.length) {
        return;
      }

      const feature = features[0];
      const featureProperties = getRegionFeatureById(
        feature.properties.id,
        mapTypeGeoJson,
        mapType
      )?.properties;

      if (!featureProperties) {
        setSelectedRegion(null);
        return;
      }

      const foundRegionLayerData = getRegionLayerData({
        featureProperties,
        layerData,
        mapType,
      });

      if (!foundRegionLayerData) {
        setSelectedRegion(null);
        return;
      }

      const regionId = getRegionIdFromFeatureProperties(
        featureProperties,
        mapType
      );
      setSelectedRegion(regionId);
    };

    const onMouseLeave = () => {
      if (!showTooltip || recommended) {
        return;
      }

      if (!popupData || !Object.keys(popupData).length) {
        clearPopupData();
      }
    };

    const onMouseClick = (e: any) => {
      if (!showTooltip || recommended) {
        return;
      }

      const features = map.queryRenderedFeatures(e.point, {
        layers: [`${mapType}-fill-layer`],
      });

      if (!features?.length || !features[0] || !layerData?.length) {
        return;
      }

      const feature = features[0];

      if (popupData && popupData.regionId === feature.properties.id) {
        return setPopupData(null);
      }

      const regionFeature = getRegionFeatureById(
        feature.properties.id,
        mapTypeGeoJson,
        mapType
      );

      const featureProperties = regionFeature?.properties;

      if (!featureProperties) {
        clearPopupData();
        return;
      }

      const field: keyof MarkersVisualisationDataDto =
        (yearProperty as keyof MarkersVisualisationDataDto) || "year";

      const regionLayerData = getRegionLayerData({
        featureProperties,
        layerData,
        mapType,
        field,
        timePeriod: year || "2017",
      });

      if (!regionLayerData) {
        clearPopupData();
        return;
      }

      const regionId = getRegionIdFromFeatureProperties(
        featureProperties,
        mapType
      );
      setSelectedRegion(regionId);

      const { centerLng, centerLat } = calculateFeatureCenter(feature);

      if (!centerLng || !centerLat) {
        clearPopupData();
        return;
      }

      const fieldValue: keyof MarkersVisualisationDataDto =
        (valueProperty as keyof MarkersVisualisationDataDto) || "value";

      const popupInfo = {
        regionId: feature.properties.id,
        title: getRegionNameFromFeatureProperties(featureProperties, mapType),
        regionName: getRegionNameFromLayerData(regionLayerData, mapType),
        value: getValueWithDecimals(regionLayerData, fieldValue),
        year: regionLayerData[field],
      };

      setPopupData({
        ...popupInfo,
        x: e.point.x,
        y: e.point.y,
        latitude: centerLat,
        longitude: centerLng,
      });
    };

    map.on("mousemove", `${mapType}-fill-layer`, onMouseEnter);
    map.on("mouseleave", `${mapType}-fill-layer`, onMouseLeave);
    map.on("click", `${mapType}-fill-layer`, onMouseClick);

    return () => {
      map.off("mousemove", `${mapType}-fill-layer`, onMouseEnter);
      map.off("mouseleave", `${mapType}-fill-layer`, onMouseLeave);
      map.off("click", `${mapType}-fill-layer`, onMouseClick);
    };
  }, [
    mapRef,
    layerData,
    year,
    popupData,
    explore,
    yearProperty,
    valueProperty,
    mapType,
    mapTypeGeoJson,
    showTooltip,
    recommended,
    exploreRegionId,
  ]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();
    if (map?.isStyleLoaded()) {
      if (disableBuildings) {
        changeLayerVisibility("building (1)", map, "visible");
      } else {
        changeLayerVisibility("building (1)", map);
      }
    }
  }, [disableBuildings, mapRef]);

  const isCard = currentWidget.layout === "card";
  const isMinimalist = currentWidget.layout === "minimalist";

  return (
    <>
      {feedbackState && <Loader />}
      <WidgetImageWrapper ref={widgetRef}>
        {!explore && isCard && <MapHeader>Map</MapHeader>}

        <MapWrapper
          ref={ref}
          $explore={explore}
          onMouseEnter={() => {
            dispatch(setIsDraggingDisabled(true));
          }}
          onMouseLeave={() => {
            dispatch(setIsDraggingDisabled(false));
          }}
        >
          <Map
            id={id}
            ref={mapRef}
            initialViewState={{
              latitude: exploreLat || 35,
              longitude: exploreLng || -120,
              zoom: explore ? 5 : 4,
            }}
            style={{
              position: "absolute",
              width: explore ? "100%" : `calc(100% - ${isCard ? "32px" : "0"})`,
              height: explore
                ? "100%"
                : `calc(100% - ${
                    isCard
                      ? recommended || !currentWidget.timeline
                        ? "56px"
                        : "114px"
                      : "0"
                  })`,
              top: 0,
              left: 0,
              margin: explore
                ? "0"
                : `${
                    isCard
                      ? recommended || !currentWidget.timeline
                        ? "40px 16px 16px"
                        : "40px 16px 74px"
                      : "0px"
                  }`,
              borderRadius: explore ? 0 : "6px",
              border: `1px solid rgb(211,219,227)`,
            }}
            mapStyle={
              currentWidget.terrain
                ? "mapbox://styles/andyk1987/clnefgdzy01ze01qne2766ihi"
                : "mapbox://styles/andyk1987/cm1kvt8ib00ie01pi1lty0ey4"
            }
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          >
            <Source id={mapType} type="geojson" data={geoJsonData}>
              <Layer beforeId="road" {...layerStyle} />
            </Source>

            {showTooltip && (
              <Source
                id={`${mapType}-selected-stroke-layer`}
                type="geojson"
                data={geoJsonData}
              >
                <Layer
                  beforeId="road"
                  {...getLayerStrokeStyle(selectedRegion, !!showTooltip)}
                />
              </Source>
            )}
          </Map>
        </MapWrapper>

        {layerData?.length ? (
          <>
            {false && (
              <div style={{ position: "absolute", top: "0", right: "10px" }}>
                <FeedBackButton onClick={() => setFeedbackState(true)} />
              </div>
            )}

            {!isPublicRoute && isEditMode && !recommended && !explore ? (
              <SettingsButtonWrapper
                $modalOpen={!showSettings}
                $map
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount + 1}
                </DatavizRecommendedCount>

                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}

            {!recommended && !explore && (
              <DisableBuildingsWrapper
                $timeline={!!years?.length && currentWidget.timeline}
                $isCard={isCard}
              >
                <SwitchComponent
                  label={!disableBuildings ? "2D" : "3D"}
                  onChange={() => setDisableBuildings(!disableBuildings)}
                  values={disableBuildings}
                />
              </DisableBuildingsWrapper>
            )}

            {recommended ? (
              <SelectBageBlock>
                <SelectBage selected={!!selected} />
              </SelectBageBlock>
            ) : null}

            {years?.length &&
            currentWidget.timeline &&
            !recommended &&
            !explore ? (
              <MapYear
                years={years}
                setYear={setYear}
                selectedYear={year!}
                isCard={isCard}
                isMinimalist={isMinimalist}
              />
            ) : null}

            {showTooltip && popupData && mapRef.current?.getContainer() && (
              <Popup
                popupData={popupData}
                mapContainer={mapRef.current.getContainer()}
                clearPopupData={clearPopupData}
              />
            )}

            {!recommended && !explore && (
              <MapLegend
                colorRanges={colorRanges}
                average={average!}
                leftSlider={leftSlider}
                rightSlider={rightSlider}
                setLeftSlider={setLeftSlider}
                setRightSlider={setRightSlider}
                isCard={isCard}
                timeline={currentWidget.timeline!}
              />
            )}
          </>
        ) : (
          <LoaderWrapper>
            <Loader blur={false} />
          </LoaderWrapper>
        )}
      </WidgetImageWrapper>
    </>
  );
};
