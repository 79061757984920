import { styled } from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 12px;
  background: inherit;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 14px;
  color: #111827;
  text-align: left;

  span {
    flex-grow: 1;
    text-align: left;
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primaryBorder};
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
  }
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  padding: 4px 0;
  background: white;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 188px;
  width: 220px;
  overflow-y: auto;
`;

export const DropdownItem = styled.li<{ $hasDescription: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: #374151;
  text-align: left;

  &:hover {
    background: #f3f4f6;
  }

  ${({ $hasDescription }) =>
    $hasDescription &&
    `
    border-bottom: 1px solid #E5E7EB;
    
    &:last-child {
      border-bottom: none;
    }
  `}
`;

export const ItemDescription = styled.div`
  font-size: 12px;
  color: #6b7280;
  margin-top: 2px;
  text-align: left;
`;
