import { PlusLg, XLg, List } from "react-bootstrap-icons";
import { Button } from "../../../../Button";
import { AiIcon } from "../../../../../assets";

import { ModalHeaderContainer, ModalHeaderContainerItem } from "./styles";

const AIChatHeader = (props: { onClose: () => void }) => {
  const { onClose } = props;

  return (
    <ModalHeaderContainer>
      <ModalHeaderContainerItem>
        <AiIcon />
        <span>
          <strong>Fuse</strong>
          Assistant
        </span>
        <List />
      </ModalHeaderContainerItem>
      <ModalHeaderContainerItem>
        <Button
          variant="primary"
          size="medium"
          name="New Session"
          icon={<PlusLg />}
          onClick={() => console.log("new session")}
        />
        <Button
          round
          variant="neutral"
          size="medium"
          icon={<XLg />}
          onClick={onClose}
        />
      </ModalHeaderContainerItem>
    </ModalHeaderContainer>
  );
};

export default AIChatHeader;
