import {
  SquareFeatText,
  StyledTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TableTitle,
  TaxRate,
} from "./styles";
import { WrapperWidget } from "../Widgets/widgets";
import { ChartName } from "../ProjectPageLayouts/EstateMetricsLayout";

type PriceHistoryTableProps = {
  date: string;
  event: string;
  price: number;
  pricePerSquareFoot: number;
};

type TaxHistoryTableProps = {
  year: number;
  tax: number;
  tax_increase_rate: number;
  tax_assessment: number;
  tax_assessment_increase_rate: number;
};

type NearbySchoolsTableProps = {
  distance: number;
  grades: string;
  school_name: string;
};

type TableBodyData =
  | PriceHistoryTableProps[]
  | TaxHistoryTableProps[]
  | NearbySchoolsTableProps[];

const Table = ({ currentWidget }: Partial<WrapperWidget>) => {
  if (!currentWidget || !currentWidget.data?.length) return null;

  const { name: tableTitle, data, headers } = currentWidget;

  return (
    <TableContainer>
      {tableTitle && <TableTitle>{tableTitle}</TableTitle>}
      <StyledTable>
        <thead>
          <TableRow>
            {headers?.map((header, index) => (
              <TableHeader
                flexGrow2To3={
                  tableTitle === ChartName.NEARBY_SCHOOLS && index === 0
                }
                key={index}
              >
                {header.label}
              </TableHeader>
            ))}
          </TableRow>
        </thead>

        {renderTableBody(tableTitle as ChartName, data as TableBodyData)}
      </StyledTable>
    </TableContainer>
  );
};

export default Table;

function PriceHistoryTableBody({ data }: { data: PriceHistoryTableProps[] }) {
  return (
    <>
      {data.map((instance) => {
        const { date, event, price, pricePerSquareFoot } = instance;
        const [year, month, day] = date.split("-");

        return (
          <TableBody>
            <TableCell>{`${day}/${month}/${year}`}</TableCell>
            <TableCell>{event}</TableCell>
            <TableCell flexDirectionColumn>
              {formatToUsCurrency(price)}{" "}
              <SquareFeatText>
                {formatToUsCurrency(pricePerSquareFoot)}/sqft
              </SquareFeatText>
            </TableCell>
          </TableBody>
        );
      })}
    </>
  );
}

function TaxHistoryTableBody({ data }: { data: TaxHistoryTableProps[] }) {
  return (
    <>
      {data.map((instance) => {
        const {
          year,
          tax,
          tax_increase_rate,
          tax_assessment,
          tax_assessment_increase_rate,
        } = instance;

        const isPositiveTaxIncreaseRate = tax_increase_rate >= 0;
        const taxIncreaseRateOperator = isPositiveTaxIncreaseRate && "+";
        const taxIncreaseRate = (
          <TaxRate isPositiveRate={isPositiveTaxIncreaseRate}>
            {taxIncreaseRateOperator}
            {tax_increase_rate}%
          </TaxRate>
        );

        const isPositiveTaxAssessmentIncreaseRate =
          tax_assessment_increase_rate >= 0;
        const taxAssessmentIncreaseRateOperator =
          isPositiveTaxAssessmentIncreaseRate && "+";
        const taxAssessmentIncreaseRate = (
          <TaxRate isPositiveRate={isPositiveTaxAssessmentIncreaseRate}>
            {taxAssessmentIncreaseRateOperator}
            {tax_assessment_increase_rate}%
          </TaxRate>
        );

        return (
          <TableBody>
            <TableCell>{year}</TableCell>
            <TableCell>
              {`${formatToUsCurrency(tax)}`}
              {taxIncreaseRate}
            </TableCell>
            <TableCell>
              {`${formatToUsCurrency(tax_assessment)} `}
              {taxAssessmentIncreaseRate}
            </TableCell>
          </TableBody>
        );
      })}
    </>
  );
}

function NearbySchoolsTableBody({ data }: { data: NearbySchoolsTableProps[] }) {
  return (
    <>
      {data.map((instance) => (
        <TableBody>
          <TableCell flexGrow2To3>{instance.school_name}</TableCell>
          <TableCell>{instance.grades}</TableCell>
          <TableCell>{`${instance.distance} mi`}</TableCell>
        </TableBody>
      ))}
    </>
  );
}

function renderTableBody(tableName: ChartName, data: TableBodyData) {
  if (tableName === ChartName.PRICE_HISTORY)
    return <PriceHistoryTableBody data={data as PriceHistoryTableProps[]} />;

  if (tableName === ChartName.TAX_HISTORY)
    return <TaxHistoryTableBody data={data as TaxHistoryTableProps[]} />;

  if (tableName === ChartName.NEARBY_SCHOOLS)
    return <NearbySchoolsTableBody data={data as NearbySchoolsTableProps[]} />;
}

function formatToUsCurrency(number: number) {
  return Intl.NumberFormat("en", {
    currency: "USD",
    style: "currency",
  }).format(number);
}
