import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

import { APP_GET_ENDPOINTS, APP_VALIDATE, GET_APPLICATIONS } from "./constants";
import { RequestCallbacks } from "../models";

export interface IValidateAppCredentials {
    service: string,
    credentials: string
    callbacks?: RequestCallbacks<any>
}
export interface IGetAppEndpoints {
    service: string,
    callbacks?: RequestCallbacks<any>
}

export interface IGetApplications {
    callbacks?: RequestCallbacks<any>
}

export const validateAppAuthCredentials = async ({service, credentials}: IValidateAppCredentials) =>
  axios
    .post(APP_VALIDATE, {service, credentials})
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getServiceEndpoints = async ({service}: IGetAppEndpoints) =>
  axios
    .get(APP_GET_ENDPOINTS.replace('{service}', service))
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getApplications = async () =>
  axios
    .get(GET_APPLICATIONS)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));