import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//@ts-ignore
import { useScreenshot } from "use-react-screenshot";

import { Loader } from "../../components/Loader";
import { PagesData } from "../../models/Pages";
import { ProjectDTO } from "../../models/Projects";
import { getActiveModal } from "../../store/selectors/modals";
import {
  getAllPages,
  getCurrentProjectData,
  getCurrentProjectId,
  getProjects,
} from "../../store/selectors/projects";
import { setIsPublic } from "../../store/slices/appConfig";
import {
  requestDeleteFile,
  requestUploadSimpleFile,
} from "../../store/slices/files";
import { setActiveModal } from "../../store/slices/modals";
import {
  requestAllPages,
  requestAllPublicPages,
  resetCurrentPageData,
  resetPagesDataItems,
  setCurrentProjectId,
} from "../../store/slices/projectPages";
import {
  requestProjectById,
  requestPublicProjectById,
  requestUpdateProjectById,
} from "../../store/slices/projects";
import { ProjectPage } from "../ProjectPage";
import RealEstateExplore from "../RealEstateExplore";
import ProjectPageLayout from "./ProjectPageLayout";
import { EmptyWidgets } from "../../components/EmptyWidgets";
import { NoDataMessage } from "../../components/NoDataMessage";

type ProjectPageContentProps = {
  pages: PagesData;
  currentProjectData: ProjectDTO;
  hasSuggestedWidgets: boolean;
  downloadScreenshot: (ref: React.RefObject<HTMLDivElement>) => void;
};

export const Project = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id: queryId, pageId: queryPageId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const pages = useSelector(getAllPages);
  const { items } = useSelector(getProjects);
  const currentProjectData = useSelector(getCurrentProjectData);
  const currentProjectId = useSelector(getCurrentProjectId);
  const activeModal = useSelector(getActiveModal);

  const [imageLoading, setImageLoading] = useState<boolean>(false);

  const projectsIds = items?.map((item) => item.id);
  const isPublicRoute = location.pathname.includes("/public");

  const hasSuggestedWidgets = !!currentProjectData.suggestionsCount;

  const pageItems = useMemo(() => pages?.items, [pages?.items]);
  const pageId = useMemo(() => queryPageId, [queryPageId]);
  const id = useMemo(() => queryId, [queryId]);

  const serverUpload = (image: any) => {
    if (image) {
      if (currentProjectData.previewImage) {
        dispatch(
          requestDeleteFile({
            path: currentProjectData.previewImage,
          })
        );
      }

      const filename = id + ".jpg";
      const byteString = atob(image.split(",")[1]);

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;

        const maxWidth = 1024;
        const maxHeight = 568;

        let width = img.width;
        let height = img.height;

        if (width > height && width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        } else if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (compressedBlob) => {
            const compressedFile = new File([compressedBlob!], filename, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });

            dispatch(
              requestUploadSimpleFile({
                files: [compressedFile],
                callbacks: {
                  onSuccess: (resp: any) => {
                    const uploadedFile = resp?.at(0);

                    if (uploadedFile) {
                      dispatch(
                        requestUpdateProjectById({
                          id: currentProjectId,
                          previewImage: uploadedFile.path,
                        })
                      );
                      setImageLoading(false);
                    }
                  },
                  onError: () => {
                    setImageLoading(false);
                  },
                },
              })
            );
          },
          "image/jpeg",
          1
        );
      };

      img.src = image;
    }
  };

  const downloadScreenshot = (ref: React.RefObject<HTMLDivElement>) => {
    //Temporary, the function remains to make a screenshot on the initial render
    if (currentProjectData.previewImage) return;

    if (ref.current) {
      setImageLoading(true);
      takeScreenShot(ref.current).then(serverUpload);
    }
  };

  useEffect(() => {
    dispatch(setIsPublic(isPublicRoute));

    const requestProjectByIdMethod = isPublicRoute
      ? requestPublicProjectById
      : requestProjectById;

    dispatch(requestProjectByIdMethod(id ?? ""));

    if (isPublicRoute) {
      dispatch(requestAllPublicPages({ projectId: id }));
    } else {
      dispatch(requestAllPages({ projectId: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(setCurrentProjectId(id!));

    if (pageItems?.length > 0 && pageItems[0].id && !pageId) {
      const publicUrl = `/projects/public/${id}/${
        pageItems[pageItems.length - 1].id
      }`;

      const privateUrl = `/projects/${id}/${
        pageItems[pageItems.length - 1].id
      }`;

      navigate(isPublicRoute ? publicUrl : privateUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectData.pages, id, pageId, pageItems]);

  useEffect(() => {
    if (
      hasSuggestedWidgets &&
      !pageItems &&
      !isPublicRoute &&
      currentProjectData.projectType !== "real_estate" &&
      !activeModal.length
    ) {
      dispatch(
        setActiveModal({ id: "projectSettingsModal", createOptions: true })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageItems, currentProjectData.projectType]);

  useEffect(() => {
    if (items && items.length && !isPublicRoute) {
      !projectsIds?.includes(id!) && !currentProjectData && navigate("/404");
    }

    return () => {
      dispatch(resetPagesDataItems());
      dispatch(resetCurrentPageData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navigateToProjectsUrl = () => {
      navigate("/projects");
    };

    window.addEventListener("popstate", navigateToProjectsUrl);

    return () => {
      window.removeEventListener("popstate", navigateToProjectsUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <ProjectPageLayout>
      {!pages.isLoading && (
        <ProjectPageContent
          pages={pages}
          currentProjectData={currentProjectData}
          downloadScreenshot={downloadScreenshot}
          hasSuggestedWidgets={hasSuggestedWidgets}
        />
      )}

      {(pages.isLoading || imageLoading) && <Loader />}
    </ProjectPageLayout>
  );
};

const ProjectPageContent = ({
  pages,
  currentProjectData,
  downloadScreenshot,
  hasSuggestedWidgets,
}: ProjectPageContentProps) => {
  const { projectType, id: projectId } = currentProjectData;
  const isRealEstateProject = projectType === "real_estate";

  if (!pages.count) {
    if (isRealEstateProject) {
      return <RealEstateExplore />;
    }

    return <EmptyWidgets />;
  }

  if (!hasSuggestedWidgets && projectId && !isRealEstateProject) {
    return <NoDataMessage />;
  }

  return <ProjectPage downloadScreenshot={downloadScreenshot} />;
};
