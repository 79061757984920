import React from "react";
import {
  FinishLoad,
  FinishLoadWrapper,
  LoaderText,
  LoaderWrapper,
  OverviewLoaderWrapper,
} from "./style";
import LoaderDots from "./components/Loader";

export type ConnectLoaderStatus = "idle" | "loading" | "success";

type Props = {
  status: ConnectLoaderStatus;
  onLoadingText: string[];
  succefullLoadingText: string[];
};


const ConnectLoader = ({
  status,
  onLoadingText,
  succefullLoadingText,
}: Props) => {
  return (
    <OverviewLoaderWrapper>
      {status === "loading" ? (
        <>
          <LoaderWrapper>
            <LoaderDots></LoaderDots>
          </LoaderWrapper>
          <LoaderText>
            {onLoadingText.map((item, index) => (
              <span key={item + index}>{item}</span>
            ))}
          </LoaderText>
        </>
      ) : (
        <>
          <FinishLoadWrapper>
            <FinishLoad></FinishLoad>
          </FinishLoadWrapper>
          <LoaderText>
            {succefullLoadingText.map((item, index) => (
              <span key={item + index}>{item}</span>
            ))}
          </LoaderText>
        </>
      )}
    </OverviewLoaderWrapper>
  );
};

export default ConnectLoader;
