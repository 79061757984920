import { WidgetItem } from "../../../../models/Widgets";

interface chartData {
  [key: string]: any;
}

export const getGroupedData = (
  currentWidgetData?: WidgetItem,
  chartSuggestion?: any
): Record<string, chartData[]> => {
  const groupBy = currentWidgetData?.groupBy?.[0];
  const xAxe = currentWidgetData?.xAxe?.[0] ?? chartSuggestion?.xAxe?.[0];
  const yAxe = currentWidgetData?.yAxe?.[0] ?? chartSuggestion?.yAxe?.[0];

  if (!currentWidgetData?.data) {
    return { default: [] };
  }

  const data: Record<string, any> = groupBy
    ? currentWidgetData.data.reduce(
        (acc: Record<string, chartData[]>, d: chartData) => {
          const key = d[groupBy];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push({
            [xAxe]: d[xAxe],
            [yAxe]: d[yAxe],
          });
          return acc;
        },
        {}
      )
    : {
        default: currentWidgetData.data.map((d: chartData) => ({
          [xAxe]: d[xAxe],
          [yAxe]: d[yAxe],
        })),
      };

  const sortedData = Object.keys(data).reduce(
    (acc: Record<string, chartData[]>, key: string) => {
      acc[key] = data[key].sort(
        (a: any, b: any) =>
          new Date(a[xAxe]).getTime() - new Date(b[xAxe]).getTime()
      );
      return acc;
    },
    {}
  );

  return sortedData;
};
