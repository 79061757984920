import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestCallbacks } from "../../models";

interface ErrorDetail {
  name?: string;
  issues: {
    name: string;
    description: string;
  }[];
}

export interface IFeedBack {
  pageId: string;
  projectId: string;
  widgetId: string;
  screenshot: string;
  overallExperience: string;
  additionalComments: string;
  dataErrors: ErrorDetail[];
  technicalErrors: ErrorDetail[];
  aestheticIssues: ErrorDetail[];
  chartDesignErrors: ErrorDetail[];
  dataIntegrityErrors: ErrorDetail[];
  userInteractionErrors: ErrorDetail[];
}

export type FeedBackKeys = keyof Omit<IFeedBack , 'pageId' | 'projectId' | 'widgetId' | 'overallExperience' | 'screenshot' | 'additionalComments'>

export interface FeedBackState {
  feedback: IFeedBack;
  callbacks?: RequestCallbacks<any>;
}

const initialState: FeedBackState = {
  feedback: {
    pageId: "",
    projectId: "",
    widgetId: "",
    screenshot: "",
    overallExperience: "",
    additionalComments: "",
    dataErrors: [],
    technicalErrors: [],
    aestheticIssues: [],
    chartDesignErrors: [],
    dataIntegrityErrors: [],
    userInteractionErrors: [],
  },
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    requestCreateFeedBack(state, action: PayloadAction<FeedBackState>) {},
  },
});

export const { requestCreateFeedBack } = feedbackSlice.actions;

export default feedbackSlice.reducer;
