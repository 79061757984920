import { css, styled } from "styled-components";

export const LayoutRealEstateContainer = styled.div`
  height: calc(100vh - 132px);
`;

export const WidgetWrapper = styled.div<{
  $storytelling?: boolean;
  $map?: boolean;
  $selected?: boolean;
  $recommended?: boolean;
}>(
  ({ theme, $storytelling, $map, $selected, $recommended }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${$map ? "" : $storytelling ? "" : "16px"};
    border: ${$selected && !$recommended
      ? `1px solid transparent`
      : !$storytelling && !$selected
      ? `1px solid ${theme.neutralBorder}`
      : `1px solid transparent`};
    border-radius: 8px;
    height: ${$storytelling ? "360px" : "100%"};
    width: 100%;
    min-width: 307px;
    min-height: 220px;
    background: ${$selected && $recommended
      ? theme.primaryBackgroundPublish
      : ""};

    & .barchartAxisLine {
      stroke: ${theme.neutralBorderMedium} !important;
    }
  `
);
