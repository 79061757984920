import { IMarker, RealEstateMap } from "../../RealEstateMap";
import { LayoutRealEstateContainer, WidgetWrapper } from "./styles";
import React, { Fragment, useEffect, useState } from "react";
import axiosInstans from "../../../api/axios";
import { Col, Row } from "react-flexbox-grid";
import { WIDGETS } from "../../Widgets/widgets";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentPageKpi,
  getCurrentProjectData,
  getPageSettings,
} from "../../../store/selectors/projects";
import { KPISettingsWrapper } from "../../KPISettingsWrapper";
import {
  getAiKpis,
  getAiSuggestions,
  getLoadingAiSuggestions,
} from "../../../store/selectors/widgets";
import {
  requestCreateKpis,
  requestPageKpis,
} from "../../../store/slices/projectPages";
import { Loader } from "../../Loader";
import { HouseSettings } from "../../RealEstateMap/types";
import axios from "axios";
import { GEOCODING_URL } from "../../../api/constants";
import { useParams } from "react-router-dom";
import { ChartProps, ChartType } from "../EstateMetricsLayout";
import { getPolarAreaChartDefaultColors } from "../../Widgets/PolarAreaChart/utils/getGroupData";
import { getLineChartColors } from "../../Widgets/LineChart/utils/getLineChartMarkers";
import { AiKpiDto } from "../../../models/Widgets";

const LayoutRealEstate = () => {
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [mapConfig, setMapConfig] = useState<HouseSettings>(); // Default to US center
  const [isLoadingMap, setIsLoadingMap] = useState(true); // Start with loading true
  const [mapError, setMapError] = useState<string | null>(null);

  const MLChartSuggestions = useSelector(getAiSuggestions);
  const MLKPIs = useSelector(getAiKpis);
  const currentPage = useSelector(getPageSettings);
  const currentProjectData = useSelector(getCurrentProjectData);
  const aiKpi = useSelector(getCurrentPageKpi);
  const isLoadingAiSuggestions = useSelector(getLoadingAiSuggestions);
  const dispatch = useDispatch();
  const { pageId } = useParams();

  useEffect(() => {
    let isSubscribed = true;

    if (!pageId || !MLKPIs?.length || isLoadingAiSuggestions) return;

    const createKpisPromises = MLKPIs.map((queryKpi: any) =>
      dispatch(
        requestCreateKpis({
          ...queryKpi,
          pageId: pageId,
        })
      )
    );

    Promise.all(createKpisPromises).catch((error) => {
      if (!isSubscribed) return;
      console.error("Error creating KPIs:", error);
    });

    return () => {
      isSubscribed = false;
    };
  }, [MLKPIs, pageId, isLoadingAiSuggestions, dispatch]);

  useEffect(() => {
    if (!pageId) return;

    dispatch(
      requestPageKpis({
        pageId: pageId,
        includeData: true,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  useEffect(() => {
    let isMounted = true;

    const getCoordinates = async () => {
      if (!isMounted) return;

      setIsLoadingMap(true);
      setMapError(null);

      try {
        const location = currentPage?.location;
        const geocodingUrl = `${GEOCODING_URL}/${encodeURIComponent(
          location || ""
        )}.json`;

        const geoResponse = await axios.get(geocodingUrl, {
          params: {
            access_token: process.env.REACT_APP_MAPBOX_REAL_ESTATE_ACCESS_TOKEN,
            limit: 1,
          },
        });

        if (!isMounted) return;

        const features = geoResponse.data.features;
        if (!features?.length) {
          throw new Error("Location not found");
        }
        const file = currentProjectData.files?.[0];
        const [longitude, latitude] = features[0].center;
        setMapConfig({
          latitude: file?.mapBoxConfig?.coordinates[1] ?? 0,
          longitude: file?.mapBoxConfig?.coordinates[0] ?? 0,
          rotateX: file?.mapBoxConfig?.rotateX ?? 0,
          rotateY: file?.mapBoxConfig?.rotateY ?? 0,
          rotateZ: file?.mapBoxConfig?.rotateZ ?? 0,
          scale: file?.mapBoxConfig?.scale ?? 1,
        });

        const propertiesResponse = await axiosInstans.post(
          "/data-provider/api/zillow/search",
          {
            location,
            coordinates: { longitude, latitude },
            page: 1,
            status: "forSale",
            getAll: false,
          }
        );

        if (!isMounted) return;

        if (propertiesResponse.data) {
          setMarkers(
            propertiesResponse.data.map((marker: IMarker) => ({
              longitude: marker.longitude ?? 0,
              latitude: marker.latitude ?? 0,
              imgSrc: marker.imgSrc,
              price: marker.price,
              streetAddress: marker.streetAddress,
              zpid: marker.zpid,
            }))
          );
        }
      } catch (error) {
        if (!isMounted) return;
        setMapError(
          error instanceof Error ? error.message : "Failed to load map data"
        );
      } finally {
        if (isMounted) {
          setIsLoadingMap(false);
        }
      }
    };

    getCoordinates();

    return () => {
      isMounted = false;
    };
  }, [currentPage?.location, currentProjectData?.files]);

  return (
    <LayoutRealEstateContainer className="layout-real-estate">
      {isLoadingAiSuggestions && <Loader fullScreen />}
      <Row style={{ height: "100%" }}>
        {/* Left column */}
        <Col
          lg={6}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: "100%",
          }}
        >
          <Row>
            <Col lg={12}>
              <WidgetWrapper $map={true}>
                <KPISettingsWrapper
                  recommended
                  storytelling={false}
                  kpis={getUniqueKPIs(aiKpi.items)}
                />
              </WidgetWrapper>
            </Col>
          </Row>

          <PolarAreaCharts chartSuggestions={MLChartSuggestions} />

          <Row style={{ height: "calc(100% - 8px)" }}>
            <Charts chartSuggestions={MLChartSuggestions} />
          </Row>
        </Col>

        {/* Map column */}
        <Col lg={6}>
          <Row
            style={{
              border: "1px #D3DBE3 solid",
              position: "relative",
              height: "calc(100vh - 136px)",
              marginLeft: "0px",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            {isLoadingMap && <Loader />}
            {mapError && (
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  padding: "1rem",
                  color: "red",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  borderRadius: "4px",
                  margin: "1rem",
                }}
              >
                {mapError}
              </div>
            )}
            <RealEstateMap
              markers={markers}
              houseSettings={mapConfig}
              threeDModelPath={currentProjectData?.files?.[0]}
            />
          </Row>
        </Col>
      </Row>
    </LayoutRealEstateContainer>
  );
};

export default LayoutRealEstate;

function PolarAreaCharts({ chartSuggestions }: ChartProps) {
  if (!chartSuggestions) return null;

  const charts = chartSuggestions
    .filter((widget) => widget.chartType === ChartType.POLAR_AREA_CHART)
    .map((widget, index) => {
      if (!WIDGETS[widget.chartType]) return null;

      return (
        <Row key={index}>
          <Col lg={12}>
            <WidgetWrapper>
              {React.createElement(WIDGETS[widget.chartType], {
                storytelling: false,
                currentWidget: {
                  ...widget,
                  formatting: getPolarAreaChartDefaultColors(widget),
                },
                recommended: false,
                showLegend: true,
              })}
            </WidgetWrapper>
          </Col>
        </Row>
      );
    })
    .filter(Boolean);

  if (!charts.length) return null;

  return <Fragment>{charts}</Fragment>;
}

function Charts({ chartSuggestions }: ChartProps) {
  if (!chartSuggestions) return null;

  const charts = chartSuggestions
    .filter((widget) => widget.chartType !== ChartType.POLAR_AREA_CHART)
    .map((widget, index) => {
      if (!WIDGETS[widget.chartType]) return null;

      return (
        <Col lg={6} key={index}>
          <WidgetWrapper>
            {React.createElement(WIDGETS[widget.chartType], {
              storytelling: false,
              currentWidget: {
                ...widget,
                formatting: getLineChartColors(widget),
              },
              recommended: false,
              showLegend: true,
            })}
          </WidgetWrapper>
        </Col>
      );
    })
    .filter(Boolean);

  if (!charts.length) return null;

  return <Fragment>{charts}</Fragment>;
}

function getUniqueKPIs(kpis: AiKpiDto[]) {
  return [
    ...new Map(
      kpis.filter(Boolean).map((item) => [item.column, item])
    ).values(),
  ];
}
