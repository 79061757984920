import { useDispatch, useSelector } from "react-redux";
import {
  KPISettings,
  KPIWrapperBorder,
  KPIWrapperCol,
  KPIWrapperMainContainer,
  KPIWrapperRow,
} from "./styles";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  SettingsButtonWrapper,
} from "../Widgets/VerticalBarchart/styles";
import { setActiveModal } from "../../store/slices/modals";
import { useEffect, useRef, useState } from "react";
import { AiKpiDto } from "../../models/Widgets";
import { getAiKpis } from "../../store/selectors/widgets";
import { KPI_TYPES, KPIComponentWrapper } from "../KPIComponentWrapper";
import { getActiveModal } from "../../store/selectors/modals";

export type KPISettingsWrapperProps = {
  recommended: boolean;
  storytelling: boolean;
  kpis: AiKpiDto[];
};

export const KPISettingsWrapper = (props: KPISettingsWrapperProps) => {
  const dispatch = useDispatch();
  const { kpis, recommended, storytelling } = props;
  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiKpis = useSelector(getAiKpis);
  const [localKpis, setLocalKpis] = useState<AiKpiDto[][]>([]);
  const [componentWidth, setComponentWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const isRowView = componentWidth > window.innerWidth * 0.33;
  const activeModal = useSelector(getActiveModal);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current)
        setComponentWidth(containerRef.current.offsetWidth);
    };
    updateWidth();

    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!kpis?.length) return;

    const firstRowFilteredKPIs = kpis.filter((kpi) =>
      [KPI_TYPES.TOP?.toString(), KPI_TYPES.TREND?.toString()].includes(
        kpi.type
      )
    );

    if (firstRowFilteredKPIs?.length > 3) {
      const firstKpi = firstRowFilteredKPIs?.filter((_, index) =>
        [0, 1].includes(index)
      );
      const firstRow = firstRowFilteredKPIs?.filter(
        (_, index) => ![0, 1].includes(index)
      );

      return setLocalKpis([firstRow, firstKpi]);
    }

    if (firstRowFilteredKPIs?.length > 2) {
      const firstKpi = firstRowFilteredKPIs?.at(0);
      const firstRow = firstRowFilteredKPIs?.filter(
        (kpi) => kpi.id !== firstKpi?.id
      );

      return setLocalKpis([firstRow, [firstKpi!]]);
    }

    const secondRowFilteredKPIs = kpis.filter((kpi) =>
      [
        KPI_TYPES.DISTINCTION?.toString(),
        KPI_TYPES.HIGH_LOW?.toString(),
      ].includes(kpi.type)
    );

    return setLocalKpis([firstRowFilteredKPIs, secondRowFilteredKPIs]);
  }, [kpis]);

  return (
    <KPIWrapperMainContainer ref={containerRef}>
      <KPIWrapperRow $isRow={isRowView}>
        {(isRowView ? [localKpis?.flat()] : localKpis)?.map((kpiRow, index) => {
          return (
            <KPIWrapperCol key={index}>
              {kpiRow?.map((kpi, key) => {
                return (
                  <KPIWrapperBorder
                    $borderRight={!isRowView && key === 0 && kpiRow?.length > 1}
                    $borderTop={
                      !isRowView &&
                      index === localKpis?.length - 1 &&
                      localKpis?.length > 1
                    }
                    $borderLeft={key !== 0 && isRowView}
                    key={kpi.id}
                  >
                    <KPIComponentWrapper
                      isRowView={isRowView}
                      key={kpi.id}
                      kpi={kpi}
                      recommended={false}
                      selected={false}
                      size={!isRowView && key === 0 ? "sm" : "md"}
                    />
                  </KPIWrapperBorder>
                );
              })}
            </KPIWrapperCol>
          );
        })}
      </KPIWrapperRow>

      {!isPublicRoute && !recommended && isEditMode && !storytelling ? (
        <KPISettings>
          <SettingsButtonWrapper
            $modalOpen={activeModal.includes("recommendedKpisModal")}
            onClick={() => {
              dispatch(setActiveModal({ id: "recommendedKpisModal" }));
            }}
          >
            <DatavizRecommendedCount>{aiKpis?.length}</DatavizRecommendedCount>
            <DatavizSettingsIcon />
          </SettingsButtonWrapper>
        </KPISettings>
      ) : null}
    </KPIWrapperMainContainer>
  );
};
