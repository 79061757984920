import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import { AiKpiDto } from "../../models/Widgets";
import { KPIComponent, KPIProps } from "../KPIComponent";
import {
  HeadingNameAndButton,
  KPIComponentRow,
  KPIName,
  KPIRow,
  KPISeparator,
  KPIWrapperMainContainer,
} from "./styles";
import { SelectBage } from "../Widgets/SelectBage";

export enum KPI_TYPES {
  TREND = "trend",
  HIGH_LOW = "high/low",
  DISTINCTION = "distinction",
  TOP = "top",
}

export type KPIComponentWrapperProps = {
  showNameBlock?: boolean;
  kpi: AiKpiDto;
  recommended: boolean;
  selected: boolean;
  onSelect?: () => void;
  size?: "xl" | "lg" | "md" | "sm";
  isRowView?: boolean;
};

const formatNumber = (num: number) => {
  if (!num) return { value: "0", label: "" };

  if (num >= 1e9)
    return { value: Math.floor(num / 1e9).toString(), label: "B" };

  if (num >= 1e6)
    return { value: Math.floor(num / 1e6).toString(), label: "M" };

  if (num >= 1e3)
    return { value: (num / 1e3).toString().replace(".", ","), label: "" };

  return { value: num.toString(), label: "" };
};

export const KPIComponentWrapper = ({
  kpi,
  recommended,
  selected,
  showNameBlock = true,
  onSelect,
  size = "lg",
  isRowView,
}: KPIComponentWrapperProps) => {
  const [kpiProps, setKpiProps] = useState<KPIProps[]>([]);

  useEffect(() => {
    const KPIGroupBy = kpi?.groupBy;
    const KPIColumn = kpi?.column;
    const KPIData = kpi?.data?.at(0);
    const KPIAggregation = kpi?.aggregations?.at(0);

    if (KPIData && kpi?.type === KPI_TYPES.TREND) {
      const { value, label } = formatNumber(
        KPIData[`${KPIAggregation}_${KPIColumn}`]
      );

      return setKpiProps([
        {
          key: value,
          value,
          size,
          indicator: KPIData.percentage ? `${KPIData.percentage}%` : undefined,
          label: { position: "right", text: label },
          subtitle: KPIData.subtitle,
        },
      ]);
    }

    const newKpis: KPIProps[] = [];
    const keys = KPIData ? Object.keys(KPIData) : [];

    for (const key of keys) {
      const localData = KPIData[key];
      const newKey = `${KPIAggregation}_${KPIColumn}`;
      const { value, label } = formatNumber(localData[newKey]);
      const subtitle = KPIGroupBy ? localData[KPIGroupBy] : undefined;

      newKpis.push({
        key: value,
        value,
        size,
        indicator: localData.percentage ? `${localData.percentage}%` : "0",
        label: { position: "right", text: label },
        subtitle,
      });
    }

    setKpiProps(newKpis || []);
  }, [kpi, size]);

  return (
    <KPIWrapperMainContainer
      $selected={selected}
      $recommended={recommended}
      onClick={onSelect}
      $isRowView={isRowView}
    >
      {showNameBlock ? (
        <HeadingNameAndButton>
          <KPIName
            $width={kpiProps?.length > 1 ? "260px" : "140px"}
            data-tooltip-id="kpi_name"
            data-tooltip-content={kpi.name}
          >
            {kpi.name}
          </KPIName>
          {recommended ? <SelectBage selected={selected} /> : null}
        </HeadingNameAndButton>
      ) : null}

      <KPIRow>
        {kpiProps?.map((kpi, index) => {
          return (
            <KPIComponentRow key={kpi.key}>
              <KPIComponent
                key={kpi.key}
                size={kpi.size}
                value={kpi.value}
                indicator={kpi.indicator}
                label={kpi.label}
                subtitle={kpi.subtitle}
              />
              {index !== kpiProps?.length - 1 ? <KPISeparator /> : null}
            </KPIComponentRow>
          );
        })}
      </KPIRow>
      <Tooltip id="kpi_name" />
    </KPIWrapperMainContainer>
  );
};
