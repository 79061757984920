import styled, { css } from "styled-components";

export const Category = styled.div<{ $active?: boolean }>`
  color: #21262E;
  user-select: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #CFD2D6;
  background: #EFF0F1;
  transition: 0.3s;

  &:hover {
    background: #DFE1E4;
  }

  &:active {
    background: #CFD2D6;
  }

  ${({ $active }) =>
    $active &&
    css`
      background: #6F6FE93D !important;
      border-color: #7D82F8 !important;
    `}
`;