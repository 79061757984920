import React, { useEffect } from "react";

import {
  ApplicationWrapper,
  ButtonsWrapper,
  Container,
  DataHeading,
  DataWrapper,
  HeadingPage,
  HeadingText,
  ResourcesWrapper,
} from "./styles";
import { Applications } from "./components/Applications";
import { Datasets } from "./pages/Datasets";
import { Button } from "../../components/Button";
import { CodeSlash, Upload } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { setActiveModal } from "../../store/slices/modals";
import { Outlet, useLocation } from "react-router-dom";

export const Resources: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <>
      {pathname.includes("/resources/datasets") ? (
        <Outlet />
      ) : (
        <Container>
          <HeadingPage>Data Resources</HeadingPage>
          <ResourcesWrapper>
            <ApplicationWrapper>
              <Applications />
            </ApplicationWrapper>
            <DataWrapper>
              <DataHeading>
                <HeadingText>Files & API’s</HeadingText>
                <ButtonsWrapper>
                  <Button
                    variant="ghost"
                    size="small"
                    onClick={() => {
                      dispatch(setActiveModal({ id: "uploadDatasetModal" }));
                    }}
                    name="Upload"
                    icon={<Upload />}
                  />
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      dispatch(setActiveModal({ id: "connectApiModal" }));
                    }}
                    name="Connect API"
                    icon={<CodeSlash />}
                  />
                </ButtonsWrapper>
              </DataHeading>
              <Datasets />
            </DataWrapper>
          </ResourcesWrapper>
        </Container>
      )}
    </>
  );
};
