import React, { useState } from "react";
import {
  WarningContainer,
  WarningContent,
  WarningDescription,
  WarningFooter,
  WarningTitle,
  WarningWrapper,
} from "./styles";
import { Button } from "../../../../Button";
import { PlusLg } from "react-bootstrap-icons";

type Props = {
  title: string;
  description: string[];
  close: () => void;
  addData?: () => void;
};

const NoDataWarning = ({ title, description, close, addData }: Props) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const handleClose = () => {
    setIsClosed(true);
    setTimeout(() => {
      setIsClosed(false);
      close();
    }, 250);
  };

  return (
    <WarningWrapper onClick={handleClose}>
      <WarningContainer $close={isClosed} onClick={(e) => e.stopPropagation()}>
        <WarningContent>
          <WarningTitle>{title}</WarningTitle>
          {description.map((item) => (
            <WarningDescription>{item}</WarningDescription>
          ))}
        </WarningContent>
        <WarningFooter>
          <Button
            onClick={handleClose}
            variant={"neutral"}
            size={"large"}
            name="Cancel"
          />
          {addData && (
            <Button
              onClick={addData}
              variant={"secondary"}
              size={"large"}
              name="Add data"
              icon={<PlusLg />}
            />
          )}
        </WarningFooter>
      </WarningContainer>
    </WarningWrapper>
  );
};

export default NoDataWarning;
