import React from "react";
import {
  MapSettingLayerContent,
  MapSettingsControl,
  MapSettingsControlName,
  MapSettingsControlsWrapper,
  MapSettingsDatasets,
  MapSettingsDatasetsHeading,
  MapSettingsDatasetsWrapper,
  MapSettingsLayerDirection,
  MapSettingsLayerHeader,
  MapSettingsLayersWrapper,
  MapSettingsLayouts,
  MapSettingsLayoutWrapper,
  MapSettingsLeftSide,
  MapSettingsRangeControlHeader,
  MapSettingsRangeControlName,
  MapSettingsRangeControlWrapper,
  MapSettingsRightSide,
  MapSettingsStyleFormating,
  MapSettingsStyleFormatingColors,
  MapSettingsStyleFormatingElement,
  MapSettingsStyleFormatingElementsList,
  MapSettingsStyleFormatingName,
  MapSettingsStyleFormatingParamSelect,
  MapSettingsStyleFormatingParamSelectContent,
  MapSettingsStyleFormatingParamSelectText,
  Marker,
  MarkerSelect,
  SwitchDataset,
} from "../../styles";
import { Button } from "../../../Button";
import { ProjectDTO } from "../../../../models/Projects";
import { DatasetsItem } from "../../../DatasetsItem";
import { Select } from "../../../Inputs/CustomSelect/Select";
import { markers, selectOptions } from "../../data/mock";
import {
  IFormatting,
  IMarkersType,
  WidgetItem,
} from "../../../../models/Widgets";
import RadioButton from "../../../Inputs/CustomRadio/RadioButton";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";
import { getMetrics, IMetrics } from "../../utils/getMetrics";
import { SelectDimensions } from "../../../Inputs/CustomSelectDimensions/SelectDimensions";
import { getCurrentColor } from "../../../Widgets/utils/getCurrentMarker";
import ChartColorsSelect from "../ChartColorsSelect";
import DataRangeFilter from "../DataRangeFilter/DataRangeFilter";
import { getSequentialColorsHex } from "../../../../constants/utils/getSequentialColors";
import { DnDBadge } from "../../../Inputs/CustomSelectDimensions/types";
import { IGroupTypesV2, IMarkers } from "../../types/types";

type Props = {
  widgetData: WidgetItem;
  setWidgetData: React.Dispatch<React.SetStateAction<WidgetItem>>;
  projectData: ProjectDTO;
  selectedDimensions: string[];
  selectedMarker?: IMarkersType;
  setSelectedMarker: React.Dispatch<
    React.SetStateAction<IMarkersType | undefined>
  >;
  selectedKey?: string;
  setSelectedKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedColors: string[];
  selectedFormatting?: IFormatting;
  setSelectedFormatting: React.Dispatch<
    React.SetStateAction<IFormatting | undefined>
  >;
  colorRanges: number[];
  setColorRanges: React.Dispatch<React.SetStateAction<number[]>>;
  paletteIndex: number;
  setPaletteIndex: React.Dispatch<React.SetStateAction<number>>;
  styleId: string;
  currentChartPalette: () => any;
};

const WidgetsConfig = ({
  widgetData,
  setWidgetData,
  projectData,
  selectedDimensions,
  selectedMarker,
  setSelectedMarker,
  selectedKey,
  setSelectedKey,
  selectedColors,
  selectedFormatting,
  setSelectedFormatting,
  colorRanges,
  setColorRanges,
  paletteIndex,
  setPaletteIndex,
  styleId,
  currentChartPalette,
}: Props) => {
  let markerTypes = [...markers];
  if (widgetData.chartType === "scatterplotChart") {
    markerTypes = markerTypes.filter((type) => type !== "disabled");
    markerTypes.unshift("cross");
  }

  const handleOnCancel = (label: string) => {
    const removeFromArray = (
      array: string[] | undefined,
      label: string
    ): string[] | undefined => {
      return array ? array.filter((name) => name !== label) : array;
    };

    setWidgetData((prev) => {
      const updatedWidgetData: WidgetItem = { ...prev };
      const data: IGroupTypesV2[] = [
        "arrangeBy",
        "groupBy",
        "display",
        "xAxe",
        "yAxe",
      ];
      data.forEach((key) => {
        updatedWidgetData[key] = removeFromArray(prev[key], label);
      });

      return updatedWidgetData;
    });
  };

  const handleOnDrop = (item: DnDBadge, groupName: IGroupTypesV2) => {
    setWidgetData((prev) => {
      const group = prev[groupName];
      const isArray = Array.isArray(group);
      if (isArray) {
        if (!selectedDimensions.includes(item.key)) {
          return {
            ...prev,
            [groupName]: [...group, item.key],
          };
        } else if (selectedDimensions.includes(item.key)) {
          const obj: WidgetItem = {
            ...prev,
            arrangeBy: prev.arrangeBy?.includes(item.key)
              ? prev.arrangeBy.filter((key) => key !== item.key)
              : prev.arrangeBy,
            groupBy: prev.groupBy?.includes(item.key)
              ? prev.groupBy.filter((key) => key !== item.key)
              : prev.groupBy,
            display: prev.display?.includes(item.key)
              ? prev.display.filter((key) => key !== item.key)
              : prev.display,
            xAxe: prev.xAxe?.includes(item.key)
              ? prev.xAxe.filter((key) => key !== item.key)
              : prev.xAxe,
            yAxe: prev.yAxe?.includes(item.key)
              ? prev.yAxe.filter((key) => key !== item.key)
              : prev.yAxe,
          };

          return {
            ...obj,
            [groupName]: [...group, item.key],
          };
        } else {
          return prev;
        }
      } else {
        return {
          ...prev,
          [groupName]: [item.key],
        };
      }
    });
  };

  const selectedBagdes = (groupName: IGroupTypesV2): string[] => {
    return widgetData[groupName] as string[];
  };

  const handlePickMarker = (type: IMarkers) => {
    if (selectedKey) {
      setSelectedMarker({ key: selectedKey, shape: type });

      setWidgetData({
        ...widgetData,
        markers: widgetData?.markers?.map((item) => {
          if (item.key === selectedKey) {
            return { ...item, shape: type };
          }
          return item;
        }),
      });
    }
  };

  const handlePickColor = (pickedColor: number) => {
    const isSelected = selectedColors.includes(String(pickedColor));

    if (selectedKey && !isSelected) {
      setSelectedFormatting({ key: selectedKey, color: String(pickedColor) });

      setWidgetData((prev) => ({
        ...prev,
        formatting: prev.formatting?.map((item) => {
          if (item.key === selectedKey) {
            return { key: item.key, color: String(pickedColor) };
          }
          return item;
        }),
      }));
    }
  };

  const handleSelectLabel = (key: string | undefined) => {
    setSelectedKey(key);
    setSelectedFormatting(
      widgetData.formatting?.find((item) => item.key === key)
    );
    setSelectedMarker(widgetData.markers?.find((item) => item.key === key));
  };

  const handleColorChangePalette = (
    state: "left" | "right",
    paletteId: string
  ) => {
    if (state === "left") {
      setPaletteIndex((prev) => prev - 1);
    } else {
      setPaletteIndex((prev) => prev + 1);
    }

    setWidgetData((prev) => ({
      ...prev,
      palette: { ...prev.palette, paletteId: paletteId },
    }));
  };

  return (
    <>
      <MapSettingsLeftSide>
        <MapSettingsDatasetsWrapper>
          <MapSettingsDatasetsHeading>
            Datasets
            <Button
              onClick={() => {}}
              variant="primary"
              size="xs"
              name="Switch dataset"
              icon={<SwitchDataset />}
            />
          </MapSettingsDatasetsHeading>
          <MapSettingsDatasets>
            {projectData.datasets.map((dataset) => (
              <DatasetsItem
                dataset={dataset}
                key={dataset.filePath}
                selectedDimensions={selectedDimensions}
                isDraggable
                disablePreview
                selectedColumns={projectData.datasetColumnKeys}
              />
            ))}
          </MapSettingsDatasets>
        </MapSettingsDatasetsWrapper>
        <MapSettingsLayersWrapper>
          <MapSettingsLayerHeader>Chart Type</MapSettingsLayerHeader>
          <MapSettingLayerContent>
            <Select
              withOutClose
              options={selectOptions}
              value={widgetData.chartType}
            />

            {!!["barChart", "lollipopChart"].includes(widgetData.chartType) && (
              <MapSettingsLayerDirection>
                <RadioButton
                  name="orientation"
                  onChange={(e) =>
                    setWidgetData({
                      ...widgetData,
                      orientation: e.target.value,
                    })
                  }
                  label={"Vertical bars"}
                  value={"vertical"}
                  checked={widgetData.orientation === "vertical"}
                />
                <RadioButton
                  name="orientation"
                  onChange={(e) =>
                    setWidgetData({
                      ...widgetData,
                      orientation: e.target.value,
                    })
                  }
                  label={"Horizontal bars"}
                  value={"horizontal"}
                  checked={widgetData.orientation === "horizontal"}
                />
              </MapSettingsLayerDirection>
            )}
          </MapSettingLayerContent>
        </MapSettingsLayersWrapper>
      </MapSettingsLeftSide>

      <MapSettingsRightSide>
        <MapSettingsLayoutWrapper>
          Metrics
          <MapSettingsLayouts>
            {getMetrics(
              widgetData.chartType,
              widgetData.orientation as "horizontal" | "vertical"
            ).map((item: IMetrics) => (
              <SelectDimensions
                key={item.type}
                onDrop={(dimension) => handleOnDrop(dimension, item.type)}
                onCancel={(name) => handleOnCancel(name)}
                selectedBadges={selectedBagdes(item.type)}
                lable={item.lable}
                subLable={item.subLable}
                icon={item.icon}
                axe={item.axe}
                acceptTypes={item.acceptTypes}
                allBadges={projectData.datasets[0].fields.map((item) => ({
                  ...item,
                  name: item.label,
                }))}
              />
            ))}
          </MapSettingsLayouts>
        </MapSettingsLayoutWrapper>
        <MapSettingsStyleFormating>
          <MapSettingsStyleFormatingName>
            Style formatting
          </MapSettingsStyleFormatingName>
          {!widgetData?.markers?.length &&
            widgetData?.groupBy?.[0] &&
            widgetData?.chartType !== "barChart" && (
              <MapSettingsStyleFormatingElementsList>
                {widgetData?.uniqueValues?.[widgetData?.groupBy?.[0]].map(
                  (item: string) => (
                    <MapSettingsStyleFormatingElement
                      key={item}
                      $selected={item === selectedKey}
                      onClick={() =>
                        handleSelectLabel(
                          selectedKey === item ? undefined : item
                        )
                      }
                    >
                      <Marker
                        $type={"circle"}
                        $color={getCurrentColor(widgetData, item, styleId)}
                      />
                      {item}
                    </MapSettingsStyleFormatingElement>
                  )
                )}
              </MapSettingsStyleFormatingElementsList>
            )}

          {!!widgetData?.markers?.length &&
            !["barChart", "polarAreaChart", "bubbleChart"].includes(
              widgetData.chartType
            ) && (
              <MapSettingsStyleFormatingElementsList>
                {widgetData?.markers
                  ?.filter((item) => item.key !== "default")
                  .map((item) => (
                    <MapSettingsStyleFormatingElement
                      key={item.key}
                      $selected={item.key === selectedKey}
                      onClick={() =>
                        handleSelectLabel(
                          selectedKey === item.key ? undefined : item.key
                        )
                      }
                    >
                      <Marker
                        $type={item.shape}
                        $color={getCurrentColor(widgetData, item.key, styleId)}
                      />
                      {item.key}
                    </MapSettingsStyleFormatingElement>
                  ))}
              </MapSettingsStyleFormatingElementsList>
            )}

          {!!widgetData?.formatting?.length &&
            [
              "barChart",
              "polarAreaChart",
              "radialBarChart",
              "bubbleChart",
            ].includes(widgetData.chartType) && (
              <MapSettingsStyleFormatingElementsList>
                {widgetData?.formatting
                  ?.filter((item) => item.key !== "default")
                  .map((item) => (
                    <MapSettingsStyleFormatingElement
                      key={item.key}
                      $selected={item.key === selectedKey}
                      onClick={() =>
                        handleSelectLabel(
                          selectedKey === item.key ? undefined : item.key
                        )
                      }
                    >
                      <Marker
                        $type={"circle"}
                        $color={getCurrentColor(widgetData, item.key, styleId)}
                      />
                      {item.key}
                    </MapSettingsStyleFormatingElement>
                  ))}
              </MapSettingsStyleFormatingElementsList>
            )}

          <MapSettingsStyleFormatingParamSelect>
            <MapSettingsStyleFormatingParamSelectText>
              Color
            </MapSettingsStyleFormatingParamSelectText>
            <MapSettingsStyleFormatingColors>
              <ChartColorsSelect
                selectedFormatting={selectedFormatting?.color}
                handlePickColor={(color: number) => handlePickColor(color)}
                currentColors={currentChartPalette()}
                paletteIndex={paletteIndex}
                handleColorChangePalette={handleColorChangePalette}
                type={
                  [
                    "matrixChart",
                    "sankeyChart",
                    "mapChart",
                    "punchcardChart",
                  ].includes(widgetData.chartType)
                    ? "sequential"
                    : "qualitative"
                }
              />
            </MapSettingsStyleFormatingColors>
          </MapSettingsStyleFormatingParamSelect>

          {[
            "areaChart",
            "lollipopChart",
            "lineChart",
            "scatterplotChart",
            "scatterplotChart",
            "radarChart",
          ].includes(widgetData.chartType) && (
            <MapSettingsStyleFormatingParamSelect>
              <MapSettingsStyleFormatingParamSelectText>
                Markers
              </MapSettingsStyleFormatingParamSelectText>
              <MapSettingsStyleFormatingParamSelectContent>
                {markerTypes.map((item) => (
                  <MarkerSelect
                    key={item}
                    $selected={selectedMarker?.shape === item}
                    onClick={() => handlePickMarker(item)}
                  >
                    <Marker
                      $type={item}
                      $color={
                        selectedMarker?.shape === item ? "#493DDD" : "#5F6877"
                      }
                    />
                  </MarkerSelect>
                ))}
              </MapSettingsStyleFormatingParamSelectContent>
            </MapSettingsStyleFormatingParamSelect>
          )}
          {typeof widgetData.tooltip === "boolean" && (
            <MapSettingsControlsWrapper>
              <MapSettingsControlName>Tooltip</MapSettingsControlName>
              <MapSettingsControl>
                <SwitchComponent
                  label={widgetData.tooltip ? "Show" : "Don't show"}
                  checked={widgetData.tooltip}
                  onChange={(e) =>
                    setWidgetData((prev) => ({
                      ...prev,
                      tooltip: e.target.checked,
                    }))
                  }
                />
              </MapSettingsControl>
            </MapSettingsControlsWrapper>
          )}
        </MapSettingsStyleFormating>

        {["matrixChart", "punchcardChart", "bubbleChart"].includes(
          widgetData.chartType
        ) && (
          <MapSettingsRangeControlWrapper>
            <MapSettingsRangeControlHeader>
              <MapSettingsRangeControlName>
                Data Points
              </MapSettingsRangeControlName>

              <SwitchComponent
                disableMargin
                checked={widgetData.palette?.autoRange}
                onChange={(e) =>
                  setWidgetData({
                    ...widgetData,
                    palette: {
                      ...widgetData.palette,
                      autoRange: e.target.checked,
                    },
                  })
                }
                label="Auto"
              />
            </MapSettingsRangeControlHeader>
            <DataRangeFilter
              colors={getSequentialColorsHex(
                styleId,
                widgetData.palette?.paletteId!,
                ["bubbleChart"].includes(widgetData.chartType)
              )}
              widgetData={widgetData}
              setColorRanges={setColorRanges}
              colorRanges={colorRanges}
              bubbles={["bubbleChart", "punchcardChart"].includes(
                widgetData.chartType
              )}
              spikes={false}
            />
          </MapSettingsRangeControlWrapper>
        )}
      </MapSettingsRightSide>
    </>
  );
};

export default WidgetsConfig;
