import usCountiesData from "../Config/GeoJson/us-counties.json";
import usStatesData from "../Config/GeoJson/us-states.json";
import worldCountriesData from "../Config/GeoJson/world-countries.json";

export const getGeoJsonForMapType = (
  mapType: string
): GeoJSON.GeoJsonProperties => {
  switch (mapType) {
    case "county":
      return usCountiesData;
    case "state":
      return usStatesData;
    case "country":
      return worldCountriesData;
    default:
      return usCountiesData;
  }
};
