import { ISelect } from "../../../Inputs/CustomSelect/types";
import Authorization from "../tabs/Authorization";
import Body from "../tabs/Body";
import Parameters from "../tabs/Parameters";
import Headers from "../tabs/Headers";
import { ConnectApiValues, ITabElement, KeyRow } from "../types/types";

export const initialValues: ConnectApiValues = {
  datasetName: "",
  url: "",
  authType: "noAuth",
  username: "",
  password: "",
  token: "",
  addJWTToken: "",
  algorithm: "",
  secret: "",
  secretBase64Encoded: false,
  payload: "{}",
  JWT_Headers: "{}",
  requestHeaderPrefix: "",
  disableRetrying: "",
  realm: "",
  nonce: "",
  qop: "",
  nonceCount: "",
  clientNonce: "",
  opaque: "",
};

export const AuthTypes: ISelect[] = [
  {
    value: "noAuth",
    option: "No Auth",
  },
  {
    value: "basicAuth",
    option: "Basic Auth",
    subLabel: "Username and password in the header.",
  },
  {
    value: "bearerToken",
    option: "Bearer Token",
    subLabel: "Opaque token in the header for access.",
  },
  {
    value: "JWTBearer",
    option: "JWT Bearer",
    subLabel: "Signed token with claims in the header.",
  },
  {
    value: "digestAuth",
    option: "Digest Auth",
    subLabel: "Hashed credentials with a server nonce.",
  },
];

export const ConnectApiModalTabs: ITabElement[] = [
  {
    name: "parameters",
    element: (props) => <Parameters {...props} />,
  },
  {
    name: "authorization",
    element: (props) => <Authorization {...props} />,
  },
  {
    name: "headers",
    element: (props) => <Headers {...props} />,
  },
  {
    name: "body",
    element: () => <Body />,
  },
];

export const defaultDataRow: KeyRow = {
  id: -1,
  selected: false,
  key: "",
  value: "",
  description: "",
  default: true,
};
