import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  color: #5F6877;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; 
`