import { css, styled } from "styled-components";

const TWO_TO_THREE_RATIO = "66.6%";
const ONE_TO_THREE_RATIO = "33.3%";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  border: 1px solid rgb(211, 219, 227);
  border-radius: 8px;
  background-color: white;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex: 1;
`;

export const TableRow = styled.tr`
  display: flex;
`;

export const TableHeader = styled.th<{ flexGrow2To3?: boolean }>(
  ({ theme: { neutralTextWeak }, flexGrow2To3 }) => css`
    padding: 8px 16px;
    background-color: #9fa4ad14;
    color: ${neutralTextWeak};
    font-weight: 400;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    flex: ${flexGrow2To3 ? TWO_TO_THREE_RATIO : ONE_TO_THREE_RATIO};
  `
);

export const TableCell = styled.td<{
  flexGrow2To3?: boolean;
  flexDirectionColumn?: boolean;
}>(
  ({ theme: { neutralText }, flexGrow2To3, flexDirectionColumn }) => css`
    display: flex;
    flex-direction: ${flexDirectionColumn ? "column" : "row"};
    gap: 4px;
    flex: ${flexGrow2To3 ? TWO_TO_THREE_RATIO : ONE_TO_THREE_RATIO};
    width: 100%;
    padding: 8px 16px;
    border-bottom: 1px solid #dfe1e4;
    color: ${neutralText};
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  `
);

export const TableTitle = styled.h5(
  ({ theme: { neutralText } }) => css`
    color: ${neutralText};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    padding: 12px 16px;
  `
);

export const TaxRate = styled.p<{ isPositiveRate?: boolean }>(
  ({ isPositiveRate }) =>
    css`
      font-size: 14px;
      color: ${isPositiveRate ? "#003E1B" : "#940000"};
    `
);

export const SquareFeatText = styled.p`
  font-size: 13px;
  color: #2e3849;
`;
