import styled, { keyframes } from "styled-components";

import { ReactComponent as FeedBackIcon } from "../../assets/FeedBackIcon.svg";

export const BlockLabelAxe = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 3px 8px 3px;
`;

export const LabelAxe = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralText};
`;

export const HeadingNameAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FeedBackButton = styled(FeedBackIcon)`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  cursor: pointer;
  display: none;
  opacity: 0;
`;

const AparitionDelay = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const WidgetImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px;
  position: relative;
  width: 100%;
  height: 100%;

  &:hover {
    ${FeedBackButton} {
      display: block;
      animation: 0.3s ${AparitionDelay} ease;
      opacity: 1;
    }
  }
`;
