// import { AiLoadingIcon } from "../../../../../assets";
import { Button } from "../../../../Button";

import {
  ResultItem,
  // ResultitemDescription,
  ResultItemHeader,
  ResultItemSubHeader,
  Title,
} from "./styles";
import { FlexContainer } from "../../../../../styles";
import { Message } from "../../types";
import { FC } from "react";
import { splitCamelCase } from "../../utils";
import { MessageTextTypeEnum, MessageTypeEnum } from "../../constants";

interface Props {
  message: Message;
  onPromptClick: (
    action: MessageTypeEnum,
    instruction: any
  ) => void;
}

const SummaryMessage: FC<Props> = ({
  message,
  onPromptClick,
}) => {
  return (
    <FlexContainer $direction="column">
      <Title>{message.elements.find((element) => element.type === MessageTextTypeEnum.TITLE)?.payload as string}</Title>
      <div>
        {message.elements.filter((element) => element.type !== MessageTextTypeEnum.TITLE).map((element) => {
          return (
            <ResultItem key={element?.name}>
              <FlexContainer $justify="space-between" $gap="16px">
                <div>
                  <ResultItemHeader>{splitCamelCase(element?.name)}</ResultItemHeader>
                  <ResultItemSubHeader>
                    {element.payload as string}
                  </ResultItemSubHeader>
                </div>
                <Button
                  name="View"
                  variant="neutral"
                  size="small"
                  onClick={() =>
                    onPromptClick(
                      MessageTypeEnum.GET_TEST,
                      {test_name: element.name, dataset_id: message.payload?.dataset_id}
                    )
                  }
                />
              </FlexContainer>
            </ResultItem>
          );
        })}
      </div>
    </FlexContainer>
  );
};

export default SummaryMessage;